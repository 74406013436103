import { PreviewStore } from "./Preview"
import { DesignerClient } from "@/client"
import { AuthStore } from "./Auth"
import { MediaStore } from "./Media"
import { QueryClient } from "@tanstack/react-query"
import { AppStore } from './App'
import { ThemaStore } from "./Thema"

type TypeMap<T extends Newable<T>> = Map<T, InstanceType<T>>

export class RootStore {
    typeMap: TypeMap<any> = new Map()
    designer: DesignerClient
    query: QueryClient

    auth: AuthStore
    thema: ThemaStore
    preview: PreviewStore
    media: MediaStore
    app: AppStore

    constructor(designer: DesignerClient, query: QueryClient) {
        this.designer = designer
        this.query = query
        this.auth = this.register(new AuthStore(this.designer))
        this.thema = this.register(new ThemaStore(this.designer))
        this.preview = this.register(new PreviewStore())
        this.media = this.register(new MediaStore(this.designer, query))
        this.app = this.register(new AppStore())
    }

    getStoreOf<T>(type: Newable<T>): T | null {
        const store = this.typeMap.get(type)
        return store as T
    }

    private register<T extends object>(store: T) {
        this.typeMap.set(store.constructor, store)
        return store
    }
}