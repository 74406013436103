import { Preview } from "@/app/Preview"
import { observer } from "mobx-react-lite"
import { Header } from "@/app/Header"
import { SettingsSideBar } from "@/app/settings/SettingsSideBar"
import { SaveButton } from "../history/SaveButton"
import { PublishButton } from "../history/PublishButton"
import { useThema } from "@/stores/Thema"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { useUser } from "@/stores/Auth"
import { useState } from "react"
import { useRootStore } from "@/contexts"
import { Button } from "@/components/Button"
import { SearchField } from "@/components/SearchField/SearchField"

export const Themer = observer(() => {
    return <>
        <SettingsSideBar/>
        <div className='flex flex-col flex-grow h-full min-h-0 min-w-0'>
            <Header/>
            <Preview/>
        </div>
        <div className="flex w-[350px] bg-slate-800 text-slate-400 h-full border border-l-slate-500">
            <ThemeVersionList/>
        </div>
    </>
})


const ThemeVersionList = observer(() => {
    const root = useRootStore()
    const thema = useThema()
    const user = useUser()
    const [search, setSearch] = useState<string>()
    const [isPublished, _setIsPublished] = useState(true)
    const [isDraft, _setIsDraft] = useState(true)
    const [offset, setOffset] = useState(0)

    const {data} = useQuery({
        queryKey: ["history", user?.selectedSite.id, {search, offset, isDraft, isPublished}],
        queryFn: async() => {
            return await root.designer.themeList({search: search})
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className="w-full flex flex-col gap-4 px-4 scroller relative bg-inherit">
            <div className="text-xl bg-inherit sticky top-0 py-4 flex flex-col gap-4">
                <span><small>Loaded Theme:</small> <strong>{thema.theme?.name ?? "DRAFT"}</strong></span>
                <div className="flex">
                    <SaveButton/>
                    <PublishButton/>
                </div>
                <SearchField className="dark" onSubmit={setSearch} onClear={() => setSearch("")}/>
            </div>
            <div className="flex flex-col gap-4">
                {data?.map(v => <>
                    <div className="flex flex-col gap-2 bg-slate-900 rounded-md p-4">
                        <div className="text-md"><strong>{v.name}</strong></div>
                        <div className="text-sm">Status: <strong>{v.status}</strong></div>
                        <div className="flex gap-2 justify-between pt-2">
                            <Button variant="outline" onPress={() => thema.restore(v.id)}>LOAD</Button>
                            <PublishButton id={v.id}/>
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    )
})