import { useNavigate } from "@tanstack/react-router"
import { observer } from "mobx-react-lite"
import { IconHelpCircleFilled } from "@tabler/icons-react"
import { SideBarBackButton } from "./SideBarBackButton"

interface SettingsProps {
    title: string
    children: React.ReactElement | React.ReactElement[]
}

export const Settings = observer(({children, title}: SettingsProps) => {
    const navigate = useNavigate()
    return (<div className="flex flex-col w-full divide-y divide-solid divide-gray-700">
        <div className="flex items-center content-center h-[60px] justify-between pr-4">
            <SideBarBackButton onPress={() => navigate({to: "/themer"})} title={title}/>
            <IconHelpCircleFilled className="text-teal-600"/>
        </div>
        {children}
    </div>)
})