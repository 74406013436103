import { Group } from '@/app/themer/Component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_themer/themer/$component')({
    component: GroupRoute
})

function GroupRoute() {
    const params = Route.useParams()
    console.log(params)
    return <Group component={params.component}/>
}
