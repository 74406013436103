export interface FontOption {
    family: string;
    name: string;
}

interface FontsDefinition {
    names: string
    text: string
}

export const formatFonts = (options: string[]): FontsDefinition => {
    const fonts: FontsDefinition = {
        names: '',
        text: '',
    };

    if (!options) return fonts;
    const chars: string[] = []

    const fontsNames = options
        .map(option => {
            return option
                .split('"')
                .join('')
                .split(' ')
                .join('+')
        })
        .join('|')

    options.forEach(option => {
        const name = option.split(' ').join('');
        name.split('').forEach(c => {
            if (!chars.includes(c)) {
                chars.push(c);
            }
        })
    })

    if (fontsNames) fonts.names = fontsNames
    if (chars.length > 0) fonts.text = chars.join('')

    return fonts;
}