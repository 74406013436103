export const WebFonts = [
    {
        "family": "ABeeZee",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf",
            "italic": "https://fonts.gstatic.com/s/abeezee/v22/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN2tOklQ.ttf"
    },
    {
        "family": "ADLaM Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "adlam",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/adlamdisplay/v1/KFOhCnGXkPOLlhx6jD8_b1ZECsHYkYBPY3o.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/adlamdisplay/v1/KFOhCnGXkPOLlhx6jD8_b1ZEOsDSlQ.ttf"
    },
    {
        "family": "AR One Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aronesans/v4/TUZ0zwhrmbFp0Srr_tH6fuSaU5EP1H3r.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWn_9PWw9mg.ttf",
        "axes": [
            {
                "tag": "ARRR",
                "start": 10,
                "end": 60
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Abel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6VhLPJp6qGI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE2VlBOA.ttf"
    },
    {
        "family": "Abhaya Libre",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/abhayalibre/v17/e3tmeuGtX-Co5MNzeAOqinEge0PWovdU4w.ttf",
            "500": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYj2ryqtxI6oYtBA.ttf",
            "600": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYo23yqtxI6oYtBA.ttf",
            "700": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYx2zyqtxI6oYtBA.ttf",
            "800": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEY22_yqtxI6oYtBA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/abhayalibre/v17/e3tmeuGtX-Co5MNzeAOqinEQeknS.ttf"
    },
    {
        "family": "Aboreto",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8blKTeA2yuxSY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8bpKXUBw.ttf"
    },
    {
        "family": "Abril Fatface",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchq-FmiA.ttf"
    },
    {
        "family": "Abyssinica SIL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "ethiopic",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/abyssinicasil/v5/oY1H8ezOqK7iI3rK_45WKoc8J6UZBFOVAXuI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/abyssinicasil/v5/oY1H8ezOqK7iI3rK_45WKoc8J5UYDlc.ttf"
    },
    {
        "family": "Aclonica",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aclonica/v22/K2FyfZJVlfNNSEBXGb7TCI6oBjLz.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aclonica/v22/K2FyfZJVlfNNSEBXGY7SAoo.ttf"
    },
    {
        "family": "Acme",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/acme/v25/RrQfboBx-C5_bx3Lb23lzLk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/acme/v25/RrQfboBx-C5_XxzBaw.ttf"
    },
    {
        "family": "Actor",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO3ekXygtUMIO.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO0elVSw.ttf"
    },
    {
        "family": "Adamina",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwu-reETl4Bno.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwi-vUFQ.ttf"
    },
    {
        "family": "Advent Pro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/adventpro/v28/V8mAoQfxVT4Dvddr_yOwtT2nKb5ZFtI.ttf",
            "italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mCoQfxVT4Dvddr_yOwhT-tLZxcBtItFw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyN_TZAs.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 100,
                "end": 200
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Afacad",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/afacad/v1/6NUX8FKMIQOGaw6qhqYLvO0cyA.ttf",
            "italic": "https://fonts.gstatic.com/s/afacad/v1/6NUV8FKMIQOGaw6ahKwPnugMyM1A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfhXtamE-9g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Afacad Flux",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/afacadflux/v1/9oRKNYYQryMlneUPykRmTvvzM83LHq0O.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/afacadflux/v1/9oRgNYYQryMlneUPykRmTuH4ET0fri4I5rJVT_CWHKDZnskVK5edsUwXVaVgRg.ttf",
        "axes": [
            {
                "tag": "slnt",
                "start": -14,
                "end": 14
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 1000
            }
        ]
    },
    {
        "family": "Agbalumo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/agbalumo/v5/55xvey5uMdT2N37KZcMFirl08KDJ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/agbalumo/v5/55xvey5uMdT2N37KZfMEgL0.ttf"
    },
    {
        "family": "Agdasima",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/agdasima/v4/PN_zRfyxp2f1fUCgAMg6rzjb_-Da.ttf",
            "700": "https://fonts.gstatic.com/s/agdasima/v4/PN_0Rfyxp2f1fUCgAPCGgBzT1PzTz2Mi.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/agdasima/v4/PN_zRfyxp2f1fUCgAPg7pTw.ttf"
    },
    {
        "family": "Aguafina Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aguafinascript/v22/If2QXTv_ZzSxGIO30LemWEOmt1bHqs4pgicOrg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aguafinascript/v22/If2QXTv_ZzSxGIO30LemWEOmt1b3q8Qt.ttf"
    },
    {
        "family": "Akatab",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tifinagh"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/akatab/v7/VuJwdNrK3Z7gqJEPWIz5NIh-YA.ttf",
            "500": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3rKXdPKNiaRpFvg.ttf",
            "600": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3gKLdPKNiaRpFvg.ttf",
            "700": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE35KPdPKNiaRpFvg.ttf",
            "800": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3-KDdPKNiaRpFvg.ttf",
            "900": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE33KHdPKNiaRpFvg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/akatab/v7/VuJwdNrK3Z7gqJE_WYb9.ttf"
    },
    {
        "family": "Akaya Kanadaka",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-PfRS5VBBSSF.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-MfQQZE.ttf"
    },
    {
        "family": "Akaya Telivigala",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uifdLdsIH0YH8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uiTdPXtA.ttf"
    },
    {
        "family": "Akronim",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRp-gBxkFYN-a8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRpygF7lA.ttf"
    },
    {
        "family": "Akshar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/akshar/v9/Yq6V-LyHWTfz9rGyoxRktOdClg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSXYF-9GS8w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Aladin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aladin/v24/ZgNSjPJFPrvJV5f16Sf4pGT2Ng.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aladin/v24/ZgNSjPJFPrvJV5fF6C38.ttf"
    },
    {
        "family": "Alata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alata/v11/PbytFmztEwbIofe6xKcRQEOX.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alata/v11/PbytFmztEwbIoce7zqM.ttf"
    },
    {
        "family": "Alatsi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alatsi/v13/TK3iWkUJAxQ2nLNGHjUHte5fKg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alatsi/v13/TK3iWkUJAxQ2nLN2Hz8D.ttf"
    },
    {
        "family": "Albert Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/albertsans/v1/i7dOIFdwYjGaAMFtZd_QA2ZcalayGhyV.ttf",
            "italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dMIFdwYjGaAMFtZd_QA1ZeYFKQHwyVd3U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5PPrYfy.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Aldrich",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aldrich/v21/MCoTzAn-1s3IGyJMZaAS3pP5H_E.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aldrich/v21/MCoTzAn-1s3IGyJMVaEY2g.ttf"
    },
    {
        "family": "Alef",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "hebrew",
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgrjJbC5FxxbU.ttf",
            "700": "https://fonts.gstatic.com/s/alef/v21/FeVQS0NQpLYglo50L5la2bxii28.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgnjNRDw.ttf"
    },
    {
        "family": "Alegreya",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alegreya/v36/4UaBrEBBsBhlBjvfkRLmzanB44N1.ttf",
            "italic": "https://fonts.gstatic.com/s/alegreya/v36/4UaHrEBBsBhlBjvfkSLkx63j5pN1MwI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alegreya/v36/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hU4_aqm.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Alegreya SC",
        "variants": [
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alegreyasc/v25/taiOGmRtCJ62-O0HhNEa-a6o05E5abe_.ttf",
            "italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiMGmRtCJ62-O0HhNEa-Z6q2ZUbbKe_DGs.ttf",
            "500": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZZc-rUxQqu2FXKD.ttf",
            "500italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4WEySK-UEGKDBz4.ttf",
            "700": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYU_LUxQqu2FXKD.ttf",
            "700italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4Sk0SK-UEGKDBz4.ttf",
            "800": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYI_7UxQqu2FXKD.ttf",
            "800italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4TU3SK-UEGKDBz4.ttf",
            "900": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYs_rUxQqu2FXKD.ttf",
            "900italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4RE2SK-UEGKDBz4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alegreyasc/v25/taiOGmRtCJ62-O0HhNEa-Z6p2ZU.ttf"
    },
    {
        "family": "Alegreya Sans",
        "variants": [
            "100",
            "100italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-30",
        "files": {
            "100": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUt9_-1phKLFgshYDvh6Vwt5TltuGdShm5bsg.ttf",
            "100italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUv9_-1phKLFgshYDvh6Vwt7V9V3G1WpGtLsgu7.ttf",
            "300": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE18imdCqxI.ttf",
            "300italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUo9_-1phKLFgshYDvh6Vwt7V9VFE92jkVHuxKiBA.ttf",
            "regular": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUz9_-1phKLFgshYDvh6Vwt3V1nvEVXlm4.ttf",
            "italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUt9_-1phKLFgshYDvh6Vwt7V9tuGdShm5bsg.ttf",
            "500": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUu9_-1phKLFgshYDvh6Vwt5alOmE18imdCqxI.ttf",
            "500italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUo9_-1phKLFgshYDvh6Vwt7V9VTE52jkVHuxKiBA.ttf",
            "700": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUu9_-1phKLFgshYDvh6Vwt5eFImE18imdCqxI.ttf",
            "700italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEh2jkVHuxKiBA.ttf",
            "800": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE18imdCqxI.ttf",
            "800italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUo9_-1phKLFgshYDvh6Vwt7V9VGEt2jkVHuxKiBA.ttf",
            "900": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE18imdCqxI.ttf",
            "900italic": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUo9_-1phKLFgshYDvh6Vwt7V9VPEp2jkVHuxKiBA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alegreyasans/v25/5aUz9_-1phKLFgshYDvh6Vwt7VxtuA.ttf"
    },
    {
        "family": "Alegreya Sans SC",
        "variants": [
            "100",
            "100italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Dipl8g5FPYtmMg.ttf",
            "100italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGl4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdlgRBH452Mvds.ttf",
            "300": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DuJH0iRrMYJ_K-4.ttf",
            "300italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdXiZhNaB6O-51OA.ttf",
            "regular": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk5v9ixALYs.ttf",
            "italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Bkxl8g5FPYtmMg.ttf",
            "500": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG0iRrMYJ_K-4.ttf",
            "500italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdBidhNaB6O-51OA.ttf",
            "700": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA0iRrMYJ_K-4.ttf",
            "700italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdTiFhNaB6O-51OA.ttf",
            "800": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1Du5D0iRrMYJ_K-4.ttf",
            "800italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdUiJhNaB6O-51OA.ttf",
            "900": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DspC0iRrMYJ_K-4.ttf",
            "900italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxddiNhNaB6O-51OA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bk9l8g.ttf"
    },
    {
        "family": "Aleo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aleo/v14/c4mv1nF8G8_s8ArD0D1ogoY.ttf",
            "italic": "https://fonts.gstatic.com/s/aleo/v14/c4mh1nF8G8_swAjJ1B9tkoZl_Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KJ3GlP8Foo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Alex Brush",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alexbrush/v22/SZc83FzrJKuqFbwMKk6EtUL57DtOmCc.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alexbrush/v22/SZc83FzrJKuqFbwMKk6EhUPz6A.ttf"
    },
    {
        "family": "Alexandria",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alexandria/v3/UMBXrPdDqW66y0Y2usFeWirXArM58BY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TpbGFps.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Alfa Slab One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alfaslabone/v19/6NUQ8FmMKwSEKjnm5-4v-4Jh6dVretWvYmE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alfaslabone/v19/6NUQ8FmMKwSEKjnm5-4v-4Jh2dRhfg.ttf"
    },
    {
        "family": "Alice",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6FcJpA_chzJ0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6GcIrgs.ttf"
    },
    {
        "family": "Alike",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alike/v21/HI_EiYEYI6BIoEjBSZXAQ4-d.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alike/v21/HI_EiYEYI6BIoHjAQ5E.ttf"
    },
    {
        "family": "Alike Angular",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alikeangular/v25/3qTrojWunjGQtEBlIcwMbSoI3kM6bB7FKjE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alikeangular/v25/3qTrojWunjGQtEBlIcwMbSoI7kIwaA.ttf"
    },
    {
        "family": "Alkalami",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi5eLw8BMuvhH.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi6eKycQ.ttf"
    },
    {
        "family": "Alkatra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "devanagari",
            "latin",
            "latin-ext",
            "oriya"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alkatra/v3/r05bGLZA5qhCYsyJdOuD5jokU8E.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48Medzngmu4cOptQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Allan",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/allan/v24/ea8XadU7WuTxEtb2P9SF8nZE.ttf",
            "700": "https://fonts.gstatic.com/s/allan/v24/ea8aadU7WuTxEu5KEPCN2WpNgEKU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/allan/v24/ea8XadU7WuTxEub3NdA.ttf"
    },
    {
        "family": "Allerta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940UnEdSQqO5uY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940YnAXTQ.ttf"
    },
    {
        "family": "Allerta Stencil",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/allertastencil/v22/HTx0L209KT-LmIE9N7OR6eiycOeF-zz313DuvQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/allertastencil/v22/HTx0L209KT-LmIE9N7OR6eiycOe1-jbz.ttf"
    },
    {
        "family": "Allison",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZOCaQ4MTgAgk.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZCCea5A.ttf"
    },
    {
        "family": "Allura",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/allura/v21/9oRPNYsQpS4zjuAPjAIXPtrrGA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/allura/v21/9oRPNYsQpS4zjuA_jQgT.ttf"
    },
    {
        "family": "Almarai",
        "variants": [
            "300",
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/almarai/v13/tssoApxBaigK_hnnS_anhnicoq72sXg.ttf",
            "regular": "https://fonts.gstatic.com/s/almarai/v13/tsstApxBaigK_hnnc1qPonC3vqc.ttf",
            "700": "https://fonts.gstatic.com/s/almarai/v13/tssoApxBaigK_hnnS-aghnicoq72sXg.ttf",
            "800": "https://fonts.gstatic.com/s/almarai/v13/tssoApxBaigK_hnnS_qjhnicoq72sXg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/almarai/v13/tsstApxBaigK_hnnQ1uFpg.ttf"
    },
    {
        "family": "Almendra",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/almendra/v26/H4ckBXKAlMnTn0CskyY6wr-wg763.ttf",
            "italic": "https://fonts.gstatic.com/s/almendra/v26/H4ciBXKAlMnTn0CskxY4yLuShq63czE.ttf",
            "700": "https://fonts.gstatic.com/s/almendra/v26/H4cjBXKAlMnTn0Cskx6G7Zu4qKK-aihq.ttf",
            "700italic": "https://fonts.gstatic.com/s/almendra/v26/H4chBXKAlMnTn0CskxY48Ae9oqacbzhqDtg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/almendra/v26/H4ckBXKAlMnTn0CskxY7yLs.ttf"
    },
    {
        "family": "Almendra Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/almendradisplay/v31/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS_eTyer338.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/almendradisplay/v31/0FlPVOGWl1Sb4O3tETtADHRRlZhzbS7USw.ttf"
    },
    {
        "family": "Almendra SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/almendrasc/v29/Iure6Yx284eebowr7hbyTZZJprVA4XQ0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/almendrasc/v29/Iure6Yx284eebowr7hbyTaZIrLE.ttf"
    },
    {
        "family": "Alumni Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alumnisans/v18/nwpQtKqkOwdO2aOIwhWudF-i5QwyYdrc.ttf",
            "italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpStKqkOwdO2aOIwhWudG-g7wgQZMrc9HY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9gqVEN.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Alumni Sans Collegiate One",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhjdayDiPw2ta.ttf",
            "italic": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpD-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhgdYwjytxntaDFU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhgdbwjw.ttf"
    },
    {
        "family": "Alumni Sans Inline One",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpD3AZcr7xbYw.ttf",
            "italic": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQDbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpP3ITdpz0fYxcrQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpP3ETdg.ttf"
    },
    {
        "family": "Alumni Sans Pinstripe",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObhezYrVIpcDA5w.ttf",
            "italic": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNDjOFFPq_AUJD1umyS30W-Xub8zD1ObheDYL9Mh8XQ5_cY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDY79M.ttf"
    },
    {
        "family": "Amarante",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amarante/v28/xMQXuF1KTa6EvGx9bq-3C3rAmD-b.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amarante/v28/xMQXuF1KTa6EvGx9bp-2AX4.ttf"
    },
    {
        "family": "Amaranth",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zPnCF9GqwnzW.ttf",
            "italic": "https://fonts.gstatic.com/s/amaranth/v18/KtkoALODe433f0j1zMnAHdWIx2zWD4I.ttf",
            "700": "https://fonts.gstatic.com/s/amaranth/v18/KtkpALODe433f0j1zMF-OPWi6WDfFpuc.ttf",
            "700italic": "https://fonts.gstatic.com/s/amaranth/v18/KtkrALODe433f0j1zMnAJWmn42T9E4ucRY8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zMnDHdU.ttf"
    },
    {
        "family": "Amatic SC",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amaticsc/v26/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf",
            "700": "https://fonts.gstatic.com/s/amaticsc/v26/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amaticsc/v26/TUZyzwprpvBS1izr_vOEDOSa.ttf"
    },
    {
        "family": "Amethysta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfWIGDWCBl0O8Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfW4GT-G.ttf"
    },
    {
        "family": "Amiko",
        "variants": [
            "regular",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amiko/v14/WwkQxPq1DFK04tqlc17MMZgJ.ttf",
            "600": "https://fonts.gstatic.com/s/amiko/v14/WwkdxPq1DFK04uJ9XXrEGoQAUco5.ttf",
            "700": "https://fonts.gstatic.com/s/amiko/v14/WwkdxPq1DFK04uIZXHrEGoQAUco5.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amiko/v14/WwkQxPq1DFK04uqkeVo.ttf"
    },
    {
        "family": "Amiri",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHqUpvrIw74NL.ttf",
            "italic": "https://fonts.gstatic.com/s/amiri/v27/J7afnpd8CGxBHpUrtLYS6pNLAjk.ttf",
            "700": "https://fonts.gstatic.com/s/amiri/v27/J7acnpd8CGxBHp2VkZY4xJ9CGyAa.ttf",
            "700italic": "https://fonts.gstatic.com/s/amiri/v27/J7aanpd8CGxBHpUrjAo9zptgHjAavCA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHpUotLY.ttf"
    },
    {
        "family": "Amiri Quran",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amiriquran/v14/_Xmo-Hk0rD6DbUL4_vH8Zq5t7Cycsu-2.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amiriquran/v14/_Xmo-Hk0rD6DbUL4_vH8Zp5s5ig.ttf",
        "colorCapabilities": [
            "COLRv0",
            "SVG"
        ]
    },
    {
        "family": "Amita",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/amita/v18/HhyaU5si9Om7PQlvAfSKEZZL.ttf",
            "700": "https://fonts.gstatic.com/s/amita/v18/HhyXU5si9Om7PTHTLtCCOopCTKkI.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/amita/v18/HhyaU5si9Om7PTluC_A.ttf"
    },
    {
        "family": "Anaheim",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anaheim/v15/8vII7w042Wp87g4G0UTUEE5eK_w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anaheim/v15/8vIX7w042Wp87g4Gy0_24JbCiPrl-h5sLprEKEY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Andada Pro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/andadapro/v21/HhyRU5Qi9-SuOEhPe4LtMI5gSbkI5_E.ttf",
            "italic": "https://fonts.gstatic.com/s/andadapro/v21/HhyTU5Qi9-SuOEhPe4LtAIxqTZsN9_Et3Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/andadapro/v21/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBb8dHrg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 840
            }
        ]
    },
    {
        "family": "Andika",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/andika/v25/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf",
            "italic": "https://fonts.gstatic.com/s/andika/v25/mem9Ya6iyW-Lwqgwb7YVeLkWVNBt.ttf",
            "700": "https://fonts.gstatic.com/s/andika/v25/mem8Ya6iyW-Lwqg40ZM1UpcaXcl0Aw.ttf",
            "700italic": "https://fonts.gstatic.com/s/andika/v25/mem6Ya6iyW-Lwqgwb46pV50ef8xkA76a.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/andika/v25/mem_Ya6iyW-LwqgwbLYV.ttf"
    },
    {
        "family": "Anek Bangla",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekbangla/v5/_gP81R38qTExHg-17BhM6mSxYPp7oSNy.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9ZEoYq4w.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Devanagari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyS7nP0CGrUsxB-QiRgw0NlLaV39ifscRzoQA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtkt9mHEk.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Gujarati",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekgujarati/v9/l7gZbj5oysqknvkCo2T_8FuiOxtiArlM4k8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5CLwkik.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Gurmukhi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QImMXRO_YSkA0quVLY79JnH07z8_ApHqqk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5tZXaQx.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Kannada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekkannada/v5/rax6HiCNvNMKe1CKFsINYFl6m2Dc-T-EKQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDETulXxM.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Latin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aneklatin/v5/co3DmWZulTRoU4a8dqrWk6Pjp3Di8U0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7ApKNqh.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Malayalam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLZKZActRTs_mZAJUZWWkhke1PTSRciY1M1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu-LMvzOA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Odia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "oriya"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anekodia/v6/TK3hWkoJARApz5UCd34jvcVDI5S01g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZfp3nV5Q.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Tamil",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anektamil/v9/XLYjIZH2bYJHGYtPGSbUHcloon8vVXg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQidKqoH8.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Anek Telugu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anektelugu/v8/LhWhMVrUNvsddMtYGCx4Fd9dGNWQg_am.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--kEyM3Q.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Angkor",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v32",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/angkor/v32/H4cmBXyAlsPdnlb-8iw-4Lqggw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/angkor/v32/H4cmBXyAlsPdnlbO8yY6.ttf"
    },
    {
        "family": "Annapurna SIL",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLv0hDY0f2iu9tPmRWtllid8NN9dZT_PZs.ttf",
            "700": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLy0hDY0f2iu9tPmRWtllidyG9SUZzUIZJ008A.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLv0hDY0f2iu9tPmRWtllidwNJ3cQ.ttf"
    },
    {
        "family": "Annie Use Your Telescope",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlDfB3UUVZA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGTlT3F.ttf"
    },
    {
        "family": "Anonymous Pro",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf",
            "italic": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf",
            "700": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf",
            "700italic": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pHl028A.ttf"
    },
    {
        "family": "Anta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anta/v1/gyBzhwQ3KsIyZFwxPFimIo0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anta/v1/gyBzhwQ3KsIyVF07OA.ttf"
    },
    {
        "family": "Antic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRaZLodgzydtk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRZZKq9w.ttf"
    },
    {
        "family": "Antic Didone",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDiqYsGBGBzCw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDSqIEC.ttf"
    },
    {
        "family": "Antic Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IWcJWXW5p5Qo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IacNcWQ.ttf"
    },
    {
        "family": "Anton",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm0K08i4gS7lu.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3K1-Co.ttf"
    },
    {
        "family": "Anton SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/antonsc/v1/4UaBrEBBsgltGn71sxLmzanB44N1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/antonsc/v1/4UaBrEBBsgltGn71syLnx60.ttf"
    },
    {
        "family": "Antonio",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/antonio/v19/gNMEW3NwSYq_9WD3-HMoFIez5MI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8StJaWQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Anuphan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anuphan/v4/2sDeZGxYgY7LkLT0qW0Ja029G7w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anuphan/v4/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCkY9T4lEGE.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Anybody",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/anybody/v11/VuJxdNvK2Ib2ppdWeKbXOIFneRo.ttf",
            "italic": "https://fonts.gstatic.com/s/anybody/v11/VuJzdNvK2Ib2ppdWSKTdPKNiaRpFvg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12D_r8_H.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 50,
                "end": 150
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Aoboshi One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aoboshione/v11/Gg8xN5kXaAXtHQrFxwl10ysLBmZX_UEg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aoboshione/v11/Gg8xN5kXaAXtHQrFxwl10xsKDGI.ttf"
    },
    {
        "family": "Arapey",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RC6Z9AcZkIzeg.ttf",
            "italic": "https://fonts.gstatic.com/s/arapey/v16/-W_9XJn-UDDA2RCKZdoYREcjeo0k.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RCKZtoY.ttf"
    },
    {
        "family": "Arbutus",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arbutus/v28/NaPYcZ7dG_5J3poob9JtryO8fMU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arbutus/v28/NaPYcZ7dG_5J3pooX9Nnqw.ttf"
    },
    {
        "family": "Arbutus Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZVa68dJlaFAQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZlaqUZ.ttf"
    },
    {
        "family": "Architects Daughter",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTY9g5g.ttf"
    },
    {
        "family": "Archivo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/archivo/v19/k3kQo8UDI-1M0wlSTd7iL0nAMaM.ttf",
            "italic": "https://fonts.gstatic.com/s/archivo/v19/k3kSo8UDI-1M0wlSfdzoK2vFIaOV8A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNZ8Rds.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Archivo Black",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/archivoblack/v21/HTxqL289NzCGg4MzN6KJ7eW6OYuP_x7yx3A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/archivoblack/v21/HTxqL289NzCGg4MzN6KJ7eW6CYqF-w.ttf"
    },
    {
        "family": "Archivo Narrow",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/archivonarrow/v30/tss0ApVBdCYD5Q7hcxTE1ArZ0Yb3g31S2s8p.ttf",
            "italic": "https://fonts.gstatic.com/s/archivonarrow/v30/tss2ApVBdCYD5Q7hcxTE1ArZ0bb1iXlw398pJxk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvLFG6pXmp.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Are You Serious",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/areyouserious/v12/ll8kK2GVSSr-PtjQ5nONVcNn4306hT9nCGRayg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/areyouserious/v12/ll8kK2GVSSr-PtjQ5nONVcNn430KhDVj.ttf"
    },
    {
        "family": "Aref Ruqaa",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkbxPW1E165rajQKDulEIAiVNo5xNY.ttf",
            "700": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkYxPW1E165rajQKDulKDwNcNIS2N_7Bdk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkbxPW1E165rajQKDulIIEoUA.ttf"
    },
    {
        "family": "Aref Ruqaa Ink",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2fY5WOGUFlt84GTOkP6Kdx72ThVIGpgnxL.ttf",
            "700": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2cY5WOGUFlt84GTOkP6Kdx71xde6WhqWBCyxWn.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2fY5WOGUFlt84GTOkP6Kdx71TgXoU.ttf",
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Arima",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "malayalam",
            "tamil",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arima/v5/neIFzCqmt4Aup-CP9IGON7Ez.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTAOpAuK.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Arimo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arimo/v29/P5sMzZCDf9_T_20eziBMjI-u.ttf",
            "italic": "https://fonts.gstatic.com/s/arimo/v29/P5sCzZCDf9_T_10cxCRuiZ-uydg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcBhrE.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Arizonia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arizonia/v21/neIIzCemt4A5qa7mv6WGHK06UY30.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arizonia/v21/neIIzCemt4A5qa7mv5WHFqk.ttf"
    },
    {
        "family": "Armata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/armata/v20/gokvH63_HV5jQ-E9lD53Q2u_mQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/armata/v20/gokvH63_HV5jQ-ENlTRz.ttf"
    },
    {
        "family": "Arsenal",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D11_WAewrhXY.ttf",
            "italic": "https://fonts.gstatic.com/s/arsenal/v12/wXKpE3kQtZQ4pF3D513cBc4ulXYrtA.ttf",
            "700": "https://fonts.gstatic.com/s/arsenal/v12/wXKuE3kQtZQ4pF3D7-P5JeQAmX8yrdk.ttf",
            "700italic": "https://fonts.gstatic.com/s/arsenal/v12/wXKsE3kQtZQ4pF3D513kueEKnV03vdnKjw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D517cBQ.ttf"
    },
    {
        "family": "Arsenal SC",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arsenalsc/v1/x3dlckLHea6e5BEtsfxiXNossybsHQI.ttf",
            "italic": "https://fonts.gstatic.com/s/arsenalsc/v1/x3dnckLHea6e5BEtsfxibNgmtwTpDQIrGg.ttf",
            "700": "https://fonts.gstatic.com/s/arsenalsc/v1/x3dmckLHea6e5BEtsfxiZGYDly7HAQsyA2Y.ttf",
            "700italic": "https://fonts.gstatic.com/s/arsenalsc/v1/x3d4ckLHea6e5BEtsfxibNgeCyvNBSk3E2YljQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arsenalsc/v1/x3dlckLHea6e5BEtsfxibNsmtw.ttf"
    },
    {
        "family": "Artifika",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/artifika/v21/VEMyRoxzronptCuxu6Wt5jDtreOL.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/artifika/v21/VEMyRoxzronptCuxu5Ws7DQ.ttf"
    },
    {
        "family": "Arvo",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arvo/v22/tDbD2oWUg0MKmSAa7Lzr7vs.ttf",
            "italic": "https://fonts.gstatic.com/s/arvo/v22/tDbN2oWUg0MKqSIQ6J7u_vvijQ.ttf",
            "700": "https://fonts.gstatic.com/s/arvo/v22/tDbM2oWUg0MKoZw1yLTA8vL7lAE.ttf",
            "700italic": "https://fonts.gstatic.com/s/arvo/v22/tDbO2oWUg0MKqSIoVLHK9tD-hAHkGg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arvo/v22/tDbD2oWUg0MKqSEQ6A.ttf"
    },
    {
        "family": "Arya",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJd9Ub1-beqdFE.ttf",
            "700": "https://fonts.gstatic.com/s/arya/v19/ga6NawNG-HJdzfra3b-BaFg3dRE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJdxUf__Q.ttf"
    },
    {
        "family": "Asap",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/asap/v30/KFOoCniXp96a-zwU4UROGzY.ttf",
            "italic": "https://fonts.gstatic.com/s/asap/v30/KFOmCniXp96ayz4e5WZLCzYlKw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUcL2mT.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Asap Condensed",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9DSWlEgGqgp-pO.ttf",
            "200italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUIFFim6CovpOkXA.ttf",
            "300": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8nSmlEgGqgp-pO.ttf",
            "300italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUOVGim6CovpOkXA.ttf",
            "regular": "https://fonts.gstatic.com/s/asapcondensed/v17/pxidypY1o9NHyXh3WvSbGSggdNeLYk1Mq3ap.ttf",
            "italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxifypY1o9NHyXh3WvSbGSggdOeJaElurmapvvM.ttf",
            "500": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9_S2lEgGqgp-pO.ttf",
            "500italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUL1Him6CovpOkXA.ttf",
            "600": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9TTGlEgGqgp-pO.ttf",
            "600italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUJFAim6CovpOkXA.ttf",
            "700": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO83TWlEgGqgp-pO.ttf",
            "700italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUPVBim6CovpOkXA.ttf",
            "800": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8rTmlEgGqgp-pO.ttf",
            "800italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUOlCim6CovpOkXA.ttf",
            "900": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8PT2lEgGqgp-pO.ttf",
            "900italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUM1Dim6CovpOkXA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/asapcondensed/v17/pxidypY1o9NHyXh3WvSbGSggdOeKaEk.ttf"
    },
    {
        "family": "Asar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIXkYQDLlTW6E.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIbkcaCA.ttf"
    },
    {
        "family": "Asset",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/asset/v29/SLXGc1na-mM4cWImRJqExst1.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/asset/v29/SLXGc1na-mM4cVInTp4.ttf"
    },
    {
        "family": "Assistant",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/assistant/v19/2sDcZGJYnIjSi6H75xkDb2-4C7wFZQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGCV2g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Astloch",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjq9wRzMtkH1Q.ttf",
            "700": "https://fonts.gstatic.com/s/astloch/v26/TuGUUVJ8QI5GSeUjk2A-6MNPA10xLMQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjm90byA.ttf"
    },
    {
        "family": "Asul",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/asul/v21/VuJ-dNjKxYr46fMFXK78JIg.ttf",
            "700": "https://fonts.gstatic.com/s/asul/v21/VuJxdNjKxYr40U8qeKbXOIFneRo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/asul/v21/VuJ-dNjKxYr42fIPWA.ttf"
    },
    {
        "family": "Athiti",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAxDNyAv2-C99ycg.ttf",
            "300": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAoDByAv2-C99ycg.ttf",
            "regular": "https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1w4DBhWCtaiAg.ttf",
            "500": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA-DFyAv2-C99ycg.ttf",
            "600": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA1DZyAv2-C99ycg.ttf",
            "700": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAsDdyAv2-C99ycg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1wIDRJS.ttf"
    },
    {
        "family": "Atkinson Hyperlegible",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45GE5ZgpewSSbQ.ttf",
            "italic": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt43C1KxNDXMspQ1lPyU89-1h6ONRlW45G055ItWQGCbUWn.ttf",
            "700": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt73C1KxNDXMspQ1lPyU89-1h6ONRlW45G8WbcNcy-OZFy-FA.ttf",
            "700italic": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt93C1KxNDXMspQ1lPyU89-1h6ONRlW45G056qRdiWKRlmuFH24.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45G05JIt.ttf"
    },
    {
        "family": "Atma",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo8JzKjc9PvedRkM.ttf",
            "regular": "https://fonts.gstatic.com/s/atma/v16/uK_84rqWc-Eom25bDj8WIv4.ttf",
            "500": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo5pyKjc9PvedRkM.ttf",
            "600": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo7Z1Kjc9PvedRkM.ttf",
            "700": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo9J0Kjc9PvedRkM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/atma/v16/uK_84rqWc-Eoq29RCg.ttf"
    },
    {
        "family": "Atomic Age",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL58Ht9a8GYeA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL18Dn8Q.ttf"
    },
    {
        "family": "Aubrey",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7vugNsCxVEgA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7fuwlo.ttf"
    },
    {
        "family": "Audiowide",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/audiowide/v20/l7gdbjpo0cum0ckerWCtkQXPExpQBw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/audiowide/v20/l7gdbjpo0cum0ckerWCdkA_L.ttf"
    },
    {
        "family": "Autour One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbk5lWVscxdKE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbo5hcUg.ttf"
    },
    {
        "family": "Average",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rIeJwVWytTyk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rEeN6UQ.ttf"
    },
    {
        "family": "Average Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJPBdqazVoK4A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJ_BNCe.ttf"
    },
    {
        "family": "Averia Gruesa Libre",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmmJw0SLRA8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZYmiDxw.ttf"
    },
    {
        "family": "Averia Libre",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEarovtb07t-pQgTw.ttf",
            "300italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFhbUTp2JEwT4Sk.ttf",
            "regular": "https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjEiAqPJZ2Xx8w.ttf",
            "italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0EKIcMGZEnV6xygz7eNjESAKnNRWDh8405.ttf",
            "700": "https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEavoztb07t-pQgTw.ttf",
            "700italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFxakTp2JEwT4Sk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjESA6nN.ttf"
    },
    {
        "family": "Averia Sans Libre",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd3lMKcQJZP1LmD9.ttf",
            "300italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKisSL5fXK3D9qtg.ttf",
            "regular": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJGIMYDo_8.ttf",
            "italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6RaxZG_G5OvCf_rt7FH3B6BHLMEdVLEoc6C5_8N3k.ttf",
            "700": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N6cQJZP1LmD9.ttf",
            "700italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKjsVL5fXK3D9qtg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEdVIEoc.ttf"
    },
    {
        "family": "Averia Serif Libre",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGCSmqwacqdrKvbQ.ttf",
            "300italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzMmw60uVLe_bXHq.ttf",
            "regular": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQGOyYw2fw.ttf",
            "italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIUzD2ms4wxr6GvjeD0X88SHPyX2xYOpwuK64kmf6u2.ttf",
            "700": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6qwacqdrKvbQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzM2xK0uVLe_bXHq.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIWzD2ms4wxr6GvjeD0X88SHPyX2xYOpAuK.ttf"
    },
    {
        "family": "Azeret Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-EHmb_jU3eRL.ttf",
            "italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XFsErsiyJsY9O_Gepph-HHkZfz22PRLd0U.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVR07ye.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "B612",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz32jm4GDigUXw.ttf",
            "italic": "https://fonts.gstatic.com/s/b612/v12/3Jn8SDDxiSz36juyHBqlQXwdVw.ttf",
            "700": "https://fonts.gstatic.com/s/b612/v12/3Jn9SDDxiSz34oWXPDCLTXUETuE.ttf",
            "700italic": "https://fonts.gstatic.com/s/b612/v12/3Jn_SDDxiSz36juKoDWBSVcBXuFb0Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz36jiyHA.ttf"
    },
    {
        "family": "B612 Mono",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/b612mono/v14/kmK_Zq85QVWbN1eW6lJl1wTcquRTtg.ttf",
            "italic": "https://fonts.gstatic.com/s/b612mono/v14/kmK5Zq85QVWbN1eW6lJV1Q7YiOFDtqtf.ttf",
            "700": "https://fonts.gstatic.com/s/b612mono/v14/kmK6Zq85QVWbN1eW6lJdayv4os9Pv7JGSg.ttf",
            "700italic": "https://fonts.gstatic.com/s/b612mono/v14/kmKkZq85QVWbN1eW6lJV1TZkp8VLnbdWSg4x.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/b612mono/v14/kmK_Zq85QVWbN1eW6lJV1g7Y.ttf"
    },
    {
        "family": "BIZ UDGothic",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "greek-ext",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bizudgothic/v12/daafSTouBF7RUjnbt8p3LuKttQN98z_MbQ.ttf",
            "700": "https://fonts.gstatic.com/s/bizudgothic/v12/daaASTouBF7RUjnbt8p3LuKVCSxZ-xTQZMhbaA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bizudgothic/v12/daafSTouBF7RUjnbt8p3LuKdtAl5.ttf"
    },
    {
        "family": "BIZ UDMincho",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "greek-ext",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bizudmincho/v11/EJRRQgI6eOxFjBdKs38yhtW1dwT7rcpY8Q.ttf",
            "700": "https://fonts.gstatic.com/s/bizudmincho/v11/EJROQgI6eOxFjBdKs38yhtWNyyvfpeFE-IyCrw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bizudmincho/v11/EJRRQgI6eOxFjBdKs38yhtWFdg7_.ttf"
    },
    {
        "family": "BIZ UDPGothic",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "greek-ext",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bizudpgothic/v14/hES36X5pHAIBjmS84VL0Bue83nUMQWkMUAk.ttf",
            "700": "https://fonts.gstatic.com/s/bizudpgothic/v14/hESq6X5pHAIBjmS84VL0Bue85skjZWEnTABCSQo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bizudpgothic/v14/hES36X5pHAIBjmS84VL0Bue87nQGRQ.ttf"
    },
    {
        "family": "BIZ UDPMincho",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "greek-ext",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bizudpmincho/v11/ypvfbXOBrmYppy7oWWTg1_58nhhYtUb0gZk.ttf",
            "700": "https://fonts.gstatic.com/s/bizudpmincho/v11/ypvCbXOBrmYppy7oWWTg1_58pqR3kU7fnZAy57k.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bizudpmincho/v11/ypvfbXOBrmYppy7oWWTg1_58rhlSsQ.ttf"
    },
    {
        "family": "Babylonica",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/babylonica/v5/5aUw9_i2qxWVCAE2aHjTqDJ0-VVMoEw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/babylonica/v5/5aUw9_i2qxWVCAE2aHjTmDN-_Q.ttf"
    },
    {
        "family": "Bacasime Antique",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bacasimeantique/v1/tDbX2pGXkFYEykldjZSrmI6T_XWZOwStSUrV_BE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bacasimeantique/v1/tDbX2pGXkFYEykldjZSrmI6T_XWZCwWnTQ.ttf"
    },
    {
        "family": "Bad Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7lOwuzd1AZtw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7pO0kyQ.ttf"
    },
    {
        "family": "Bagel Fat One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bagelfatone/v1/hYkPPucsQOr5dy02WmQr5Zkd0B5mvv0dSbM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bagelfatone/v1/hYkPPucsQOr5dy02WmQr5Zkd4B9sug.ttf"
    },
    {
        "family": "Bahiana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bahiana/v23/uU9PCBUV4YenPWJU7xPb3vyHmlI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bahiana/v23/uU9PCBUV4YenPWJU3xLR2g.ttf"
    },
    {
        "family": "Bahianita",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bahianita/v21/yYLr0hTb3vuqqsBUgxWtxTvV2NJPcA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bahianita/v21/yYLr0hTb3vuqqsBUgxWdxDHR.ttf"
    },
    {
        "family": "Bai Jamjuree",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0kePuk5A1-yiSgA.ttf",
            "200italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oGkpox2S2CgOva.ttf",
            "300": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa09eDuk5A1-yiSgA.ttf",
            "300italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pikZox2S2CgOva.ttf",
            "regular": "https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoaMWcjKm7sp8g.ttf",
            "italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIrapSCOBt_aeQQ7ftydoa8W8LOub458jGL.ttf",
            "500": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0reHuk5A1-yiSgA.ttf",
            "500italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_o6kJox2S2CgOva.ttf",
            "600": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0gebuk5A1-yiSgA.ttf",
            "600italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oWl5ox2S2CgOva.ttf",
            "700": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa05efuk5A1-yiSgA.ttf",
            "700italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pylpox2S2CgOva.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoa8WMLO.ttf"
    },
    {
        "family": "Bakbak One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bakbakone/v8/zOL54pXAl6RI-p_ardnuycRuv-hHkOs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bakbakone/v8/zOL54pXAl6RI-p_ardnu-cVkuw.ttf"
    },
    {
        "family": "Ballet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ballet/v27/QGYvz_MYZA-HM4N5s0Frc4H0ng.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ballet/v27/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3O4C0Fg.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 16,
                "end": 72
            }
        ]
    },
    {
        "family": "Baloo 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloo2/v21/wXKrE3kTposypRyd11_WAewrhXY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdgazZpu_lI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Bhai 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlDdRSL-z1VEWZ4YNA7Y5I3cdTmiH1gFQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNighAXaIpw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Bhaijaan 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMMIZmdd_qFmo.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyRSpP5JY4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Bhaina 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "oriya",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWczB6yyq4P9Adr3RtoX1q6yShz7mDUoupoI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEssPfRP5P.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Chettan 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8udRbmXEva26PK-NtuX4ynWEzf4P17OpYDlg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CeKTC1sUGw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Da 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/balooda2/v24/2-ci9J9j0IaUMQZwAJyJcu7XoZFDf2Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjALsQNf7ZI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Paaji 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dMIFFzbz-QHZUdV9_UGWZuYFKQHwyVd3U.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9AX77fzZxE.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Tamma 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/balootamma2/v16/vEFX2_hCAgcR46PaajtrYlBbT0g21tqeR7c.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPq-1Klo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Tammudu 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt2g8TIS_SAmkLguUdFP8UaJcK-xXEW6aGXHw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jf8e0c-FYQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Baloo Thambi 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9cfjeOW0NHpmOQXranrbDyu4hHBJOxZQPp.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKzcIDaA5C.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Balsamiq Sans",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sEzZiAbNrN8SB3lQQX7Pnc8dkdIYdNHzs.ttf",
            "italic": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sazZiAbNrN8SB3lQQX7PncwdsXJaVIDzvcXA.ttf",
            "700": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sZzZiAbNrN8SB3lQQX7PncyWUyBY9mAzLFRQI.ttf",
            "700italic": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sfzZiAbNrN8SB3lQQX7PncwdsvmYpsBxDAVQI4aA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sEzZiAbNrN8SB3lQQX7PncwdgXJQ.ttf"
    },
    {
        "family": "Balthazar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEvyRTo39l8hw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEfyB7s.ttf"
    },
    {
        "family": "Bangers",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bangers/v24/FeVQS0BTqb0h60ACL5la2bxii28.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bangers/v24/FeVQS0BTqb0h60ACH5hQ3Q.ttf"
    },
    {
        "family": "Barlow",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E3b8s8yn4hnCci.ttf",
            "100italic": "https://fonts.gstatic.com/s/barlow/v12/7cHtv4kjgoGqM7E_CfNYwHoDmTcibrA.ttf",
            "200": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3w-oc4FAtlT47dw.ttf",
            "200italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfP04Voptzsrd6m9.ttf",
            "300": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3p-kc4FAtlT47dw.ttf",
            "300italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOQ4loptzsrd6m9.ttf",
            "regular": "https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf",
            "italic": "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Ccs8yn4hnCci.ttf",
            "500": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf",
            "500italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPI41optzsrd6m9.ttf",
            "600": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf",
            "600italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPk5Foptzsrd6m9.ttf",
            "700": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf",
            "700italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5Voptzsrd6m9.ttf",
            "800": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3q-0c4FAtlT47dw.ttf",
            "800italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOc5loptzsrd6m9.ttf",
            "900": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3j-wc4FAtlT47dw.ttf",
            "900italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfO451optzsrd6m9.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7E_Css8.ttf"
    },
    {
        "family": "Barlow Condensed",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B43LT31vytKgbaw.ttf",
            "100italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxzL3I-JCGChYJ8VI-L6OO_au7B6xTru1H2lq0La6JN.ttf",
            "200": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B497y_3HcuKECcrs.ttf",
            "200italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrF3DWvIMHYrtUxg.ttf",
            "300": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47rx_3HcuKECcrs.ttf",
            "300italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrc3PWvIMHYrtUxg.ttf",
            "regular": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xbZ23n3pKg.ttf",
            "italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT31vytKgbaw.ttf",
            "500": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4-Lw_3HcuKECcrs.ttf",
            "500italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrK3LWvIMHYrtUxg.ttf",
            "600": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3HcuKECcrs.ttf",
            "600italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrB3XWvIMHYrtUxg.ttf",
            "700": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3HcuKECcrs.ttf",
            "700italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvIMHYrtUxg.ttf",
            "800": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47b1_3HcuKECcrs.ttf",
            "800italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrf3fWvIMHYrtUxg.ttf",
            "900": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B45L0_3HcuKECcrs.ttf",
            "900italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrW3bWvIMHYrtUxg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xfT3w.ttf"
    },
    {
        "family": "Barlow Semi Condensed",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfG4qvKk8ogoSP.ttf",
            "100italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpjgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbLLIEsKh5SPZWs.ttf",
            "200": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRft6uPAGEki52WfA.ttf",
            "200italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJnAWsgqZiGfHK5.ttf",
            "300": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAGEki52WfA.ttf",
            "300italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAmsgqZiGfHK5.ttf",
            "regular": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnf4CrCEo4gg.ttf",
            "italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfYqvKk8ogoSP.ttf",
            "500": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAGEki52WfA.ttf",
            "500italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA2sgqZiGfHK5.ttf",
            "600": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAGEki52WfA.ttf",
            "600italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BGsgqZiGfHK5.ttf",
            "700": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAGEki52WfA.ttf",
            "700italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbITBWsgqZiGfHK5.ttf",
            "800": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAGEki52WfA.ttf",
            "800italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIPBmsgqZiGfHK5.ttf",
            "900": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAGEki52WfA.ttf",
            "900italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sgqZiGfHK5.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfoqv.ttf"
    },
    {
        "family": "Barriecito",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_KdUiYwTO0MU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_GdQoZw.ttf"
    },
    {
        "family": "Barrio",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDiEdQYhWdsX1Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDi0dAwl.ttf"
    },
    {
        "family": "Basic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKQN34lDVyR7D.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKTN26FQ.ttf"
    },
    {
        "family": "Baskervville",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baskervville/v16/YA9Ur0yU4l_XOrogbkun3kQgt5OohvbJ9A.ttf",
            "italic": "https://fonts.gstatic.com/s/baskervville/v16/YA9Kr0yU4l_XOrogbkun3kQQtZmspPPZ9Mlt.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baskervville/v16/YA9Ur0yU4l_XOrogbkun3kQQtpms.ttf"
    },
    {
        "family": "Baskervville SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baskervvillesc/v1/X7n94bc_DeKlh6bBbk_WiKnBSUvR71R3tiSx0g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baskervvillesc/v1/X7n94bc_DeKlh6bBbk_WiKnBSUvh7l5z.ttf"
    },
    {
        "family": "Battambang",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/battambang/v24/uk-kEGe7raEw-HjkzZabNhGp5w50_o9T7Q.ttf",
            "300": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNtmLxyRa8oZK9I0.ttf",
            "regular": "https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabDnWj4yxx7o8.ttf",
            "700": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNsmMxyRa8oZK9I0.ttf",
            "900": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNvGOxyRa8oZK9I0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabPnSp5w.ttf"
    },
    {
        "family": "Baumans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3QfpR_oyaksqY5Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3Qfpd_s4bg.ttf"
    },
    {
        "family": "Bayon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v34",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bayon/v34/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bayon/v34/9XUrlJNmn0LPFm-oMBI.ttf"
    },
    {
        "family": "Be Vietnam Pro",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVNSTAyLFyeg_IDWvOJmVES_HRUBX8YYbAiah8.ttf",
            "100italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsSZZIneh-waA.ttf",
            "200": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HT4JF8yT7wrcwap.ttf",
            "200italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczRbgJdhapcUU.ttf",
            "300": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HScJ18yT7wrcwap.ttf",
            "300italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwRbgJdhapcUU.ttf",
            "regular": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_EwwD3s6ZKAi.ttf",
            "italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVNSTAyLFyeg_IDWvOJmVES_HwyBX8YYbAiah8.ttf",
            "500": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJl8yT7wrcwap.ttf",
            "500italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxRbgJdhapcUU.ttf",
            "600": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HToIV8yT7wrcwap.ttf",
            "600italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2RbgJdhapcUU.ttf",
            "700": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HSMIF8yT7wrcwap.ttf",
            "700italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3RbgJdhapcUU.ttf",
            "800": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HSQI18yT7wrcwap.ttf",
            "800italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80RbgJdhapcUU.ttf",
            "900": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HS0Il8yT7wrcwap.ttf",
            "900italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1RbgJdhapcUU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_HwxBX8.ttf"
    },
    {
        "family": "Beau Rivage",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUlmg3NZp8K2sL.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUllg2P54.ttf"
    },
    {
        "family": "Bebas Neue",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXoo9W9hzg.ttf"
    },
    {
        "family": "Beiruti",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/beiruti/v2/JTUXjIU69Cmr9FGceA9n4WZA1g8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/beiruti/v2/JTUIjIU69Cmr9FGcYgRFEb7cdQn2-9a95zuii2w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Belanosima",
        "variants": [
            "regular",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/belanosima/v3/3y9k6bI8ejDo_3MfCDSLxABbF3JBg54.ttf",
            "600": "https://fonts.gstatic.com/s/belanosima/v3/3y9n6bI8ejDo_3MfCDSL_Nh1M3pqn5cdJ-4.ttf",
            "700": "https://fonts.gstatic.com/s/belanosima/v3/3y9n6bI8ejDo_3MfCDSL_Lx0M3pqn5cdJ-4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/belanosima/v3/3y9k6bI8ejDo_3MfCDSL9AFREw.ttf"
    },
    {
        "family": "Belgrano",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZcMFirl08KDJ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZfMEgL0.ttf"
    },
    {
        "family": "Bellefair",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG19__A2pOdvDA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG1N_voy.ttf"
    },
    {
        "family": "Belleza",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4BtcbyawzruP8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4Bhcf4bw.ttf"
    },
    {
        "family": "Bellota",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55kGMViblPtXs.ttf",
            "300italic": "https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEfjZtKpXulTQ.ttf",
            "regular": "https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAGJJRtGs-lbA.ttf",
            "italic": "https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBbsEk7hbBWrA.ttf",
            "700": "https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-kGMViblPtXs.ttf",
            "700italic": "https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYfjZtKpXulTQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJNbsA.ttf"
    },
    {
        "family": "Bellota Text",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/bellotatext/v18/0FlMVP2VnlWS4f3-UE9hHXM5VfsqfQXwQy6yxg.ttf",
            "300italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlOVP2VnlWS4f3-UE9hHXMx--Gmfw_0YSuixmYK.ttf",
            "regular": "https://fonts.gstatic.com/s/bellotatext/v18/0FlTVP2VnlWS4f3-UE9hHXMB-dMOdS7sSg.ttf",
            "italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlNVP2VnlWS4f3-UE9hHXMx-9kKVyv8Sjer.ttf",
            "700": "https://fonts.gstatic.com/s/bellotatext/v18/0FlMVP2VnlWS4f3-UE9hHXM5RfwqfQXwQy6yxg.ttf",
            "700italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlOVP2VnlWS4f3-UE9hHXMx--G2eA_0YSuixmYK.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bellotatext/v18/0FlTVP2VnlWS4f3-UE9hHXMx-NkK.ttf"
    },
    {
        "family": "BenchNine",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf",
            "regular": "https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf",
            "700": "https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosbVslx.ttf"
    },
    {
        "family": "Benne",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6Vjxlt6qTDBN.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6WjwnNo.ttf"
    },
    {
        "family": "Bentham",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglKWw7CJaK_y4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglGW0xDA.ttf"
    },
    {
        "family": "Berkshire Swash",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/berkshireswash/v20/ptRRTi-cavZOGqCvnNJDl5m5XmNPrcQybX4pQA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/berkshireswash/v20/ptRRTi-cavZOGqCvnNJDl5m5XmN_rM42.ttf"
    },
    {
        "family": "Besley",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/besley/v20/PlI8FlO1MaNwaNGMWw2G-H1cnA.ttf",
            "italic": "https://fonts.gstatic.com/s/besley/v20/PlI-FlO1MaNwaNG8WQeC2nhMnNy5.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/besley/v20/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBedViEA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Beth Ellen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bethellen/v21/WwkbxPW2BE-3rb_JNT-qEIAiVNo5xNY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bethellen/v21/WwkbxPW2BE-3rb_JNT-qIIEoUA.ttf"
    },
    {
        "family": "Bevan",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bevan/v24/4iCj6KZ0a9NXjF8aUir7tlSJ.ttf",
            "italic": "https://fonts.gstatic.com/s/bevan/v24/4iCt6KZ0a9NXjG8YWC7Zs0SJD4U.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bevan/v24/4iCj6KZ0a9NXjG8bWC4.ttf"
    },
    {
        "family": "BhuTuka Expanded One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bhutukaexpandedone/v7/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJCyX8pg_RVH1.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bhutukaexpandedone/v7/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJByW-Jw.ttf"
    },
    {
        "family": "Big Shoulders Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1_PZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1sy9rvLpMc2g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87FF8wTQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Big Shoulders Inline Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LObmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CAtiniAptAoU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE0R4wvM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Big Shoulders Inline Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8kdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NKSr6H1I1A9U.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y5GM54o.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Big Shoulders Stencil Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeq4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPysiQ2HMJz0ji.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0j_KXbj.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Big Shoulders Stencil Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUg9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcbz9ambTsehi.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04T4GCXL.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Big Shoulders Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xxezRtP9G3CGPIf49hxc8P0eytUxBsnIx7QMISdQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q7TMFMg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Bigelow Rules",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigelowrules/v29/RrQWboly8iR_I3KWSzeRuN0zT4cCH8WAJVk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigelowrules/v29/RrQWboly8iR_I3KWSzeRuN0zf4YIGw.ttf"
    },
    {
        "family": "Bigshot One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bigshotone/v29/u-470qukhRkkO6BD_7cM_gxuUQJBXv_-.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bigshotone/v29/u-470qukhRkkO6BD_7cM_jxvWwY.ttf"
    },
    {
        "family": "Bilbo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ210hpIRz4wxE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ220groA.ttf"
    },
    {
        "family": "Bilbo Swash Caps",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAPopiRfKp8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdmALiog.ttf"
    },
    {
        "family": "BioRhyme",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/biorhyme/v17/1cXwaULHBpDMsHYW_HxGpVWIgNit.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OjIiqieujw.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 100,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "BioRhyme Expanded",
        "variants": [
            "200",
            "300",
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9LjbffxxcblSHSdTXrb_z.ttf",
            "300": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9Ljbffxw4bVSHSdTXrb_z.ttf",
            "regular": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dQIE1zZzytGswgU577CDY9LjbffySURXCPYsje.ttf",
            "700": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9LjbffxwoalSHSdTXrb_z.ttf",
            "800": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9Ljbffxw0aVSHSdTXrb_z.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dQIE1zZzytGswgU577CDY9LjbffxSVT3Q.ttf"
    },
    {
        "family": "Birthstone",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/birthstone/v14/8AtsGs2xO4yLRhy87sv_HLn5jRfZHzM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/birthstone/v14/8AtsGs2xO4yLRhy87sv_LLjziQ.ttf"
    },
    {
        "family": "Birthstone Bounce",
        "variants": [
            "regular",
            "500"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6XaxZF43lIvTWrktHOTBJZGH7dEeVJGIMYDo_8.ttf",
            "500": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6SaxZF43lIvTWrktHOTBJZGH7dEd29MacQJZP1LmD9.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6XaxZF43lIvTWrktHOTBJZGH7dEdVIEoc.ttf"
    },
    {
        "family": "Biryani",
        "variants": [
            "200",
            "300",
            "regular",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddYQyGTBSU-J-RxQ.ttf",
            "300": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddeAxGTBSU-J-RxQ.ttf",
            "regular": "https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdTUwZPTh5T-s.ttf",
            "600": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddZQ3GTBSU-J-RxQ.ttf",
            "700": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddfA2GTBSU-J-RxQ.ttf",
            "800": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddew1GTBSU-J-RxQ.ttf",
            "900": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddcg0GTBSU-J-RxQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdfU0TOQ.ttf"
    },
    {
        "family": "Bitter",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bitter/v36/rax8HiqOu8IVPmnLeIZoDDlCmg.ttf",
            "italic": "https://fonts.gstatic.com/s/bitter/v36/rax-HiqOu8IVPmn7eoxsLjxSmlLZ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bitter/v36/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfOL7OWA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Black And White Picture",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blackandwhitepicture/v24/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5HbcMi-yWY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blackandwhitepicture/v24/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqL5GZ8c.ttf"
    },
    {
        "family": "Black Han Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blackhansans/v17/ea8Aad44WunzF9a-dL6toA8r8nqVIXSkH-Hc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blackhansans/v17/ea8Aad44WunzF9a-dL6toA8r8kqUK3A.ttf"
    },
    {
        "family": "Black Ops One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3WDzRtjkho4M.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aD3bsg.ttf"
    },
    {
        "family": "Blaka",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blaka/v7/8vIG7w8722p_6kdr20D2FV5e.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blaka/v7/8vIG7w8722p_6ndq0UQ.ttf"
    },
    {
        "family": "Blaka Hollow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blakahollow/v7/MCoUzAL91sjRE2FsKsxUtezYB9oFyW_-oA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blakahollow/v7/MCoUzAL91sjRE2FsKsxUtezoBtAB.ttf"
    },
    {
        "family": "Blaka Ink",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/blakaink/v8/AlZy_zVVtpj22Znag2chdXf4XB0Tow.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blakaink/v8/AlZy_zVVtpj22Znag2cRdH38.ttf",
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Blinker",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/blinker/v13/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf",
            "200": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf",
            "300": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf",
            "regular": "https://fonts.gstatic.com/s/blinker/v13/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf",
            "600": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf",
            "700": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf",
            "800": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf",
            "900": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/blinker/v13/cIf9MaFatEE-VTaP9CihZA.ttf"
    },
    {
        "family": "Bodoni Moda",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bodonimoda/v25/aFTQ7PxzY382XsXX63LUYKSNQqn0X0BO.ttf",
            "italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFTS7PxzY382XsXX63LUYJSPSK3WWlBOoas.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7a8IF4sQ.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 6,
                "end": 96
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Bodoni Moda SC",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bodonimodasc/v1/LYjbdGTykkIgA8197UwkzHp8F__fcpC69i6N.ttf",
            "italic": "https://fonts.gstatic.com/s/bodonimodasc/v1/LYjZdGTykkIgA8197UwkzHp8F8_deJSY8z6Np1k.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bodonimodasc/v1/LYjhdGTykkIgA8197UwkzHp8F-XUUG5UNs2KqliEb-g_DaUdIA6SfiYHbA7ubJ2R8A.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 6,
                "end": 96
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Bokor",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiR2WdInbcaxE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiS2Xfo0.ttf"
    },
    {
        "family": "Bona Nova",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bonanova/v11/B50NF7ZCpX7fcHfvIUBJi6hqHK-CLA.ttf",
            "italic": "https://fonts.gstatic.com/s/bonanova/v11/B50LF7ZCpX7fcHfvIUB5iaJuPqqSLJYf.ttf",
            "700": "https://fonts.gstatic.com/s/bonanova/v11/B50IF7ZCpX7fcHfvIUBxN4dOFISeJY8GgQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bonanova/v11/B50NF7ZCpX7fcHfvIUB5iqJu.ttf"
    },
    {
        "family": "Bona Nova SC",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bonanovasc/v1/mem5YaShyGWDiYdPG_c1Af4-VeJoCqeDjg.ttf",
            "italic": "https://fonts.gstatic.com/s/bonanovasc/v1/memnYaShyGWDiYdPG_c1Af4OV-hsKKKTjrPW.ttf",
            "700": "https://fonts.gstatic.com/s/bonanovasc/v1/memmYaShyGWDiYdPG_c1Af4G6c1MAoyfh6rPXA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bonanovasc/v1/mem5YaShyGWDiYdPG_c1Af4OVOhs.ttf"
    },
    {
        "family": "Bonbon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bonbon/v30/0FlVVPeVlFec4ee_cDEAbQY5-A.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bonbon/v30/0FlVVPeVlFec4eePcTsE.ttf"
    },
    {
        "family": "Bonheur Royale",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bonheurroyale/v13/c4m51nt_GMTrtX-b9GcG4-YRmYK_c0f1N5Ij.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bonheurroyale/v13/c4m51nt_GMTrtX-b9GcG4-YRmbK-eUM.ttf"
    },
    {
        "family": "Boogaloo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/boogaloo/v23/kmK-Zq45GAvOdnaW6x1F_SrQo_1K.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/boogaloo/v23/kmK-Zq45GAvOdnaW6y1E9y4.ttf"
    },
    {
        "family": "Borel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/borel/v3/6qLOKZsftAPisgshYyMnOjwE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/borel/v3/6qLOKZsftAPisjsgaSc.ttf"
    },
    {
        "family": "Bowlby One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bowlbyone/v23/taiPGmVuC4y96PFeqp8smo6C_Z0wcK4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bowlbyone/v23/taiPGmVuC4y96PFeqp8sqo-I-Q.ttf"
    },
    {
        "family": "Bowlby One SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bowlbyonesc/v25/DtVlJxerQqQm37tzN3wMug9Pzgj8owhNjuE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bowlbyonesc/v25/DtVlJxerQqQm37tzN3wMug9P_gn2pw.ttf"
    },
    {
        "family": "Braah One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/braahone/v6/KFOlCnWUpt6LsxxxiylvAx05IsDqlA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/braahone/v6/KFOlCnWUpt6LsxxxiylfAhc9.ttf"
    },
    {
        "family": "Brawler",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGsgC7S9XdZN8.ttf",
            "700": "https://fonts.gstatic.com/s/brawler/v19/xn7lYHE3xXewAscGiryUb932eNaPfk8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGggGxTw.ttf"
    },
    {
        "family": "Bree Serif",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluAx63j5pN1MwI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluA96zp4g.ttf"
    },
    {
        "family": "Bricolage Grotesque",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y996as8bTXq_nANBjzKo3IeZx8z6up5H--HGN6NLPo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvRvi-Mwltw.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 12,
                "end": 96
            },
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Bruno Ace",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/brunoace/v5/WwkcxPa2E06x4trkOj_kMKoMWNMg3Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/brunoace/v5/WwkcxPa2E06x4trkOj_UMaAI.ttf"
    },
    {
        "family": "Bruno Ace SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/brunoacesc/v5/ptROTiycffFLBuiHjdJDl634LSFrpe8uZA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/brunoacesc/v5/ptROTiycffFLBuiHjdJDl63ILCtv.ttf"
    },
    {
        "family": "Brygada 1918",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/brygada1918/v25/pe0pMI6eKpdGqlF5LANrM--aA_Rue1UwVg.ttf",
            "italic": "https://fonts.gstatic.com/s/brygada1918/v25/pe03MI6eKpdGqlF5LANrM--qAf5qWVAgVol-.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/brygada1918/v25/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-fyV4cvw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Bubblegum Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bubblegumsans/v20/AYCSpXb_Z9EORv1M5QTjEzMEtdaHzoPPb7R4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bubblegumsans/v20/AYCSpXb_Z9EORv1M5QTjEzMEteaGxIc.ttf"
    },
    {
        "family": "Bubbler One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bubblerone/v21/f0Xy0eqj68ppQV9KBLmAouHH26MPePkt.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bubblerone/v21/f0Xy0eqj68ppQV9KBLmAotHG0ac.ttf"
    },
    {
        "family": "Buda",
        "variants": [
            "300"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/buda/v29/GFDqWAN8mnyIJSSrG7UBr7pZKA0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/buda/v29/GFDqWAN8mnyIJSSrK7QLqw.ttf"
    },
    {
        "family": "Buenard",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsPzf9qGi9HL4.ttf",
            "700": "https://fonts.gstatic.com/s/buenard/v17/OD5GuM6Cyma8FnnsB4vSjGCWALepwss.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsDzb3rA.ttf"
    },
    {
        "family": "Bungee",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungee/v14/N0bU2SZBIuF2PU_ECn50Kd_PmA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungee/v14/N0bU2SZBIuF2PU_0C3Rw.ttf"
    },
    {
        "family": "Bungee Hairline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeehairline/v23/snfys0G548t04270a_ljTLUVrv-7YB2dQ5ZPqQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeehairline/v23/snfys0G548t04270a_ljTLUVrv-LYReZ.ttf"
    },
    {
        "family": "Bungee Inline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeeinline/v16/Gg8zN58UcgnlCweMrih332VuDGJ1-FEglsc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeeinline/v16/Gg8zN58UcgnlCweMrih332VuPGN__A.ttf"
    },
    {
        "family": "Bungee Outline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeeoutline/v21/_6_mEDvmVP24UvU2MyiGDslL3Qg3YhJqPXxo.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeeoutline/v21/_6_mEDvmVP24UvU2MyiGDslL3Tg2aBY.ttf"
    },
    {
        "family": "Bungee Shade",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeeshade/v14/DtVkJxarWL0t2KdzK3oI_jks7iLSrwFUlw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeeshade/v14/DtVkJxarWL0t2KdzK3oI_jkc7yjW.ttf"
    },
    {
        "family": "Bungee Spice",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeespice/v12/nwpTtK2nIhxE0q-IwgSpZBqCzyI-aMPF7Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeespice/v12/nwpTtK2nIhxE0q-IwgSpZBqyzig6.ttf",
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Bungee Tint",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/bungeetint/v1/J7abnpl_EGtUEuAJwN9WmrtKMDwTpTkB.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/bungeetint/v1/J7abnpl_EGtUEuAJwN9WmotLOjg.ttf",
        "colorCapabilities": [
            "COLRv0"
        ]
    },
    {
        "family": "Butcherman",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb1zWzq_5uT84.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb5zS5rw.ttf"
    },
    {
        "family": "Butterfly Kids",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/butterflykids/v25/ll8lK2CWTjuqAsXDqlnIbMNs5S4arxFrAX1D.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/butterflykids/v25/ll8lK2CWTjuqAsXDqlnIbMNs5R4bpRU.ttf"
    },
    {
        "family": "Cabin",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cabin/v27/u-4x0qWljRw-Pe839fxqmjRv.ttf",
            "italic": "https://fonts.gstatic.com/s/cabin/v27/u-4_0qWljRw-Pd81__hInyRvYwc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EH7Clwg.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Cabin Condensed",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpMtK6mNhBK2err_hqkYhHRqmwaYOjZ5HZl8Q.ttf",
            "500": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwilMH97F15-K1oqQ.ttf",
            "600": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwiuMb97F15-K1oqQ.ttf",
            "700": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwi3Mf97F15-K1oqQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpMtK6mNhBK2err_hqkYhHRqmwqYeLd.ttf"
    },
    {
        "family": "Cabin Sketch",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cabinsketch/v21/QGYpz_kZZAGCONcK2A4bGOjMn9JM6fnuKg.ttf",
            "700": "https://fonts.gstatic.com/s/cabinsketch/v21/QGY2z_kZZAGCONcK2A4bGOj0I_1o4dLyI4CMFw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cabinsketch/v21/QGYpz_kZZAGCONcK2A4bGOj8nthI.ttf"
    },
    {
        "family": "Cactus Classical Serif",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cactusclassicalserif/v5/sZlVdQ6K-zJOCzUaS90zMNN-Ep-OoC8dZr0JFuBIFX-pv-E.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cactusclassicalserif/v5/sZlVdQ6K-zJOCzUaS90zMNN-Ep-OoC8dZr0JJuFCEQ.ttf"
    },
    {
        "family": "Caesar Dressing",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrR3cbb6LZttyg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrRHcLz-.ttf"
    },
    {
        "family": "Cagliostro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TjGXEM4COoE4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TvGTONw.ttf"
    },
    {
        "family": "Cairo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cairo/v28/SLXGc1nY6HkvamImRJqExst1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-a1XiKQ.ttf",
        "axes": [
            {
                "tag": "slnt",
                "start": -11,
                "end": 11
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 1000
            }
        ]
    },
    {
        "family": "Cairo Play",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cairoplay/v7/wXKuE3QSpo4vpRz_mz6FJeQAmX8yrdk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYa9i_e.ttf",
        "axes": [
            {
                "tag": "slnt",
                "start": -11,
                "end": 11
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 1000
            }
        ],
        "colorCapabilities": [
            "COLRv0"
        ]
    },
    {
        "family": "Caladea",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUjR93-9IztOc.ttf",
            "italic": "https://fonts.gstatic.com/s/caladea/v7/kJExBugZ7AAjhybUvR19__A2pOdvDA.ttf",
            "700": "https://fonts.gstatic.com/s/caladea/v7/kJE2BugZ7AAjhybUtaNY39oYqO52FZ0.ttf",
            "700italic": "https://fonts.gstatic.com/s/caladea/v7/kJE0BugZ7AAjhybUvR1FQ98SrMxzBZ2lDA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUvR59_w.ttf"
    },
    {
        "family": "Calistoga",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/calistoga/v16/6NUU8F2OJg6MeR7l4e0vtMYAwdRZfw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/calistoga/v16/6NUU8F2OJg6MeR7l4e0ftcwE.ttf"
    },
    {
        "family": "Calligraffitti",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vnFZM5ZBpYN-.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vkFYOZI.ttf"
    },
    {
        "family": "Cambay",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDsGbrSIz9JsaA.ttf",
            "italic": "https://fonts.gstatic.com/s/cambay/v12/SLXLc1rY6H0_ZDs2bL6M7dd8aGZk.ttf",
            "700": "https://fonts.gstatic.com/s/cambay/v12/SLXKc1rY6H0_ZDs-0pusx_lwYX99kA.ttf",
            "700italic": "https://fonts.gstatic.com/s/cambay/v12/SLXMc1rY6H0_ZDs2bIYwwvN0Q3ptkDMN.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDs2b76M.ttf"
    },
    {
        "family": "Cambo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cambo/v18/IFSqHeNEk8FJk416ok7xkPm8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cambo/v18/IFSqHeNEk8FJk717qEo.ttf"
    },
    {
        "family": "Candal",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8cNAR4Jt9Yxlw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8c9ABQN.ttf"
    },
    {
        "family": "Cantarell",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO01Ji6hqHK-CLA.ttf",
            "italic": "https://fonts.gstatic.com/s/cantarell/v17/B50LF7ZDq37KMUvlO015iaJuPqqSLJYf.ttf",
            "700": "https://fonts.gstatic.com/s/cantarell/v17/B50IF7ZDq37KMUvlO01xN4dOFISeJY8GgQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/cantarell/v17/B50WF7ZDq37KMUvlO015iZrSEY6aB4oWgWHB.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO015iqJu.ttf"
    },
    {
        "family": "Cantata One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslVxEt8CwfGaD.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslWxFvcQ.ttf"
    },
    {
        "family": "Cantora One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX6zdZ4vZVbgZ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX5zcbY8.ttf"
    },
    {
        "family": "Caprasimo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caprasimo/v5/esDT31JQOPuXIUGBp72klZUCGpG-GQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caprasimo/v5/esDT31JQOPuXIUGBp72UlJ8G.ttf"
    },
    {
        "family": "Capriola",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/capriola/v14/wXKoE3YSppcvo1PDln_8L-AinG8y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/capriola/v14/wXKoE3YSppcvo1PDlk_9JeQ.ttf"
    },
    {
        "family": "Caramel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_ShyxCRuiZ-uydg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_Shy9CVkjQ.ttf"
    },
    {
        "family": "Carattere",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvTt2aMH4V_gg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvjtmyI.ttf"
    },
    {
        "family": "Cardo",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqiv_1oAZ2H5O.ttf",
            "italic": "https://fonts.gstatic.com/s/cardo/v19/wlpxgwjKBV1pqhv93IQ73W5OcCk.ttf",
            "700": "https://fonts.gstatic.com/s/cardo/v19/wlpygwjKBV1pqhND-aQR82JHaTBX.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqhv-3IQ.ttf"
    },
    {
        "family": "Carlito",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carlito/v3/3Jn9SDPw3m-pk039PDCLTXUETuE.ttf",
            "italic": "https://fonts.gstatic.com/s/carlito/v3/3Jn_SDPw3m-pk039DDKBSVcBXuFb0Q.ttf",
            "700": "https://fonts.gstatic.com/s/carlito/v3/3Jn4SDPw3m-pk039BIykaX0vUuhCyOo.ttf",
            "700italic": "https://fonts.gstatic.com/s/carlito/v3/3Jn6SDPw3m-pk039DDK59XglVspH2OprMQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carlito/v3/3Jn9SDPw3m-pk039DDGBSQ.ttf"
    },
    {
        "family": "Carme",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDOjGxLNrxfbZ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDNjHzrc.ttf"
    },
    {
        "family": "Carrois Gothic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9amC1Co.ttf"
    },
    {
        "family": "Carrois Gothic SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28nNHabY4dN.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-18mPnI.ttf"
    },
    {
        "family": "Carter One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNIxR2hYxREMs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNExV8gQ.ttf"
    },
    {
        "family": "Castoro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/castoro/v19/1q2GY5yMCld3-O4cHYhEzOYenEU.ttf",
            "italic": "https://fonts.gstatic.com/s/castoro/v19/1q2EY5yMCld3-O4cLYpOyMQbjEX5fw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/castoro/v19/1q2GY5yMCld3-O4cLYlOyA.ttf"
    },
    {
        "family": "Castoro Titling",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/castorotitling/v8/buEupouwccj03leTfjUAhEZWlrNqYgckeo9RMw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/castorotitling/v8/buEupouwccj03leTfjUAhEZWlrNaYw0g.ttf"
    },
    {
        "family": "Catamaran",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/catamaran/v19/o-0IIpQoyXQa2RxT7-5b4j5Ba_2c7A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd5ajdvg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Caudex",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caudex/v17/esDQ311QOP6BJUrIyviAnb4eEw.ttf",
            "italic": "https://fonts.gstatic.com/s/caudex/v17/esDS311QOP6BJUr4yPKEv7sOE4in.ttf",
            "700": "https://fonts.gstatic.com/s/caudex/v17/esDT311QOP6BJUrwdteklZUCGpG-GQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/caudex/v17/esDV311QOP6BJUr4yMo4kJ8GOJSuGdLB.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caudex/v17/esDQ311QOP6BJUr4y_KE.ttf"
    },
    {
        "family": "Caveat",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caveat/v18/Wnz6HAc5bAfYB2QLYTwZqg_MPQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIOpZA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Caveat Brush",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXtMW7mT03pdQw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXt8WrOX.ttf"
    },
    {
        "family": "Cedarville Cursive",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJbBxw8BeTg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_nuJLp1.ttf"
    },
    {
        "family": "Ceviche One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX6zdZ4vZVbgZ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX5zcbY8.ttf"
    },
    {
        "family": "Chakra Petch",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeNIhFQJXE3AY00g.ttf",
            "300italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpLJQp_A_gMk0izH.ttf",
            "regular": "https://fonts.gstatic.com/s/chakrapetch/v11/cIf6MapbsEk7TDLdtEz1BwkmmKBhSL7Y1Q.ttf",
            "italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfkMapbsEk7TDLdtEz1BwkWmqplarvI1R8t.ttf",
            "500": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkebIlFQJXE3AY00g.ttf",
            "500italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpKRQ5_A_gMk0izH.ttf",
            "600": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeQI5FQJXE3AY00g.ttf",
            "600italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpK9RJ_A_gMk0izH.ttf",
            "700": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeJI9FQJXE3AY00g.ttf",
            "700italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpLZRZ_A_gMk0izH.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chakrapetch/v11/cIf6MapbsEk7TDLdtEz1BwkWmapl.ttf"
    },
    {
        "family": "Changa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/changa/v27/2-cm9JNi2YuVOUcUYZa_Wu_lpA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xcjHj8g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Changa One",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/changaone/v20/xfu00W3wXn3QLUJXhzq46AbouLfbK64.ttf",
            "italic": "https://fonts.gstatic.com/s/changaone/v20/xfu20W3wXn3QLUJXhzq42ATivJXeO67ISw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/changaone/v20/xfu00W3wXn3QLUJXhzq42AfivA.ttf"
    },
    {
        "family": "Chango",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chango/v27/2V0cKI0OB5U7WaJyz324TFUaAw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chango/v27/2V0cKI0OB5U7WaJCzne8.ttf"
    },
    {
        "family": "Charis SIL",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/charissil/v2/oPWK_kV3l-s-Q8govXvKrPrmYjZ2Xn0.ttf",
            "italic": "https://fonts.gstatic.com/s/charissil/v2/oPWI_kV3l-s-Q8govXvKnPjsZhRzTn2Ozw.ttf",
            "700": "https://fonts.gstatic.com/s/charissil/v2/oPWJ_kV3l-s-Q8govXvKlEbJRj5dQnSX1ko.ttf",
            "700italic": "https://fonts.gstatic.com/s/charissil/v2/oPWX_kV3l-s-Q8govXvKnPjU2jtXRlaSxkrMCQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/charissil/v2/oPWK_kV3l-s-Q8govXvKnPvsZg.ttf"
    },
    {
        "family": "Charm",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/charm/v11/7cHmv4oii5K0MeYvIe804WIo.ttf",
            "700": "https://fonts.gstatic.com/s/charm/v11/7cHrv4oii5K0Md6TDss8yn4hnCci.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/charm/v11/7cHmv4oii5K0MdYuK-s.ttf"
    },
    {
        "family": "Charmonman",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiWJGovLdh6OE.ttf",
            "700": "https://fonts.gstatic.com/s/charmonman/v18/MjQAmiR3vP_nuxDv47jiYC2HmL9K9OhmGnY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiaJCiuA.ttf"
    },
    {
        "family": "Chathura",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v20",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/chathura/v20/_gP91R7-rzUuVjim42dEq0SbTvZyuDo.ttf",
            "300": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eMiWSxYPp7oSNy.ttf",
            "regular": "https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim418goUC5S-Zy.ttf",
            "700": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42ecjmSxYPp7oSNy.ttf",
            "800": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eAjWSxYPp7oSNy.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim428hq0Q.ttf"
    },
    {
        "family": "Chau Philomene One",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnIx7QMISdQ.ttf",
            "italic": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xzezRsPtfie1vPY49qzdgSlJiHRQFcnoZ_YscCdXQB.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFcnYZ_.ttf"
    },
    {
        "family": "Chela One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIy31vWNTMwoQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIC3lHS.ttf"
    },
    {
        "family": "Chelsea Market",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKBhlLA4uKkWk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKChkJgo.ttf"
    },
    {
        "family": "Chenla",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer"
        ],
        "version": "v25",
        "lastModified": "2022-09-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9eXxfonUPL6Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9uXh3s.ttf"
    },
    {
        "family": "Cherish",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cherish/v8/ll88K2mXUyqsDsTN5iDCI6IJjg8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cherish/v8/ll88K2mXUyqsDsTN1iHIJw.ttf"
    },
    {
        "family": "Cherry Bomb One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cherrybombone/v9/y83DW4od1h6KlV3c6JJhRhGOdhrKDNpF41fr-w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cherrybombone/v9/y83DW4od1h6KlV3c6JJhRhGOdhr6DdBB.ttf"
    },
    {
        "family": "Cherry Cream Soda",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6Cd47DJ7G.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciQd7A9o.ttf"
    },
    {
        "family": "Cherry Swash",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cherryswash/v20/i7dNIFByZjaNAMxtZcnfAy58QHi-EwWMbg.ttf",
            "700": "https://fonts.gstatic.com/s/cherryswash/v20/i7dSIFByZjaNAMxtZcnfAy5E_FeaGy6QZ3WfYg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cherryswash/v20/i7dNIFByZjaNAMxtZcnfAy5MQXK6.ttf"
    },
    {
        "family": "Chewy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wk5xIDMfO-ed.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wn5wKjc.ttf"
    },
    {
        "family": "Chicle",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chicle/v25/lJwG-pw9i2dqU-BDyWKuobYSxw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chicle/v25/lJwG-pw9i2dqU-BzyGiq.ttf"
    },
    {
        "family": "Chilanka",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chilanka/v20/WWXRlj2DZQiMJYaYRrJQI9EAZhTO.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chilanka/v20/WWXRlj2DZQiMJYaYRoJRKdU.ttf"
    },
    {
        "family": "Chivo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chivo/v18/va9I4kzIxd1KFoBvS-J3kbDP.ttf",
            "italic": "https://fonts.gstatic.com/s/chivo/v18/va9G4kzIxd1KFrBtQeZVlKDPWTY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB_elmrW.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Chivo Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chivomono/v9/mFT0WbgRxKvF_Z5eQMO9sxgJ1EJ7i90.ttf",
            "italic": "https://fonts.gstatic.com/s/chivomono/v9/mFT2WbgRxKvF_Z5eQMO9gxoD0GB-m91P3Q.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hr5fUIJw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Chocolate Classical Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chocolateclassicalsans/v5/nuFqD-PLTZX4XIgT-P2ToCDudWHHflqUpTpfjWdDPI2J9mHITw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chocolateclassicalsans/v5/nuFqD-PLTZX4XIgT-P2ToCDudWHHflqUpTpfjWdzPYeN.ttf"
    },
    {
        "family": "Chokokutai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chokokutai/v10/kmK4Zqw4HwvCeHGM8Fws9y7ypu1Kr7I.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chokokutai/v10/kmK4Zqw4HwvCeHGM8Fwsxy_4og.ttf"
    },
    {
        "family": "Chonburi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/chonburi/v12/8AtqGs-wOpGRTBq66IWaFr3biAfZ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/chonburi/v12/8AtqGs-wOpGRTBq66LWbHLk.ttf"
    },
    {
        "family": "Cinzel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cinzel/v23/8vIJ7ww63mVu7gtL8W76HEdHMg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnfYvlCA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Cinzel Decorative",
        "variants": [
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaCSScvJGqLYhG8nNt8KPPswUAPnh7URs1LaCyC.ttf",
            "700": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaHSScvJGqLYhG8nNt8KPPswUAPniZoaelDQzCLlQXE.ttf",
            "900": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaHSScvJGqLYhG8nNt8KPPswUAPniZQa-lDQzCLlQXE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaCSScvJGqLYhG8nNt8KPPswUAPni7VTMk.ttf"
    },
    {
        "family": "Clicker Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq72rY2zqUKafv.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq71rZ0T4.ttf"
    },
    {
        "family": "Climate Crisis",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/climatecrisis/v8/wEOkEB3AntNeKCPBVW9XOKlmp2ofo7fHQOnM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/climatecrisis/v8/wEOpEB3AntNeKCPBVW9XOKlmp3AUgWFN1DvIvcM0gFpKjK8v.ttf",
        "axes": [
            {
                "tag": "YEAR",
                "start": 1979,
                "end": 2050
            }
        ]
    },
    {
        "family": "Coda",
        "variants": [
            "regular",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8JUIMapaN39I.ttf",
            "800": "https://fonts.gstatic.com/s/coda/v21/SLXIc1jY5nQ8HeIgTp6mw9t1cX8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8FUMGbg.ttf"
    },
    {
        "family": "Codystar",
        "variants": [
            "300",
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/codystar/v17/FwZf7-Q1xVk-40qxOuYsyuyrj0e29bfC.ttf",
            "regular": "https://fonts.gstatic.com/s/codystar/v17/FwZY7-Q1xVk-40qxOt6A4sijpFu_.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/codystar/v17/FwZY7-Q1xVk-40qxOu6B6Mw.ttf"
    },
    {
        "family": "Coiny",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PXwbElSvO5Tc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PUwaGFA.ttf"
    },
    {
        "family": "Combo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhg0iBu9y8Hf0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhj0jDOs.ttf"
    },
    {
        "family": "Comfortaa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v45",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/comfortaa/v45/1Ptsg8LJRfWJmhDAuUsISotrDfGGxA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMDrUfJQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Comforter",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/comforter/v7/H4clBXOCl8nQnlaql3Qa6JG8iqeuag.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comforter/v7/H4clBXOCl8nQnlaql3Qq6Zu4.ttf"
    },
    {
        "family": "Comforter Brush",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/comforterbrush/v7/Y4GTYa1xVSggrfzZI5WMjxRaOz0jwLL9Th8YYA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comforterbrush/v7/Y4GTYa1xVSggrfzZI5WMjxRaOz0Twbj5.ttf"
    },
    {
        "family": "Comic Neue",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf",
            "300italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf",
            "regular": "https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
            "italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf",
            "700": "https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf",
            "700italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeD96zp4g.ttf"
    },
    {
        "family": "Coming Soon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1u-SUjjzsykh.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1t-TWDw.ttf"
    },
    {
        "family": "Comme",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/comme/v2/8QIHdirKhMbn-vu-sowsrqjk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1z5cFb6oQb.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Commissioner",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/commissioner/v20/tDbL2o2WnlgI0FNDgduEk4jajCr4EwWfTA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTMNdKPaxEg.ttf",
        "axes": [
            {
                "tag": "FLAR",
                "start": 0,
                "end": 100
            },
            {
                "tag": "VOLM",
                "start": 0,
                "end": 100
            },
            {
                "tag": "slnt",
                "start": -12,
                "end": 0
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Concert One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/concertone/v22/VEM1Ro9xs5PjtzCu-srDqRTlhv-CuVAQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/concertone/v22/VEM1Ro9xs5PjtzCu-srDqSTkjPs.ttf"
    },
    {
        "family": "Condiment",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/condiment/v24/pONk1hggFNmwvXALyH6Sq4n4o1vyCQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/condiment/v24/pONk1hggFNmwvXALyH6iqoP8.ttf"
    },
    {
        "family": "Content",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "khmer"
        ],
        "version": "v24",
        "lastModified": "2022-09-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwUaDyIiL0RCg.ttf",
            "700": "https://fonts.gstatic.com/s/content/v24/zrfg0HLayePhU_AwaRzdBirfWCHvkAI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwYaH4Jg.ttf"
    },
    {
        "family": "Contrail One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/contrailone/v19/eLGbP-j_JA-kG0_Zo51noafdZUvt_c092w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/contrailone/v19/eLGbP-j_JA-kG0_Zo51noaftZEHp.ttf"
    },
    {
        "family": "Convergence",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYRhasU7Q8Cad.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYShbu0o.ttf"
    },
    {
        "family": "Cookie",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cookie/v21/syky-y18lb0tSbfNlQCT9tPdpw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cookie/v21/syky-y18lb0tSbf9lAqX.ttf"
    },
    {
        "family": "Copse",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb0djHkihUb-A.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb3diFEw.ttf"
    },
    {
        "family": "Corben",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/corben/v21/LYjDdGzzklQtCMp9oAlEpVs3VQ.ttf",
            "700": "https://fonts.gstatic.com/s/corben/v21/LYjAdGzzklQtCMpFHCZgrXArXN7HWQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/corben/v21/LYjDdGzzklQtCMpNoQNA.ttf"
    },
    {
        "family": "Corinthia",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/corinthia/v11/wEO_EBrAnchaJyPMHE0FUfAL3EsHiA.ttf",
            "700": "https://fonts.gstatic.com/s/corinthia/v11/wEO6EBrAnchaJyPMHE097d8v1GAbgbLXQA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/corinthia/v11/wEO_EBrAnchaJyPMHE01UPoP.ttf"
    },
    {
        "family": "Cormorant",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cormorant/v21/H4clBXOCl9bbnla_nHIa6JG8iqeuag.ttf",
            "italic": "https://fonts.gstatic.com/s/cormorant/v21/H4cjBXOCl9bbnla_nHIq6pu4qKK-aihq.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFhFTc7Vq6A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Cormorant Garamond",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQAllvuQWJ5heb_w.ttf",
            "300italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPjuw-NxBKL_y94.ttf",
            "regular": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf",
            "italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrHtPkyuF7w6C.ttf",
            "500": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQWlhvuQWJ5heb_w.ttf",
            "500italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEO7ug-NxBKL_y94.ttf",
            "600": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9vuQWJ5heb_w.ttf",
            "600italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEOXvQ-NxBKL_y94.ttf",
            "700": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5vuQWJ5heb_w.ttf",
            "700italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvA-NxBKL_y94.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYr3tP.ttf"
    },
    {
        "family": "Cormorant Infant",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9951w3_DMrQqcdJrk.ttf",
            "300italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItcDEhRoUYNrn_Ig.ttf",
            "regular": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN993_Af2DsAXq4.ttf",
            "italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyJU44g9vKiM1sORYSiWeAsLN997_IV3BkFTq4EPw.ttf",
            "500": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995wQ2_DMrQqcdJrk.ttf",
            "500italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItKDAhRoUYNrn_Ig.ttf",
            "600": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995ygx_DMrQqcdJrk.ttf",
            "600italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItBDchRoUYNrn_Ig.ttf",
            "700": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9950ww_DMrQqcdJrk.ttf",
            "700italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItYDYhRoUYNrn_Ig.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN997_EV3A.ttf"
    },
    {
        "family": "Cormorant SC",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmABIU_R3y8DOWGA.ttf",
            "regular": "https://fonts.gstatic.com/s/cormorantsc/v18/0yb5GD4kxqXBmOVLG30OGwserDow9Tbu-Q.ttf",
            "500": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmWBMU_R3y8DOWGA.ttf",
            "600": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmdBQU_R3y8DOWGA.ttf",
            "700": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmEBUU_R3y8DOWGA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorantsc/v18/0yb5GD4kxqXBmOVLG30OGwsurTA0.ttf"
    },
    {
        "family": "Cormorant Unicase",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9N_tucv7Gy0DRzS.ttf",
            "regular": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vTnsMnx3C9.ttf",
            "500": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Mnt-cv7Gy0DRzS.ttf",
            "600": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsOcv7Gy0DRzS.ttf",
            "700": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Nvsecv7Gy0DRzS.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX9vSlMc.ttf"
    },
    {
        "family": "Cormorant Upright",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDsU9X6RPzQ.ttf",
            "regular": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y2vVjjTkeMnz.ttf",
            "500": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDsU9X6RPzQ.ttf",
            "600": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDsU9X6RPzQ.ttf",
            "700": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDsU9X6RPzQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y1vUhDA.ttf"
    },
    {
        "family": "Courgette",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/courgette/v17/wEO_EBrAnc9BLjLQAUkFUfAL3EsHiA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/courgette/v17/wEO_EBrAnc9BLjLQAUk1UPoP.ttf"
    },
    {
        "family": "Courier Prime",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/courierprime/v9/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf",
            "italic": "https://fonts.gstatic.com/s/courierprime/v9/u-4n0q2lgwslOqpF_6gQ8kELawRpX837pvjxPA.ttf",
            "700": "https://fonts.gstatic.com/s/courierprime/v9/u-4k0q2lgwslOqpF_6gQ8kELY7pMf-fVqvHoJXw.ttf",
            "700italic": "https://fonts.gstatic.com/s/courierprime/v9/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LfrtPtNXyeAg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/courierprime/v9/u-450q2lgwslOqpF_6gQ8kELawdpXw.ttf"
    },
    {
        "family": "Cousine",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cousine/v27/d6lIkaiiRdih4SpPzSMlzTbtz9k.ttf",
            "italic": "https://fonts.gstatic.com/s/cousine/v27/d6lKkaiiRdih4SpP_SEvyRTo39l8hw.ttf",
            "700": "https://fonts.gstatic.com/s/cousine/v27/d6lNkaiiRdih4SpP9Z8K6T7G09BlnmQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/cousine/v27/d6lPkaiiRdih4SpP_SEXdTvM1_JgjmRpOA.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cousine/v27/d6lIkaiiRdih4SpP_SIvyQ.ttf"
    },
    {
        "family": "Coustard",
        "variants": [
            "regular",
            "900"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU9UPvWXuROTd.ttf",
            "900": "https://fonts.gstatic.com/s/coustard/v16/3XFuErgg3YsZ5fqUU-2LkEHmb_jU3eRL.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU-UOt2E.ttf"
    },
    {
        "family": "Covered By Your Grace",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0FgItq6bFIg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOSEFwgp.ttf"
    },
    {
        "family": "Crafty Girls",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NvuQR37fF3Wlg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NveQBf_.ttf"
    },
    {
        "family": "Creepster",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4hdXf4XB0Tow.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4RdH38.ttf"
    },
    {
        "family": "Crete Round",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/creteround/v15/55xoey1sJNPjPiv1ZZZrxJ1827zAKnxN.ttf",
            "italic": "https://fonts.gstatic.com/s/creteround/v15/55xqey1sJNPjPiv1ZZZrxK1-0bjiL2xNhKc.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/creteround/v15/55xoey1sJNPjPiv1ZZZrxK190bg.ttf"
    },
    {
        "family": "Crimson Pro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uDsoa5M_tv7IihmnkabDRcq4BYCdKi.ttf",
            "italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uBsoa5M_tv7IihmnkabAReoYR6DMKivlQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm1MOZs7.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Crimson Text",
        "variants": [
            "regular",
            "italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJvaAJSA_JN3Q.ttf",
            "italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlpogwHKFkZgtmSR3NB0oRJfaghWIfdd3ahG.ttf",
            "600": "https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJXsCx2C9lR1LFffg.ttf",
            "600italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajCOD9NV9rRPfrKu.ttf",
            "700": "https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJX1C12C9lR1LFffg.ttf",
            "700italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajDqDtNV9rRPfrKu.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJfaQhW.ttf"
    },
    {
        "family": "Croissant One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/croissantone/v26/3y9n6bU9bTPg4m8NDy3Kq24UM3pqn5cdJ-4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/croissantone/v26/3y9n6bU9bTPg4m8NDy3Kq24UA3tgmw.ttf"
    },
    {
        "family": "Crushed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/crushed/v29/U9Mc6dym6WXImTlFT1kfuIqyLzA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/crushed/v29/U9Mc6dym6WXImTlFf1gVvA.ttf"
    },
    {
        "family": "Cuprum",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cuprum/v25/dg4k_pLmvrkcOkB9IeFDh701Sg.ttf",
            "italic": "https://fonts.gstatic.com/s/cuprum/v25/dg4m_pLmvrkcOkBNI-tHpbglShon.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6Vj3YIg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Cute Font",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cutefont/v22/Noaw6Uny2oWPbSHMrY6vmJNVNC9hkw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cutefont/v22/Noaw6Uny2oWPbSHMrY6fmZlR.ttf"
    },
    {
        "family": "Cutive",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cutive/v22/NaPZcZ_fHOhV3Ip7T_hDoyqlZQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cutive/v22/NaPZcZ_fHOhV3IpLTvJH.ttf"
    },
    {
        "family": "Cutive Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/cutivemono/v21/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/cutivemono/v21/m8JWjfRfY7WVjVi2E-K9H6RETms.ttf"
    },
    {
        "family": "DM Mono",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/dmmono/v14/aFTR7PB1QTsUX8KYvrGyIYSnbKX9Rlk.ttf",
            "300italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTT7PB1QTsUX8KYth-orYataIf4VllXuA.ttf",
            "regular": "https://fonts.gstatic.com/s/dmmono/v14/aFTU7PB1QTsUX8KYhh2aBYyMcKw.ttf",
            "italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTW7PB1QTsUX8KYth-QAa6JYKzkXw.ttf",
            "500": "https://fonts.gstatic.com/s/dmmono/v14/aFTR7PB1QTsUX8KYvumzIYSnbKX9Rlk.ttf",
            "500italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTT7PB1QTsUX8KYth-o9YetaIf4VllXuA.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dmmono/v14/aFTU7PB1QTsUX8KYthyQAQ.ttf"
    },
    {
        "family": "DM Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dmsans/v15/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf",
            "italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2Fp2ywxg089UriCZaIGDWCBl0O8Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxRT23z.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 9,
                "end": 40
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 1000
            }
        ]
    },
    {
        "family": "DM Serif Display",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFnOHM81r4j6k0gjAW3mujVU2B2K_d709jy92k.ttf",
            "italic": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFhOHM81r4j6k0gjAW3mujVU2B2G_Vx1_r352np3Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFnOHM81r4j6k0gjAW3mujVU2B2G_Zx1w.ttf"
    },
    {
        "family": "DM Serif Text",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCu-xZa_krGokauCeNq1wWyafOPXHIJErY.ttf",
            "italic": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCw-xZa_krGokauCeNq1wWyWfGFWFAMArZKqQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCu-xZa_krGokauCeNq1wWyWfKFWA.ttf"
    },
    {
        "family": "Dai Banna SIL",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "new-tai-lue"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tYKoDsrKOgMX95A.ttf",
            "300italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyygsjAPiES55D3Vg.ttf",
            "regular": "https://fonts.gstatic.com/s/daibannasil/v2/lW-4wj0AJWmpwGyJ2uEoA4I7jS6AKsLhJgo.ttf",
            "italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-mwj0AJWmpwGyJ2uEoA4I7vSyKLuDkNgoO7g.ttf",
            "500": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tdqpDsrKOgMX95A.ttf",
            "500italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyy2snAPiES55D3Vg.ttf",
            "600": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tfauDsrKOgMX95A.ttf",
            "600italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyy9s7APiES55D3Vg.ttf",
            "700": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tZKvDsrKOgMX95A.ttf",
            "700italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyyks_APiES55D3Vg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/daibannasil/v2/lW-4wj0AJWmpwGyJ2uEoA4I7vS-KLg.ttf"
    },
    {
        "family": "Damion",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/damion/v15/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/damion/v15/hv-XlzJ3KEUe_YZkbGwz.ttf"
    },
    {
        "family": "Dancing Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dancingscript/v25/If2RXTr6YS-zF4S-kcSWSVi_swLngOAliz4X.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3TOp5.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Danfo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/danfo/v3/snfks0u_98t16SvUCZwCP8F9.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/danfo/v3/snf3s0u_98t16THfK1Csj3N41ZqbYDeJSrdX.ttf",
        "axes": [
            {
                "tag": "ELSH",
                "start": 0,
                "end": 100
            }
        ]
    },
    {
        "family": "Dangrek",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dangrek/v30/LYjCdG30nEgoH8E2gCNqqVIuTN4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dangrek/v30/LYjCdG30nEgoH8E2sCJgrQ.ttf"
    },
    {
        "family": "Darker Grotesque",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MH6cuh-mLQlC4BKCtayOfARkSVm7beJWcKUOI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXw3qk7z83U.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Darumadrop One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/darumadropone/v12/cY9cfjeIW11dpCKgRLi675a87IhHBJOxZQPp.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/darumadropone/v12/cY9cfjeIW11dpCKgRLi675a87LhGDpc.ttf"
    },
    {
        "family": "David Libre",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/davidlibre/v16/snfus0W_99N64iuYSvp4W_l86p6TYS-Y.ttf",
            "500": "https://fonts.gstatic.com/s/davidlibre/v16/snfzs0W_99N64iuYSvp4W8GIw7qbSjORSo9W.ttf",
            "700": "https://fonts.gstatic.com/s/davidlibre/v16/snfzs0W_99N64iuYSvp4W8HAxbqbSjORSo9W.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/davidlibre/v16/snfus0W_99N64iuYSvp4W8l94Jo.ttf"
    },
    {
        "family": "Dawning of a New Day",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dawningofanewday/v20/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8bhWJGNoBE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dawningofanewday/v20/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAw8aj2Y.ttf"
    },
    {
        "family": "Days One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/daysone/v18/mem9YaCnxnKRiYZOCLYVeLkWVNBt.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/daysone/v18/mem9YaCnxnKRiYZOCIYUcr0.ttf"
    },
    {
        "family": "Dekko",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dekko/v21/46khlb_wWjfSrttFR0vsfl1B.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dekko/v21/46khlb_wWjfSrutETU8.ttf"
    },
    {
        "family": "Dela Gothic One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/delagothicone/v16/hESp6XxvMDRA-2eD0lXpDa6QkBAGRUsJQAlbUA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/delagothicone/v16/hESp6XxvMDRA-2eD0lXpDa6QkBA2REEN.ttf"
    },
    {
        "family": "Delicious Handrawn",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/delicioushandrawn/v8/wlpsgx_NAUNkpmKQifcxkQchDFo3fJ113JpDd6u3AQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/delicioushandrawn/v8/wlpsgx_NAUNkpmKQifcxkQchDFo3fJ1F3ZBH.ttf"
    },
    {
        "family": "Delius",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/delius/v19/PN_xRfK0pW_9e1rtYcI-jT3L_w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/delius/v19/PN_xRfK0pW_9e1rdYMg6.ttf"
    },
    {
        "family": "Delius Swash Caps",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/deliusswashcaps/v23/oY1E8fPLr7v4JWCExZpWebxVKORpXXedKmeBvEYs.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/deliusswashcaps/v23/oY1E8fPLr7v4JWCExZpWebxVKORpXUecIGM.ttf"
    },
    {
        "family": "Delius Unicase",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/deliusunicase/v28/845BNMEwEIOVT8BmgfSzIr_6mmLHd-73LXWs.ttf",
            "700": "https://fonts.gstatic.com/s/deliusunicase/v28/845CNMEwEIOVT8BmgfSzIr_6mlp7WMr_BmmlS5aw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/deliusunicase/v28/845BNMEwEIOVT8BmgfSzIr_6mlLGfeo.ttf"
    },
    {
        "family": "Della Respira",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dellarespira/v22/RLp5K5v44KaueWI6iEJQBiGPRfkSu6EuTHo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dellarespira/v22/RLp5K5v44KaueWI6iEJQBiGPdfgYvw.ttf"
    },
    {
        "family": "Denk One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/denkone/v19/dg4m_pzhrqcFb2IzROtHpbglShon.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/denkone/v19/dg4m_pzhrqcFb2IzRNtGr7w.ttf"
    },
    {
        "family": "Devonshire",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/devonshire/v27/46kqlbDwWirWr4gtBD2BX0Vq01lYAZM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/devonshire/v27/46kqlbDwWirWr4gtBD2Bb0Rg1w.ttf"
    },
    {
        "family": "Dhurjati",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v24",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dhurjati/v24/_6_8ED3gSeatXfFiFX3ySKQtuTA2.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dhurjati/v24/_6_8ED3gSeatXfFiFU3zQqA.ttf"
    },
    {
        "family": "Didact Gothic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/didactgothic/v20/ahcfv8qz1zt6hCC5G4F_P4ASpUySp0LlcyQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/didactgothic/v20/ahcfv8qz1zt6hCC5G4F_P4ASlU2Yow.ttf"
    },
    {
        "family": "Diphylleia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/diphylleia/v1/DtVmJxCtRKMixK4_HXsIulwm6gDXvwE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/diphylleia/v1/DtVmJxCtRKMixK4_HXsIil0s7g.ttf"
    },
    {
        "family": "Diplomata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/diplomata/v31/Cn-0JtiMXwhNwp-wKxyfYGxYrdM9Sg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/diplomata/v31/Cn-0JtiMXwhNwp-wKxyvYWZc.ttf"
    },
    {
        "family": "Diplomata SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/diplomatasc/v28/buExpoi3ecvs3kidKgBJo2kf-P5Oaiw4cw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/diplomatasc/v28/buExpoi3ecvs3kidKgBJo2kv-fRK.ttf"
    },
    {
        "family": "Do Hyeon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dohyeon/v19/TwMN-I8CRRU2zM86HFE3ZwaH__-C.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dohyeon/v19/TwMN-I8CRRU2zM86HGE2bQI.ttf"
    },
    {
        "family": "Dokdo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dokdo/v17/esDf315XNuCBLxLo4NaMlKcH.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dokdo/v17/esDf315XNuCBLyLp6tI.ttf"
    },
    {
        "family": "Domine",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/domine/v23/L0x8DFMnlVwD4h3RvPCmRSlUig.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/domine/v23/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAE1wfFg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Donegal One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/donegalone/v21/m8JWjfRYea-ZnFz6fsK9FZRFRG-K3Mud.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/donegalone/v21/m8JWjfRYea-ZnFz6fsK9FaRETms.ttf"
    },
    {
        "family": "Dongle",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/dongle/v12/sJoG3Ltdjt6VPkqeEcxrYjWNzXvVPA.ttf",
            "regular": "https://fonts.gstatic.com/s/dongle/v12/sJoF3Ltdjt6VPkqmveRPah6RxA.ttf",
            "700": "https://fonts.gstatic.com/s/dongle/v12/sJoG3Ltdjt6VPkqeActrYjWNzXvVPA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dongle/v12/sJoF3Ltdjt6VPkqWvO5L.ttf"
    },
    {
        "family": "Doppio One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-lgshKGpe5Fg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-pgorLA.ttf"
    },
    {
        "family": "Dorsa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dorsa/v27/yYLn0hjd0OGwqo493XCFxAnQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dorsa/v27/yYLn0hjd0OGwqr4813Q.ttf"
    },
    {
        "family": "Dosis",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dosis/v32/HhyaU5sn9vOmLwlvAfSKEZZL.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml3RMB.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "DotGothic16",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dotgothic16/v18/v6-QGYjBJFKgyw5nSoDAGE7L435YPFrT.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dotgothic16/v18/v6-QGYjBJFKgyw5nSoDAGH7K6Xo.ttf"
    },
    {
        "family": "Dr Sugiyama",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/drsugiyama/v28/HTxoL2k4N3O9n5I1boGI7abRM4-t-g7y.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/drsugiyama/v28/HTxoL2k4N3O9n5I1boGI7ZbQOYs.ttf"
    },
    {
        "family": "Duru Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/durusans/v20/xn7iYH8xwmSyTvEV_HOxT_fYdN-WZw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/durusans/v20/xn7iYH8xwmSyTvEV_HOBTv3c.ttf"
    },
    {
        "family": "DynaPuff",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dynapuff/v4/z7NXdRvsZDIVHbYPMgxC_pjcTeWU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSxYu2YnhTQ.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Dynalight",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/dynalight/v22/1Ptsg8LOU_aOmQvTsF4ISotrDfGGxA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/dynalight/v22/1Ptsg8LOU_aOmQvTsF44S4Fv.ttf"
    },
    {
        "family": "EB Garamond",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ebgaramond/v30/SlGUmQSNjdsmc35JDF1K5FRyQjgdYxPJ.ttf",
            "italic": "https://fonts.gstatic.com/s/ebgaramond/v30/SlGWmQSNjdsmc35JDF1K5GRwSDw_ZgPJtWk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ebgaramond/v30/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkAo96.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Eagle Lake",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eaglelake/v24/ptRMTiqbbuNJDOiKj9wG5O7yKQNute8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eaglelake/v24/ptRMTiqbbuNJDOiKj9wG1O_4LQ.ttf"
    },
    {
        "family": "East Sea Dokdo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eastseadokdo/v22/xfuo0Wn2V2_KanASqXSZp22m05_aGavYS18y.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eastseadokdo/v22/xfuo0Wn2V2_KanASqXSZp22m06_bE68.ttf"
    },
    {
        "family": "Eater",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eater/v25/mtG04_FCK7bOvpu2u3FwsXsR.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eater/v25/mtG04_FCK7bOvqu3sXU.ttf"
    },
    {
        "family": "Economica",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/economica/v15/Qw3fZQZaHCLgIWa29ZBrMcgAAl1lfQ.ttf",
            "italic": "https://fonts.gstatic.com/s/economica/v15/Qw3ZZQZaHCLgIWa29ZBbM8IEIFh1fWUl.ttf",
            "700": "https://fonts.gstatic.com/s/economica/v15/Qw3aZQZaHCLgIWa29ZBTjeckCnZ5dHw8iw.ttf",
            "700italic": "https://fonts.gstatic.com/s/economica/v15/Qw3EZQZaHCLgIWa29ZBbM_q4D3x9Vnksi4M7.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/economica/v15/Qw3fZQZaHCLgIWa29ZBbMMIE.ttf"
    },
    {
        "family": "Eczar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eczar/v22/BXRlvF3Pi-DLmw0iBu9y8Hf0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6mqDgR.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Edu AU VIC WA NT Dots",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eduauvicwantdots/v1/S6ujw5FFVDKI3kwwDUbsPHCpzZNhzrA3or3lDKWpMFUwWQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eduauvicwantdots/v1/S6uQw5FFVDKI3kwwDUbsPHCpzZNhzrA3or3_B4dZ6MmTX8QNLvtYItiZ3Q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu AU VIC WA NT Guides",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eduauvicwantguides/v1/TuGBUUJ4V48KZ9Nr3ZV46JQkJxtkFIKnvy00LDxlIzIU5RwD.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eduauvicwantguides/v1/TuG-UUJ4V48KZ9Nr3ZV46JQkJxtkFIKnvy00LCZuAcLMeb8FnyPdK1Zqz4tw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu AU VIC WA NT Hand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eduauvicwanthand/v1/C8c94dY1tX2x0uuiUHFS4y7ERV-jfqJ6x06tFtksa4Q7LA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eduauvicwanthand/v1/C8cO4dY1tX2x0uuiUHFS4y7ERV-jfqJ6x063HfvcsxiYKifhtCJ1pKpPaQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu NSW ACT Foundation",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v2",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxsHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAeFBdseyoHko.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9tosGKcis.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu QLD Beginner",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOKz5iUuHLEMNXyohhc_Y56PR3A69hp5ySriqg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE4E3reaga8.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu SA Beginner",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/edusabeginner/v3/rnCw-xRb1x-1lHXnLaZZ2xOoLIGFWFAMArZKqQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9989Tox4Aw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu TAS Beginner",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXu9e04WubHfGVY-1TcNg7AFUmshmcPqoeRWfbs.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HwelkqD2A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Edu VIC WA NT Beginner",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jizLRF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7qNuL4o61H_E.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OXlMmEVQ0.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "El Messiri",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6AoqKAyLzfWo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe55hjDk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Electrolize",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/electrolize/v18/cIf5Ma1dtE0zSiGSiED7AUEGso5tQafB.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/electrolize/v18/cIf5Ma1dtE0zSiGSiED7AXEHuIo.ttf"
    },
    {
        "family": "Elsie",
        "variants": [
            "regular",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/elsie/v24/BCanqZABrez54yYu9slAeLgX.ttf",
            "900": "https://fonts.gstatic.com/s/elsie/v24/BCaqqZABrez54x6q2-1IU6QeXSBk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/elsie/v24/BCanqZABrez54xYv_M0.ttf"
    },
    {
        "family": "Elsie Swash Caps",
        "variants": [
            "regular",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/elsieswashcaps/v24/845DNN8xGZyVX5MVo_upKf7KnjK0ferVKGWsUo8.ttf",
            "900": "https://fonts.gstatic.com/s/elsieswashcaps/v24/845ENN8xGZyVX5MVo_upKf7KnjK0RW74DG2HToawrdU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/elsieswashcaps/v24/845DNN8xGZyVX5MVo_upKf7KnjK0TevfLA.ttf"
    },
    {
        "family": "Emblema One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOEIRBHl57G_f_.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOELRAFFo.ttf"
    },
    {
        "family": "Emilys Candy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/emilyscandy/v19/2EbgL-1mD1Rnb0OGKudbk0y5r9xrX84JjA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/emilyscandy/v19/2EbgL-1mD1Rnb0OGKudbk0yJrtZv.ttf"
    },
    {
        "family": "Encode Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/encodesans/v19/LDI2apOFNxEwR-Bd1O9uYMOsc-bGkqIw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjVtSF6A.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Encode Sans Condensed",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_76_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-5a-JLQoFI2KR.ttf",
            "200": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-SY6pByQJKnuIFA.ttf",
            "300": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-LY2pByQJKnuIFA.ttf",
            "regular": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgaWNDw8VIw.ttf",
            "500": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-dYypByQJKnuIFA.ttf",
            "600": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYupByQJKnuIFA.ttf",
            "700": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-PYqpByQJKnuIFA.ttf",
            "800": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-IYmpByQJKnuIFA.ttf",
            "900": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-BYipByQJKnuIFA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfT2gK-J.ttf"
    },
    {
        "family": "Encode Sans Expanded",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mx1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpJGKQNicoAbJlw.ttf",
            "200": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLqCCNIXIwSP0XD.ttf",
            "300": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKOCyNIXIwSP0XD.ttf",
            "regular": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoiIwdAd5Ab.ttf",
            "500": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLWCiNIXIwSP0XD.ttf",
            "600": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DSNIXIwSP0XD.ttf",
            "700": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKeDCNIXIwSP0XD.ttf",
            "800": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKCDyNIXIwSP0XD.ttf",
            "900": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKmDiNIXIwSP0XD.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpojKQM.ttf"
    },
    {
        "family": "Encode Sans SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyT7nLwCGzQ9zE7ZyRg0QRXHOxX3AngeAXx.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhmwc5HPA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Encode Sans Semi Condensed",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT6oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1T19MFtQ9jpVUA.ttf",
            "200": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RZ1eFHbdTgTFmr.ttf",
            "300": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Q91uFHbdTgTFmr.ttf",
            "regular": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR_sVPRsjp.ttf",
            "500": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Rl1-FHbdTgTFmr.ttf",
            "600": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0OFHbdTgTFmr.ttf",
            "700": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qt0eFHbdTgTFmr.ttf",
            "800": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qx0uFHbdTgTFmr.ttf",
            "900": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1QV0-FHbdTgTFmr.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yQ9ME.ttf"
    },
    {
        "family": "Encode Sans Semi Expanded",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8xOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM-41KwrlKXeOEA.ttf",
            "200": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM0IUCyDLJX6XCWU.ttf",
            "300": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyYXCyDLJX6XCWU.ttf",
            "regular": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o_LyjgOXc.ttf",
            "500": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM34WCyDLJX6XCWU.ttf",
            "600": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IRCyDLJX6XCWU.ttf",
            "700": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMzYQCyDLJX6XCWU.ttf",
            "800": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyoTCyDLJX6XCWU.ttf",
            "900": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMw4SCyDLJX6XCWU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TO4s1Kw.ttf"
    },
    {
        "family": "Engagement",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/engagement/v26/x3dlckLDZbqa7RUs9MFVXNossybsHQI.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/engagement/v26/x3dlckLDZbqa7RUs9MFVbNsmtw.ttf"
    },
    {
        "family": "Englebert",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/englebert/v21/xn7iYH8w2XGrC8AR4HSxT_fYdN-WZw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/englebert/v21/xn7iYH8w2XGrC8AR4HSBTv3c.ttf"
    },
    {
        "family": "Enriqueta",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/enriqueta/v17/goksH6L7AUFrRvV44HVTS0CjkP1Yog.ttf",
            "500": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVrv2mHmNZEq6TTFw.ttf",
            "600": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVrk26HmNZEq6TTFw.ttf",
            "700": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVr92-HmNZEq6TTFw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/enriqueta/v17/goksH6L7AUFrRvV44HVjSkqn.ttf"
    },
    {
        "family": "Ephesis",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ephesis/v9/uU9PCBUS8IerL2VG7xPb3vyHmlI.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ephesis/v9/uU9PCBUS8IerL2VG3xLR2g.ttf"
    },
    {
        "family": "Epilogue",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/epilogue/v17/O4ZRFGj5hxF0EhjimmIjuAkalnmd.ttf",
            "italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZXFGj5hxF0EhjimlIhsg04k2md0kI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPSDZ_R.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Erica One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ericaone/v27/WBLnrEXccV9VGrOKmGD1W0_MJMGxiQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ericaone/v27/WBLnrEXccV9VGrOKmGDFWkXI.ttf"
    },
    {
        "family": "Esteban",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/esteban/v15/r05bGLZE-bdGdN-GdOuD5jokU8E.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/esteban/v15/r05bGLZE-bdGdN-GROqJ4g.ttf"
    },
    {
        "family": "Estonia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/estonia/v11/7Au_p_4ijSecA1yHCCL8zkwMIFg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/estonia/v11/7Au_p_4ijSecA1yHOCP2yg.ttf"
    },
    {
        "family": "Euphoria Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/euphoriascript/v20/mFTpWb0X2bLb_cx6To2B8GpKoD5ak_ZT1D8x7Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/euphoriascript/v20/mFTpWb0X2bLb_cx6To2B8GpKoD5qkvxX.ttf"
    },
    {
        "family": "Ewert",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ewert/v25/va9I4kzO2tFODYBvS-J3kbDP.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ewert/v25/va9I4kzO2tFODbBuQeY.ttf"
    },
    {
        "family": "Exo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/exo/v21/4UaOrEtFpBIidHSi-DP-5g.ttf",
            "italic": "https://fonts.gstatic.com/s/exo/v21/4UaMrEtFpBISdn6m2jbu5rXI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3OwJmPg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Exo 2",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/exo2/v21/7cHmv4okm5zmbuYvIe804WIo.ttf",
            "italic": "https://fonts.gstatic.com/s/exo2/v21/7cHov4okm5zmbtYtK-sW5HIohT4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsP9C6.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Expletus Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/expletussans/v29/RLp5K5v5_bqufTYdnhFzDj2dRfkSu6EuTHo.ttf",
            "italic": "https://fonts.gstatic.com/s/expletussans/v29/RLpnK5v5_bqufTYdnhFzDj2ddfsYv4MrXHrRDA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSY2v1pHwA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Explora",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cherokee",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/explora/v9/tsstApxFfjUH4wrvc1qPonC3vqc.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/explora/v9/tsstApxFfjUH4wrvQ1uFpg.ttf"
    },
    {
        "family": "Fahkwang",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJHmZlRFipxkwjx.ttf",
            "200italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgHFQHC5Tlhjxdw4.ttf",
            "300": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIjmplRFipxkwjx.ttf",
            "300italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgBVTHC5Tlhjxdw4.ttf",
            "regular": "https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpNqPsr1ZPTZ4.ttf",
            "italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa36Uj3zpmBOgbNpOqNuLl7OCZ4ihE.ttf",
            "500": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJ7m5lRFipxkwjx.ttf",
            "500italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgE1SHC5Tlhjxdw4.ttf",
            "600": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJXnJlRFipxkwjx.ttf",
            "600italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgGFVHC5Tlhjxdw4.ttf",
            "700": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIznZlRFipxkwjx.ttf",
            "700italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgAVUHC5Tlhjxdw4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpOqOuLk.ttf"
    },
    {
        "family": "Familjen Grotesk",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3GZR9ZHiDnImG6-NEMQ41wby8WXH0OjpM75PE.ttf",
            "italic": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3AZR9ZHiDnImG6-NEMQ41wby8WbH8EirE-9PGLfQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMGJaRzseVw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Fanwood Text",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xXGRdbY1P1Sbg.ttf",
            "italic": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFzErwl05Ad_vSCF6Fq7xX2R9zc9vhCblye.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xX2RNzc.ttf"
    },
    {
        "family": "Farro",
        "variants": [
            "300",
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3hNJ6-WkJUQUq7.ttf",
            "regular": "https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHZVNHLq2cV5d.ttf",
            "500": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa25NZ6-WkJUQUq7.ttf",
            "700": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3xM56-WkJUQUq7.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHaVMFr4.ttf"
    },
    {
        "family": "Farsan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/farsan/v22/VEMwRoJ0vY_zsyz62q-pxDX9rQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/farsan/v22/VEMwRoJ0vY_zsyzK26Wt.ttf"
    },
    {
        "family": "Fascinate",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIEli1LbQRPyNrw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIElS1bzU.ttf"
    },
    {
        "family": "Fascinate Inline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy1g3CfRXxWZQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy5gzIeQ.ttf"
    },
    {
        "family": "Faster One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fasterone/v19/H4ciBXCHmdfClFb-vWhfyLuShq63czE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fasterone/v19/H4ciBXCHmdfClFb-vWhf-LqYgg.ttf"
    },
    {
        "family": "Fasthand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEESkuYkw2rQg1.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEERkvaEg.ttf"
    },
    {
        "family": "Fauna One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/faunaone/v15/wlpzgwTPBVpjpCuwkuEx2UxLYClOCg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/faunaone/v15/wlpzgwTPBVpjpCuwkuEB2EZP.ttf"
    },
    {
        "family": "Faustina",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/faustina/v20/XLYlIZPxYpJfTbZAFW-4F81Kp28v.ttf",
            "italic": "https://fonts.gstatic.com/s/faustina/v20/XLYjIZPxYpJfTbZAFV-6Hcloon8vVXg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEfGF-S.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Federant",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/federant/v29/2sDdZGNfip_eirT0_U0jRUG0AqUc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/federant/v29/2sDdZGNfip_eirT0_X0iT0U.ttf"
    },
    {
        "family": "Federo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbmjVOe2WTG7Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbWjFma.ttf"
    },
    {
        "family": "Felipa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/felipa/v25/FwZa7-owz1Eu4F_wSNSEwM2zpA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/felipa/v25/FwZa7-owz1Eu4F_ASd6A.ttf"
    },
    {
        "family": "Fenix",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5ostKzAFvs8o.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5rssITQ.ttf"
    },
    {
        "family": "Festive",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/festive/v9/cY9Ffj6KX1xcoDWhFtfgy9HTkak.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/festive/v9/cY9Ffj6KX1xcoDWhJtbqzw.ttf"
    },
    {
        "family": "Figtree",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/figtree/v6/_Xms-HUzqDCFdgfMq4O3DIZs3ik.ttf",
            "italic": "https://fonts.gstatic.com/s/figtree/v6/_Xmu-HUzqDCFdgfMm4G9CKRpzimMsg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/figtree/v6/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QG5fyEU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Finger Paint",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fingerpaint/v19/0QInMXVJ-o-oRn_7dron8YWO85bS8ANesw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fingerpaint/v19/0QInMXVJ-o-oRn_7dron8YW-8pzW.ttf"
    },
    {
        "family": "Finlandica",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/finlandica/v8/-nF5OGk-8vAc7lEtg0aS05uPOwkOE_Y.ttf",
            "italic": "https://fonts.gstatic.com/s/finlandica/v8/-nF7OGk-8vAc7lEtg0aS45mFPysLA_ZJ1g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19A7rHjw_Co.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Fira Code",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/firacode/v22/uU9NCBsR6Z2vfE9aq3bR2t6CilKOdQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sJVb7Mw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Fira Mono",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtQIfTTkdbJYA.ttf",
            "500": "https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDto1d33mf3VaZBRBQ.ttf",
            "700": "https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDtondv3mf3VaZBRBQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtgIP7X.ttf"
    },
    {
        "family": "Fira Sans",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9IjOazP3dUTP.ttf",
            "100italic": "https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqYTfVcFTPj0s.ttf",
            "200": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKnuQR37fF3Wlg.ttf",
            "200italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQBf_XljGllLX.ttf",
            "300": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKruQR37fF3Wlg.ttf",
            "300italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQxf_XljGllLX.ttf",
            "regular": "https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf",
            "italic": "https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkojOazP3dUTP.ttf",
            "500": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKvuQR37fF3Wlg.ttf",
            "500italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qhf_XljGllLX.ttf",
            "600": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzuQR37fF3Wlg.ttf",
            "600italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRRf_XljGllLX.ttf",
            "700": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3uQR37fF3Wlg.ttf",
            "700italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRBf_XljGllLX.ttf",
            "800": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7uQR37fF3Wlg.ttf",
            "800italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRxf_XljGllLX.ttf",
            "900": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_uQR37fF3Wlg.ttf",
            "900italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBKRhf_XljGllLX.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvkYjO.ttf"
    },
    {
        "family": "Fira Sans Condensed",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqOlQfx9CjA.ttf",
            "100italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONUXRpSjJcu.ttf",
            "200": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMN-cxZblY4.ttf",
            "200italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dzRehY43EA.ttf",
            "300": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMN-cxZblY4.ttf",
            "300italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dzRehY43EA.ttf",
            "regular": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfHnrMtVbx8.ttf",
            "italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqOlQfx9CjA.ttf",
            "500": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMN-cxZblY4.ttf",
            "500italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dzRehY43EA.ttf",
            "600": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMN-cxZblY4.ttf",
            "600italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dzRehY43EA.ttf",
            "700": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMN-cxZblY4.ttf",
            "700italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dzRehY43EA.ttf",
            "800": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMN-cxZblY4.ttf",
            "800italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dzRehY43EA.ttf",
            "900": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMN-cxZblY4.ttf",
            "900italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dzRehY43EA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarUfDtqA.ttf"
    },
    {
        "family": "Fira Sans Extra Condensed",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuv1WarE9ncg.ttf",
            "100italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-ejkp3cn22.ttf",
            "200": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn3-0oEZ-a2Q.ttf",
            "200italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pGR7e2SvJQ.ttf",
            "300": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn3-0oEZ-a2Q.ttf",
            "300italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pGR7e2SvJQ.ttf",
            "regular": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fiku3efvE8.ttf",
            "italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquv1WarE9ncg.ttf",
            "500": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn3-0oEZ-a2Q.ttf",
            "500italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pGR7e2SvJQ.ttf",
            "600": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn3-0oEZ-a2Q.ttf",
            "600italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pGR7e2SvJQ.ttf",
            "700": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn3-0oEZ-a2Q.ttf",
            "700italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pGR7e2SvJQ.ttf",
            "800": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn3-0oEZ-a2Q.ttf",
            "800italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pGR7e2SvJQ.ttf",
            "900": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn3-0oEZ-a2Q.ttf",
            "900italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pGR7e2SvJQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fmuvw.ttf"
    },
    {
        "family": "Fjalla One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fjallaone/v15/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fjallaone/v15/Yq6R-LCAWCX3-6Ky7FAFrOd6lw.ttf"
    },
    {
        "family": "Fjord One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKr6e5As-FeiQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKb6ORE.ttf"
    },
    {
        "family": "Flamenco",
        "variants": [
            "300",
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/flamenco/v18/neIPzCehqYguo67ssZ0qNIkyepH9qGsf.ttf",
            "regular": "https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssaWGHK06UY30.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssZWHFqk.ttf"
    },
    {
        "family": "Flavors",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/flavors/v26/FBV2dDrhxqmveJTpbkzlNqkG9UY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/flavors/v26/FBV2dDrhxqmveJTpXk3vMg.ttf"
    },
    {
        "family": "Fleur De Leah",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fleurdeleah/v9/AYCNpXX7ftYZWLhv9UmPJTMC5vat4I_Gdq0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fleurdeleah/v9/AYCNpXX7ftYZWLhv9UmPJTMC1ven5A.ttf"
    },
    {
        "family": "Flow Block",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/flowblock/v11/wlp0gwfPCEB65UmTk-d6-WZlbCBXE_I.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/flowblock/v11/wlp0gwfPCEB65UmTk-d6yWdvaA.ttf"
    },
    {
        "family": "Flow Circular",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/flowcircular/v11/lJwB-pc4j2F-H8YKuyvfxdZ45ifpWdr2rIg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/flowcircular/v11/lJwB-pc4j2F-H8YKuyvfxdZ41ibjXQ.ttf"
    },
    {
        "family": "Flow Rounded",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/flowrounded/v11/-zki91mtwsU9qlLiGwD4oQX3oZX-Xup87g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/flowrounded/v11/-zki91mtwsU9qlLiGwD4oQXHoJ_6.ttf"
    },
    {
        "family": "Foldit",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/foldit/v5/aFTV7PF3Y3c9WdjXpje0CYWVaQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XpAMqateZA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ],
        "colorCapabilities": [
            "COLRv1"
        ]
    },
    {
        "family": "Fondamento",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fondamento/v20/4UaHrEJGsxNmFTPDnkaJx63j5pN1MwI.ttf",
            "italic": "https://fonts.gstatic.com/s/fondamento/v20/4UaFrEJGsxNmFTPDnkaJ96_p4rFwIwJePw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fondamento/v20/4UaHrEJGsxNmFTPDnkaJ96zp4g.ttf"
    },
    {
        "family": "Fontdiner Swanky",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fontdinerswanky/v23/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uEfKiGvxts.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fontdinerswanky/v23/ijwOs4XgRNsiaI5-hcVb4hQgMvCD0uAVLg.ttf"
    },
    {
        "family": "Forum",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew8IXOpc.ttf"
    },
    {
        "family": "Fragment Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fragmentmono/v4/4iCr6K5wfMRRjxp0DA6-2CLnN4RNh4UI_1U.ttf",
            "italic": "https://fonts.gstatic.com/s/fragmentmono/v4/4iC16K5wfMRRjxp0DA6-2CLnB4ZHg6cN71URtQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fragmentmono/v4/4iCr6K5wfMRRjxp0DA6-2CLnB4VHgw.ttf"
    },
    {
        "family": "Francois One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/francoisone/v21/_Xmr-H4zszafZw3A-KPSZutNxgKQu_avAg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/francoisone/v21/_Xmr-H4zszafZw3A-KPSZut9xwiU.ttf"
    },
    {
        "family": "Frank Ruhl Libre",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/frankruhllibre/v21/j8_w6_fAw7jrcalD7oKYNX0QfAnPa7fv4JjnmY4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/frankruhllibre/v21/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw7FYWqRNRA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Fraunces",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fraunces/v31/6NUV8FyLNQOQZAnv9awPnugMyM1A.ttf",
            "italic": "https://fonts.gstatic.com/s/fraunces/v31/6NUT8FyLNQOQZAnv9ZwNlOwuzd1AZtw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxuTD_7W.ttf",
        "axes": [
            {
                "tag": "SOFT",
                "start": 0,
                "end": 100
            },
            {
                "tag": "WONK",
                "start": 0,
                "end": 1
            },
            {
                "tag": "opsz",
                "start": 9,
                "end": 144
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Freckle Face",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/freckleface/v15/AMOWz4SXrmKHCvXTohxY-YI0U1K2w9lb4g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/freckleface/v15/AMOWz4SXrmKHCvXTohxY-YIEUliy.ttf"
    },
    {
        "family": "Fredericka the Great",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Skz7Ylch2L.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--SlxbI.ttf"
    },
    {
        "family": "Fredoka",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fredoka/v14/X7nl4b87HvSqjb_WOCaQ4MTgAgk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3O8SL8F-mP.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Freehand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v31",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/freehand/v31/cIf-Ma5eqk01VjKTgAmBTmUOmZJk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/freehand/v31/cIf-Ma5eqk01VjKTgDmARGE.ttf"
    },
    {
        "family": "Freeman",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/freeman/v1/S6u9w4NGQiLN8nh-ew-FGC_p9dw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/freeman/v1/S6u9w4NGQiLN8nh-Sw6PHA.ttf"
    },
    {
        "family": "Fresca",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fresca/v22/6ae94K--SKgCzbM2Gr0W13DKPA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fresca/v22/6ae94K--SKgCzbMGG7cS.ttf"
    },
    {
        "family": "Frijole",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ7xPb3vyHmlI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ3xLR2g.ttf"
    },
    {
        "family": "Fruktur",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fruktur/v27/SZc53FHsOru5QYsMfz3GkUrS8DI.ttf",
            "italic": "https://fonts.gstatic.com/s/fruktur/v27/SZc73FHsOru5QYsMTz_MlWjX4DJXgQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fruktur/v27/SZc53FHsOru5QYsMTzzMlQ.ttf"
    },
    {
        "family": "Fugaz One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fugazone/v19/rax_HiWKp9EAITukFslMBBJek0vA8A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fugazone/v19/rax_HiWKp9EAITukFsl8BRha.ttf"
    },
    {
        "family": "Fuggles",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fuggles/v12/k3kQo8UEJOlD1hpOTd7iL0nAMaM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fuggles/v12/k3kQo8UEJOlD1hpOfd_oKw.ttf"
    },
    {
        "family": "Fustat",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fustat/v1/NaPZcZ_aHO9Iy5t7T_hDoyqlZQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fustat/v1/NaPEcZ_aHO9Iy5thRNqze7YGY9HGCEQQdr25sQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Fuzzy Bubbles",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLGKZMbrgv9pwtjPEVNV0F2NnP5Zxsreko.ttf",
            "700": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLbKZMbrgv9pwtjPEVNV0F2Ds_WQxMAZkM1pn4.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLGKZMbrgv9pwtjPEVNV0F2BnLzYw.ttf"
    },
    {
        "family": "GFS Didot",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek"
        ],
        "version": "v16",
        "lastModified": "2024-04-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gfsdidot/v16/Jqzh5TybZ9vZMWFssvwiF-fGFSCGAA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gfsdidot/v16/Jqzh5TybZ9vZMWFssvwSFu3C.ttf"
    },
    {
        "family": "GFS Neohellenic",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "greek"
        ],
        "version": "v25",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLahP46UDUw.ttf",
            "italic": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QITdiDOrfiq0b7R8O1Iw9WLcY5jL6JLwaATU91X.ttf",
            "700": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdr644fWsRO9w.ttf",
            "700italic": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIWdiDOrfiq0b7R8O1Iw9WLcY5jL5r37oQbeMFe985V.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5jLKJL.ttf"
    },
    {
        "family": "Ga Maamli",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gamaamli/v2/uU9NCBsQ4c-DPW1Yo3rR2t6CilKOdQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gamaamli/v2/uU9NCBsQ4c-DPW1Yo3rh29SG.ttf"
    },
    {
        "family": "Gabarito",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gabarito/v7/QGYtz_0dZAGKJJ4t3EtvUYTknuBJ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gabarito/v7/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj248KEFwgp.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Gabriela",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gabriela/v21/qkBWXvsO6sreR8E-b_m-zrpHmRzC.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gabriela/v21/qkBWXvsO6sreR8E-b8m_xL4.ttf"
    },
    {
        "family": "Gaegu",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/gaegu/v17/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf",
            "regular": "https://fonts.gstatic.com/s/gaegu/v17/TuGfUVB6Up9NU6ZLodgzydtk.ttf",
            "700": "https://fonts.gstatic.com/s/gaegu/v17/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gaegu/v17/TuGfUVB6Up9NU5ZKq9w.ttf"
    },
    {
        "family": "Gafata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gafata/v20/XRXV3I6Cn0VJKon4MuyAbsrVcA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gafata/v20/XRXV3I6Cn0VJKonIM-aE.ttf"
    },
    {
        "family": "Gajraj One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gajrajone/v5/1cX2aUDCDpXsuWVb1jIjr1GqhcitzeM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gajrajone/v5/1cX2aUDCDpXsuWVb1jIjn1CggQ.ttf"
    },
    {
        "family": "Galada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/galada/v18/H4cmBXyGmcjXlUX-8iw-4Lqggw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/galada/v18/H4cmBXyGmcjXlUXO8yY6.ttf"
    },
    {
        "family": "Galdeano",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boDPx8PCOg0uX.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boAPw-vQ.ttf"
    },
    {
        "family": "Galindo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/galindo/v24/HI_KiYMeLqVKqwyuQ5HiRp-dhpQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/galindo/v24/HI_KiYMeLqVKqwyuc5DoQg.ttf"
    },
    {
        "family": "Gamja Flower",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gamjaflower/v22/6NUR8FiKJg-Pa0rM6uN40Z4kyf9Fdty2ew.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gamjaflower/v22/6NUR8FiKJg-Pa0rM6uN40Z4UyPVB.ttf"
    },
    {
        "family": "Gantari",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gantari/v1/jVyK7nvyB2HL8iZyFEUkpiwFR80.ttf",
            "italic": "https://fonts.gstatic.com/s/gantari/v1/jVyI7nvyB2HL8iZyJEcuog4AV81P9w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOg3xYZU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Gasoek One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gasoekone/v3/EJRTQgQ_UMUKvDgnlX80zrq_cyb-vco.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gasoekone/v3/EJRTQgQ_UMUKvDgnlX80_ru1dw.ttf"
    },
    {
        "family": "Gayathri",
        "variants": [
            "100",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "malayalam"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/gayathri/v17/MCoWzAb429DbBilWLLhc-pvSA_gA2W8.ttf",
            "regular": "https://fonts.gstatic.com/s/gayathri/v17/MCoQzAb429DbBilWLIA48J_wBugA.ttf",
            "700": "https://fonts.gstatic.com/s/gayathri/v17/MCoXzAb429DbBilWLLiE37v4LfQJwHbn.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gayathri/v17/MCoQzAb429DbBilWLLA5-ps.ttf"
    },
    {
        "family": "Gelasio",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gelasio/v12/cIf9MaFfvUQxTTqSxCmrYGkHgIs.ttf",
            "italic": "https://fonts.gstatic.com/s/gelasio/v12/cIf_MaFfvUQxTTqS9CuhZEsCkIt9QQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gelasio/v12/cIfiMaFfvUQxTTqS3iKJkLGbI41wQL8Ilxct8zQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Gemunu Libre",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gemunulibre/v14/X7ni4bQ6Cfy7jKGXVE_YlqnBGiL1539rvw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJDvWBfA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Genos",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cherokee",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/genos/v12/SlGemQqPqpUOYSwoSzYsgWxz.ttf",
            "italic": "https://fonts.gstatic.com/s/genos/v12/SlGQmQqPqpUOYRwqQTIOhHxzcD0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVqkjYrEi-.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Gentium Book Plus",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpofKfdbLOrdPV.ttf",
            "italic": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFN2-RHBgUK5fbjKxRpbBtJPyRpocKdf7bsqMPVZb4.ttf",
            "700": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFO2-RHBgUK5fbjKxRpbBtJPyRpocojWpbGhs_cfKe1.ttf",
            "700italic": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFA2-RHBgUK5fbjKxRpbBtJPyRpocKdRwrDjMv-ebe1Els.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpocKef7Y.ttf"
    },
    {
        "family": "Gentium Plus",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gentiumplus/v2/Iurd6Ytw-oSPaZ00r2bNe8VpjJtM6G0t9w.ttf",
            "italic": "https://fonts.gstatic.com/s/gentiumplus/v2/IurD6Ytw-oSPaZ00r2bNe8VZjpFIymg9957e.ttf",
            "700": "https://fonts.gstatic.com/s/gentiumplus/v2/IurC6Ytw-oSPaZ00r2bNe8VRMLRo4EYx_ofHsw.ttf",
            "700italic": "https://fonts.gstatic.com/s/gentiumplus/v2/IurA6Ytw-oSPaZ00r2bNe8VZjqn05Uw13ILXs-h6.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gentiumplus/v2/Iurd6Ytw-oSPaZ00r2bNe8VZjZFI.ttf"
    },
    {
        "family": "Geo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/geo/v21/CSRz4zRZlufVL3BmQjlCbQ.ttf",
            "italic": "https://fonts.gstatic.com/s/geo/v21/CSRx4zRZluflLXpiYDxSbf8r.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/geo/v21/CSRz4zRZluflLnpi.ttf"
    },
    {
        "family": "Geologica",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/geologica/v1/oY1c8evIr7j9P3TN9YwXCv5xY4QBLw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqDx_rc-ISBQ.ttf",
        "axes": [
            {
                "tag": "CRSV",
                "start": 0,
                "end": 1
            },
            {
                "tag": "SHRP",
                "start": 0,
                "end": 100
            },
            {
                "tag": "slnt",
                "start": -12,
                "end": 0
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Georama",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/georama/v13/MCoTzAn438bIEyxFZaAS3pP5H_E.ttf",
            "italic": "https://fonts.gstatic.com/s/georama/v13/MCoRzAn438bIEyxFVaIY2rH8D_EZwA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgdmAEP.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 150
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Geostar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/geostar/v26/sykz-yx4n701VLOftSq9-trEvlQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/geostar/v26/sykz-yx4n701VLOfhSu3_g.ttf"
    },
    {
        "family": "Geostar Fill",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/geostarfill/v26/AMOWz4SWuWiXFfjEohxQ9os0U1K2w9lb4g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/geostarfill/v26/AMOWz4SWuWiXFfjEohxQ9osEUliy.ttf"
    },
    {
        "family": "Germania One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3ezAJONau6ew.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3uzQhK.ttf"
    },
    {
        "family": "Gideon Roman",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gideonroman/v11/e3tmeuGrVOys8sxzZgWlmXoge0PWovdU4w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gideonroman/v11/e3tmeuGrVOys8sxzZgWlmXoQeknS.ttf"
    },
    {
        "family": "Gidugu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gidugu/v25/L0x8DFMkk1Uf6w3RvPCmRSlUig.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gidugu/v25/L0x8DFMkk1Uf6w3hvfqi.ttf"
    },
    {
        "family": "Gilda Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gildadisplay/v18/t5tmIRoYMoaYG0WEOh7HwMeR7TnFrpOHYh4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gildadisplay/v18/t5tmIRoYMoaYG0WEOh7HwMeR3TjPqg.ttf"
    },
    {
        "family": "Girassol",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/girassol/v22/JTUUjIo_-DK48laaNC9Nz2pJzxbi.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/girassol/v22/JTUUjIo_-DK48laaNB9MxW4.ttf"
    },
    {
        "family": "Give You Glory",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYc1zB4ZD6rwa.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYf1yDYI.ttf"
    },
    {
        "family": "Glass Antiqua",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/glassantiqua/v24/xfu30Wr0Wn3NOQM2piC0uXOjnL_wN6fRUkY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/glassantiqua/v24/xfu30Wr0Wn3NOQM2piC0uXOjrL76Mw.ttf"
    },
    {
        "family": "Glegoo",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/glegoo/v16/_Xmt-HQyrTKWaw2Ji6mZAI91xw.ttf",
            "700": "https://fonts.gstatic.com/s/glegoo/v16/_Xmu-HQyrTKWaw2xN4a9CKRpzimMsg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/glegoo/v16/_Xmt-HQyrTKWaw25iqOd.ttf"
    },
    {
        "family": "Gloock",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gloock/v6/Iurb6YFw84WUY4N5jxylBrdRjQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gloock/v6/Iurb6YFw84WUY4NJjhah.ttf"
    },
    {
        "family": "Gloria Hallelujah",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gloriahallelujah/v21/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy3TKEvkCF.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gloriahallelujah/v21/LYjYdHv3kUk9BMV96EIswT9DIbW-MISz1zY.ttf"
    },
    {
        "family": "Glory",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/glory/v15/q5uJsoi9Lf1w5sfPkC1gAgxd.ttf",
            "italic": "https://fonts.gstatic.com/s/glory/v15/q5uHsoi9Lf1w5vfNmilCBxxdmYU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQwImzp3WZ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Gluten",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gluten/v16/HhyVU5gk9fW7OUdPK9qGGI9STA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7S1KsHw.ttf",
        "axes": [
            {
                "tag": "slnt",
                "start": -13,
                "end": 13
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Goblin One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/goblinone/v26/CSR64z1ZnOqZRjRCBVY_TOcATNt_pOU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/goblinone/v26/CSR64z1ZnOqZRjRCBVY_fOYKSA.ttf"
    },
    {
        "family": "Gochi Hand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gochihand/v23/hES06XlsOjtJsgCkx1PkTo71-n0nXWA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gochihand/v23/hES06XlsOjtJsgCkx1Pkfo___g.ttf"
    },
    {
        "family": "Goldman",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/goldman/v19/pe0uMIWbN4JFplR2LDJ4Bt-7G98.ttf",
            "700": "https://fonts.gstatic.com/s/goldman/v19/pe0rMIWbN4JFplR2FI5XIteQB9Zra1U.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/goldman/v19/pe0uMIWbN4JFplR2HDNyAg.ttf"
    },
    {
        "family": "Golos Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/golostext/v4/q5uCsoe9Lv5t7Meb31EcIxR2hYxREMs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plVRRT5dGLs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Gorditas",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gorditas/v22/ll8_K2aTVD26DsPEtQDoDa4AlxYb.ttf",
            "700": "https://fonts.gstatic.com/s/gorditas/v22/ll84K2aTVD26DsPEtThUIooIvAoShA1i.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gorditas/v22/ll8_K2aTVD26DsPEtTDpB6o.ttf"
    },
    {
        "family": "Gothic A1",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/gothica1/v13/CSR74z5ZnPydRjlCCwlCCMcqYtd2vfwk.ttf",
            "200": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCpOYKSPl6tOU9Eg.ttf",
            "300": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCwOUKSPl6tOU9Eg.ttf",
            "regular": "https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwl6bM0uQNJmvQ.ttf",
            "500": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCmOQKSPl6tOU9Eg.ttf",
            "600": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCtOMKSPl6tOU9Eg.ttf",
            "700": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC0OIKSPl6tOU9Eg.ttf",
            "800": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCzOEKSPl6tOU9Eg.ttf",
            "900": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC6OAKSPl6tOU9Eg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwlKbccq.ttf"
    },
    {
        "family": "Gotu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gotu/v16/o-0FIpksx3QOlH0Lioh6-hU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gotu/v16/o-0FIpksx3QOpHwBjg.ttf"
    },
    {
        "family": "Goudy Bookletter 1911",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/goudybookletter1911/v19/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tRbXZ4IXAA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/goudybookletter1911/v19/sykt-z54laciWfKv-kX8krex0jDiD2HbY6IJtBzT.ttf"
    },
    {
        "family": "Gowun Batang",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRhXLH-yuAtLw.ttf",
            "700": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwNs5nhRMIjYsdSgcMa3wRZ4J7awssxJii23w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRRXbv6.ttf"
    },
    {
        "family": "Gowun Dodum",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJF0FfORL0fNy.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJG0EduA.ttf"
    },
    {
        "family": "Graduate",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/graduate/v17/C8cg4cs3o2n15t_2YxgR6X2NZAn2.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/graduate/v17/C8cg4cs3o2n15t_2YygQ43k.ttf"
    },
    {
        "family": "Grand Hotel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grandhotel/v19/7Au7p_IgjDKdCRWuR1azpmQNEl0O0kEx.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grandhotel/v19/7Au7p_IgjDKdCRWuR1azplQMGFk.ttf"
    },
    {
        "family": "Grandiflora One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grandifloraone/v3/0ybmGD0g27bCk_5MGWZcKWhxwnUU_R3y8DOWGA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grandifloraone/v3/0ybmGD0g27bCk_5MGWZcKWhxwnUk_Bf2.ttf"
    },
    {
        "family": "Grandstander",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grandstander/v18/ga6KawtA-GpSsTWrnNHPCSIWbTq6fMRRMw.ttf",
            "italic": "https://fonts.gstatic.com/s/grandstander/v18/ga6UawtA-GpSsTWrnNHPCSImbzC-XsFBM58R.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grandstander/v18/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--P3ncsg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Grape Nuts",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grapenuts/v5/syk2-yF4iLM2RfKj4F7k3tLvol2RN1E.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grapenuts/v5/syk2-yF4iLM2RfKj4F7k7tPlpg.ttf"
    },
    {
        "family": "Gravitas One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gravitasone/v19/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gravitasone/v19/5h1diZ4hJ3cblKy3LWakKQmqDG5J.ttf"
    },
    {
        "family": "Great Vibes",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/greatvibes/v19/RWmMoKWR9v4ksMfaWd_JN-XCg6UKDXlq.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/greatvibes/v19/RWmMoKWR9v4ksMfaWd_JN9XDiaE.ttf"
    },
    {
        "family": "Grechen Fuemen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grechenfuemen/v9/vEFI2_tHEQ4d5ObgKxBzZh0MAWgc-NaXXq7H.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grechenfuemen/v9/vEFI2_tHEQ4d5ObgKxBzZh0MAVgd8tI.ttf"
    },
    {
        "family": "Grenze",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/grenze/v15/O4ZRFGb7hR12BxqPm2IjuAkalnmd.ttf",
            "100italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZXFGb7hR12BxqH_VpHsg04k2md0kI.ttf",
            "200": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPN0MDkicWn2CEyw.ttf",
            "200italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vrrky0SvWWUy1uW.ttf",
            "300": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPU0ADkicWn2CEyw.ttf",
            "300italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqPkC0SvWWUy1uW.ttf",
            "regular": "https://fonts.gstatic.com/s/grenze/v15/O4ZTFGb7hR12Bxq3_2gnmgwKlg.ttf",
            "italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZRFGb7hR12BxqH_WIjuAkalnmd.ttf",
            "500": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPC0EDkicWn2CEyw.ttf",
            "500italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VrXkS0SvWWUy1uW.ttf",
            "600": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPJ0YDkicWn2CEyw.ttf",
            "600italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vr7li0SvWWUy1uW.ttf",
            "700": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPQ0cDkicWn2CEyw.ttf",
            "700italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vqfly0SvWWUy1uW.ttf",
            "800": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPX0QDkicWn2CEyw.ttf",
            "800italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqDlC0SvWWUy1uW.ttf",
            "900": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPe0UDkicWn2CEyw.ttf",
            "900italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqnlS0SvWWUy1uW.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grenze/v15/O4ZTFGb7hR12BxqH_mIj.ttf"
    },
    {
        "family": "Grenze Gotisch",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4sPjjqNDz1osh_jX9YfjudpAhKF66qe6T5.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9kcYqZ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Grey Qo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/greyqo/v9/BXRrvF_Nmv_TyXxNDOtQ9Wf0QcE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/greyqo/v9/BXRrvF_Nmv_TyXxNPOpa8Q.ttf"
    },
    {
        "family": "Griffy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/griffy/v22/FwZa7-ox2FQh9kfwSNSEwM2zpA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/griffy/v22/FwZa7-ox2FQh9kfASd6A.ttf"
    },
    {
        "family": "Gruppo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gruppo/v21/WwkfxPmzE06v_ZWFWXDAOIEQUQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gruppo/v21/WwkfxPmzE06v_ZW1WHrE.ttf"
    },
    {
        "family": "Gudea",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp-CP9IGON7Ez.ttf",
            "italic": "https://fonts.gstatic.com/s/gudea/v15/neILzCqgsI0mp9CN_oWsMqEzSJQ.ttf",
            "700": "https://fonts.gstatic.com/s/gudea/v15/neIIzCqgsI0mp9gz26WGHK06UY30.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp9CO_oU.ttf"
    },
    {
        "family": "Gugi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gugi/v15/A2BVn5dXywshVA6A9DEfgqM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gugi/v15/A2BVn5dXywshZA-K8A.ttf"
    },
    {
        "family": "Gulzar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gulzar/v12/Wnz6HAc9eB3HB2ILYTwZqg_MPQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gulzar/v12/Wnz6HAc9eB3HB2I7YDYd.ttf"
    },
    {
        "family": "Gupter",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QUYZa_Wu_lpA.ttf",
            "500": "https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qslb-bUsT5rZhaZg.ttf",
            "700": "https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qs3bmbUsT5rZhaZg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QkYJy7.ttf"
    },
    {
        "family": "Gurajada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gurajada/v19/FwZY7-Qx308m-l-0Kd6A4sijpFu_.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gurajada/v19/FwZY7-Qx308m-l-0Ke6B6Mw.ttf"
    },
    {
        "family": "Gwendolyn",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBXXvoO_M3CSss-d7ee5JRLkAXbMQ.ttf",
            "700": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBSXvoO_M3CSss-d7emWLtvmC7HONiSFQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBXXvoO_M3CSss-d7eu5Z5P.ttf"
    },
    {
        "family": "Habibi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNCShJeZOYySQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNySxha.ttf"
    },
    {
        "family": "Hachi Maru Pop",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hachimarupop/v20/HI_TiYoRLqpLrEiMAuO9Ysfz7rW1EM_btd8u.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hachimarupop/v20/HI_TiYoRLqpLrEiMAuO9Ysfz7oW0Gss.ttf"
    },
    {
        "family": "Hahmlet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hahmlet/v13/BngIUXpCQ3nKpIo0V_jQjP_L3qE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKOOjpZMc.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Halant",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2Pbsvc_pCmwZqcwdRXg.ttf",
            "regular": "https://fonts.gstatic.com/s/halant/v14/u-4-0qaujRI2PbsX39Jmky12eg.ttf",
            "500": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvK_tCmwZqcwdRXg.ttf",
            "600": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvB_xCmwZqcwdRXg.ttf",
            "700": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvY_1CmwZqcwdRXg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/halant/v14/u-4-0qaujRI2Pbsn3thi.ttf"
    },
    {
        "family": "Hammersmith One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnbshsks4MRXw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnrsxEg.ttf"
    },
    {
        "family": "Hanalei",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHXzgmVydREus.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHbzksUw.ttf"
    },
    {
        "family": "Hanalei Fill",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hanaleifill/v22/fC1mPYtObGbfyQznIaQzPQiMVwLBplm9aw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hanaleifill/v22/fC1mPYtObGbfyQznIaQzPQi8VgjF.ttf"
    },
    {
        "family": "Handjet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "armenian",
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/handjet/v19/oY1e8eXHq7n1OnbQtu0dDtx0c4Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHTmaYkQmFA.ttf",
        "axes": [
            {
                "tag": "ELGR",
                "start": 1,
                "end": 2
            },
            {
                "tag": "ELSH",
                "start": 0,
                "end": 16
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Handlee",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/handlee/v18/-F6xfjBsISg9aMakDmr6oilJ3ik.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/handlee/v18/-F6xfjBsISg9aMakPmvwpg.ttf"
    },
    {
        "family": "Hanken Grotesk",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVn2YZDLWuGJpnzaiwFXS9tYupa7dGTCTs5.ttf",
            "italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVl2YZDLWuGJpnzaiwFXS9tYtpY59WxDCs5cvI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2Zq4PBJ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Hanuman",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/hanuman/v22/VuJzdNvD15HhpJJBQMLdPKNiaRpFvg.ttf",
            "300": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQAr_HIlMZRNcp0o.ttf",
            "regular": "https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBeKbXOIFneRo.ttf",
            "700": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQBr4HIlMZRNcp0o.ttf",
            "900": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQCL6HIlMZRNcp0o.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBSKfdPA.ttf"
    },
    {
        "family": "Happy Monkey",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OABwD2bWqVjw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OwBgry.ttf"
    },
    {
        "family": "Harmattan",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/harmattan/v19/goksH6L2DkFvVvRp9XpTS0CjkP1Yog.ttf",
            "500": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xprv2mHmNZEq6TTFw.ttf",
            "600": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xprk26HmNZEq6TTFw.ttf",
            "700": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xpr92-HmNZEq6TTFw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/harmattan/v19/goksH6L2DkFvVvRp9XpjSkqn.ttf"
    },
    {
        "family": "Headland One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/headlandone/v16/yYLu0hHR2vKnp89Tk1TCq3Tx0PlTeZ3mJA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/headlandone/v16/yYLu0hHR2vKnp89Tk1TCq3TB0fNX.ttf"
    },
    {
        "family": "Hedvig Letters Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hedvigletterssans/v2/CHy_V_PfGVjobSBkihHWDT98RVp37w8jQJ1N3Twgi1w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hedvigletterssans/v2/CHy_V_PfGVjobSBkihHWDT98RVp37w8jcJxH2Q.ttf"
    },
    {
        "family": "Hedvig Letters Serif",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hedviglettersserif/v2/OD5cuN2I2mekHmyoU1Kj2AXOd5_7v7gIDk_3iBYVfsc4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hedviglettersserif/v2/OD5puN2I2mekHmyoU1Kj2AXOd5_7v7gIDlX8quj7viQ_N1HixEApfr3Y.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 12,
                "end": 24
            }
        ]
    },
    {
        "family": "Heebo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/heebo/v26/NGS6v5_NC0k9P-HxR7BDsbMB.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysc0mj.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Henny Penny",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfMQqt3M7tMDT.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfPQrvXc.ttf"
    },
    {
        "family": "Hepta Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/heptaslab/v23/ea8cadoyU_jkHdalebHv025vhVKUE3E.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5wfaaZQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 900
            }
        ]
    },
    {
        "family": "Herr Von Muellerhoff",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/herrvonmuellerhoff/v21/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6_qJY3QPQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/herrvonmuellerhoff/v21/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft0c79KY.ttf"
    },
    {
        "family": "Hi Melody",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/himelody/v15/46ktlbP8Vnz0pJcqCTbEf29E31BBGA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/himelody/v15/46ktlbP8Vnz0pJcqCTb0fmVA.ttf"
    },
    {
        "family": "Hina Mincho",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hinamincho/v13/2sDaZGBRhpXa2Jjz5w5LAGW8KbkVZTHR.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hinamincho/v13/2sDaZGBRhpXa2Jjz5w5LAFW9I70.ttf"
    },
    {
        "family": "Hind",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfMJaIRuYjDpf5Vw.ttf",
            "regular": "https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIRG5yBROzkDM.ttf",
            "500": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfJpbIRuYjDpf5Vw.ttf",
            "600": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfLZcIRuYjDpf5Vw.ttf",
            "700": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfNJdIRuYjDpf5Vw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIdG94AQ.ttf"
    },
    {
        "family": "Hind Guntur",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yGn1czn9zaj5Ju.ttf",
            "regular": "https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd8Qqt3M7tMDT.ttf",
            "500": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zenlczn9zaj5Ju.ttf",
            "600": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zymVczn9zaj5Ju.ttf",
            "700": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yWmFczn9zaj5Ju.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd_QrvXc.ttf"
    },
    {
        "family": "Hind Madurai",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfXaUnecsoMJ0b_g.ttf",
            "regular": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjn8Y0DceA0OQ.ttf",
            "500": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfBaQnecsoMJ0b_g.ttf",
            "600": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfKaMnecsoMJ0b_g.ttf",
            "700": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfTaInecsoMJ0b_g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjX8IcH.ttf"
    },
    {
        "family": "Hind Siliguri",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRDf44uEfKiGvxts.ttf",
            "regular": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf",
            "500": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRG_54uEfKiGvxts.ttf",
            "600": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoREP-4uEfKiGvxts.ttf",
            "700": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRCf_4uEfKiGvxts.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEoTJrawg.ttf"
    },
    {
        "family": "Hind Vadodara",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSDn3iXM0oSOL2Yw.ttf",
            "regular": "https://fonts.gstatic.com/s/hindvadodara/v13/neINzCKvrIcn5pbuuuriV9tTcJXfrXsfvSo.ttf",
            "500": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSGH2iXM0oSOL2Yw.ttf",
            "600": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSE3xiXM0oSOL2Yw.ttf",
            "700": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSCnwiXM0oSOL2Yw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hindvadodara/v13/neINzCKvrIcn5pbuuuriV9tTQJTVqQ.ttf"
    },
    {
        "family": "Holtwood One SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/holtwoodonesc/v21/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cbb6LZttyg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/holtwoodonesc/v21/yYLx0hLR0P-3vMFSk1TCq3Txg5BHcLz-.ttf"
    },
    {
        "family": "Homemade Apple",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/homemadeapple/v22/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/homemadeapple/v22/Qw3EZQFXECDrI2q789EKQZJob0x8XH0.ttf"
    },
    {
        "family": "Homenaje",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Ld6A4sijpFu_.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Le6B6Mw.ttf"
    },
    {
        "family": "Honk",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/honk/v1/m8JdjftUea-X2z28WoXSaLU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/honk/v1/m8J7jftUea-XwTaemClumrBQbmvynOmXBji9zFhHRr8WFgV7orFJ.ttf",
        "axes": [
            {
                "tag": "MORF",
                "start": 0,
                "end": 45
            },
            {
                "tag": "SHLN",
                "start": 0,
                "end": 100
            }
        ],
        "colorCapabilities": [
            "COLRv1"
        ]
    },
    {
        "family": "Hubballi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hubballi/v7/o-0JIpUj3WIZ1RFN56B7yBBNYuSF.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hubballi/v7/o-0JIpUj3WIZ1RFN55B6whQ.ttf"
    },
    {
        "family": "Hurricane",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldyAv2-C99ycg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldCA_e6.ttf"
    },
    {
        "family": "IBM Plex Mono",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n3kwq0n1hj-sNFQ.ttf",
            "100italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6rfjptAgt5VM-kVkqdyU8n1ioStndlre4dFcFh.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3uAL8ldPg-IUDNg.ttf",
            "200italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFh8ARHNh4zg.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3oQI8ldPg-IUDNg.ttf",
            "300italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFh8ARHNh4zg.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n5igg1l9kn-s.ttf",
            "italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n1ioq0n1hj-sNFQ.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3twJ8ldPg-IUDNg.ttf",
            "500italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFh8ARHNh4zg.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf",
            "600italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFh8ARHNh4zg.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3pQP8ldPg-IUDNg.ttf",
            "700italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFh8ARHNh4zg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n1ikq0g.ttf"
    },
    {
        "family": "IBM Plex Sans",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKjbLeEKxIedbzDw.ttf",
            "100italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZMW9PjD3N8.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIZmdd_qFmo.ttf",
            "200italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idscf3vBmpl8A.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIZmdd_qFmo.ttf",
            "300italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscf3vBmpl8A.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf",
            "italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKhdTeEKxIedbzDw.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIZmdd_qFmo.ttf",
            "500italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscf3vBmpl8A.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf",
            "600italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscf3vBmpl8A.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIZmdd_qFmo.ttf",
            "700italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscf3vBmpl8A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdfeEA.ttf"
    },
    {
        "family": "IBM Plex Sans Arabic",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "arabic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3MZRtWPQCuHme67tEYUIx3Kh0PHR9N6YNe3PC5eMlAMg0.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPy_dCTVsVJKxTs.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOW_tCTVsVJKxTs.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6bs61vSbfdlA.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPO_9CTVsVJKxTs.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-NCTVsVJKxTs.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOG-dCTVsVJKxTs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6Ys73PA.ttf"
    },
    {
        "family": "IBM Plex Sans Condensed",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY7KyKvBgYsMDhM.ttf",
            "100italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8hN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8M_LhakJHhOgBg.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5m6Yvrr4cFFwq5.ttf",
            "200italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8GPqpYMnEhq5H1w.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4C6ovrr4cFFwq5.ttf",
            "300italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8AfppYMnEhq5H1w.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbauwq_jhJsM.ttf",
            "italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYasyKvBgYsMDhM.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5a64vrr4cFFwq5.ttf",
            "500italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8F_opYMnEhq5H1w.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527Ivrr4cFFwq5.ttf",
            "600italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8HPvpYMnEhq5H1w.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4S7Yvrr4cFFwq5.ttf",
            "700italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8BfupYMnEhq5H1w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYavyKs.ttf"
    },
    {
        "family": "IBM Plex Sans Devanagari",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic-ext",
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXB3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HMUjwUcjwCEQq.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HnWnQe-b8AV0z0w.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H-WrQe-b8AV0z0w.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O__VUL0c83gCA.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HoWvQe-b8AV0z0w.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HjWzQe-b8AV0z0w.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H6W3Qe-b8AV0z0w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_PVEjw.ttf"
    },
    {
        "family": "IBM Plex Sans Hebrew",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXB-l0VqDaM7C4.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt230_hjqF9Tc2.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2H0_hjqF9Tc2.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXH2l8Fk3rSaM.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2X0_hjqF9Tc2.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93n0_hjqF9Tc2.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ330_hjqF9Tc2.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2k-l0.ttf"
    },
    {
        "family": "IBM Plex Sans JP",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XLDn9KbTDf6_f7dISNqYf_tvPT7E7yjPB7twdmHQ.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7OLTrNpVuw5_BAM.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7IbQrNpVuw5_BAM.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XNDn9KbTDf6_f7dISNqYf_tvPT1Cr4iNJ-pwc.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7N7RrNpVuw5_BAM.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7PLWrNpVuw5_BAM.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7JbXrNpVuw5_BAM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsansjp/v6/Z9XNDn9KbTDf6_f7dISNqYf_tvPT5CvyjA.ttf"
    },
    {
        "family": "IBM Plex Sans KR",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFM2-VJISZe3O_rc3ZVYh4aTwNOyra_X5zCpMrMfA.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyhqef7bsqMPVZb4.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyn6df7bsqMPVZb4.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFK2-VJISZe3O_rc3ZVYh4aTwNO8tK1W77HtMo.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyiacf7bsqMPVZb4.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOygqbf7bsqMPVZb4.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOym6af7bsqMPVZb4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFK2-VJISZe3O_rc3ZVYh4aTwNOwtO_Xw.ttf"
    },
    {
        "family": "IBM Plex Sans Thai",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "thai"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JNje1VVIzcq1HzJq2AEdo2Tj_qvLqEatYlR8ZKUqcX.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqExvcFbehGW74OXw.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEovQFbehGW74OXw.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLq8DtwhZcNaUg.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE-vUFbehGW74OXw.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE1vIFbehGW74OXw.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEsvMFbehGW74OXw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLqMD9Yl.ttf"
    },
    {
        "family": "IBM Plex Sans Thai Looped",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "thai"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss5AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_HaKpHOtFCQ76Q.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_NqrhFmDGC0i8Cc.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_L6ohFmDGC0i8Cc.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30LxBKAoFGoBCQ.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_OaphFmDGC0i8Cc.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_MquhFmDGC0i8Cc.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_K6vhFmDGC0i8Cc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L9BOKpA.ttf"
    },
    {
        "family": "IBM Plex Serif",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizBREVNn1dOx-zrZ2X3pZvkTi182zIZj1bIkNo.ttf",
            "100italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YTi3TNgNq55w.ttf",
            "200": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-hIzoVrBicOg.ttf",
            "200italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oyq17jjNOg_oc.ttf",
            "300": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi20-RIzoVrBicOg.ttf",
            "300italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xq17jjNOg_oc.ttf",
            "regular": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizDREVNn1dOx-zrZ2X3pZvkThUY0TY7ikbI.ttf",
            "italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizBREVNn1dOx-zrZ2X3pZvkTiUa2zIZj1bIkNo.ttf",
            "500": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIzoVrBicOg.ttf",
            "500italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywq17jjNOg_oc.ttf",
            "600": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3A_xIzoVrBicOg.ttf",
            "600italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3q17jjNOg_oc.ttf",
            "700": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi2k_hIzoVrBicOg.ttf",
            "700italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442q17jjNOg_oc.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizDREVNn1dOx-zrZ2X3pZvkTiUZ2zI.ttf"
    },
    {
        "family": "IM Fell DW Pica",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZAPUvi88MQ.ttf",
            "italic": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDEZGRQotv9nbn2qSl0TxXVYNwNZgnQnCosMXm0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNwNZQnQ.ttf"
    },
    {
        "family": "IM Fell DW Pica SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-BiKEUiBGA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTm-RKO.ttf"
    },
    {
        "family": "IM Fell Double Pica",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf",
            "italic": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0W_VA.ttf"
    },
    {
        "family": "IM Fell Double Pica SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0Eeru2dGg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ0YwOfg.ttf"
    },
    {
        "family": "IM Fell English",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAz3F6mZVY9Y5w.ttf",
            "italic": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk3ALSLW8zDe0rthJysWrnLsAzHFaOdd4pI59zg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAzHFqOd.ttf"
    },
    {
        "family": "IM Fell English SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZLO4gOg0shzA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZ7OoIK.ttf"
    },
    {
        "family": "IM Fell French Canon",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coVPt1ozoPz.ttf",
            "italic": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6gfiNtDWYfYc-tDiyiw08rrghJszkK6foXNNlKy5PzzrU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6foUNNk.ttf"
    },
    {
        "family": "IM Fell French Canon SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0UBCVIVqH.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H0c1WhQ.ttf"
    },
    {
        "family": "IM Fell Great Primer",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorXvDo3SQY1.ttf",
            "italic": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6UNwSJtayYxOkbYFsT6hMsLzX7u85rJrrVtj4VTBY1N6U.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJrrWtj4.ttf"
    },
    {
        "family": "IM Fell Great Primer SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9BXz0s8FG.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTIf8D3g.ttf"
    },
    {
        "family": "Ibarra Real Nova",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlfdQiA-DBIDCcaWtQzL4BZHoiDoHxSENxuLuE.ttf",
            "italic": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlZdQiA-DBIDCcaWtQzL4BZHoiDkH5YFP5rPuF6EA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5PDsXzs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Iceberg",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/iceberg/v24/8QIJdijAiM7o-qnZuIgOq7jkAOw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/iceberg/v24/8QIJdijAiM7o-qnZiIkErw.ttf"
    },
    {
        "family": "Iceland",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/iceland/v20/rax9HiuFsdMNOnWPWKxGADBbg0s.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/iceland/v20/rax9HiuFsdMNOnWPaK1MBA.ttf"
    },
    {
        "family": "Imbue",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imbue/v27/RLpxK5P16Ki3fWJoxzUobkvv.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXTOoHMA.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 10,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Imperial Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imperialscript/v6/5DCPAKrpzy_H98IV2ISnZBbGrVNvPenlvttWNg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imperialscript/v6/5DCPAKrpzy_H98IV2ISnZBbGrVNfPOPh.ttf"
    },
    {
        "family": "Imprima",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/imprima/v18/VEMxRoN7sY3yuy-7-oWHyDzktPo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/imprima/v18/VEMxRoN7sY3yuy-7yoSNzA.ttf"
    },
    {
        "family": "Inclusive Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkxC9biPuwflXcJ46P4PGWE0971owa2LB4i.ttf",
            "italic": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkzC9biPuwflXcJ46P4PGWE0-73qQKUKQ4iT6o.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkxC9biPuwflXcJ46P4PGWE0-70qQI.ttf"
    },
    {
        "family": "Inconsolata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inconsolata/v32/QldKNThLqRwH-OJ1UHjlKFle7KlmxuHx.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8WRv2kg.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 50,
                "end": 200
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Inder",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4vq6pw-P3U4O.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4sq7rQs.ttf"
    },
    {
        "family": "Indie Flower",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/indieflower/v21/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/indieflower/v21/m8JVjfNVeKWVnh3QMuKkFcZVb0uC.ttf"
    },
    {
        "family": "Ingrid Darling",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ingriddarling/v5/LDIrapaJNxUtSuFdw-9yf4rCPsLOub458jGL.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ingriddarling/v5/LDIrapaJNxUtSuFdw-9yf4rCPvLPs7o.ttf"
    },
    {
        "family": "Inika",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-phTHRcc2s2XH.ttf",
            "700": "https://fonts.gstatic.com/s/inika/v21/rnCr-x5X3QP-pix7auM-mHnOSOuk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-piTGT8M.ttf"
    },
    {
        "family": "Inknut Antiqua",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2vwrj5bBoIYJNf.ttf",
            "regular": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKXUD6pzxRwYB.ttf",
            "500": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU33w7j5bBoIYJNf.ttf",
            "600": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxLj5bBoIYJNf.ttf",
            "700": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xbj5bBoIYJNf.ttf",
            "800": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2jxrj5bBoIYJNf.ttf",
            "900": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2Hx7j5bBoIYJNf.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKUUC4Jg.ttf"
    },
    {
        "family": "Inria Sans",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3ELaDQtFqeY3fX4.ttf",
            "300italic": "https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAgQlPrcQybX4pQA.ttf",
            "regular": "https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ5O7yKQNute8.ttf",
            "italic": "https://fonts.gstatic.com/s/inriasans/v14/ptROTiqXYfZMCOiVj9kQ1Oz4LSFrpe8uZA.ttf",
            "700": "https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3FLdDQtFqeY3fX4.ttf",
            "700italic": "https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAkQ5PrcQybX4pQA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ1O_4LQ.ttf"
    },
    {
        "family": "Inria Serif",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/inriaserif/v16/fC14PYxPY3rXxEndZJAzN3wAVQjFhFyta3xN.ttf",
            "300italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC16PYxPY3rXxEndZJAzN3SuT4THjliPbmxN0_E.ttf",
            "regular": "https://fonts.gstatic.com/s/inriaserif/v16/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf",
            "italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC1nPYxPY3rXxEndZJAzN3SudyjvqlCkcmU.ttf",
            "700": "https://fonts.gstatic.com/s/inriaserif/v16/fC14PYxPY3rXxEndZJAzN3wQUgjFhFyta3xN.ttf",
            "700italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC16PYxPY3rXxEndZJAzN3SuT5TAjliPbmxN0_E.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inriaserif/v16/fC1lPYxPY3rXxEndZJAzN3Stdyg.ttf"
    },
    {
        "family": "Inspiration",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inspiration/v6/x3dkckPPZa6L4wIg5cZOEvoGnSrlBBsy.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inspiration/v6/x3dkckPPZa6L4wIg5cZOEsoHly4.ttf"
    },
    {
        "family": "Instrument Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/instrumentsans/v1/pxicypc9vsFDm051Uf6KVwgkfoSrSGNAom-wpw.ttf",
            "italic": "https://fonts.gstatic.com/s/instrumentsans/v1/pxieypc9vsFDm051Uf6KVwgkfoSbSmlEgGqgp-pO.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSTF-Tf0kyk.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Instrument Serif",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/instrumentserif/v4/jizBRFtNs2ka5fXjeivQ4LroWlx-2zIZj1bIkNo.ttf",
            "italic": "https://fonts.gstatic.com/s/instrumentserif/v4/jizHRFtNs2ka5fXjeivQ4LroWlx-6zATi3TNgNq55w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/instrumentserif/v4/jizBRFtNs2ka5fXjeivQ4LroWlx-6zMTiw.ttf"
    },
    {
        "family": "Inter",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/inter/v18/UcCo3FwrK3iLTfvlaQc78lA2.ttf",
            "italic": "https://fonts.gstatic.com/s/inter/v18/UcCm3FwrK3iLTcvnYwMZ90A2B58.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuLyfAZlhjQ.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 14,
                "end": 32
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Inter Tight",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/intertight/v7/NGSwv5HMAFg6IuGlBNMjxIsA-6lMQHe9.ttf",
            "italic": "https://fonts.gstatic.com/s/intertight/v7/NGSyv5HMAFg6IuGlBNMjxLsC8a1uRWe9amg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-aXS5X.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Irish Grover",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgf-P5Oaiw4cw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgv-fRK.ttf"
    },
    {
        "family": "Island Moments",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/islandmoments/v6/NaPBcZfVGvBdxIt7Ar0qzkXJF-TGIohbZ6SY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/islandmoments/v6/NaPBcZfVGvBdxIt7Ar0qzkXJF9THKIw.ttf"
    },
    {
        "family": "Istok Web",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/istokweb/v24/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf",
            "italic": "https://fonts.gstatic.com/s/istokweb/v24/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf",
            "700": "https://fonts.gstatic.com/s/istokweb/v24/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf",
            "700italic": "https://fonts.gstatic.com/s/istokweb/v24/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/istokweb/v24/3qTvojGmgSyUukBzKslpAGt6.ttf"
    },
    {
        "family": "Italiana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/italiana/v20/QldNNTtLsx4E__B0XTmRY31Wx7Vv.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/italiana/v20/QldNNTtLsx4E__B0XQmQaXk.ttf"
    },
    {
        "family": "Italianno",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/italianno/v17/dg4n_p3sv6gCJkwzT6Rnj5YpQwM-gg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/italianno/v17/dg4n_p3sv6gCJkwzT6RXjpwt.ttf"
    },
    {
        "family": "Itim",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/itim/v14/0nknC9ziJOYewARKkc7ZdwU.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/itim/v14/0nknC9ziJOYe8AVAlQ.ttf"
    },
    {
        "family": "Jacquard 12",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquard12/v6/vm8ydRLuXETEweL79J4rGc3JUnr34c9-.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquard12/v6/vm8ydRLuXETEweL79J4rGf3IWH4.ttf"
    },
    {
        "family": "Jacquard 12 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquard12charted/v2/i7dWIE97bzCOB9Q_Up6PQmYfKDPIb2HwT3StZ9jetKY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquard12charted/v2/i7dWIE97bzCOB9Q_Up6PQmYfKDPIb2Hwf3WnYw.ttf"
    },
    {
        "family": "Jacquard 24",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquard24/v2/jVyO7nf_B2zO5jVpUGU8lgQEdchf9xXp.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquard24/v2/jVyO7nf_B2zO5jVpUGU8ljQFf8w.ttf"
    },
    {
        "family": "Jacquard 24 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquard24charted/v3/mtGm4-dNK6HaudrE9VVKhENTsEXEYish0iRrMYJ_K-4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquard24charted/v3/mtGm4-dNK6HaudrE9VVKhENTsEXEYish4iVhNQ.ttf"
    },
    {
        "family": "Jacquarda Bastarda 9",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquardabastarda9/v4/f0Xp0fWr_8t6WFtKQJfOhaC0hcZ1HYAMAbwD1TB_JHHY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquardabastarda9/v4/f0Xp0fWr_8t6WFtKQJfOhaC0hcZ1HYAMAYwC3zQ.ttf"
    },
    {
        "family": "Jacquarda Bastarda 9 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquardabastarda9charted/v2/Yq6D-KaMUyfq4qLgx19A_ocp43FeLd9m0vDxm-yf8JPuf0cPaL8pmQg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquardabastarda9charted/v2/Yq6D-KaMUyfq4qLgx19A_ocp43FeLd9m0vDxm-yf8JPuT0YFbA.ttf"
    },
    {
        "family": "Jacques Francois",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquesfrancois/v24/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcPqoeRWfbs.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquesfrancois/v24/ZXu9e04ZvKeOOHIe1TMahbcIU2cgqcLgpQ.ttf"
    },
    {
        "family": "Jacques Francois Shadow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jacquesfrancoisshadow/v25/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8VsHuV5ULS.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jacquesfrancoisshadow/v25/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_p8Uun8.ttf"
    },
    {
        "family": "Jaini",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jaini/v1/fC1vPYJMbGHQzEmOK-ZSUHyt.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jaini/v1/fC1vPYJMbGHQzHmPIeI.ttf"
    },
    {
        "family": "Jaini Purva",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jainipurva/v1/CHynV-vdHVXwbWcUswbUGHoOHH4sj3lR.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jainipurva/v1/CHynV-vdHVXwbWcUswbUGEoPFno.ttf"
    },
    {
        "family": "Jaldi",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI30NUZpD_B6g8.ttf",
            "700": "https://fonts.gstatic.com/s/jaldi/v12/or3hQ67z0_CI33voSbT3LLQ1niPn.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI33NVbJQ.ttf"
    },
    {
        "family": "Jaro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jaro/v6/ea8WadQwV_r_XPbcEdiM628.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jaro/v6/ea8DadQwV_r_Rv3-7zZMCGhQgVr1SmCtPvk.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 6,
                "end": 72
            }
        ]
    },
    {
        "family": "Jersey 10",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey10/v2/GftH7vZKsggXMf9n_J5X-JLgy1wtSw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey10/v2/GftH7vZKsggXMf9n_J5n-Zjk.ttf"
    },
    {
        "family": "Jersey 10 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey10charted/v2/oY1E8fPFr6XiNWqEp90XSbwUGfF8SnedKmeBvEYs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey10charted/v2/oY1E8fPFr6XiNWqEp90XSbwUGfF8SkecIGM.ttf"
    },
    {
        "family": "Jersey 15",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey15/v2/_6_9EDzuROGsUuk2TWjSYoohsCkvSQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey15/v2/_6_9EDzuROGsUuk2TWjiY4Al.ttf"
    },
    {
        "family": "Jersey 15 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey15charted/v2/nuFjD-rCQIjoVp1Sva2ToCTudGbLeRv4r2024gxi.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey15charted/v2/nuFjD-rCQIjoVp1Sva2ToCTudGbLeSv5pWk.ttf"
    },
    {
        "family": "Jersey 20",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey20/v2/ZgNRjP1ON6jeW4D12z3crE_qP4mXuQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey20/v2/ZgNRjP1ON6jeW4D12z3srUXu.ttf"
    },
    {
        "family": "Jersey 20 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey20charted/v2/JTUNjJMy9DKq5FzVaj9tpgYgvHqGn_Z1ji-rqnQ_.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey20charted/v2/JTUNjJMy9DKq5FzVaj9tpgYgvHqGn8Z0hCs.ttf"
    },
    {
        "family": "Jersey 25",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey25/v2/ll8-K2eeXj2tAs6F9BXIJ4AMng8ChA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey25/v2/ll8-K2eeXj2tAs6F9BX4JooI.ttf"
    },
    {
        "family": "Jersey 25 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jersey25charted/v2/6NUM8EWHIhCWbxOqtLkv94Rlu6EkGv2uUGQW93Cg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jersey25charted/v2/6NUM8EWHIhCWbxOqtLkv94Rlu6EkGs2vWmA.ttf"
    },
    {
        "family": "JetBrains Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jetbrainsmono/v20/tDbV2o-flEEny0FZhsfKu5WU4yD8MQCPTFrV.ttf",
            "italic": "https://fonts.gstatic.com/s/jetbrainsmono/v20/tDbX2o-flEEny0FZhsfKu5WU4xD-OwStSUrV_BE.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jetbrainsmono/v20/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxTPFOQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Jim Nightshade",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-NS5XX4.ttf"
    },
    {
        "family": "Joan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/joan/v11/ZXupe1oZsqWRbRdH8X1p_Ng.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/joan/v11/ZXupe1oZsqWRXRZN9Q.ttf"
    },
    {
        "family": "Jockey One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jockeyone/v21/HTxpL2g2KjCFj4x8WI6ArIb7HYOk4xc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jockeyone/v21/HTxpL2g2KjCFj4x8WI6AnIfxGQ.ttf"
    },
    {
        "family": "Jolly Lodger",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3VWerT3ZyuqA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3lWODX.ttf"
    },
    {
        "family": "Jomhuria",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jomhuria/v20/Dxxp8j-TMXf-llKur2b1MOGbC3Dh.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jomhuria/v20/Dxxp8j-TMXf-llKur1b0OuU.ttf"
    },
    {
        "family": "Jomolhari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "tibetan"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jomolhari/v18/EvONzA1M1Iw_CBd2hsQCF1IZKq5INg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jomolhari/v18/EvONzA1M1Iw_CBd2hsQyFlgd.ttf"
    },
    {
        "family": "Josefin Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3aZQNVED7rKGKxtqIqX5EkCnZ5dHw8iw.ttf",
            "italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3EZQNVED7rKGKxtqIqX5EUCHx9Vnksi4M7.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMBhKg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Josefin Slab",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/josefinslab/v26/lW-5wjwOK3Ps5GSJlNNkMalXrQSuJsv4Pw.ttf",
            "italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-nwjwOK3Ps5GSJlNNkMalnrw6qBM7oPxMX.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msR3g9Lw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Jost",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jost/v18/92zatBhPNqw77oPX4xYlbxM.ttf",
            "italic": "https://fonts.gstatic.com/s/jost/v18/92zUtBhPNqw73oHd5zQgfxMi7w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jost/v18/92zPtBhPNqw79Ij1E865zBUv7myjJTVHNI0.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Joti One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jotione/v26/Z9XVDmdJQAmWm9TwaYTe4u2El6GC.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jotione/v26/Z9XVDmdJQAmWm9TwabTf6Ok.ttf"
    },
    {
        "family": "Jua",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jua/v16/co3KmW9ljjAjc-DZCsKgsg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jua/v16/co3KmW9ljjATcurd.ttf"
    },
    {
        "family": "Judson",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/judson/v19/FeVRS0Fbvbc14VxRD7N01bV7kg.ttf",
            "italic": "https://fonts.gstatic.com/s/judson/v19/FeVTS0Fbvbc14VxhDblw97BrknZf.ttf",
            "700": "https://fonts.gstatic.com/s/judson/v19/FeVSS0Fbvbc14Vxps5xQ3Z5nm29Gww.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/judson/v19/FeVRS0Fbvbc14VxhDrlw.ttf"
    },
    {
        "family": "Julee",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/julee/v25/TuGfUVB3RpZPQ6ZLodgzydtk.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/julee/v25/TuGfUVB3RpZPQ5ZKq9w.ttf"
    },
    {
        "family": "Julius Sans One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/juliussansone/v18/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xXEW6aGXHw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/juliussansone/v18/1Pt2g8TAX_SGgBGUi0tGOYEga5WOxHsS.ttf"
    },
    {
        "family": "Junge",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/junge/v24/gokgH670Gl1lUqAdvhB7SnKm.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/junge/v24/gokgH670Gl1lUpActBQ.ttf"
    },
    {
        "family": "Jura",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "kayah-li",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/jura/v31/z7NbdRfiaC4VbcNDUCRDzJ0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7ZugR_0.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Just Another Hand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOcr_BmmlS5aw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOfr-DG0.ttf"
    },
    {
        "family": "Just Me Again Down Here",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAtwGtKQ.ttf"
    },
    {
        "family": "K2D",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE6UpvrIw74NL.ttf",
            "100italic": "https://fonts.gstatic.com/s/k2d/v11/J7afnpF2V0EjdZ1NtLYS6pNLAjk.ttf",
            "200": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Erv4QJlJw85ppSGw.ttf",
            "200italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3hlZY4xJ9CGyAa.ttf",
            "300": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Er24cJlJw85ppSGw.ttf",
            "300italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2FlpY4xJ9CGyAa.ttf",
            "regular": "https://fonts.gstatic.com/s/k2d/v11/J7aTnpF2V0ETd68tnLcg7w.ttf",
            "italic": "https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0EjdaUpvrIw74NL.ttf",
            "500": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Erg4YJlJw85ppSGw.ttf",
            "500italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3dl5Y4xJ9CGyAa.ttf",
            "600": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Err4EJlJw85ppSGw.ttf",
            "600italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3xkJY4xJ9CGyAa.ttf",
            "700": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Ery4AJlJw85ppSGw.ttf",
            "700italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2VkZY4xJ9CGyAa.ttf",
            "800": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Er14MJlJw85ppSGw.ttf",
            "800italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2JkpY4xJ9CGyAa.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/k2d/v11/J7aTnpF2V0EjdqUp.ttf"
    },
    {
        "family": "Kablammo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kablammo/v1/bWt77fHPcgrhC-J3ld_qU8Z0WH5p.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kablammo/v1/bWtm7fHPcgrhC-J3lcXhcQTY5Ixs6Au9YgCTjhcV.ttf",
        "axes": [
            {
                "tag": "MORF",
                "start": 0,
                "end": 60
            }
        ]
    },
    {
        "family": "Kadwa",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7iphTHRcc2s2XH.ttf",
            "700": "https://fonts.gstatic.com/s/kadwa/v10/rnCr-x5V0g7ipix7auM-mHnOSOuk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7ipiTGT8M.ttf"
    },
    {
        "family": "Kaisei Decol",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kaiseidecol/v9/bMrwmSqP45sidWf3QmfFW6iyW1EP22OjoA.ttf",
            "500": "https://fonts.gstatic.com/s/kaiseidecol/v9/bMrvmSqP45sidWf3QmfFW6iKr3gr00i_qb57kA.ttf",
            "700": "https://fonts.gstatic.com/s/kaiseidecol/v9/bMrvmSqP45sidWf3QmfFW6iK534r00i_qb57kA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kaiseidecol/v9/bMrwmSqP45sidWf3QmfFW6iCWlsL.ttf"
    },
    {
        "family": "Kaisei HarunoUmi",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kaiseiharunoumi/v9/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGsv5sM8u3mk.ttf",
            "500": "https://fonts.gstatic.com/s/kaiseiharunoumi/v9/HI_WiZQSLqBQoAHhK_C6N_nzy_jcIj_QlMcFwmC9FAU.ttf",
            "700": "https://fonts.gstatic.com/s/kaiseiharunoumi/v9/HI_WiZQSLqBQoAHhK_C6N_nzy_jcInfWlMcFwmC9FAU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kaiseiharunoumi/v9/HI_RiZQSLqBQoAHhK_C6N_nzy_jcKsrztA.ttf"
    },
    {
        "family": "Kaisei Opti",
        "variants": [
            "regular",
            "500",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kaiseiopti/v9/QldKNThJphYb8_g6c2nlIFle7KlmxuHx.ttf",
            "500": "https://fonts.gstatic.com/s/kaiseiopti/v9/QldXNThJphYb8_g6c2nlIGGqxY1u7f34DYwn.ttf",
            "700": "https://fonts.gstatic.com/s/kaiseiopti/v9/QldXNThJphYb8_g6c2nlIGHiw41u7f34DYwn.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kaiseiopti/v9/QldKNThJphYb8_g6c2nlIGlf5q0.ttf"
    },
    {
        "family": "Kaisei Tokumin",
        "variants": [
            "regular",
            "500",
            "700",
            "800"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kaiseitokumin/v9/Gg8sN5wdZg7xCwuMsylww2ZiQkJf1l0pj946.ttf",
            "500": "https://fonts.gstatic.com/s/kaiseitokumin/v9/Gg8vN5wdZg7xCwuMsylww2ZiQnqr_3khpMIzeI6v.ttf",
            "700": "https://fonts.gstatic.com/s/kaiseitokumin/v9/Gg8vN5wdZg7xCwuMsylww2ZiQnrj-XkhpMIzeI6v.ttf",
            "800": "https://fonts.gstatic.com/s/kaiseitokumin/v9/Gg8vN5wdZg7xCwuMsylww2ZiQnr_-nkhpMIzeI6v.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kaiseitokumin/v9/Gg8sN5wdZg7xCwuMsylww2ZiQnJe3Fk.ttf"
    },
    {
        "family": "Kalam",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf",
            "regular": "https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMuhWMibDszkB.ttf",
            "700": "https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMthXOCI.ttf"
    },
    {
        "family": "Kalnia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kalnia/v2/11hAGpPCwUbbYwZDNGatWKaZ3g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW9illxQEtM.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 100,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Kalnia Glaze",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kalniaglaze/v1/wlp2gwHCBUNjrGrfu-hwowNvaAJSA_JN3Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kalniaglaze/v1/wlpQgwHCBUNjrGrfu-hwowN1YyC-42Lu26VHf2LtOkAod0wTxm2tFYdL6knYI_w.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 100,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ],
        "colorCapabilities": [
            "COLRv1"
        ]
    },
    {
        "family": "Kameron",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kameron/v16/vm82dR7vXErQxuznsL4wL-XIYH8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4Ne3cYcat5c.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Kanit",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/kanit/v15/nKKX-Go6G5tXcr72GwWKcaxALFs.ttf",
            "100italic": "https://fonts.gstatic.com/s/kanit/v15/nKKV-Go6G5tXcraQI2GAdY5FPFtrGw.ttf",
            "200": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5aOiWgX6BJNUJy.ttf",
            "200italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI82hVaRrMFJyAu4.ttf",
            "300": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4-OSWgX6BJNUJy.ttf",
            "300italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI6miVaRrMFJyAu4.ttf",
            "regular": "https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcoaSEQGodLxA.ttf",
            "italic": "https://fonts.gstatic.com/s/kanit/v15/nKKX-Go6G5tXcraQGwWKcaxALFs.ttf",
            "500": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5mOCWgX6BJNUJy.ttf",
            "500italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI_GjVaRrMFJyAu4.ttf",
            "600": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5KPyWgX6BJNUJy.ttf",
            "600italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI92kVaRrMFJyAu4.ttf",
            "700": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4uPiWgX6BJNUJy.ttf",
            "700italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI7mlVaRrMFJyAu4.ttf",
            "800": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4yPSWgX6BJNUJy.ttf",
            "800italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI6WmVaRrMFJyAu4.ttf",
            "900": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4WPCWgX6BJNUJy.ttf",
            "900italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI4GnVaRrMFJyAu4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraTGwU.ttf"
    },
    {
        "family": "Kantumruy Pro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "khmer",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2AY5aECkp34vEBSPFOmJxwpETLeo2gm2U.ttf",
            "italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2eY5aECkp34vEBSPFOmJxwlEbBfq-li2VSyw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urHs1OXo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Karantina",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxXCgf-P5Oaiw4cw.ttf",
            "regular": "https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMABJ8AA78NVSYw.ttf",
            "700": "https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxTC8f-P5Oaiw4cw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMAB58Qo_.ttf"
    },
    {
        "family": "Karla",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/karla/v31/qkBbXvYC6trAT4RSJN225aZO.ttf",
            "italic": "https://fonts.gstatic.com/s/karla/v31/qkBVXvYC6trAT7RQLtmU4LZOgAU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaFUlP.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Karma",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjDY8Z_uqzGQC_-.ttf",
            "regular": "https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGIBvS-J3kbDP.ttf",
            "500": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLibYsZ_uqzGQC_-.ttf",
            "600": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLi3ZcZ_uqzGQC_-.ttf",
            "700": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjTZMZ_uqzGQC_-.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGLBuQeY.ttf"
    },
    {
        "family": "Katibeh",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/katibeh/v19/ZGjXol5MQJog4bxDaC1RVDNdGDs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/katibeh/v19/ZGjXol5MQJog4bxDWCxbUA.ttf"
    },
    {
        "family": "Kaushan Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kaushanscript/v18/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kaushanscript/v18/vm8vdRfvXFLG3OLnsO15WYS5DG7-wNc.ttf"
    },
    {
        "family": "Kavivanar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kavivanar/v20/o-0IIpQgyXYSwhxP7_Jb4j5Ba_2c7A.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kavivanar/v20/o-0IIpQgyXYSwhxP7_Jr4zRF.ttf"
    },
    {
        "family": "Kavoon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kavoon/v23/pxiFyp4_scRYhlU4NLr6f1pdEQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kavoon/v23/pxiFyp4_scRYhlUINbD-.ttf"
    },
    {
        "family": "Kay Pho Du",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "kayah-li",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kayphodu/v2/jizfREFPvGNOx-jhPwHR4OmnLD0Z4zM.ttf",
            "500": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2B2OCDUy_zoyk18.ttf",
            "600": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2DGJCDUy_zoyk18.ttf",
            "700": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2FWICDUy_zoyk18.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kayphodu/v2/jizfREFPvGNOx-jhPwHR0OitKA.ttf"
    },
    {
        "family": "Kdam Thmor Pro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kdamthmorpro/v4/EJRPQgAzVdcI-Qdvt34jzurnGA7_j89I8ZWb.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kdamthmorpro/v4/EJRPQgAzVdcI-Qdvt34jzurnGD7-hcs.ttf"
    },
    {
        "family": "Keania One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/keaniaone/v24/zOL54pXJk65E8pXardnuycRuv-hHkOs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/keaniaone/v24/zOL54pXJk65E8pXardnu-cVkuw.ttf"
    },
    {
        "family": "Kelly Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kellyslab/v17/-W_7XJX0Rz3cxUnJC5t6TkMBf50kbiM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kellyslab/v17/-W_7XJX0Rz3cxUnJC5t6fkILew.ttf"
    },
    {
        "family": "Kenia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kenia/v28/jizURE5PuHQH9qCONUGswfGM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kenia/v28/jizURE5PuHQH9pCPP0U.ttf"
    },
    {
        "family": "Khand",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bL5cFE3ZwaH__-C.ttf",
            "regular": "https://fonts.gstatic.com/s/khand/v17/TwMA-IINQlQQ0YpVWHU_TBqO.ttf",
            "500": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKhcVE3ZwaH__-C.ttf",
            "600": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKNdlE3ZwaH__-C.ttf",
            "700": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bLpd1E3ZwaH__-C.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/khand/v17/TwMA-IINQlQQ0bpUUnE.ttf"
    },
    {
        "family": "Khmer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer"
        ],
        "version": "v29",
        "lastModified": "2023-08-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/khmer/v29/MjQImit_vPPwpF-BpN2EeYmD.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/khmer/v29/MjQImit_vPPwpG-Artk.ttf"
    },
    {
        "family": "Khula",
        "variants": [
            "300",
            "regular",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-ljCvUrC59XwXD.ttf",
            "regular": "https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7FcJpA_chzJ0.ttf",
            "600": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G_RiivUrC59XwXD.ttf",
            "700": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-1iyvUrC59XwXD.ttf",
            "800": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-piCvUrC59XwXD.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7GcIrgs.ttf"
    },
    {
        "family": "Kings",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kings/v7/8AtnGsK4O5CYXU_Iq6GSPaHS.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kings/v7/8AtnGsK4O5CYXX_JoaU.ttf"
    },
    {
        "family": "Kirang Haerang",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kiranghaerang/v21/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kiranghaerang/v21/E21-_dn_gvvIjhYON1lpIU4-bfquUvI.ttf"
    },
    {
        "family": "Kite One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kiteone/v22/70lQu7shLnA_E02vyq1b6HnGO4uA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kiteone/v22/70lQu7shLnA_E02vyp1a4n0.ttf"
    },
    {
        "family": "Kiwi Maru",
        "variants": [
            "300",
            "regular",
            "500"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/kiwimaru/v15/R70djykGkuuDep-hRg6gNCi0Vxn9R5ShnA.ttf",
            "regular": "https://fonts.gstatic.com/s/kiwimaru/v15/R70YjykGkuuDep-hRg6YmACQXzLhTg.ttf",
            "500": "https://fonts.gstatic.com/s/kiwimaru/v15/R70djykGkuuDep-hRg6gbCm0Vxn9R5ShnA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kiwimaru/v15/R70YjykGkuuDep-hRg6omQqU.ttf"
    },
    {
        "family": "Klee One",
        "variants": [
            "regular",
            "600"
        ],
        "subsets": [
            "cyrillic",
            "greek-ext",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kleeone/v8/LDIxapCLNRc6A8oT4q4AOeekWPrP.ttf",
            "600": "https://fonts.gstatic.com/s/kleeone/v8/LDI2apCLNRc6A8oT4pbYF8Osc-bGkqIw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kleeone/v8/LDIxapCLNRc6A8oT4p4BM-M.ttf"
    },
    {
        "family": "Knewave",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSItSq9-trEvlQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSIhSu3_g.ttf"
    },
    {
        "family": "KoHo",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf",
            "200italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf",
            "300": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf",
            "300italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf",
            "regular": "https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNBikefJbSOos.ttf",
            "italic": "https://fonts.gstatic.com/s/koho/v16/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf",
            "500": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf",
            "500italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf",
            "600": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf",
            "600italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf",
            "700": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf",
            "700italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNNigUeA.ttf"
    },
    {
        "family": "Kodchasan",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeR1Cggeqo3eMeoA.ttf",
            "200italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUlIgOCs_-YOoIgN.ttf",
            "300": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeI1Oggeqo3eMeoA.ttf",
            "300italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUksg-Cs_-YOoIgN.ttf",
            "regular": "https://fonts.gstatic.com/s/kodchasan/v17/1cXxaUPOAJv9sG4I-DJmj3uEicG01A.ttf",
            "italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cX3aUPOAJv9sG4I-DJWjXGAq8Sk1PoH.ttf",
            "500": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJee1Kggeqo3eMeoA.ttf",
            "500italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUl0guCs_-YOoIgN.ttf",
            "600": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeV1Wggeqo3eMeoA.ttf",
            "600italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUlYheCs_-YOoIgN.ttf",
            "700": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeM1Sggeqo3eMeoA.ttf",
            "700italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUk8hOCs_-YOoIgN.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kodchasan/v17/1cXxaUPOAJv9sG4I-DJWjnGA.ttf"
    },
    {
        "family": "Kode Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kodemono/v2/A2BYn5pb0QgtVEPFnlY-mojxW5KJuQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kodemono/v2/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq5myxDuZbA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Koh Santepheap",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v11",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMfW3p6SJbwyGj2rBZyeOrTjNuFHVyTtjNJUWU.ttf",
            "300": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtNP3y5mD9ASHz5.ttf",
            "regular": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMdW3p6SJbwyGj2rBZyeOrTjOPhF1ixsyNJ.ttf",
            "700": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtdOHy5mD9ASHz5.ttf",
            "900": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtlOny5mD9ASHz5.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMdW3p6SJbwyGj2rBZyeOrTjNPgHVw.ttf"
    },
    {
        "family": "Kolker Brush",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kolkerbrush/v6/iJWDBXWRZjfKWdvmzwvvog3-7KJ6x8qNUQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kolkerbrush/v6/iJWDBXWRZjfKWdvmzwvvog3O7ah-.ttf"
    },
    {
        "family": "Konkhmer Sleokchher",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/konkhmersleokchher/v2/_Xmw-GE-rjmabA_M-aPOZOsCrUv825LFI3507E0d-W0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/konkhmersleokchher/v2/_Xmw-GE-rjmabA_M-aPOZOsCrUv825LFE39-6A.ttf"
    },
    {
        "family": "Kosugi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kosugi/v15/pxiFyp4_v8FCjlI4NLr6f1pdEQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kosugi/v15/pxiFyp4_v8FCjlIINbD-.ttf"
    },
    {
        "family": "Kosugi Maru",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGiTqivr9iBq_.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGhTrgP4.ttf"
    },
    {
        "family": "Kotta One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWqPHA3s5dwt7w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWq_HQfo.ttf"
    },
    {
        "family": "Koulen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/koulen/v28/AMOQz46as3KIBPeWgnA9kuYMUg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/koulen/v28/AMOQz46as3KIBPemg3o5.ttf"
    },
    {
        "family": "Kranky",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kranky/v28/hESw6XVgJzlPsFnMpheEZo_H_w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kranky/v28/hESw6XVgJzlPsFn8px2A.ttf"
    },
    {
        "family": "Kreon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v37",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kreon/v37/t5tuIRIUKY-TFEXAeWm_rb7p.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtiWez8b.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Kristi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kristi/v21/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kristi/v21/uK_y4ricdeU6zwdhCxcX.ttf"
    },
    {
        "family": "Krona One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUCMY-h3cWkWg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUyMIWl.ttf"
    },
    {
        "family": "Krub",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZo47KLF4R6gWaf8.ttf",
            "200italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQiwLByQ4oTef_6gQ.ttf",
            "300": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZuo4KLF4R6gWaf8.ttf",
            "300italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQipLNyQ4oTef_6gQ.ttf",
            "regular": "https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYXkYQDLlTW6E.ttf",
            "italic": "https://fonts.gstatic.com/s/krub/v9/sZlFdRyC6CRYbkQaCJtWS6EPcA.ttf",
            "500": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZrI5KLF4R6gWaf8.ttf",
            "500italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi_LJyQ4oTef_6gQ.ttf",
            "600": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZp4-KLF4R6gWaf8.ttf",
            "600italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi0LVyQ4oTef_6gQ.ttf",
            "700": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZvo_KLF4R6gWaf8.ttf",
            "700italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQitLRyQ4oTef_6gQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYbkcaCA.ttf"
    },
    {
        "family": "Kufam",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kufam/v21/C8ct4cY7pG7w_p6CLDwZwmGE.ttf",
            "italic": "https://fonts.gstatic.com/s/kufam/v21/C8cj4cY7pG7w_q6AJjg7x3GEfRA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqkLqAqN.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Kulim Park",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjJYNwa5aZbUvGjU.ttf",
            "200italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUKa9QYZcqCjVVUA.ttf",
            "300": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjPIOwa5aZbUvGjU.ttf",
            "300italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUTaxQYZcqCjVVUA.ttf",
            "regular": "https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwtF4m5aZxebw.ttf",
            "italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdN59secq3hflz1Uu3IwhFws4YR0abw2Aw.ttf",
            "600": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjIYIwa5aZbUvGjU.ttf",
            "600italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUOapQYZcqCjVVUA.ttf",
            "700": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjOIJwa5aZbUvGjU.ttf",
            "700italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUXatQYZcqCjVVUA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwhF8s4Q.ttf"
    },
    {
        "family": "Kumar One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kumarone/v21/bMr1mS-P958wYi6YaGeGNO6WU3oT0g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kumarone/v21/bMr1mS-P958wYi6YaGe2NeSS.ttf"
    },
    {
        "family": "Kumar One Outline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDwRQu8EGKF.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9wDxTw8.ttf"
    },
    {
        "family": "Kumbh Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kumbhsans/v22/c4ml1n92AsfhuCq6tVsauodX-Kq-QUI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQkZYw8L7q.ttf",
        "axes": [
            {
                "tag": "YOPQ",
                "start": 40,
                "end": 300
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Kurale",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/kurale/v12/4iCs6KV9e9dXjho6eAT3v02QFg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/kurale/v12/4iCs6KV9e9dXjhoKeQ7z.ttf"
    },
    {
        "family": "LXGW WenKai Mono TC",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "lisu",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/lxgwwenkaimonotc/v5/pxiVyos4iPVgyWx9WtufHnsIf5nkaB0HssKqhvJljXmo2SA.ttf",
            "regular": "https://fonts.gstatic.com/s/lxgwwenkaimonotc/v5/pxiYyos4iPVgyWx9WtufHnsIf5nkaB0Him6CovpOkXA.ttf",
            "700": "https://fonts.gstatic.com/s/lxgwwenkaimonotc/v5/pxiVyos4iPVgyWx9WtufHnsIf5nkaB0HstKthvJljXmo2SA.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lxgwwenkaimonotc/v5/pxiYyos4iPVgyWx9WtufHnsIf5nkaB0Hum-Ipg.ttf"
    },
    {
        "family": "LXGW WenKai TC",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "lisu",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/lxgwwenkaitc/v5/w8gAH20td8wNsI3f40DmtXZb4_MmBfkpTClICyan.ttf",
            "regular": "https://fonts.gstatic.com/s/lxgwwenkaitc/v5/w8gDH20td8wNsI3f40DmtXZb48uKLd0hZzVB.ttf",
            "700": "https://fonts.gstatic.com/s/lxgwwenkaitc/v5/w8gAH20td8wNsI3f40DmtXZb4_M2AvkpTClICyan.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lxgwwenkaitc/v5/w8gDH20td8wNsI3f40DmtXZb4_uLJ9k.ttf"
    },
    {
        "family": "La Belle Aurore",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/labelleaurore/v20/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/labelleaurore/v20/RrQIbot8-mNYKnGNDkWlocovHeI4Gu2B.ttf"
    },
    {
        "family": "Labrada",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/labrada/v2/ieV-2Y9HLWefIpOyDV5ALUIfEcs.ttf",
            "italic": "https://fonts.gstatic.com/s/labrada/v2/ieV82Y9HLWefIpOyPVxKKWAaActCxg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSzM5SvM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lacquer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBB7-AXhttQ5I.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBN76KWg.ttf"
    },
    {
        "family": "Laila",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLzxogNAh14nVcfe.ttf",
            "regular": "https://fonts.gstatic.com/s/laila/v15/LYjMdG_8nE8jDIRdiidIrEIu.ttf",
            "500": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLypowNAh14nVcfe.ttf",
            "600": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLyFpANAh14nVcfe.ttf",
            "700": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLzhpQNAh14nVcfe.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/laila/v15/LYjMdG_8nE8jDLRcgCM.ttf"
    },
    {
        "family": "Lakki Reddy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lakkireddy/v21/S6u5w49MUSzD9jlCPmvLZQfox9k97-xZ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lakkireddy/v21/S6u5w49MUSzD9jlCPmvLZTfpzd0.ttf"
    },
    {
        "family": "Lalezar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82UaDyIiL0RCg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82YaH4Jg.ttf"
    },
    {
        "family": "Lancelot",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lancelot/v26/J7acnppxBGtQEulG4JY4xJ9CGyAa.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lancelot/v26/J7acnppxBGtQEulG4KY5zps.ttf"
    },
    {
        "family": "Langar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/langar/v27/kJEyBukW7AIlgjGVrTVZ99sqrQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/langar/v27/kJEyBukW7AIlgjGlrD9d.ttf"
    },
    {
        "family": "Lateef",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0bjygbqTb9nQ-RA.ttf",
            "300": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0Cj-gbqTb9nQ-RA.ttf",
            "regular": "https://fonts.gstatic.com/s/lateef/v30/hESw6XVnNCxEvkbMpheEZo_H_w.ttf",
            "500": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0Uj6gbqTb9nQ-RA.ttf",
            "600": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0fjmgbqTb9nQ-RA.ttf",
            "700": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0GjigbqTb9nQ-RA.ttf",
            "800": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0BjugbqTb9nQ-RA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lateef/v30/hESw6XVnNCxEvkb8px2A.ttf"
    },
    {
        "family": "Lato",
        "variants": [
            "100",
            "100italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf",
            "100italic": "https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf",
            "300": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf",
            "300italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf",
            "regular": "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf",
            "italic": "https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf",
            "700": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf",
            "700italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf",
            "900": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf",
            "900italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxgwWw.ttf"
    },
    {
        "family": "Lavishly Yours",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lavishlyyours/v5/jizDREVIvGwH5OjiZmX9r5z_WxUY0TY7ikbI.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lavishlyyours/v5/jizDREVIvGwH5OjiZmX9r5z_WyUZ2zI.ttf"
    },
    {
        "family": "League Gothic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/leaguegothic/v11/qFdC35CBi4tvBz81xy7WG7ep4h8ij1I7LLE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/leaguegothic/v11/qFdR35CBi4tvBz81xy7WG7ep-BQAY7Krj7feObpH_9ang9A.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            }
        ]
    },
    {
        "family": "League Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/leaguescript/v28/CSR54zpSlumSWj9CGVsoBZdeaNNUuOwkC2s.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/leaguescript/v28/CSR54zpSlumSWj9CGVsoBZdeWNJevA.ttf"
    },
    {
        "family": "League Spartan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEqBuEW6A0lliaV_m88ja5TwsZ3J5i1DJZg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oTZBc1Y.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Leckerli One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/leckerlione/v20/V8mCoQH8VCsNttEnxnGQ-1itLZxcBtItFw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/leckerlione/v20/V8mCoQH8VCsNttEnxnGQ-1idLJZY.ttf"
    },
    {
        "family": "Ledger",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sxm8DwHTBhHw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sBmsr0.ttf"
    },
    {
        "family": "Lekton",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lekton/v20/SZc43FDmLaWmWpBeXxfonUPL6Q.ttf",
            "italic": "https://fonts.gstatic.com/s/lekton/v20/SZc63FDmLaWmWpBuXR3sv0bb6StO.ttf",
            "700": "https://fonts.gstatic.com/s/lekton/v20/SZc73FDmLaWmWpBm4zjMlWjX4DJXgQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lekton/v20/SZc43FDmLaWmWpBuXh3s.ttf"
    },
    {
        "family": "Lemon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lemon/v17/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lemon/v17/HI_EiYEVKqRMq3jAQ5E.ttf"
    },
    {
        "family": "Lemonada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lemonada/v28/0QIjMXFD9oygTWy_R_tOtfWm8qTX.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeutGmP-Y.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Lexend",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexend/v23/wlpwgwvFAVdoq2_f_K4V0WdXaQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexend/v23/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsWzLFnfw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Deca",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexenddeca/v21/K2F1fZFYk-dHSE0UPPuwQ6qgLS76ZHOM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1wrRnG.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Exa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendexa/v30/UMBXrPdOoHOnxExyjdBeWirXArM58BY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TpbGFps.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Giga",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendgiga/v25/PlI5Fl67Mah5Y8yMHE7lkVxEt8CwfGaD.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li06sAs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Mega",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendmega/v25/qFdA35aBi5JtHD41zSTFEv7K6BsAikI7.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmfvOam.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Peta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendpeta/v27/BXRvvFPGjeLPh0kCfI4OkE_1c8Tf1IW3.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SBCWlwq.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Tera",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendtera/v27/RrQUbo98_jt_IXnBPwCWtZhARYMgGtWA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMjd5bw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Lexend Zetta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lexendzetta/v28/ll87K2KYXje7CdOFnEWcU8soliQejRR7AQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG4z9pag.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Libre Barcode 128",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode128/v28/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode128/v28/cIfnMbdUsUoiW3O_hVviCwVjuLtXeK_B9Ac.ttf"
    },
    {
        "family": "Libre Barcode 128 Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode128text/v28/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV3Y6M90w4k.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode128text/v28/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5QVzS7A.ttf"
    },
    {
        "family": "Libre Barcode 39",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode39/v21/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode39/v21/-nFnOHM08vwC6h8Li1eQnP_AHzI2G_Zx1w.ttf"
    },
    {
        "family": "Libre Barcode 39 Extended",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode39extended/v27/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNRCOZ1GOBw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode39extended/v27/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgTNVIPQ.ttf"
    },
    {
        "family": "Libre Barcode 39 Extended Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode39extendedtext/v27/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fLIiPopNNA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode39extendedtext/v27/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP6Mfbgm.ttf"
    },
    {
        "family": "Libre Barcode 39 Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcode39text/v28/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031RRL-9rYaw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcode39text/v28/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-0H1B5P.ttf"
    },
    {
        "family": "Libre Barcode EAN13 Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebarcodeean13text/v21/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96PAGEki52WfA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebarcodeean13text/v21/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96_AWsg.ttf"
    },
    {
        "family": "Libre Baskerville",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf",
            "italic": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcaxYaDc2V2ro.ttf",
            "700": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNfaxY.ttf"
    },
    {
        "family": "Libre Bodoni",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librebodoni/v5/_Xmr-H45qDWDYULr5OfyZudNxgKQu_avAg.ttf",
            "italic": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm1-H45qDWDYULr5OfyZud9xAiUmfO_AmZf.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6I1f8W3TdA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Libre Caslon Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librecaslondisplay/v16/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lRdRhtCC4d.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librecaslondisplay/v16/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr1lQfxw.ttf"
    },
    {
        "family": "Libre Caslon Text",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_3eNrHgO1.ttf",
            "italic": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT678IGsGw1aF1JU10PUbTvNNaDMfq91-dJGxO1q9o.ttf",
            "700": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8sdjNR-8ssPt.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT878IGsGw1aF1JU10PUbTvNNaDMfq-1-c.ttf"
    },
    {
        "family": "Libre Franklin",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/librefranklin/v18/jizDREVItHgc8qDIbSTKq4XkRhUY0TY7ikbI.ttf",
            "italic": "https://fonts.gstatic.com/s/librefranklin/v18/jizBREVItHgc8qDIbSTKq4XkRiUa2zIZj1bIkNo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/librefranklin/v18/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWkBtDM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Licorice",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/licorice/v6/t5tjIR8TMomTCAyjNk23hqLgzCHu.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/licorice/v6/t5tjIR8TMomTCAyjNn22jKY.ttf"
    },
    {
        "family": "Life Savers",
        "variants": [
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lifesavers/v21/ZXuie1UftKKabUQMgxAal_lrFgpbuNvB.ttf",
            "700": "https://fonts.gstatic.com/s/lifesavers/v21/ZXu_e1UftKKabUQMgxAal8HXOS5Tk8fIpPRW.ttf",
            "800": "https://fonts.gstatic.com/s/lifesavers/v21/ZXu_e1UftKKabUQMgxAal8HLOi5Tk8fIpPRW.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lifesavers/v21/ZXuie1UftKKabUQMgxAal8lqHA4.ttf"
    },
    {
        "family": "Lilita One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lilitaone/v15/i7dPIFZ9Zz-WBtRtedDbUEZ2RFq7AwU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lilitaone/v15/i7dPIFZ9Zz-WBtRtedDbYEd8QA.ttf"
    },
    {
        "family": "Lily Script One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gsSjQNsRMg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLSIg86n.ttf"
    },
    {
        "family": "Limelight",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/limelight/v19/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/limelight/v19/XLYkIZL7aopJVbZJHDuoPOlC.ttf"
    },
    {
        "family": "Linden Hill",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lindenhill/v24/-F61fjxoKSg9Yc3hZgO8ygFI7CwC009k.ttf",
            "italic": "https://fonts.gstatic.com/s/lindenhill/v24/-F63fjxoKSg9Yc3hZgO8yjFK5igg1l9kn-s.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lindenhill/v24/-F61fjxoKSg9Yc3hZgO8yjFJ5ig.ttf"
    },
    {
        "family": "Linefont",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v5",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/linefont/v5/dg4m_pzpoqcLKUIzVetHpbglShon.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY4UMXu_nnQ.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 25,
                "end": 200
            },
            {
                "tag": "wght",
                "start": 4,
                "end": 1000
            }
        ]
    },
    {
        "family": "Lisu Bosa",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "lisu"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXX2DGRdbY1P1Sbg.ttf",
            "200italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXkuRNzc9vhCblye.ttf",
            "300": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXO2PGRdbY1P1Sbg.ttf",
            "300italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlKR9zc9vhCblye.ttf",
            "regular": "https://fonts.gstatic.com/s/lisubosa/v2/3XFoErkv240fsdmJRJQvl0viTf3E3Q.ttf",
            "italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFuErkv240fsdmJRJQflUHmb_jU3eRL.ttf",
            "500": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXY2LGRdbY1P1Sbg.ttf",
            "500italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXkSRtzc9vhCblye.ttf",
            "600": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXT2XGRdbY1P1Sbg.ttf",
            "600italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXk-Qdzc9vhCblye.ttf",
            "700": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXK2TGRdbY1P1Sbg.ttf",
            "700italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlaQNzc9vhCblye.ttf",
            "800": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXN2fGRdbY1P1Sbg.ttf",
            "800italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlGQ9zc9vhCblye.ttf",
            "900": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXE2bGRdbY1P1Sbg.ttf",
            "900italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXliQtzc9vhCblye.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lisubosa/v2/3XFoErkv240fsdmJRJQflkHm.ttf"
    },
    {
        "family": "Literata",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v35",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/literata/v35/or3hQ6P12-iJxAIgLbT3LLQ1niPn.ttf",
            "italic": "https://fonts.gstatic.com/s/literata/v35/or3nQ6P12-iJxAIgLYT1JrAXmzPnnks.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_J_fWSQ.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 7,
                "end": 72
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Liu Jian Mao Cao",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/liujianmaocao/v20/845DNN84HJrccNonurqXILGpvCOoferVKGWsUo8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/liujianmaocao/v20/845DNN84HJrccNonurqXILGpvCOoTevfLA.ttf"
    },
    {
        "family": "Livvic",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/livvic/v14/rnCr-x1S2hzjrlffC-M-mHnOSOuk.ttf",
            "100italic": "https://fonts.gstatic.com/s/livvic/v14/rnCt-x1S2hzjrlfXbdtakn3sTfukQHs.ttf",
            "200": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffp8IeslfCQfK9WQ.ttf",
            "200italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdv2s13GY_etWWIJ.ttf",
            "300": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffw8EeslfCQfK9WQ.ttf",
            "300italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbduSsF3GY_etWWIJ.ttf",
            "regular": "https://fonts.gstatic.com/s/livvic/v14/rnCp-x1S2hzjrlfnb-k6unzeSA.ttf",
            "italic": "https://fonts.gstatic.com/s/livvic/v14/rnCr-x1S2hzjrlfXbeM-mHnOSOuk.ttf",
            "500": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffm8AeslfCQfK9WQ.ttf",
            "500italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdvKsV3GY_etWWIJ.ttf",
            "600": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlfft8ceslfCQfK9WQ.ttf",
            "600italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdvmtl3GY_etWWIJ.ttf",
            "700": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlff08YeslfCQfK9WQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbduCt13GY_etWWIJ.ttf",
            "900": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlff68QeslfCQfK9WQ.ttf",
            "900italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdu6tV3GY_etWWIJ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/livvic/v14/rnCp-x1S2hzjrlfXbuM-.ttf"
    },
    {
        "family": "Lobster",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9_oWsMqEzSJQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9zoSmNg.ttf"
    },
    {
        "family": "Lobster Two",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lobstertwo/v20/BngMUXZGTXPUvIoyV6yN59fK7KSJ4ACD.ttf",
            "italic": "https://fonts.gstatic.com/s/lobstertwo/v20/BngOUXZGTXPUvIoyV6yN5-fI5qCr5RCDY_k.ttf",
            "700": "https://fonts.gstatic.com/s/lobstertwo/v20/BngRUXZGTXPUvIoyV6yN5-92w4CByxyKeuDp.ttf",
            "700italic": "https://fonts.gstatic.com/s/lobstertwo/v20/BngTUXZGTXPUvIoyV6yN5-fI3hyEwRiof_DpXMY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lobstertwo/v20/BngMUXZGTXPUvIoyV6yN5-fL5qA.ttf"
    },
    {
        "family": "Londrina Outline",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/londrinaoutline/v27/C8c44dM8vmb14dfsZxhetg3pDH-SfuoxrSKMDvI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/londrinaoutline/v27/C8c44dM8vmb14dfsZxhetg3pDH-STus7qQ.ttf"
    },
    {
        "family": "Londrina Shadow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/londrinashadow/v26/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRlaSxkrMCQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/londrinashadow/v26/oPWX_kB4kOQoWNJmjxLV5JuoCUlnR1yW.ttf"
    },
    {
        "family": "Londrina Sketch",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/londrinasketch/v25/c4m41npxGMTnomOHtRU68eIJn8qfWWn5Pos6CA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/londrinasketch/v25/c4m41npxGMTnomOHtRU68eIJn8qvWGP9.ttf"
    },
    {
        "family": "Londrina Solid",
        "variants": [
            "100",
            "300",
            "regular",
            "900"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/londrinasolid/v17/flUjRq6sw40kQEJxWNgkLuudGfs9KBYesZHhV64.ttf",
            "300": "https://fonts.gstatic.com/s/londrinasolid/v17/flUiRq6sw40kQEJxWNgkLuudGfv1CjY0n53oTrcL.ttf",
            "regular": "https://fonts.gstatic.com/s/londrinasolid/v17/flUhRq6sw40kQEJxWNgkLuudGcNZIhI8tIHh.ttf",
            "900": "https://fonts.gstatic.com/s/londrinasolid/v17/flUiRq6sw40kQEJxWNgkLuudGfvdDzY0n53oTrcL.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/londrinasolid/v17/flUhRq6sw40kQEJxWNgkLuudGfNYKBY.ttf"
    },
    {
        "family": "Long Cang",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRgrXArXN7HWQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRQrHov.ttf"
    },
    {
        "family": "Lora",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v35",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lora/v35/0QIvMX1D_JOuAw3xItNPh_A.ttf",
            "italic": "https://fonts.gstatic.com/s/lora/v35/0QIhMX1D_JOuMw_7JvFKl_C28g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787weuxJHkqg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Love Light",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lovelight/v6/t5tlIR0TNJyZWimpNAXDjKbCyTHuspo.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lovelight/v6/t5tlIR0TNJyZWimpNAXDvKfIzQ.ttf"
    },
    {
        "family": "Love Ya Like A Sister",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/loveyalikeasister/v22/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78nZcsGGycA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/loveyalikeasister/v22/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-BeL83xY.ttf"
    },
    {
        "family": "Loved by the King",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lovedbytheking/v21/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiNlXFu2R64.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lovedbytheking/v21/Gw6gwdP76VDVJNXerebZxUMeRXUF2MiMn3U.ttf"
    },
    {
        "family": "Lovers Quarrel",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/loversquarrel/v23/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAsY7pO6siz.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/loversquarrel/v23/Yq6N-LSKXTL-5bCy8ksBzpQ_-wAtab4.ttf"
    },
    {
        "family": "Luckiest Guy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/luckiestguy/v22/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/luckiestguy/v22/_gP_1RrxsjcxVyin9l9n_j2hS95z.ttf"
    },
    {
        "family": "Lugrasimo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lugrasimo/v4/qkBXXvoF_s_eT9c7Y7ae5JRLkAXbMQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lugrasimo/v4/qkBXXvoF_s_eT9c7Y7au5Z5P.ttf"
    },
    {
        "family": "Lumanosimo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lumanosimo/v4/K2F0fZBYg_JDSEZHEfO8AoqKAyLzfWo.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lumanosimo/v4/K2F0fZBYg_JDSEZHEfO8MouABw.ttf"
    },
    {
        "family": "Lunasima",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lunasima/v1/wEO-EBvPh9RSOj7JFAwle94H1VIe.ttf",
            "700": "https://fonts.gstatic.com/s/lunasima/v1/wEO5EBvPh9RSOj7JFDSZVPoP_k4XiKvO.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lunasima/v1/wEO-EBvPh9RSOj7JFDwkcdo.ttf"
    },
    {
        "family": "Lusitana",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKxhaRuMiSct_.ttf",
            "700": "https://fonts.gstatic.com/s/lusitana/v13/CSR74z9ShvucWzsMKyDmaccqYtd2vfwk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKyhbTOc.ttf"
    },
    {
        "family": "Lustria",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOrCg5MtPyAcg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOnCkzNg.ttf"
    },
    {
        "family": "Luxurious Roman",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/luxuriousroman/v8/buEupou_ZcP1w0yTKxJJokVSmbpqYgckeo9RMw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/luxuriousroman/v8/buEupou_ZcP1w0yTKxJJokVSmbpaYw0g.ttf"
    },
    {
        "family": "Luxurious Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/luxuriousscript/v7/ahcCv9e7yydulT32KZ0rBIoD7DzMg0rOby1JtYk.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/luxuriousscript/v7/ahcCv9e7yydulT32KZ0rBIoD7DzMs0vEaw.ttf"
    },
    {
        "family": "M PLUS 1",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mplus1/v11/R70ZjygA28ymD4HgBVu4si6cViv4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mplus1/v11/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSaL83xY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "M PLUS 1 Code",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mplus1code/v13/ypvfbXOOx2xFpzmYJS3N2_J2nhhYtUb0gZk.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mplus1code/v13/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0EHoYJg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "M PLUS 1p",
        "variants": [
            "100",
            "300",
            "regular",
            "500",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/mplus1p/v30/e3tleuShHdiFyPFzBRrQnDQAUW3aq-5N.ttf",
            "300": "https://fonts.gstatic.com/s/mplus1p/v30/e3tmeuShHdiFyPFzBRrQVBYge0PWovdU4w.ttf",
            "regular": "https://fonts.gstatic.com/s/mplus1p/v30/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf",
            "500": "https://fonts.gstatic.com/s/mplus1p/v30/e3tmeuShHdiFyPFzBRrQDBcge0PWovdU4w.ttf",
            "700": "https://fonts.gstatic.com/s/mplus1p/v30/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf",
            "800": "https://fonts.gstatic.com/s/mplus1p/v30/e3tmeuShHdiFyPFzBRrQWBIge0PWovdU4w.ttf",
            "900": "https://fonts.gstatic.com/s/mplus1p/v30/e3tmeuShHdiFyPFzBRrQfBMge0PWovdU4w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mplus1p/v30/e3tjeuShHdiFyPFzBRrY-TQA.ttf"
    },
    {
        "family": "M PLUS 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mplus2/v10/7Au8p_Eq3gO_OGbGGgLW4EAFOUEH.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mplus2/v10/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa6lx1CD.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "M PLUS Code Latin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-MlyV-aXg7x7tULiNXXBA0Np4WMTUULmBGjSwZ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbA2i9GrA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 100,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "M PLUS Rounded 1c",
        "variants": [
            "100",
            "300",
            "regular",
            "500",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGCAYIAV6gnpUpoWwNkYvrugw9RuM3ixLsg6-av1x0.ttf",
            "300": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0q5psKxeqmzgRK.ttf",
            "regular": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWGzr8C7vav.ttf",
            "500": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM1y55sKxeqmzgRK.ttf",
            "700": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsKxeqmzgRK.ttf",
            "800": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0m4psKxeqmzgRK.ttf",
            "900": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0C45sKxeqmzgRK.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mplusrounded1c/v16/VdGEAYIAV6gnpUpoWwNkYvrugw9RuMWHxLs.ttf"
    },
    {
        "family": "Ma Shan Zheng",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXaRMTsDIRSfr0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXadMXmCA.ttf"
    },
    {
        "family": "Macondo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/macondo/v25/RrQQboN9-iB1IXmOS2XO0LBBd4Y.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/macondo/v25/RrQQboN9-iB1IXmOe2TE1A.ttf"
    },
    {
        "family": "Macondo Swash Caps",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/macondoswashcaps/v24/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oekoa_mm5bA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/macondoswashcaps/v24/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6-e0Ae.ttf"
    },
    {
        "family": "Mada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mada/v19/7Auwp_0qnzeSTTXMLCrX0kU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFnOkEkx0e0.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Madimi One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/madimione/v1/2V0YKIEADpA8U6RygDnZZFQoBoHMd2U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/madimione/v1/2V0YKIEADpA8U6RygDnZVFUiAg.ttf"
    },
    {
        "family": "Magra",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72k5xIDMfO-ed.ttf",
            "700": "https://fonts.gstatic.com/s/magra/v14/uK_w4ruaZus72nbNDxcXEPuUX1ow.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72n5wKjc.ttf"
    },
    {
        "family": "Maiden Orange",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/maidenorange/v29/kJE1BuIX7AUmhi2V4m08kb1XjOZdCZS8FY8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/maidenorange/v29/kJE1BuIX7AUmhi2V4m08kb1XvOdXDQ.ttf"
    },
    {
        "family": "Maitree",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklhGNWJGovLdh6OE.ttf",
            "300": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklnWOWJGovLdh6OE.ttf",
            "regular": "https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkrtmmfJmDoL4.ttf",
            "500": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrkli2PWJGovLdh6OE.ttf",
            "600": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklgGIWJGovLdh6OE.ttf",
            "700": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklmWJWJGovLdh6OE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkntiseA.ttf"
    },
    {
        "family": "Major Mono Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/majormonodisplay/v16/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCn2QIndPww.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/majormonodisplay/v16/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7C3eU.ttf"
    },
    {
        "family": "Mako",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mako/v19/H4coBX6Mmc_Z0ST09g478Lo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mako/v19/H4coBX6Mmc_Z4SX-8g.ttf"
    },
    {
        "family": "Mali",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QOLlKlRaJdbWgdY.ttf",
            "200italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8wlVQIfTTkdbJYA.ttf",
            "300": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QIbmKlRaJdbWgdY.ttf",
            "300italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8plZQIfTTkdbJYA.ttf",
            "regular": "https://fonts.gstatic.com/s/mali/v10/N0ba2SRONuN4eCrODlxxOd8.ttf",
            "italic": "https://fonts.gstatic.com/s/mali/v10/N0bU2SRONuN4SCjECn50Kd_PmA.ttf",
            "500": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QN7nKlRaJdbWgdY.ttf",
            "500italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8_ldQIfTTkdbJYA.ttf",
            "600": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QPLgKlRaJdbWgdY.ttf",
            "600italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj80lBQIfTTkdbJYA.ttf",
            "700": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QJbhKlRaJdbWgdY.ttf",
            "700italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8tlFQIfTTkdbJYA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mali/v10/N0ba2SRONuN4SCvECg.ttf"
    },
    {
        "family": "Mallanna",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mallanna/v14/hv-Vlzx-KEQb84YaDGwzEzRwVvJ-.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mallanna/v14/hv-Vlzx-KEQb84YaDFwyGTA.ttf"
    },
    {
        "family": "Maname",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala",
            "vietnamese"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/maname/v1/gNMFW3J8RpCx9my42FkGGI6q_Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/maname/v1/gNMFW3J8RpCx9myI2VMC.ttf"
    },
    {
        "family": "Mandali",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mandali/v15/LhWlMVbYOfASNfNUVFk1ZPdcKtA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mandali/v15/LhWlMVbYOfASNfNUZFg_YA.ttf"
    },
    {
        "family": "Manjari",
        "variants": [
            "100",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/manjari/v12/k3kSo8UPMOBO2w1UdbroK2vFIaOV8A.ttf",
            "regular": "https://fonts.gstatic.com/s/manjari/v12/k3kQo8UPMOBO2w1UTd7iL0nAMaM.ttf",
            "700": "https://fonts.gstatic.com/s/manjari/v12/k3kVo8UPMOBO2w1UdWLNC0HrLaqM6Q4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/manjari/v12/k3kQo8UPMOBO2w1Ufd_oKw.ttf"
    },
    {
        "family": "Manrope",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRsgC7S9XdZN8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_E-bw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Mansalva",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mansalva/v14/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mansalva/v14/aWB4m0aacbtDfvq5NKlkKYo.ttf"
    },
    {
        "family": "Manuale",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/manuale/v28/f0X20eas_8Z-TFZdBPbEw8nG6aY.ttf",
            "italic": "https://fonts.gstatic.com/s/manuale/v28/f0X00eas_8Z-TFZdNPTOx-vD-aYfeA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke4wC3zQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Marcellus",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF8FUfAL3EsHiA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF81UPoP.ttf"
    },
    {
        "family": "Marcellus SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLEPgdydGKikhA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_gNaZ.ttf"
    },
    {
        "family": "Marck Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marckscript/v20/nwpTtK2oNgBA3Or78gapdwuCzyI-aMPF7Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marckscript/v20/nwpTtK2oNgBA3Or78gapdwuyzig6.ttf"
    },
    {
        "family": "Margarine",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/margarine/v25/qkBXXvoE6trLT9Y7YLye5JRLkAXbMQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/margarine/v25/qkBXXvoE6trLT9Y7YLyu5Z5P.ttf"
    },
    {
        "family": "Marhey",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marhey/v6/x3dhck7Laq-T7wlhkYUldNsetg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBctwC2YdjA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Markazi Text",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/markazitext/v23/syk0-ydym6AtQaiEtX7yhqblpn-UJ1H6Uw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQf4IvAQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Marko One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/markoone/v23/9Btq3DFG0cnVM5lw1haaKpUfrHPzUw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/markoone/v23/9Btq3DFG0cnVM5lw1haqK58b.ttf"
    },
    {
        "family": "Marmelad",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marmelad/v18/Qw3eZQdSHj_jK2e-8tFLG-YMC0R8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marmelad/v18/Qw3eZQdSHj_jK2e-8uFKEeI.ttf"
    },
    {
        "family": "Martel",
        "variants": [
            "200",
            "300",
            "regular",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XVqekahRbX9vnDzw.ttf",
            "300": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XVzeoahRbX9vnDzw.ttf",
            "regular": "https://fonts.gstatic.com/s/martel/v11/PN_xRfK9oXHga0XtYcI-jT3L_w.ttf",
            "600": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XVuewahRbX9vnDzw.ttf",
            "700": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XV3e0ahRbX9vnDzw.ttf",
            "800": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XVwe4ahRbX9vnDzw.ttf",
            "900": "https://fonts.gstatic.com/s/martel/v11/PN_yRfK9oXHga0XV5e8ahRbX9vnDzw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/martel/v11/PN_xRfK9oXHga0XdYMg6.ttf"
    },
    {
        "family": "Martel Sans",
        "variants": [
            "200",
            "300",
            "regular",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hAX5suHFUknqMxQ.ttf",
            "300": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hBz5cuHFUknqMxQ.ttf",
            "regular": "https://fonts.gstatic.com/s/martelsans/v13/h0GsssGi7VdzDgKjM-4d8ijfze-PPlUu.ttf",
            "600": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hAH48uHFUknqMxQ.ttf",
            "700": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hBj4suHFUknqMxQ.ttf",
            "800": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hB_4cuHFUknqMxQ.ttf",
            "900": "https://fonts.gstatic.com/s/martelsans/v13/h0GxssGi7VdzDgKjM-4d8hBb4MuHFUknqMxQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/martelsans/v13/h0GsssGi7VdzDgKjM-4d8hjex-s.ttf"
    },
    {
        "family": "Martian Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/martianmono/v3/2V0aKIcADoYhV6w87xrTKjsiAqPJZ2Xx8w.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qu_6XBbo.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 112.5
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Marvel",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/marvel/v16/nwpVtKeoNgBV0qaIkV7ED366zg.ttf",
            "italic": "https://fonts.gstatic.com/s/marvel/v16/nwpXtKeoNgBV0qa4k1TALXuqzhA7.ttf",
            "700": "https://fonts.gstatic.com/s/marvel/v16/nwpWtKeoNgBV0qawLXHgB1WmxwkiYQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/marvel/v16/nwpQtKeoNgBV0qa4k2x8Al-i5QwyYdrc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/marvel/v16/nwpVtKeoNgBV0qa4kFTA.ttf"
    },
    {
        "family": "Mate",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mate/v17/m8JdjftRd7WZ2z28WoXSaLU.ttf",
            "italic": "https://fonts.gstatic.com/s/mate/v17/m8JTjftRd7WZ6z-2XqfXeLVdbw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mate/v17/m8JdjftRd7WZ6zy2Xg.ttf"
    },
    {
        "family": "Mate SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/matesc/v22/-nF8OGQ1-uoVr2wKyiXZ95OkJwA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/matesc/v22/-nF8OGQ1-uoVr2wK-iTT8w.ttf"
    },
    {
        "family": "Matemasie",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/matemasie/v2/OD5BuMCN3ne3Gmr7dlL3rEq4DL6w2w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/matemasie/v2/OD5BuMCN3ne3Gmr7dlLHrUC8.ttf"
    },
    {
        "family": "Material Icons",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v142",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNZIhI8tIHh.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDvNYKBY.ttf"
    },
    {
        "family": "Material Icons Outlined",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v109",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcdl5GuI2Ze.otf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhXcanZM.otf"
    },
    {
        "family": "Material Icons Round",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v108",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialiconsround/v108/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmMq_fTTvg-.otf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialiconsround/v108/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flzmLofU.otf"
    },
    {
        "family": "Material Icons Sharp",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v109",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialiconssharp/v109/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvSImEyc0vd.otf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialiconssharp/v109/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvlvVKGM.otf"
    },
    {
        "family": "Material Icons Two Tone",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v112",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialiconstwotone/v112/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu3NjDrH_77.otf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialiconstwotone/v112/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNBuwPDI.otf",
        "colorCapabilities": [
            "COLRv0"
        ]
    },
    {
        "family": "Material Symbols Outlined",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v210",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v210/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsfjAOVZBgs.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v210/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHNenZ9o.ttf",
        "axes": [
            {
                "tag": "FILL",
                "start": 0,
                "end": 1
            },
            {
                "tag": "GRAD",
                "start": -50,
                "end": 200
            },
            {
                "tag": "opsz",
                "start": 20,
                "end": 48
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Material Symbols Rounded",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v209",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialsymbolsrounded/v209/sykg-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190Fjzb6kLCeA_PQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialsymbolsrounded/v209/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rIelbxODLA.ttf",
        "axes": [
            {
                "tag": "FILL",
                "start": 0,
                "end": 1
            },
            {
                "tag": "GRAD",
                "start": -50,
                "end": 200
            },
            {
                "tag": "opsz",
                "start": 20,
                "end": 48
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Material Symbols Sharp",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v206",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/materialsymbolssharp/v206/gNMVW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4aWTSTGlMyd8.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/materialsymbolssharp/v206/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxOLogCPLVU.ttf",
        "axes": [
            {
                "tag": "FILL",
                "start": 0,
                "end": 1
            },
            {
                "tag": "GRAD",
                "start": -50,
                "end": 200
            },
            {
                "tag": "opsz",
                "start": 20,
                "end": 48
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Maven Pro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mavenpro/v36/7Au9p_AqnyWWAxW2Wk32ym4JMFge0g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX21nGjpw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "McLaren",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mclaren/v17/2EbnL-ZuAXFqZFXISYYf8z2Yt_c.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mclaren/v17/2EbnL-ZuAXFqZFXIeYcV9w.ttf"
    },
    {
        "family": "Mea Culpa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/meaculpa/v6/AMOTz4GcuWbEIuza8jsZms0QW3mqyg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/meaculpa/v6/AMOTz4GcuWbEIuza8jspm8cU.ttf"
    },
    {
        "family": "Meddon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/meddon/v24/kmK8ZqA2EgDNeHTZhBdB3y_Aow.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/meddon/v24/kmK8ZqA2EgDNeHTphR1F.ttf"
    },
    {
        "family": "MedievalSharp",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/medievalsharp/v26/EvOJzAlL3oU5AQl2mP5KdgptAq96MwvXLDk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/medievalsharp/v26/EvOJzAlL3oU5AQl2mP5KdgptMq5wNw.ttf"
    },
    {
        "family": "Medula One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yuoiAgtw.ttf"
    },
    {
        "family": "Meera Inimai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "tamil"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILeZ_mLH.ttf"
    },
    {
        "family": "Megrim",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZlbWXgd0RY1g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZVbG_k.ttf"
    },
    {
        "family": "Meie Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMjxn5IXg0WDz.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMgxm7oE.ttf"
    },
    {
        "family": "Meow Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ESch0_0CfDf1.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ERcg2fk.ttf"
    },
    {
        "family": "Merienda",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/merienda/v19/gNMHW3x8Qoy5_mf8uVMCOou6_dvg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5enhYSET4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Merriweather",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf",
            "300italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf",
            "regular": "https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf",
            "italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf",
            "700": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf",
            "700italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf",
            "900": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf",
            "900italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5-eiZJ.ttf"
    },
    {
        "family": "Merriweather Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/merriweathersans/v26/2-c99IRs1JiJN1FRAMjTN5zd9vgsFEXySDTL8wtf.ttf",
            "italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-c79IRs1JiJN1FRAMjTN5zd9vgsFHXwQjDp9htf1ZM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4Vj-sC.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Metal",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/metal/v30/lW-wwjUJIXTo7i3nnoQAUdN2.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/metal/v30/lW-wwjUJIXTo7h3mlIA.ttf"
    },
    {
        "family": "Metal Mania",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJeXCg6UKDXlq.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJdXDiaE.ttf"
    },
    {
        "family": "Metamorphous",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/metamorphous/v20/Wnz8HA03aAXcC39ZEX5y1330PCCthTsmaQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/metamorphous/v20/Wnz8HA03aAXcC39ZEX5y133EPSqp.ttf"
    },
    {
        "family": "Metrophobic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/metrophobic/v23/sJoA3LZUhMSAPV_u0qwiAT-J737FPEEL.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/metrophobic/v23/sJoA3LZUhMSAPV_u0qwiAQ-I5Xo.ttf"
    },
    {
        "family": "Michroma",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/michroma/v19/PN_zRfy9qWD8fEagAMg6rzjb_-Da.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/michroma/v19/PN_zRfy9qWD8fEagAPg7pTw.ttf"
    },
    {
        "family": "Micro 5",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/micro5/v1/H4cnBX2MkcfEngTr0gYQ7LO5mqc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/micro5/v1/H4cnBX2MkcfEngTr4gca6A.ttf"
    },
    {
        "family": "Micro 5 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/micro5charted/v1/hESp6XxmPDtTtADZhn7oD_yrmxEGRUsJQAlbUA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/micro5charted/v1/hESp6XxmPDtTtADZhn7oD_yrmxE2REEN.ttf"
    },
    {
        "family": "Milonga",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/milonga/v22/SZc53FHnIaK9W5kffz3GkUrS8DI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/milonga/v22/SZc53FHnIaK9W5kfTzzMlQ.ttf"
    },
    {
        "family": "Miltonian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/miltonian/v30/zOL-4pbPn6Ne9JqTg9mr6e5As-FeiQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/miltonian/v30/zOL-4pbPn6Ne9JqTg9mb6ORE.ttf"
    },
    {
        "family": "Miltonian Tattoo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/miltoniantattoo/v32/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwP8MDBku-s.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/miltoniantattoo/v32/EvOUzBRL0o0kCxF-lcMCQxlpVsA_JwL2NA.ttf"
    },
    {
        "family": "Mina",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARrz9j7i3y65o.ttf",
            "700": "https://fonts.gstatic.com/s/mina/v11/-nF8OGc18vARl4NMyiXZ95OkJwA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARnz5p6g.ttf"
    },
    {
        "family": "Mingzat",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "lepcha"
        ],
        "version": "v8",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mingzat/v8/0QIgMX5C-o-oWWyvBttkm_mv670.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mingzat/v8/0QIgMX5C-o-oWWyvNtpunw.ttf"
    },
    {
        "family": "Miniver",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/miniver/v25/eLGcP-PxIg-5H0vC770Cy8r8fWA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/miniver/v25/eLGcP-PxIg-5H0vC37wIzw.ttf"
    },
    {
        "family": "Miriam Libre",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/miriamlibre/v14/DdTh798HsHwubBAqfkcBTL_vYJn_Teun9g.ttf",
            "700": "https://fonts.gstatic.com/s/miriamlibre/v14/DdT-798HsHwubBAqfkcBTL_X3LbbRcC7_-Z7Hg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/miriamlibre/v14/DdTh798HsHwubBAqfkcBTL_fYZP7.ttf"
    },
    {
        "family": "Mirza",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mirza/v17/co3ImWlikiN5EurdKMewsrvI.ttf",
            "500": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtIpAeO4mafBomDi.ttf",
            "600": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtIFBuO4mafBomDi.ttf",
            "700": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtJhB-O4mafBomDi.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mirza/v17/co3ImWlikiN5EtrcIsM.ttf"
    },
    {
        "family": "Miss Fajardose",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb8qvWPaJq4no.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb_quUvI.ttf"
    },
    {
        "family": "Mitr",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf",
            "300": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf",
            "regular": "https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZFyTsyMJj_b1o.ttf",
            "500": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf",
            "600": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf",
            "700": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZF-To4NA.ttf"
    },
    {
        "family": "Mochiy Pop One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mochiypopone/v10/QdVPSTA9Jh-gg-5XZP2UmU4O9kwwD3s6ZKAi.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mochiypopone/v10/QdVPSTA9Jh-gg-5XZP2UmU4O9nwxBX8.ttf"
    },
    {
        "family": "Mochiy Pop P One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mochiypoppone/v10/Ktk2AKuPeY_td1-h9LayHYWCjAqyN4O3WYZB_sU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mochiypoppone/v10/Ktk2AKuPeY_td1-h9LayHYWCjAqyB4K9XQ.ttf"
    },
    {
        "family": "Modak",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEsnMH8BVZ76KU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEskMG-hE.ttf"
    },
    {
        "family": "Modern Antiqua",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/modernantiqua/v24/NGStv5TIAUg6Iq_RLNo_2dp1sI1Ea2u0c3Gi.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/modernantiqua/v24/NGStv5TIAUg6Iq_RLNo_2dp1sL1FYW8.ttf"
    },
    {
        "family": "Moderustic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moderustic/v1/2-ci9J9s3o6eLFNHFdXYcu7XoZFDf2Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moderustic/v1/2-c39J9s3o6eLFNHFdXYaOX1UUnf3GLnYjALsQNf7ZI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Mogra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TBo4DvLmxtnG.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TCo5BPY.ttf"
    },
    {
        "family": "Mohave",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mohave/v9/7cHpv4ksjJunKqMPC8E46HsxnA.ttf",
            "italic": "https://fonts.gstatic.com/s/mohave/v9/7cHrv4ksjJunKqM_Ccs8yn4hnCci.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdnn_LvGapg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Moirai One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moiraione/v1/2sDbZGFUgJLJmby6xgNGT0WWB7UcfCg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moiraione/v1/2sDbZGFUgJLJmby6xgNGf0ScAw.ttf"
    },
    {
        "family": "Molengo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQbbRQkiCvs5Y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQXbValg.ttf"
    },
    {
        "family": "Molle",
        "variants": [
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "italic": "https://fonts.gstatic.com/s/molle/v23/E21n_dL5hOXFhWEsXzgmVydREus.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/molle/v23/E21n_dL5hOXFhWEsbzksUw.ttf"
    },
    {
        "family": "Monda",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/monda/v17/TK3tWkYFABsmjvpmNBsLvPdG.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/monda/v17/TK3-WkYFABsmjuBtFuvTIFRAPpWsH3o8oGFC.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Monofett",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/monofett/v23/mFTyWbofw6zc9NtnW43SuRwr0VJ7.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/monofett/v23/mFTyWbofw6zc9NtnW73Tsxg.ttf"
    },
    {
        "family": "Monomaniac One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/monomaniacone/v12/4iC06K17YctZjx50EU-QlwPmcqRnqYkB5kwI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/monomaniacone/v12/4iC06K17YctZjx50EU-QlwPmcpRmo40.ttf"
    },
    {
        "family": "Monoton",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/monoton/v19/5h1aiZUrOngCibe4fkbBQ2S7FU8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/monoton/v19/5h1aiZUrOngCibe4TkfLRw.ttf"
    },
    {
        "family": "Monsieur La Doulaise",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5ewkEU4HTy.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZm5fyEU.ttf"
    },
    {
        "family": "Montaga",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_0gYQ7LO5mqc.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_4gca6A.ttf"
    },
    {
        "family": "Montagu Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montaguslab/v12/6qLHKZIQtB_zv0xUaXRDWkYlFlnXaxIyYw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEkP8HMo.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 16,
                "end": 144
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "MonteCarlo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montecarlo/v11/buEzpo6-f9X01GadLA0G0CoV_NxLeiw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montecarlo/v11/buEzpo6-f9X01GadLA0G4Csf-A.ttf"
    },
    {
        "family": "Montez",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montez/v22/845ZNMk5GoGIX8lm1LDeSd-R_g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montez/v22/845ZNMk5GoGIX8lW1bra.ttf"
    },
    {
        "family": "Montserrat",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf",
            "italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw_aX8.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Montserrat Alternates",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU0xiKfVKphL03l4.ttf",
            "100italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTjWacfw6zH4dthXcyms1lPpC8I_b0juU057p-xIJxp1ml4imo.ttf",
            "200": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xJIb1ALZH2mBhkw.ttf",
            "200italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8dAbxD-GVxk3Nd.ttf",
            "300": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIX1ALZH2mBhkw.ttf",
            "300italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p95ArxD-GVxk3Nd.ttf",
            "regular": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7K3RCJ1b0w.ttf",
            "italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU057qfVKphL03l4.ttf",
            "500": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1ALZH2mBhkw.ttf",
            "500italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8hA7xD-GVxk3Nd.ttf",
            "600": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1ALZH2mBhkw.ttf",
            "600italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8NBLxD-GVxk3Nd.ttf",
            "700": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUIL1ALZH2mBhkw.ttf",
            "700italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9pBbxD-GVxk3Nd.ttf",
            "800": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xTIH1ALZH2mBhkw.ttf",
            "800italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p91BrxD-GVxk3Nd.ttf",
            "900": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xaID1ALZH2mBhkw.ttf",
            "900italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9RB7xD-GVxk3Nd.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU057afV.ttf"
    },
    {
        "family": "Montserrat Subrayada",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8LYuceqGT-.ttf",
            "700": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TcMWg3j36Ebz.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQH8KaOM.ttf"
    },
    {
        "family": "Moo Lah Lah",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moolahlah/v6/dg4h_p_opKZOA0w1AYcm55wtYQYugjW4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moolahlah/v6/dg4h_p_opKZOA0w1AYcm56wsawI.ttf"
    },
    {
        "family": "Mooli",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mooli/v1/-F6_fjJpLyk1bYPBBG7YpzlJ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mooli/v1/-F6_fjJpLyk1bbPADmo.ttf"
    },
    {
        "family": "Moon Dance",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moondance/v6/WBLgrEbUbFlYW9ekmGawe2XiKMiokE4.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moondance/v6/WBLgrEbUbFlYW9ekmGawS2ToLA.ttf"
    },
    {
        "family": "Moul",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moul/v27/nuF2D__FSo_3E-RYiJCy-00.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moul/v27/nuF2D__FSo_3I-VSjA.ttf"
    },
    {
        "family": "Moulpali",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v30",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/moulpali/v30/H4ckBXKMl9HagUWymyY6wr-wg763.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/moulpali/v30/H4ckBXKMl9HagUWymxY7yLs.ttf"
    },
    {
        "family": "Mountains of Christmas",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNamMPNpJpc.ttf",
            "700": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJFPtCOp6IaEA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eDNesNA.ttf"
    },
    {
        "family": "Mouse Memoirs",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mousememoirs/v17/t5tmIRoSNJ-PH0WNNgDYxdSb7TnFrpOHYh4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mousememoirs/v17/t5tmIRoSNJ-PH0WNNgDYxdSb3TjPqg.ttf"
    },
    {
        "family": "Mr Bedfort",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mrbedfort/v22/MQpR-WCtNZSWAdTMwBicliq0XZe_Iy8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mrbedfort/v22/MQpR-WCtNZSWAdTMwBicpiu-WQ.ttf"
    },
    {
        "family": "Mr Dafoe",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrGiqg7MCxz_C.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrFiribc.ttf"
    },
    {
        "family": "Mr De Haviland",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOj3C4ULVNTlB.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOg3D60Y.ttf"
    },
    {
        "family": "Mrs Saint Delafield",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62QK8nKg.ttf"
    },
    {
        "family": "Mrs Sheppards",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mrssheppards/v23/PN_2Rfm9snC0XUGoEZhb91ig3vjxynMix4Y.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mrssheppards/v23/PN_2Rfm9snC0XUGoEZhb91ig7vn7zg.ttf"
    },
    {
        "family": "Ms Madi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1N-DPiI7QAYo.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1B-HFjA.ttf"
    },
    {
        "family": "Mukta",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf",
            "300": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf",
            "regular": "https://fonts.gstatic.com/s/mukta/v14/iJWKBXyXfDDVXYnGp32S0H3f.ttf",
            "500": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf",
            "600": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf",
            "700": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf",
            "800": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mukta/v14/iJWKBXyXfDDVXbnHrXk.ttf"
    },
    {
        "family": "Mukta Mahee",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9MFcBoHJndqZCsW.ttf",
            "300": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NhcxoHJndqZCsW.ttf",
            "regular": "https://fonts.gstatic.com/s/muktamahee/v16/XRXQ3IOIi0hcP8iVU67hA-vNWz4PDWtj.ttf",
            "500": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9M5choHJndqZCsW.ttf",
            "600": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9MVdRoHJndqZCsW.ttf",
            "700": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NxdBoHJndqZCsW.ttf",
            "800": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NtdxoHJndqZCsW.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/muktamahee/v16/XRXQ3IOIi0hcP8iVU67hA9vMUTo.ttf"
    },
    {
        "family": "Mukta Malar",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMwBtAB62ruoAZW.ttf",
            "300": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINUBdAB62ruoAZW.ttf",
            "regular": "https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqLv4LfQJwHbn.ttf",
            "500": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMMBNAB62ruoAZW.ttf",
            "600": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMgA9AB62ruoAZW.ttf",
            "700": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINEAtAB62ruoAZW.ttf",
            "800": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINYAdAB62ruoAZW.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqIv5J_A.ttf"
    },
    {
        "family": "Mukta Vaani",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXNV8BD-u97MW1a.ttf",
            "300": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWpVMBD-u97MW1a.ttf",
            "regular": "https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIF0FfORL0fNy.ttf",
            "500": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXxVcBD-u97MW1a.ttf",
            "600": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXdUsBD-u97MW1a.ttf",
            "700": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGW5U8BD-u97MW1a.ttf",
            "800": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWlUMBD-u97MW1a.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIG0EduA.ttf"
    },
    {
        "family": "Mulish",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqU2AAsQqB3BA.ttf",
            "italic": "https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2gooYKVnBOif.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aGvHg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 1000
            }
        ]
    },
    {
        "family": "Murecho",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/murecho/v12/q5uHsoq3NOBn_I-gmilCBxxdmYU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/murecho/v12/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5UWYJi4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "MuseoModerno",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/museomoderno/v27/zrfi0HnU0_7wWdMrFcWqSEXVXAPqgALaow.ttf",
            "italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrf80HnU0_7wWdMrFcWqSEXlXgnuogfKozRf.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEie0QiQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "My Soul",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mysoul/v5/3XFqErcuy945_u6KF_Ulk2nnXf0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mysoul/v5/3XFqErcuy945_u6KJ_Qvlw.ttf"
    },
    {
        "family": "Mynerve",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mynerve/v6/P5sCzZKPdNjb4jt7xCRuiZ-uydg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mynerve/v6/P5sCzZKPdNjb4jt79CVkjQ.ttf"
    },
    {
        "family": "Mystery Quest",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRHwElD_9Qz9E.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRLwAvCw.ttf"
    },
    {
        "family": "NTR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjiGGhs5TA4bg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjSGWJo.ttf"
    },
    {
        "family": "Nabla",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "math",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nabla/v10/j8_l6-LI0Lvpe6kRse78FCl4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nabla/v10/j8_D6-LI0Lvpe7Makz5UhJt9C3uqg_X_75gyGS4jAxsNIjrRBRFeEA.ttf",
        "axes": [
            {
                "tag": "EDPT",
                "start": 0,
                "end": 200
            },
            {
                "tag": "EHLT",
                "start": 0,
                "end": 24
            }
        ],
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Namdhinggo",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "limbu"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-mEGe3rbgg8Xzoy5-TDnWj4yxx7o8.ttf",
            "500": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNoGKxyRa8oZK9I0.ttf",
            "600": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNq2NxyRa8oZK9I0.ttf",
            "700": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNsmMxyRa8oZK9I0.ttf",
            "800": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNtWPxyRa8oZK9I0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-mEGe3rbgg8Xzoy5-TPnSp5w.ttf"
    },
    {
        "family": "Nanum Brush Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjqBr1lO97Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazCj6pv.ttf"
    },
    {
        "family": "Nanum Gothic",
        "variants": [
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf",
            "700": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf",
            "800": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_oRfi-oW3hYwmKDpxS7F_LXv77zlEn14YEUQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_3Rfi-oW3hYwmKDpxS7F_D_9jb.ttf"
    },
    {
        "family": "Nanum Gothic Coding",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6QxVs1X7tc.ttf",
            "700": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsV88t5V9r4.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIVdjzHisX_8vv59_xMxtPFW4IXROws-6U7Ug.ttf"
    },
    {
        "family": "Nanum Myeongjo",
        "variants": [
            "regular",
            "700",
            "800"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Btx3DZF0dXLMZlywRbVRNhxy1LreHQ8juyl.ttf",
            "700": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1A0pfCs5Kos.ttf",
            "800": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Bty3DZF0dXLMZlywRbVRNhxy2pLVFA0pfCs5Kos.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Btx3DZF0dXLMZlywRbVRNhxy2LqcnA.ttf"
    },
    {
        "family": "Nanum Pen Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN6z_0bA.ttf"
    },
    {
        "family": "Narnoor",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "gunjala-gondi",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v7",
        "lastModified": "2023-11-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/narnoor/v7/cIf9MaFWuVo-UTyPxCmrYGkHgIs.ttf",
            "500": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_N2CRGEsnIJkWL4.ttf",
            "600": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_PGFRGEsnIJkWL4.ttf",
            "700": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_JWERGEsnIJkWL4.ttf",
            "800": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_ImHRGEsnIJkWL4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/narnoor/v7/cIf9MaFWuVo-UTyP9CihZA.ttf"
    },
    {
        "family": "Neonderthaw",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/neonderthaw/v6/Iure6Yx5-oWVZI0r-17AeZZJprVA4XQ0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/neonderthaw/v6/Iure6Yx5-oWVZI0r-17AeaZIrLE.ttf"
    },
    {
        "family": "Nerko One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nerkoone/v16/m8JQjfZSc7OXlB3ZMOjzcJ5BZmqa3A.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nerkoone/v16/m8JQjfZSc7OXlB3ZMOjDcZRF.ttf"
    },
    {
        "family": "Neucha",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bvugNsCxVEgA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bfuwlo.ttf"
    },
    {
        "family": "Neuton",
        "variants": [
            "200",
            "300",
            "regular",
            "italic",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "200": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKAKkfegD5Drog6Q.ttf",
            "300": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKZKofegD5Drog6Q.ttf",
            "regular": "https://fonts.gstatic.com/s/neuton/v22/UMBTrPtMoH62xUZyyII7civlBw.ttf",
            "italic": "https://fonts.gstatic.com/s/neuton/v22/UMBRrPtMoH62xUZCyog_UC71B6M5.ttf",
            "700": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKdK0fegD5Drog6Q.ttf",
            "800": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKaK4fegD5Drog6Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/neuton/v22/UMBTrPtMoH62xUZCyYg_.ttf"
    },
    {
        "family": "New Amsterdam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/newamsterdam/v1/YA9Vr02Y5lucHqUlbEe51kBtl7mGiv_Q7dA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/newamsterdam/v1/YA9Vr02Y5lucHqUlbEe51kBtp7iMjg.ttf"
    },
    {
        "family": "New Rocker",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJkGMViblPtXs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJoGIfjQ.ttf"
    },
    {
        "family": "New Tegomin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/newtegomin/v11/SLXMc1fV7Gd9USdBAfPlqfN0Q3ptkDMN.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/newtegomin/v11/SLXMc1fV7Gd9USdBAfPlqcN1SX4.ttf"
    },
    {
        "family": "News Cycle",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/newscycle/v23/CSR64z1Qlv-GDxkbKVQ_TOcATNt_pOU.ttf",
            "700": "https://fonts.gstatic.com/s/newscycle/v23/CSR54z1Qlv-GDxkbKVQ_dFsvaNNUuOwkC2s.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/newscycle/v23/CSR64z1Qlv-GDxkbKVQ_fOYKSA.ttf"
    },
    {
        "family": "Newsreader",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/newsreader/v20/cY9AfjOCX1hbuyalUrK479n4jaBGNpY.ttf",
            "italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9CfjOCX1hbuyalUrK439vyiYJDJpahZQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_wDeZA.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 6,
                "end": 72
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Niconne",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfrQut2F4OuOo.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfnQqn3A.ttf"
    },
    {
        "family": "Niramit",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVXx7tiiEr5_BdZ8.ttf",
            "200italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiXimOq73EZZ_f6w.ttf",
            "300": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVRh4tiiEr5_BdZ8.ttf",
            "300italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiOiqOq73EZZ_f6w.ttf",
            "regular": "https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLbbRQkiCvs5Y.ttf",
            "italic": "https://fonts.gstatic.com/s/niramit/v10/I_usMpWdvgLdNxVLXbZalgKqo5bYbA.ttf",
            "500": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5tiiEr5_BdZ8.ttf",
            "500italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiuOq73EZZ_f6w.ttf",
            "600": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-tiiEr5_BdZ8.ttf",
            "600italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiyOq73EZZ_f6w.ttf",
            "700": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVQh_tiiEr5_BdZ8.ttf",
            "700italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiKi2Oq73EZZ_f6w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLXbValg.ttf"
    },
    {
        "family": "Nixie One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUoUOFzpS-yLw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUYUet3.ttf"
    },
    {
        "family": "Nobile",
        "variants": [
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1i2XqfXeLVdbw.ttf",
            "italic": "https://fonts.gstatic.com/s/nobile/v17/m8JRjflSeaOVl1iGXK3TWrBNb3OD.ttf",
            "500": "https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iOqo7zcJ5BZmqa3A.ttf",
            "500italic": "https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJUnc5RFRG-K3Mud.ttf",
            "700": "https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iO4ojzcJ5BZmqa3A.ttf",
            "700italic": "https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJVvdZRFRG-K3Mud.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1iGX63T.ttf"
    },
    {
        "family": "Nokora",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v31",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/nokora/v31/hYkKPuwgTubzaWxoXzALgPNw8QZN.ttf",
            "300": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxolxIrqt18-B9Uuw.ttf",
            "regular": "https://fonts.gstatic.com/s/nokora/v31/hYkIPuwgTubzaWxQOzoPovZg8Q.ttf",
            "700": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxohxUrqt18-B9Uuw.ttf",
            "900": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxovxcrqt18-B9Uuw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nokora/v31/hYkIPuwgTubzaWxgOjAL.ttf"
    },
    {
        "family": "Norican",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/norican/v15/MwQ2bhXp1eSBqjkPGJJRtGs-lbA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/norican/v15/MwQ2bhXp1eSBqjkPKJNbsA.ttf"
    },
    {
        "family": "Nosifer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nosifer/v22/ZGjXol5JTp0g5bxZaC1RVDNdGDs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nosifer/v22/ZGjXol5JTp0g5bxZWCxbUA.ttf"
    },
    {
        "family": "Notable",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notable/v18/gNMEW3N_SIqx-WX9-HMoFIez5MI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notable/v18/gNMEW3N_SIqx-WX9yHIiEA.ttf"
    },
    {
        "family": "Nothing You Could Do",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nothingyoucoulddo/v19/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJl1ol2Ymo.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nothingyoucoulddo/v19/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb3OInV4.ttf"
    },
    {
        "family": "Noticia Text",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP1GYTFZt0rNpQ.ttf",
            "italic": "https://fonts.gstatic.com/s/noticiatext/v15/VuJodNDF2Yv9qppOePKYRP12YztdlU_dpSjt.ttf",
            "700": "https://fonts.gstatic.com/s/noticiatext/v15/VuJpdNDF2Yv9qppOePKYRP1-3R59v2HRrDH0eA.ttf",
            "700italic": "https://fonts.gstatic.com/s/noticiatext/v15/VuJrdNDF2Yv9qppOePKYRP12YwPhumvVjjTkeMnz.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12YDtd.ttf"
    },
    {
        "family": "Noto Color Emoji",
        "variants": [
            "regular"
        ],
        "subsets": [
            "emoji"
        ],
        "version": "v32",
        "lastModified": "2024-09-03",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notocoloremoji/v32/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFab5s79iz64w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notocoloremoji/v32/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFWb9m6w.ttf",
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Noto Emoji",
        "variants": [
            "regular"
        ],
        "subsets": [
            "emoji"
        ],
        "version": "v51",
        "lastModified": "2024-09-03",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoemoji/v51/bMrymSyK7YY-MEu6aWjPFMS4X3MKy2M.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoemoji/v51/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-r0gwuQeU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Kufi Arabic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v21",
        "lastModified": "2024-02-29",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRk4ydQnPyaDxEXLFF6LZVLKrodnOQPF2KpMzE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v37bOlrA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Music",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "music"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notomusic/v20/pe0rMIiSN5pO63htf1sxIteQB9Zra1U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notomusic/v20/pe0rMIiSN5pO63htf1sxEtaaAw.ttf"
    },
    {
        "family": "Noto Naskh Arabic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v34",
        "lastModified": "2024-04-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notonaskharabic/v34/RrQKbpV-9Dd1b1OAGA6M9PkyDuVBeO2BF1yELmgy.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notonaskharabic/v34/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5Urac3.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Nastaliq Urdu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2023-03-21",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhW4MUPbN-oZdNFcBy1-DJYsEoTq5puHSPANO9blOA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qt_-PKyV3w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Rashi Hebrew",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-06-05",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notorashihebrew/v26/EJRMQh82XsIK-QFmqXk4zvLwFVya0utA2omSrzS8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notorashihebrew/v26/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HURiC-.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "devanagari",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-02-16",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosans/v36/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf",
            "italic": "https://fonts.gstatic.com/s/notosans/v36/o-0OIpQlx3QUlC5A4PNr4DRFSfiM7HBj.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A-9c6Vc.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Adlam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "adlam",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansadlam/v22/neITzCCpqp0s5pPusPamd81eMfjVqVkarSqSwA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0fGjLYA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Adlam Unjoined",
        "variants": [
            "regular"
        ],
        "subsets": [
            "adlam",
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansadlamunjoined/v26/P5sRzY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcAaZ41lBRPOw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansadlamunjoined/v26/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35DMAU_A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Anatolian Hieroglyphs",
        "variants": [
            "regular"
        ],
        "subsets": [
            "anatolian-hieroglyphs",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v16/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWXybIymc5QYo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v16/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWbyfCzg.ttf"
    },
    {
        "family": "Noto Sans Arabic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic"
        ],
        "version": "v18",
        "lastModified": "2022-06-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpPtLGrOAZMl5nJ_wfgRg3DrWFZQML36H986K0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyfunqF.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Armenian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "armenian",
            "latin",
            "latin-ext"
        ],
        "version": "v43",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgNOjOZKPa7CHqq0h37c7ReDUubm2SEHHliXap5UrA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb50jaSo.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Avestan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "avestan",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansavestan/v21/bWti7ejKfBziStx7lIzKOLQZKhIJkyu9SASLji8U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansavestan/v21/bWti7ejKfBziStx7lIzKOLQZKhIJkxu8QgA.ttf"
    },
    {
        "family": "Noto Sans Balinese",
        "variants": [
            "regular"
        ],
        "subsets": [
            "balinese",
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPFcYvSBuhTirw6IaFn6UrRDaqje-lzZpaduWMmxA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdtE9fcw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Bamum",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bamum",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-7EGK3o6EruUbnwovcbBTkkklQ9qRP5I18Mg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddOy_kBzg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Bassa Vah",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bassa-vah",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_sRee-r3f7LnqsD5sax12gjZn7mBpL_4c2VNUQptE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MaAf6o1Yw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Batak",
        "variants": [
            "regular"
        ],
        "subsets": [
            "batak",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-02-29",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbatak/v20/gok2H6TwAEdtF9N8-mdTCQvT-Zdgo4_PHuk74A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbatak/v20/gok2H6TwAEdtF9N8-mdTCQvT-ZdQooXL.ttf"
    },
    {
        "family": "Noto Sans Bengali",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2022-09-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-sJsCGWQxOjaGwMQ6fIiMywrNJIlaxvBuclp_T.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLidGu9g.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Bhaiksuki",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bhaiksuki",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbhaiksuki/v17/UcC63EosKniBH4iELXATsSBWdvUHXxhj8rLUdU4wh9U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbhaiksuki/v17/UcC63EosKniBH4iELXATsSBWdvUHXxhjwrPecQ.ttf"
    },
    {
        "family": "Noto Sans Brahmi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "brahmi",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v19",
        "lastModified": "2024-02-29",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbrahmi/v19/vEFK2-VODB8RrNDvZSUmQQIIByV18tK1W77HtMo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbrahmi/v19/vEFK2-VODB8RrNDvZSUmQQIIByV1wtO_Xw.ttf"
    },
    {
        "family": "Noto Sans Buginese",
        "variants": [
            "regular"
        ],
        "subsets": [
            "buginese",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbuginese/v18/esDM30ldNv-KYGGJpKGk18phe_7Da6_gtfuEXLmNtw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbuginese/v18/esDM30ldNv-KYGGJpKGk18phe_7Da6_QtPGA.ttf"
    },
    {
        "family": "Noto Sans Buhid",
        "variants": [
            "regular"
        ],
        "subsets": [
            "buhid",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansbuhid/v22/Dxxy8jiXMW75w3OmoDXVWJD7YwzAe6tgnaFoGA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansbuhid/v22/Dxxy8jiXMW75w3OmoDXVWJD7YwzweqFk.ttf"
    },
    {
        "family": "Noto Sans Canadian Aboriginal",
        "variants": [
            "regular"
        ],
        "subsets": [
            "canadian-aboriginal",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v26",
        "lastModified": "2024-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v26/4C_gLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmly1RyBEnAtbz.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v26/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLnPyQEk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Carian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "carian",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscarian/v16/LDIpaoiONgYwA9Yc6f0gUILeMIOgs7ob9yGLmfI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscarian/v16/LDIpaoiONgYwA9Yc6f0gUILeMIOgg7sR8w.ttf"
    },
    {
        "family": "Noto Sans Caucasian Albanian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "caucasian-albanian",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscaucasianalbanian/v18/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXrYDmoVmRSZo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscaucasianalbanian/v18/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXnYHspQ.ttf"
    },
    {
        "family": "Noto Sans Chakma",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chakma",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi4JjTQhYBeYo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi0JnZRg.ttf"
    },
    {
        "family": "Noto Sans Cham",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cham",
            "latin",
            "latin-ext"
        ],
        "version": "v31",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscham/v31/pe03MIySN5pO62Z5YkFyQb_bbv5qWVAgVol-.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscham/v31/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwsvru2.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Cherokee",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cherokee",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFO6Cm6Yu8uF-29fiz9vQF9YWK6Z8O1ue1GwCVgHYg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PPkizqA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Chorasmian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chorasmian",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v3",
        "lastModified": "2024-01-26",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanschorasmian/v3/MQpL-X6uKMC7ROPLwRnI9ULxK_7NVkf8S5vyoH7w4g9b.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanschorasmian/v3/MQpL-X6uKMC7ROPLwRnI9ULxK_7NVkf8S6vzqno.ttf"
    },
    {
        "family": "Noto Sans Coptic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "coptic",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2023-12-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscoptic/v21/iJWfBWmUZi_OHPqn4wq6kgqumOEd78u_VG0xR4Y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscoptic/v21/iJWfBWmUZi_OHPqn4wq6kgqumOEd38q1UA.ttf"
    },
    {
        "family": "Noto Sans Cuneiform",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cuneiform",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscuneiform/v17/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWgb9JlRQueeQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscuneiform/v17/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWsb5DkQ.ttf"
    },
    {
        "family": "Noto Sans Cypriot",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cypriot",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscypriot/v19/8AtzGta9PYqQDjyp79a6f8Cj-3a3cxIsK5MPpahF.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscypriot/v19/8AtzGta9PYqQDjyp79a6f8Cj-3a3cyItIZc.ttf"
    },
    {
        "family": "Noto Sans Cypro Minoan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cypro-minoan",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanscyprominoan/v1/2Eb2L_dtDUlkNmPHB_UVtEzp3ZlPGqZ_4nAGq9eSf8_eQSE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanscyprominoan/v1/2Eb2L_dtDUlkNmPHB_UVtEzp3ZlPGqZ_4nAGm9aYew.ttf"
    },
    {
        "family": "Noto Sans Deseret",
        "variants": [
            "regular"
        ],
        "subsets": [
            "deseret",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansdeseret/v17/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Vq9ZnJSZtQG.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansdeseret/v17/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Wq8bHY.ttf"
    },
    {
        "family": "Noto Sans Devanagari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansdevanagari/v26/TuGOUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv2lRdRhtCC4d.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansdevanagari/v26/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQl--E5pA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Display",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLplK4fy6r6tOBEJg0IAKzqdFZVZxokvfn_BDLxR.ttf",
            "italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpjK4fy6r6tOBEJg0IAKzqdFZVZxrktdHvjCaxRgew.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKZTCa3g.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Duployan",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "duployan",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansduployan/v18/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvrFsIn6WYDvA.ttf",
            "700": "https://fonts.gstatic.com/s/notosansduployan/v18/gokwH7nwAEdtF9N8-mdTDx_X9JM5wsvTqu0D4U0ftZS-Iw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansduployan/v18/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvbF8gj.ttf"
    },
    {
        "family": "Noto Sans Egyptian Hieroglyphs",
        "variants": [
            "regular"
        ],
        "subsets": [
            "egyptian-hieroglyphs",
            "latin",
            "latin-ext"
        ],
        "version": "v29",
        "lastModified": "2024-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v29/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYindSVK8xRg7iw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v29/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYintSFi4.ttf"
    },
    {
        "family": "Noto Sans Elbasan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "elbasan",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-05-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanselbasan/v16/-F6rfiZqLzI2JPCgQBnw400qp1trvHdlre4dFcFh.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanselbasan/v16/-F6rfiZqLzI2JPCgQBnw400qp1trvEdkp-o.ttf"
    },
    {
        "family": "Noto Sans Elymaic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "elymaic",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanselymaic/v17/UqyKK9YTJW5liNMhTMqe9vUFP65ZD4AjWOT0zi2V.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanselymaic/v17/UqyKK9YTJW5liNMhTMqe9vUFP65ZD7AiUuA.ttf"
    },
    {
        "family": "Noto Sans Ethiopic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "ethiopic",
            "latin",
            "latin-ext"
        ],
        "version": "v47",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cH1v50vjIepfJVOZZgcpQ5B9FBTH9KcPtq-rpvLpQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OK5DiyGM.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Georgian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "georgian",
            "greek-ext",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v44",
        "lastModified": "2024-02-29",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIgFke5O6RzLfvNNVSitxkr76PRHBCiaf5GGPq86g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzGj_dZE.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Glagolitic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "glagolitic",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v18",
        "lastModified": "2024-01-26",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansglagolitic/v18/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4ERK4Amu7nM1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansglagolitic/v18/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4HRL6g0.ttf"
    },
    {
        "family": "Noto Sans Gothic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gothic",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgothic/v16/TuGKUUVzXI5FBtUq5a8bj6wRbzxTFMX40kFQRx0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgothic/v16/TuGKUUVzXI5FBtUq5a8bj6wRbzxTJMTy1g.ttf"
    },
    {
        "family": "Noto Sans Grantha",
        "variants": [
            "regular"
        ],
        "subsets": [
            "grantha",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-06-10",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgrantha/v19/3y976akwcCjmsU8NDyrKo3IQfQ4o-r8cFeulHc6N.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgrantha/v19/3y976akwcCjmsU8NDyrKo3IQfQ4o-o8dH-8.ttf"
    },
    {
        "family": "Noto Sans Gujarati",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v25",
        "lastModified": "2023-11-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpsgx_HC1ti5ViekvcxnhMlCVo3f5p13JpDd6u3AQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFzPN9uM.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Gunjala Gondi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gunjala-gondi",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWto7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5hcVXYMTK4q1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYL4YD04ZQr.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Gurmukhi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8gHH3EvQP81sInb43inmyN9zZ7hb7AJZgdEAj--IQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1Oekbwbf8.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans HK",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanshk/v32/nKKQ-GM_FYFRJvXzVXaAPe9hNHB3Eu7mOQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanshk/v32/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qHB-yoSZjQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Hanifi Rohingya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hanifi-rohingya",
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h1IiYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLN0IbPeXAy64Y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4g6ut88.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Hanunoo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hanunoo",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanshanunoo/v21/f0Xs0fCv8dxkDWlZSoXOj6CphMloFsEsEpgL_ix2.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanshanunoo/v21/f0Xs0fCv8dxkDWlZSoXOj6CphMloFvEtGJw.ttf"
    },
    {
        "family": "Noto Sans Hatran",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hatran",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanshatran/v16/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_mM83r1nwzDs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanshatran/v16/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_qM49qw.ttf"
    },
    {
        "family": "Noto Sans Hebrew",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v46",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanshebrew/v46/or35Q7v33eiDljA1IufXTtVf7V6Rpko_aen0c78.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanshebrew/v46/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qdoyhh.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Imperial Aramaic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "imperial-aramaic",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansimperialaramaic/v17/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrtdml3YfPNno.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansimperialaramaic/v17/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrudnnXI.ttf"
    },
    {
        "family": "Noto Sans Indic Siyaq Numbers",
        "variants": [
            "regular"
        ],
        "subsets": [
            "indic-siyaq-numbers",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v16/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKx2WPOpVd5Iu.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v16/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKy2XNu4.ttf"
    },
    {
        "family": "Noto Sans Inscriptional Pahlavi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "inscriptional-pahlavi",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v17/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCVtqVOAYK0QA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v17/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCZtufPA.ttf"
    },
    {
        "family": "Noto Sans Inscriptional Parthian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "inscriptional-parthian",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansinscriptionalparthian/v17/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2yklBJ2jNkLlLr.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansinscriptionalparthian/v17/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2ykmBI0Dc.ttf"
    },
    {
        "family": "Noto Sans JP",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v53",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansjp/v53/-F62fjtqLzI2JPCgQBnw7HFoxgIO2lZ9hg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansjp/v53/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEj35rS1g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Javanese",
        "variants": [
            "regular"
        ],
        "subsets": [
            "javanese",
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V0AKJkDAIA6Hp4zoSScDjV0Y-eoHAHJ8r88Rp29eA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxJnkJFhoYQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans KR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2023-08-17",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskr/v36/PbykFmXiEBPT4ITbgNA5Cgm21nTs4JMMuA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzuoySLXg9A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Kaithi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kaithi",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskaithi/v22/buEtppS9f8_vkXadMBJJu0tWjLwjQi0KdoZIKlo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskaithi/v22/buEtppS9f8_vkXadMBJJu0tWjLwjciwAcg.ttf"
    },
    {
        "family": "Noto Sans Kannada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIS7xs32H97qzQKnzfeXycxXZyUmz6kR47NCV5Z.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvN_SMGKg.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Kawi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kawi",
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskawi/v3/92zMtBJLNqsg7tCciW0EPHNNh0xrTCFOFZUF.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnK4AhWC552.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Kayah Li",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kayah-li",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50SF61OpWTRcGrhOVJJwOMXdca6Yec-gFPEM4EAUw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3OZDyFQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Kharoshthi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kharoshthi",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskharoshthi/v16/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE5z4rFyx5mR1.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskharoshthi/v16/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE6z5plg.ttf"
    },
    {
        "family": "Noto Sans Khmer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijwNs5roRME5LLRxjsRb-gssOenawssxJii23w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAKz5mgI.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Khojki",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khojki",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-02-16",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskhojki/v19/-nFnOHM29Oofr2wohFbTuPPKVWpmK_d709jy92k.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskhojki/v19/-nFnOHM29Oofr2wohFbTuPPKVWpmG_Zx1w.ttf"
    },
    {
        "family": "Noto Sans Khudawadi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khudawadi",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-08-21",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanskhudawadi/v22/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHIjRnVVXz9MY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanskhudawadi/v22/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHEjVtUQ.ttf"
    },
    {
        "family": "Noto Sans Lao",
        "variants": [
            "regular"
        ],
        "subsets": [
            "lao",
            "latin",
            "latin-ext"
        ],
        "version": "v30",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslao/v30/bx6DNx2Ol_ixgdYWLm9BwxM3L2Wj1qsPTKs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbtfpkO.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Lao Looped",
        "variants": [
            "regular"
        ],
        "subsets": [
            "lao",
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IGNpbwKmHXpgXbMIsbSMP7-3U72qUOX4IKoU4xzO9n.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr3A-d64Q.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Lepcha",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "lepcha"
        ],
        "version": "v19",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslepcha/v19/0QI7MWlB_JWgA166SKhu05TekNS32AJstqBXgd4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslepcha/v19/0QI7MWlB_JWgA166SKhu05TekNS36ANmsg.ttf"
    },
    {
        "family": "Noto Sans Limbu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "limbu"
        ],
        "version": "v24",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslimbu/v24/3JnlSDv90Gmq2mrzckOBBRRoNJVj0MF3OHRDnA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslimbu/v24/3JnlSDv90Gmq2mrzckOBBRRoNJVT0ctz.ttf"
    },
    {
        "family": "Noto Sans Linear A",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "linear-a"
        ],
        "version": "v18",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslineara/v18/oPWS_l16kP4jCuhpgEGmwJOiA18FZj22zmHQAGQicw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslineara/v18/oPWS_l16kP4jCuhpgEGmwJOiA18FZj2Gz2vU.ttf"
    },
    {
        "family": "Noto Sans Linear B",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "linear-b"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslinearb/v17/HhyJU4wt9vSgfHoORYOiXOckKNB737IV3BkFTq4EPw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslinearb/v17/HhyJU4wt9vSgfHoORYOiXOckKNB737Il3RMB.ttf"
    },
    {
        "family": "Noto Sans Lisu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "lisu"
        ],
        "version": "v25",
        "lastModified": "2023-03-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-6EGO3o6EruUbnwovcYhz6kgRw3IpD7ZRl.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwd2tgh.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Lycian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "lycian"
        ],
        "version": "v15",
        "lastModified": "2022-05-10",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA5_naCJwn00E.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA1_jQDA.ttf"
    },
    {
        "family": "Noto Sans Lydian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "lydian"
        ],
        "version": "v18",
        "lastModified": "2023-12-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanslydian/v18/c4m71mVzGN7s8FmIukZJ1v4ZlcPReUPXMoIjEQI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanslydian/v18/c4m71mVzGN7s8FmIukZJ1v4ZlcPRSULdNg.ttf"
    },
    {
        "family": "Noto Sans Mahajani",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "mahajani"
        ],
        "version": "v19",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmahajani/v19/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD5Fh8ARHNh4zg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmahajani/v19/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD51hsoV.ttf"
    },
    {
        "family": "Noto Sans Malayalam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam"
        ],
        "version": "v26",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoY3K5XjsSdcnzn071rL37lpAOsUThnF5k90TZO69o.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuDNBVbA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Mandaic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "mandaic"
        ],
        "version": "v17",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmandaic/v17/cIfnMbdWt1w_HgCcilqhKQBo_OsMI5_A_gMk0izH.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmandaic/v17/cIfnMbdWt1w_HgCcilqhKQBo_OsMI6_B9Ac.ttf"
    },
    {
        "family": "Noto Sans Manichaean",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "manichaean"
        ],
        "version": "v18",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmanichaean/v18/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfekqCNTtFCtdX.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmanichaean/v18/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfenqDPz8.ttf"
    },
    {
        "family": "Noto Sans Marchen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "marchen"
        ],
        "version": "v20",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmarchen/v20/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMHhPk652ZaHk.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmarchen/v20/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMEhOmao.ttf"
    },
    {
        "family": "Noto Sans Masaram Gondi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "masaram-gondi"
        ],
        "version": "v17",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPnuGe7RI9WSWX.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPntGf5xY.ttf"
    },
    {
        "family": "Noto Sans Math",
        "variants": [
            "regular"
        ],
        "subsets": [
            "math"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UHkG-V048PW0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UEkH81k.ttf"
    },
    {
        "family": "Noto Sans Mayan Numerals",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "mayan-numerals"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmayannumerals/v16/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIiE68oo6eepYQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmayannumerals/v16/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIi06sAs.ttf"
    },
    {
        "family": "Noto Sans Medefaidrin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "medefaidrin"
        ],
        "version": "v23",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkAxOq6Dk-wranENynkfeVsNbRZtbOIdLbvcDV8OQsNbw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWpTz_9Q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Meetei Mayek",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "meetei-mayek"
        ],
        "version": "v15",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTx9L3QyKieByqY9eZPFweO0be7M21uSphSdnKkpbrJN35k.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ_sTX7vw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Mende Kikakui",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "mende-kikakui"
        ],
        "version": "v28",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLUx_aDJLAHer.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLXx-YjY.ttf"
    },
    {
        "family": "Noto Sans Meroitic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "meroitic",
            "meroitic-cursive",
            "meroitic-hieroglyphs"
        ],
        "version": "v18",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmeroitic/v18/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UDgDhTiKY9KQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmeroitic/v18/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UzgTJX.ttf"
    },
    {
        "family": "Noto Sans Miao",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "miao"
        ],
        "version": "v17",
        "lastModified": "2022-09-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZUjgUYVslLhx.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZXjhW4E.ttf"
    },
    {
        "family": "Noto Sans Modi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "modi"
        ],
        "version": "v23",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmodi/v23/pe03MIySN5pO62Z5YkFyT7jeav5qWVAgVol-.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmodi/v23/pe03MIySN5pO62Z5YkFyT7jeas5rU1Q.ttf"
    },
    {
        "family": "Noto Sans Mongolian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "mongolian",
            "symbols"
        ],
        "version": "v22",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmongolian/v22/VdGCAYADGIwE0EopZx8xQfHlgEAMsrToxLsg6-av1x0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmongolian/v22/VdGCAYADGIwE0EopZx8xQfHlgEAMsrTo9Loq7w.ttf"
    },
    {
        "family": "Noto Sans Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmono/v30/BngRUXNETWXI6LwhGYvaxZikqYCByxyKeuDp.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ09vdUA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Mro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "mro"
        ],
        "version": "v18",
        "lastModified": "2022-12-08",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmro/v18/qWcsB6--pZv9TqnUQMhe9b39WDzRtjkho4M.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmro/v18/qWcsB6--pZv9TqnUQMhe9b39aD3bsg.ttf"
    },
    {
        "family": "Noto Sans Multani",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "multani"
        ],
        "version": "v20",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO1A0pfCs5Kos.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO2A1r_Q.ttf"
    },
    {
        "family": "Noto Sans Myanmar",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "myanmar"
        ],
        "version": "v20",
        "lastModified": "2022-09-28",
        "files": {
            "100": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZs_y1ZtY3ymOryg38hOCSdOnFq0HGS1uEapkAC3AY.ttf",
            "200": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HE-98EwiEwLxR-r.ttf",
            "300": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFa9MEwiEwLxR-r.ttf",
            "regular": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0En23OU4o1AC.ttf",
            "500": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEC9cEwiEwLxR-r.ttf",
            "600": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEu8sEwiEwLxR-r.ttf",
            "700": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFK88EwiEwLxR-r.ttf",
            "800": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFW8MEwiEwLxR-r.ttf",
            "900": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFy8cEwiEwLxR-r.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0Hn31uE.ttf"
    },
    {
        "family": "Noto Sans NKo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nko"
        ],
        "version": "v6",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnko/v6/esDX31ZdNv-KYGGJpKGk2_RpMpCMHMLBrdA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnko/v6/esDX31ZdNv-KYGGJpKGk2_RpApGGGA.ttf"
    },
    {
        "family": "Noto Sans NKo Unjoined",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nko"
        ],
        "version": "v2",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCoCzBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szJutjFqMvktM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rvF2jEOz_o.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Nabataean",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nabataean"
        ],
        "version": "v16",
        "lastModified": "2023-06-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnabataean/v16/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNoBJ9hK8kMK4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnabataean/v16/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNkBN3gA.ttf"
    },
    {
        "family": "Noto Sans Nag Mundari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nag-mundari"
        ],
        "version": "v2",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnagmundari/v2/3qTzoi2hnSyU8TNFIdhZTyod3g5lBnKlQFksmg2vd02J1Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnagmundari/v2/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHRxirXUCK1w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Nandinagari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nandinagari"
        ],
        "version": "v4",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnandinagari/v4/or38Q7733eiDljA1IufXSNFT-1KI5y10H4jVa5RXzC1KOw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnandinagari/v4/or38Q7733eiDljA1IufXSNFT-1KI5y10H4jlap5T.ttf"
    },
    {
        "family": "Noto Sans New Tai Lue",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "new-tai-lue"
        ],
        "version": "v22",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4c5BW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wwY55O4AS32A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdeXAUUfqgA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Newa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "newa"
        ],
        "version": "v16",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg8n_qN4R5lNU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg_n-oto.ttf"
    },
    {
        "family": "Noto Sans Nushu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nushu"
        ],
        "version": "v19",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansnushu/v19/rnCw-xRQ3B7652emAbAe_Ai1IYaFWFAMArZKqQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansnushu/v19/rnCw-xRQ3B7652emAbAe_Ai1IYa1WVoI.ttf"
    },
    {
        "family": "Noto Sans Ogham",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "ogham"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansogham/v17/kmKlZqk1GBDGN0mY6k5lmEmww4hrt5laQxcoCA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansogham/v17/kmKlZqk1GBDGN0mY6k5lmEmww4hbtpNe.ttf"
    },
    {
        "family": "Noto Sans Ol Chiki",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "ol-chiki"
        ],
        "version": "v29",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0bI2TJNOPt-eHmFZCdQbrL32r-4CvhpBBaAT48zZA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk227MwhQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Old Hungarian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-hungarian"
        ],
        "version": "v18",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldhungarian/v18/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgRfjbg5nCYXt.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldhungarian/v18/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgSfiZAo.ttf"
    },
    {
        "family": "Noto Sans Old Italic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-italic"
        ],
        "version": "v17",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansolditalic/v17/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tWlRdRhtCC4d.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansolditalic/v17/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tVlQfxw.ttf"
    },
    {
        "family": "Noto Sans Old North Arabian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-north-arabian"
        ],
        "version": "v16",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldnortharabian/v16/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBQmUo_xw4ABw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldnortharabian/v16/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBgmEA7.ttf"
    },
    {
        "family": "Noto Sans Old Permic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "old-permic"
        ],
        "version": "v17",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldpermic/v17/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKLdXgv_dKYB5.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldpermic/v17/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKIdWiPs.ttf"
    },
    {
        "family": "Noto Sans Old Persian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-persian"
        ],
        "version": "v16",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldpersian/v16/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_tqOlQfx9CjA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldpersian/v16/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_dqeNU.ttf"
    },
    {
        "family": "Noto Sans Old Sogdian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-sogdian"
        ],
        "version": "v17",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldsogdian/v17/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURt7neIqM-9uyg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldsogdian/v17/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURtLnOgu.ttf"
    },
    {
        "family": "Noto Sans Old South Arabian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-south-arabian"
        ],
        "version": "v16",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldsoutharabian/v16/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKx1OtDT9HwTA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldsoutharabian/v16/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKB1eFH.ttf"
    },
    {
        "family": "Noto Sans Old Turkic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-turkic"
        ],
        "version": "v18",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoldturkic/v18/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsE2RjEw-Vyws.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoldturkic/v18/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsH2Qhkg.ttf"
    },
    {
        "family": "Noto Sans Oriya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "oriya"
        ],
        "version": "v31",
        "lastModified": "2024-04-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansoriya/v31/AYCTpXfzfccDCstK_hrjDyADv5en5K3DZq1hIg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansoriya/v31/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0f5_d4rw.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Osage",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "osage"
        ],
        "version": "v18",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtXRlaSxkrMCQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtnR1yW.ttf"
    },
    {
        "family": "Noto Sans Osmanya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "osmanya"
        ],
        "version": "v18",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmz6kR47NCV5Z.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmw6lTYo.ttf"
    },
    {
        "family": "Noto Sans Pahawh Hmong",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "pahawh-hmong"
        ],
        "version": "v18",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanspahawhhmong/v18/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7krzc_c48aMpM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanspahawhhmong/v18/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7knzY1dw.ttf"
    },
    {
        "family": "Noto Sans Palmyrene",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "palmyrene"
        ],
        "version": "v16",
        "lastModified": "2023-06-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanspalmyrene/v16/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCPnK5ZpdNtcA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanspalmyrene/v16/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCDnOzYg.ttf"
    },
    {
        "family": "Noto Sans Pau Cin Hau",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "pau-cin-hau"
        ],
        "version": "v20",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanspaucinhau/v20/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehIdjEWqKMxsKw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanspaucinhau/v20/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehItjU-u.ttf"
    },
    {
        "family": "Noto Sans Phags Pa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "phags-pa"
        ],
        "version": "v15",
        "lastModified": "2022-05-10",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChkqkSsrvNXiA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChUq06o.ttf"
    },
    {
        "family": "Noto Sans Phoenician",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "phoenician"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansphoenician/v17/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5X7Jot-p5561.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansphoenician/v17/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5U7IqNs.ttf"
    },
    {
        "family": "Noto Sans Psalter Pahlavi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "psalter-pahlavi"
        ],
        "version": "v17",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanspsalterpahlavi/v17/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w1G3KsUQBct4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanspsalterpahlavi/v17/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w5GzAtQ.ttf"
    },
    {
        "family": "Noto Sans Rejang",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "rejang"
        ],
        "version": "v21",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansrejang/v21/Ktk2AKuMeZjqPnXgyqrib7DIogqwN4O3WYZB_sU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansrejang/v21/Ktk2AKuMeZjqPnXgyqrib7DIogqwB4K9XQ.ttf"
    },
    {
        "family": "Noto Sans Runic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "runic"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansrunic/v17/H4c_BXWPl9DZ0Xe_nHUaus7W68WWaxpvHtgIYg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansrunic/v17/H4c_BXWPl9DZ0Xe_nHUaus7W68WmahBr.ttf"
    },
    {
        "family": "Noto Sans SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v37",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssc/v37/k3kXo84MPvpLmixcA63oeALhKYiJ-Q7m8w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssc/v37/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG9_FrY1HaA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Samaritan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "samaritan"
        ],
        "version": "v16",
        "lastModified": "2023-06-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssamaritan/v16/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOUo5jNlOVMzQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssamaritan/v16/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOYo9pMg.ttf"
    },
    {
        "family": "Noto Sans Saurashtra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "saurashtra"
        ],
        "version": "v23",
        "lastModified": "2023-09-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssaurashtra/v23/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef9ndjhPTSIx9.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssaurashtra/v23/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef-nchBc.ttf"
    },
    {
        "family": "Noto Sans Sharada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sharada"
        ],
        "version": "v16",
        "lastModified": "2022-11-18",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwoXLPOwr4H8a.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwrXKNug.ttf"
    },
    {
        "family": "Noto Sans Shavian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "shavian"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansshavian/v17/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4EFQSplv2Cwg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansshavian/v17/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4HFRQJ0.ttf"
    },
    {
        "family": "Noto Sans Siddham",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "siddham"
        ],
        "version": "v20",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssiddham/v20/OZpZg-FwqiNLe9PELUikxTWDoCCeGqndk3Ic92ZH.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssiddham/v20/OZpZg-FwqiNLe9PELUikxTWDoCCeGpncmXY.ttf"
    },
    {
        "family": "Noto Sans SignWriting",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "signwriting"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssignwriting/v4/Noas6VX_wIWFbTTCrYmvy9A2UnkL-2SZAWiUEVCARYQemg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssignwriting/v4/Noas6VX_wIWFbTTCrYmvy9A2UnkL-2SZAWikEFqE.ttf"
    },
    {
        "family": "Noto Sans Sinhala",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v26",
        "lastModified": "2022-09-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJIMJBya43H0SUF_WmcBEEf4rQVO3ny-WmZp1A3.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a1lkBoA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Sogdian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sogdian"
        ],
        "version": "v16",
        "lastModified": "2023-06-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssogdian/v16/taiQGn5iC4--qtsfi4Jp6eHPnfxQBo--Pm6KHidM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssogdian/v16/taiQGn5iC4--qtsfi4Jp6eHPnfxQBr-_NGo.ttf"
    },
    {
        "family": "Noto Sans Sora Sompeng",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sora-sompeng"
        ],
        "version": "v24",
        "lastModified": "2023-03-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIsFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolMPuO7-gt-ec.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR81_Dob3Y.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Soyombo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "soyombo"
        ],
        "version": "v17",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssoyombo/v17/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY0FrIFOcK25W.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssoyombo/v17/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY3FqKlc.ttf"
    },
    {
        "family": "Noto Sans Sundanese",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sundanese"
        ],
        "version": "v26",
        "lastModified": "2024-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssundanese/v26/FwZH7_84xUkosG2xJo2gm7nFwSLQkdymsWKGP6kvdPA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssundanese/v26/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNOHDgT8.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Syloti Nagri",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "syloti-nagri"
        ],
        "version": "v23",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssylotinagri/v23/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGFVfxN87gsj0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssylotinagri/v23/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGJVb7Mw.ttf"
    },
    {
        "family": "Noto Sans Symbols",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "symbols"
        ],
        "version": "v43",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssymbols/v43/rP2dp3q65FkAtHfwd-eIS2brbDN6gwn8wq-72bOY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssymbols/v43/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8Qa_9B.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Symbols 2",
        "variants": [
            "regular"
        ],
        "subsets": [
            "braille",
            "latin",
            "latin-ext",
            "math",
            "mayan-numerals",
            "symbols"
        ],
        "version": "v24",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssymbols2/v24/I_uyMoGduATTei9eI8daxVHDyfisHr71ypPqfX71-AI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssymbols2/v24/I_uyMoGduATTei9eI8daxVHDyfisHr71-pLgeQ.ttf"
    },
    {
        "family": "Noto Sans Syriac",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "syriac"
        ],
        "version": "v16",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk2AKuMeZjqPnXgyqribqzQqgW0N4O3WYZB_sU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-VD9PaIw5M.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Syriac Eastern",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "syriac"
        ],
        "version": "v1",
        "lastModified": "2023-07-13",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noah6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxSkTa8Ck93gbw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPi-fgzGB4Q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans TC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-traditional",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstc/v36/-nF7OG829Oofr2wohFbTp9iFPysLA_ZJ1g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstc/v36/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76CyzCtEsg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Tagalog",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tagalog"
        ],
        "version": "v22",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstagalog/v22/J7aFnoNzCnFcV9ZI-sUYuvote1R0wwEAA8jHexnL.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstagalog/v22/J7aFnoNzCnFcV9ZI-sUYuvote1R0wzEBCcw.ttf"
    },
    {
        "family": "Noto Sans Tagbanwa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tagbanwa"
        ],
        "version": "v18",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstagbanwa/v18/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_nZRjQEaYpGoQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstagbanwa/v18/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_npRz4A.ttf"
    },
    {
        "family": "Noto Sans Tai Le",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tai-le"
        ],
        "version": "v17",
        "lastModified": "2022-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR58tK1W77HtMo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR5wtO_Xw.ttf"
    },
    {
        "family": "Noto Sans Tai Tham",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tai-tham"
        ],
        "version": "v20",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEuBv0U4hgtwxDUw2x9q7tbjLIfbPGdDaRlkJrUNw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbDgukzQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Tai Viet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tai-viet"
        ],
        "version": "v19",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstaiviet/v19/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZr644fWsRO9w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstaiviet/v19/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZb6oQb.ttf"
    },
    {
        "family": "Noto Sans Takri",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "takri"
        ],
        "version": "v24",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstakri/v24/TuGJUVpzXI5FBtUq5a8bnKIOdTwQNO_W3khJXg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstakri/v24/TuGJUVpzXI5FBtUq5a8bnKIOdTwgNeXS.ttf"
    },
    {
        "family": "Noto Sans Tamil",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v27",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVm2YdFI3GCY6SyQy1KfStzYKZ6x_-fACIgaw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo40QoqQ.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Tamil Supplement",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil-supplement"
        ],
        "version": "v21",
        "lastModified": "2023-06-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstamilsupplement/v21/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax_vsAeMkeq1x.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstamilsupplement/v21/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax8vtC-c.ttf"
    },
    {
        "family": "Noto Sans Tangsa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tangsa"
        ],
        "version": "v6",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NPdQPmcigbbZAIOl9igP26K470jouLY_WiBuM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1YkxoQa94.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Telugu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v26",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlPVOGZlE2Rrtr-HmgkMWJNjJ5_XS_eTyer338.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbaQEzb.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Thaana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thaana"
        ],
        "version": "v24",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c44dM-vnz-s-3jaEsxlxHkBH-WfuoxrSKMDvI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbiLglOo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Thai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai"
        ],
        "version": "v25",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansthai/v25/iJWdBXeUZi_OHPqn4wq6hQ2_hah-5c-dUX0x.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtlzB0RQ.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Sans Thai Looped",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai"
        ],
        "version": "v14",
        "lastModified": "2023-04-27",
        "files": {
            "100": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50fF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3YX6AYeCT_Wfd1.ttf",
            "200": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y84E4UgrzUO5sKA.ttf",
            "300": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yl4I4UgrzUO5sKA.ttf",
            "regular": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3gO6ocWiHvWQ.ttf",
            "500": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yz4M4UgrzUO5sKA.ttf",
            "600": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y44Q4UgrzUO5sKA.ttf",
            "700": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yh4U4UgrzUO5sKA.ttf",
            "800": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Ym4Y4UgrzUO5sKA.ttf",
            "900": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yv4c4UgrzUO5sKA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3QOqAY.ttf"
    },
    {
        "family": "Noto Sans Tifinagh",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tifinagh"
        ],
        "version": "v20",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstifinagh/v20/I_uzMoCduATTei9eI8dawkHIwvmhCvbn6rnEcXfs4Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstifinagh/v20/I_uzMoCduATTei9eI8dawkHIwvmhCvbX67PA.ttf"
    },
    {
        "family": "Noto Sans Tirhuta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tirhuta"
        ],
        "version": "v16",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanstirhuta/v16/t5t6IQYRNJ6TWjahPR6X-M-apUyby7uGUBsTrn5P.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanstirhuta/v16/t5t6IQYRNJ6TWjahPR6X-M-apUyby4uHWh8.ttf"
    },
    {
        "family": "Noto Sans Ugaritic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "ugaritic"
        ],
        "version": "v16",
        "lastModified": "2023-05-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansugaritic/v16/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXkMhoIkiazfg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansugaritic/v16/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXUMxAM.ttf"
    },
    {
        "family": "Noto Sans Vai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vai"
        ],
        "version": "v17",
        "lastModified": "2022-09-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrURMTsDIRSfr0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrUdMXmCA.ttf"
    },
    {
        "family": "Noto Sans Vithkuqi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vithkuqi"
        ],
        "version": "v1",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyX7m77CXvQswd6WjYu9E1wN6cih2TIeTf0SZST2g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnva5SsKIxug.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Sans Wancho",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "wancho"
        ],
        "version": "v17",
        "lastModified": "2022-09-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBqAPopiRfKp8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBmALiog.ttf"
    },
    {
        "family": "Noto Sans Warang Citi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "warang-citi"
        ],
        "version": "v17",
        "lastModified": "2022-09-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKRpeVCCXzdgA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKhpO9G.ttf"
    },
    {
        "family": "Noto Sans Yi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "yi"
        ],
        "version": "v19",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosansyi/v19/sJoD3LFXjsSdcnzn071rO3apxVDJNVgSNg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosansyi/v19/sJoD3LFXjsSdcnzn071rO3aZxFrN.ttf"
    },
    {
        "family": "Noto Sans Zanabazar Square",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "zanabazar-square"
        ],
        "version": "v19",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notosanszanabazarsquare/v19/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQtJxOCEgN0Gc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notosanszanabazarsquare/v19/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQuJwMiU.ttf"
    },
    {
        "family": "Noto Serif",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserif/v23/ga6Iaw1J5X9T9RW6j9bNTFAcaRi_bMQ.ttf",
            "italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6Kaw1J5X9T9RW6j9bNfFIWbTq6fMRRMw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFCTwccP.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Ahom",
        "variants": [
            "regular"
        ],
        "subsets": [
            "ahom",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifahom/v20/FeVIS0hfp6cprmEUffAW_fUL_AN-wuYrPFiwaw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifahom/v20/FeVIS0hfp6cprmEUffAW_fUL_ANOw-wv.ttf"
    },
    {
        "family": "Noto Serif Armenian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "armenian",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2023-11-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XF2EqMt3YoFsciDRZxptyCUKJmytZ0kT0S1UL5Ayp0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8Krx__e.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Balinese",
        "variants": [
            "regular"
        ],
        "subsets": [
            "balinese",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifbalinese/v20/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXRbgJdhapcUU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifbalinese/v20/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXdbkDcg.ttf"
    },
    {
        "family": "Noto Serif Bengali",
        "variants": [
            "regular"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2022-09-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkUPvggTvnzO14VSXltirUdnnkt1pw8UZiHeH5yig.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHkqm6rw.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Devanagari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v30",
        "lastModified": "2024-07-01",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifdevanagari/v30/x3d-cl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4djEWqKMxsKw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifdevanagari/v30/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-oz-GO0M.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Display",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2023-08-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buErppa9f8_vkXaZLAgP0G5Wi6QmA1QAcqRNOlqMKg.ttf",
            "italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEpppa9f8_vkXaZLAgP0G5Wi6QmA1QwcK5JGF-cKi3r.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugLYjE.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Dogra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "dogra",
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2023-11-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifdogra/v23/MQpP-XquKMC7ROPP3QOOlm7xPu3fGy63IbPzkns.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifdogra/v23/MQpP-XquKMC7ROPP3QOOlm7xPu3fKy-9JQ.ttf"
    },
    {
        "family": "Noto Serif Ethiopic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "ethiopic",
            "latin",
            "latin-ext"
        ],
        "version": "v30",
        "lastModified": "2023-09-14",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mZoR7-XjwJ8_Au3Ti5tXj5Rd83frpWNqU_FjYhCmo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUTkKm5.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Georgian",
        "variants": [
            "regular"
        ],
        "subsets": [
            "georgian",
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2023-11-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMtRpd8s4nv8hG_qOzL7HOAw4nt0Sl_RRe4_Tsdkf4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvovdj70.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Grantha",
        "variants": [
            "regular"
        ],
        "subsets": [
            "grantha",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-06-10",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifgrantha/v21/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiqFENLR7fHGw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifgrantha/v21/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiaFUlP.ttf"
    },
    {
        "family": "Noto Serif Gujarati",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v27",
        "lastModified": "2023-11-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESt6WBlOixO-3OJ1FTmTsmqlBRUJBVkaAhpVQOwBDU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycLzvOVY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Gurmukhi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92zJtA9LNqsg7tCYlXdCV1VPnAEeDU0vNI0un_HLMEo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eSTMkKE.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif HK",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-hongkong",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifhk/v9/BngOUXBETWXI6LwlBZGcqL-B5qCr5RCDY_k.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifhk/v9/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMd-K1RnXdA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Hebrew",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-06-10",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifhebrew/v28/k3kKo9MMPvpLmixYH7euCwmkS9Dohi_-2KiSGg-H.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifhebrew/v28/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSMG41ug.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif JP",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifjp/v30/xn7mYHs72GKoTvER4Gn3b5eMXN6kYkY0T84.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifjp/v30/xn71YHs72GKoTvER4Gn3b5eMRtWGkp6o7MjQ2bwxOtbBKrc.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif KR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "korean",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifkr/v28/3Jn7SDn90Gmq2mr3blnHaTZXduBp1ONyKHQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifkr/v28/3JnoSDn90Gmq2mr3blnHaTZXbOtLJDvui3JOncjmeP53658.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Kannada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-JGZHLJFKIhClqUYqXDiWqpxQxWSPyUIQDIsKCHg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDQeVD6w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Khitan Small Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khitan-small-script",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifkhitansmallscript/v4/jizzRFVKsm4Bt9PrbSzC4KLlQUF5lRJg5j-l5PvyhfTdd4TsZ8lb39iddA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifkhitansmallscript/v4/jizzRFVKsm4Bt9PrbSzC4KLlQUF5lRJg5j-l5PvyhfTdd4TcZsNf.ttf"
    },
    {
        "family": "Noto Serif Khmer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6qfidqLzI2JPCkXAO2hmogq0148ldPg-IUDNg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8AXUxO.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Khojki",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khojki",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-02-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_u0MoOduATTei9aP90ctmPGxP2rBL7HwJfIeG71.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY0ghfyJcU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Serif Lao",
        "variants": [
            "regular"
        ],
        "subsets": [
            "lao",
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2023-09-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriflao/v24/3y946bYwcCjmsU8JEzCMxEwQfFpAsZsUPves.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKnvKpkg.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Makasar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "makasar"
        ],
        "version": "v1",
        "lastModified": "2023-06-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifmakasar/v1/memjYbqtyH-NiZpFH_9zcvB_PqkfY9S7j4HTVSmevw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifmakasar/v1/memjYbqtyH-NiZpFH_9zcvB_PqkfY9SLjovX.ttf"
    },
    {
        "family": "Noto Serif Malayalam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "malayalam"
        ],
        "version": "v28",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAsUU5sdmdP_HMcVcZFcH7DeVBeGVgSMFM9UJWbN_un.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xvnFYD.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Myanmar",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "myanmar"
        ],
        "version": "v13",
        "lastModified": "2022-09-28",
        "files": {
            "100": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJudM7F2Yv76aBKKs-bHMQfAHUw3jnNwBDsU9X6RPzQ.ttf",
            "200": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNbDHMefv2TeXJng.ttf",
            "300": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNCDLMefv2TeXJng.ttf",
            "regular": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jn1pBrocdDqRA.ttf",
            "500": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNUDPMefv2TeXJng.ttf",
            "600": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNfDTMefv2TeXJng.ttf",
            "700": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNGDXMefv2TeXJng.ttf",
            "800": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNBDbMefv2TeXJng.ttf",
            "900": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNIDfMefv2TeXJng.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jnFpRDs.ttf"
    },
    {
        "family": "Noto Serif NP Hmong",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "nyiakeng-puachue-hmong"
        ],
        "version": "v1",
        "lastModified": "2022-12-08",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pON61gItFMO79E4L1GPUi-2sixKHZyFj7peYDHDLnAo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbjPhGLo1Ok.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Serif Old Uyghur",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "old-uyghur"
        ],
        "version": "v4",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifolduyghur/v4/v6-KGZbLJFKIhClqUYqXDiGnrVoFRCW6JdwnKumeF2yVgA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifolduyghur/v4/v6-KGZbLJFKIhClqUYqXDiGnrVoFRCW6JdwXK-Oa.ttf"
    },
    {
        "family": "Noto Serif Oriya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "oriya"
        ],
        "version": "v4",
        "lastModified": "2023-03-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQdmj56u-r69izk_LDqWN7w0cYB0OBNBn8Kwb0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxrc_Ey_tUk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Serif Ottoman Siyaq",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "ottoman-siyaq-numbers"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifottomansiyaq/v2/fC1yPZ9IYnzRhTrrc4s8cSvYI0eozzaFOQ01qoHLJrgA00kAdA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifottomansiyaq/v2/fC1yPZ9IYnzRhTrrc4s8cSvYI0eozzaFOQ01qoH7J7IE.ttf"
    },
    {
        "family": "Noto Serif SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-07-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifsc/v31/H4chBXePl9DZ0Xe7gG9cyOj7oqacbzhqDtg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifsc/v31/H4cyBXePl9DZ0Xe7gG9cyOj7uK2-n-D2rd4FY7SCqxWu-Yo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Sinhala",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v26",
        "lastModified": "2023-05-02",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtV-JwinQqclnZE2CnsPug9lgGC3y2Fslsq8DNibFw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR2Mtz6Y.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif TC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-traditional",
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftc/v32/XLYgIZb5bJNDGYxLBibeHZ0BhncESXFtUsM.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftc/v32/XLYzIZb5bJNDGYxLBibeHZ0BnHwmuanx8cUaGX9aMNpCM2I.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Tamil",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v28",
        "lastModified": "2023-04-27",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjZdHr-klIgTfc40komjQ5OObazeJSY8z6Np1k.ttf",
            "italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjfdHr-klIgTfc40komjQ5OObazSJaS9xyIt1mcXg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-h8fbz.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Tangut",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tangut"
        ],
        "version": "v16",
        "lastModified": "2023-05-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftangut/v16/xn76YGc72GKoTvER4Gn3b4m9Ern7Em41fcvN2KT4.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftangut/v16/xn76YGc72GKoTvER4Gn3b4m9Ern7El40d88.ttf"
    },
    {
        "family": "Noto Serif Telugu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v26",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbW2pCbnkEKmXNVmt2M1q6f4HWbbiSHZ0bc5Qj9.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCAuIeb.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Thai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai"
        ],
        "version": "v24",
        "lastModified": "2023-10-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kIo80MPvpLmixYH7euCxWpSMut8SX6-q2CGg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF9RQAG8.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 62.5,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Tibetan",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tibetan"
        ],
        "version": "v22",
        "lastModified": "2023-03-09",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokzH7nwAEdtF9N45n0Vaz7O-pk0wsvrFsIn6WYDvA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcDS_hcQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Noto Serif Toto",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "toto"
        ],
        "version": "v5",
        "lastModified": "2024-07-16",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoseriftoto/v5/Ktk1ALSMeZjqPnXk1rCkHYHNtwv3F6mZVY9Y5w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoseriftoto/v5/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCy3Ip-ep4w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Serif Vithkuqi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vithkuqi"
        ],
        "version": "v1",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA9Pr1OY7FjTf5szakutkndpw9HH-4a41dFGZiCpggI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRWMdV2DoSs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Serif Yezidi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "yezidi"
        ],
        "version": "v21",
        "lastModified": "2023-07-24",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLY8IYr5bJNDGYxLBibeHZAn3B5KJFlsYMYHGGeT.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEUr1uN.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Traditional Nushu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "nushu"
        ],
        "version": "v17",
        "lastModified": "2023-05-23",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZco3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67J011GNh6SYA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXus1unOYbA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Noto Znamenny Musical Notation",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "znamenny"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/notoznamennymusicalnotation/v3/CSRW4ylQnPyaDwAMK1U_AolTaJ4Lz41GcgaIZV9YO2rO88jvtpqqdoWa7g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/notoznamennymusicalnotation/v3/CSRW4ylQnPyaDwAMK1U_AolTaJ4Lz41GcgaIZV9YO2rO88jft5Cu.ttf",
        "colorCapabilities": [
            "COLRv0"
        ]
    },
    {
        "family": "Nova Cut",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkWxPKTM1K9nz.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkVxOIzc.ttf"
    },
    {
        "family": "Nova Flat",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVebEuStkm20oJA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVeb0uCFg.ttf"
    },
    {
        "family": "Nova Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek",
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novamono/v20/Cn-0JtiGWQ5Ajb--MRKfYGxYrdM9Sg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novamono/v20/Cn-0JtiGWQ5Ajb--MRKvYWZc.ttf"
    },
    {
        "family": "Nova Oval",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswCMY-h3cWkWg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswyMIWl.ttf"
    },
    {
        "family": "Nova Round",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTYYfeeetYEBc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTUYbUfQ.ttf"
    },
    {
        "family": "Nova Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvmQNEl0O0kEx.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvlQMGFk.ttf"
    },
    {
        "family": "Nova Slim",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcn-yMOInrib9Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcnOycmM.ttf"
    },
    {
        "family": "Nova Square",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/novasquare/v24/RrQUbo9-9DV7b06QHgSWsZhARYMgGtWA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/novasquare/v24/RrQUbo9-9DV7b06QHgSWsahBT4c.ttf"
    },
    {
        "family": "Numans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH8IYRggiHVqaQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH84YBIk.ttf"
    },
    {
        "family": "Nunito",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nunito/v26/XRXV3I6Li01BKof4MuyAbsrVcA.ttf",
            "italic": "https://fonts.gstatic.com/s/nunito/v26/XRXX3I6Li01BKofIMOaETM_FcCIG.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTI3ig.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 1000
            }
        ]
    },
    {
        "family": "Nunito Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nunitosans/v15/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf",
            "italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilXtVUg.ttf",
        "axes": [
            {
                "tag": "YTLC",
                "start": 440,
                "end": 540
            },
            {
                "tag": "opsz",
                "start": 6,
                "end": 12
            },
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 1000
            }
        ]
    },
    {
        "family": "Nuosu SIL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "yi"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/nuosusil/v10/8vIK7wM3wmRn_kc4uAjeFGxbO_zo-w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/nuosusil/v10/8vIK7wM3wmRn_kc4uAjuFWZf.ttf"
    },
    {
        "family": "Odibee Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/odibeesans/v18/neIPzCSooYAho6WvjeToRYkyepH9qGsf.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/odibeesans/v18/neIPzCSooYAho6WvjeToRbkzcJU.ttf"
    },
    {
        "family": "Odor Mean Chey",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4mrY2zqUKafv.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4lrZ0T4.ttf"
    },
    {
        "family": "Offside",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/offside/v24/HI_KiYMWKa9QrAykQ5HiRp-dhpQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/offside/v24/HI_KiYMWKa9QrAykc5DoQg.ttf"
    },
    {
        "family": "Oi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "tamil",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oi/v19/w8gXH2EuRqtaut6yjBOG.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oi/v19/w8gXH2EuRptbsNo.ttf"
    },
    {
        "family": "Ojuju",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ojuju/v3/7r3IqXF7v9ApbqkppYgA3LZg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ojuju/v3/7r3bqXF7v9ApbrMih3jYQBVm9-n_SpkJ5mtV.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Old Standard TT",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQubh3o1vLImiwAVvYawgcf2eVurVC5RHdCZg.ttf",
            "italic": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQsbh3o1vLImiwAVvYawgcf2eVer1q9ZnJSZtQG.ttf",
            "700": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-dTFxeb80flQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQubh3o1vLImiwAVvYawgcf2eVerFq9.ttf"
    },
    {
        "family": "Oldenburg",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oldenburg/v22/fC1jPY5JYWzbywv7c4V6UU6oXyndrw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oldenburg/v22/fC1jPY5JYWzbywv7c4VKUESs.ttf"
    },
    {
        "family": "Ole",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rd89fw69qJ_ew.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rdM9PY-.ttf"
    },
    {
        "family": "Oleo Script",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccBhasU7Q8Cad.ttf",
            "700": "https://fonts.gstatic.com/s/oleoscript/v14/raxkHieDvtMOe0iICsUccCDmnmrY2zqUKafv.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccChbu0o.ttf"
    },
    {
        "family": "Oleo Script Swash Caps",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXquSY0Hg90.ttf",
            "700": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBbYUsn9T5dt0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HAXukTQ.ttf"
    },
    {
        "family": "Onest",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/onest/v6/gNMKW3F-SZuj7ymY8ncKEZez.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPh9R-psxtI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Oooh Baby",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ooohbaby/v4/2sDcZGJWgJTT2Jf76xQDb2-4C7wFZQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ooohbaby/v4/2sDcZGJWgJTT2Jf76xQzbmW8.ttf"
    },
    {
        "family": "Open Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v40",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/opensans/v40/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf",
            "italic": "https://fonts.gstatic.com/s/opensans/v40/mem6YaGs126MiZpBA-UFUJ0ef8xkA76a.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4maVc.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Oranienbaum",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-3zn7kz3dpHc.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-0zm5Eg.ttf"
    },
    {
        "family": "Orbit",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/orbit/v1/_LOCmz7I-uHd2mjEeqciRwRm.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/orbit/v1/_LOCmz7I-uHd2ljFcKM.ttf"
    },
    {
        "family": "Orbitron",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/orbitron/v31/yMJRMIlzdpvBhQQL_Tq8fSx5i814.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6Bp2g2.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Oregano",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oregano/v15/If2IXTPxciS3H4S2kZffPznO3yM.ttf",
            "italic": "https://fonts.gstatic.com/s/oregano/v15/If2KXTPxciS3H4S2oZXVOxvLzyP_qw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oregano/v15/If2IXTPxciS3H4S2oZbVOw.ttf"
    },
    {
        "family": "Orelega One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/orelegaone/v12/3qTpojOggD2XtAdFb-QXZGt61EcYaQ7F.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/orelegaone/v12/3qTpojOggD2XtAdFb-QXZFt73kM.ttf"
    },
    {
        "family": "Orienta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/orienta/v15/PlI9FlK4Jrl5Y9zNeyeo9HRFhcU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/orienta/v15/PlI9FlK4Jrl5Y9zNSyai8A.ttf"
    },
    {
        "family": "Original Surfer",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/originalsurfer/v22/RWmQoKGZ9vIirYntXJ3_MbekzNMiDEtvAlaMKw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/originalsurfer/v22/RWmQoKGZ9vIirYntXJ3_MbekzNMSDUFr.ttf"
    },
    {
        "family": "Oswald",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v53",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oswald/v53/TK3iWkUHHAIjg75GHjUHte5fKg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUBiYA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 700
            }
        ]
    },
    {
        "family": "Outfit",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/outfit/v11/QGYvz_MVcBeNP4N5s0Frc4H0ng.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4C0Fg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Over the Rainbow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/overtherainbow/v20/11haGoXG1k_HKhMLUWz7Mc7vvW5upvOm9NA2XG0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/overtherainbow/v20/11haGoXG1k_HKhMLUWz7Mc7vvW5ulvKs8A.ttf"
    },
    {
        "family": "Overlock",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/overlock/v17/Z9XVDmdMWRiN1_T9Z4Te4u2El6GC.ttf",
            "italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XTDmdMWRiN1_T9Z7Tc6OmmkrGC7Cs.ttf",
            "700": "https://fonts.gstatic.com/s/overlock/v17/Z9XSDmdMWRiN1_T9Z7xizcmMvL2L9TLT.ttf",
            "700italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XQDmdMWRiN1_T9Z7Tc0FWJtrmp8CLTlNs.ttf",
            "900": "https://fonts.gstatic.com/s/overlock/v17/Z9XSDmdMWRiN1_T9Z7xaz8mMvL2L9TLT.ttf",
            "900italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XQDmdMWRiN1_T9Z7Tc0G2Ltrmp8CLTlNs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/overlock/v17/Z9XVDmdMWRiN1_T9Z7Tf6Ok.ttf"
    },
    {
        "family": "Overlock SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/overlocksc/v23/1cX3aUHKGZrstGAY8nwVzHGAq8Sk1PoH.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/overlocksc/v23/1cX3aUHKGZrstGAY8nwVzEGBocA.ttf"
    },
    {
        "family": "Overpass",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/overpass/v16/qFdH35WCmI96Ajtm82GiWdrCwwcJ.ttf",
            "italic": "https://fonts.gstatic.com/s/overpass/v16/qFdB35WCmI96Ajtm81GgU97gxhcJk1s.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/overpass/v16/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PPbOJqP.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Overpass Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/overpassmono/v16/_Xmq-H86tzKDdAPa-KPQZ-AC5ii-t_-2G38.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUonzyVO4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Ovo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ovo/v17/yYLl0h7Wyfzjy4Q5_3WVxA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ovo/v17/yYLl0h7WyfzTyo49.ttf"
    },
    {
        "family": "Oxanium",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oxanium/v19/RrQQboN_4yJ0JmiMS2XO0LBBd4Y.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfkiND3A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Oxygen",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCJW8Db2-4C7wFZQ.ttf",
            "regular": "https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf",
            "700": "https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCNWgDb2-4C7wFZQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4LcnbuKiE0j.ttf"
    },
    {
        "family": "Oxygen Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/oxygenmono/v14/h0GsssGg9FxgDgCjLeAd7ijfze-PPlUu.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/oxygenmono/v14/h0GsssGg9FxgDgCjLeAd7hjex-s.ttf"
    },
    {
        "family": "PT Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9ArCg5MtPyAcg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9AnCkzNg.ttf"
    },
    {
        "family": "PT Sans",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
            "italic": "https://fonts.gstatic.com/s/ptsans/v17/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf",
            "700": "https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf",
            "700italic": "https://fonts.gstatic.com/s/ptsans/v17/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0SEwA.ttf"
    },
    {
        "family": "PT Sans Caption",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQXwQy6yxg.ttf",
            "700": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrUSwWuz38Tgg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlMVP6Hrxmt7-fsUFhlFXNIlpcafA_0.ttf"
    },
    {
        "family": "PT Sans Narrow",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf",
            "700": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngRUXNadjH0qYEzV7ab-oWlsbCAwRg.ttf"
    },
    {
        "family": "PT Serif",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf",
            "italic": "https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf",
            "700": "https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf",
            "700italic": "https://fonts.gstatic.com/s/ptserif/v18/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFQzro.ttf"
    },
    {
        "family": "PT Serif Caption",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf",
            "italic": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVj2ZhbGCW-JoW6S34pSDpqYKU019e7CAk8YvJEeg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU019S7CA.ttf"
    },
    {
        "family": "Pacifico",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6B6Mw.ttf"
    },
    {
        "family": "Padauk",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "myanmar"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbBa0_g3LlYbg.ttf",
            "700": "https://fonts.gstatic.com/s/padauk/v16/RrQSboJg-id7Onb512DE1JJEZ4YwGg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbxakXk.ttf"
    },
    {
        "family": "Padyakke Expanded One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/padyakkeexpandedone/v6/K2FvfY9El_tbR0JfHb6WWvrBaU6XAUvC4IAYOKRkpDjeoQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/padyakkeexpandedone/v6/K2FvfY9El_tbR0JfHb6WWvrBaU6XAUvC4IAoOa5g.ttf"
    },
    {
        "family": "Palanquin",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/palanquin/v13/9XUhlJ90n1fBFg7ceXwUEltI7rWmZzTH.ttf",
            "200": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUvnpoxJuqbi3ezg.ttf",
            "300": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwU2nloxJuqbi3ezg.ttf",
            "regular": "https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwsdlFMzLC2Zw.ttf",
            "500": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUgnhoxJuqbi3ezg.ttf",
            "600": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUrn9oxJuqbi3ezg.ttf",
            "700": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUyn5oxJuqbi3ezg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwcd1tI.ttf"
    },
    {
        "family": "Palanquin Dark",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/palanquindark/v14/xn75YHgl1nqmANMB-26xC7yuF_6OTEo9VtfE.ttf",
            "500": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8Z6ZW41fcvN2KT4.ttf",
            "600": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8ZWYm41fcvN2KT4.ttf",
            "700": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8YyY241fcvN2KT4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/palanquindark/v14/xn75YHgl1nqmANMB-26xC7yuF86PRk4.ttf"
    },
    {
        "family": "Palette Mosaic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/palettemosaic/v11/AMOIz4aBvWuBFe3TohdW6YZ9MFiy4dxL4jSr.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/palettemosaic/v11/AMOIz4aBvWuBFe3TohdW6YZ9MGiz69g.ttf"
    },
    {
        "family": "Pangolin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWPfK5d3aiLBG.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWMfL79k.ttf"
    },
    {
        "family": "Paprika",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2022-09-22",
        "files": {
            "regular": "https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfuIgOq7jkAOw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfiIkErw.ttf"
    },
    {
        "family": "Parisienne",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpcy96DuKuavM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpQy5wCg.ttf"
    },
    {
        "family": "Passero One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/passeroone/v26/JTUTjIko8DOq5FeaeEAjgE5B5Arr-s50.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/passeroone/v26/JTUTjIko8DOq5FeaeEAjgH5A7g4.ttf"
    },
    {
        "family": "Passion One",
        "variants": [
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/passionone/v18/PbynFmL8HhTPqbjUzux3JHuW_Frg6YoV.ttf",
            "700": "https://fonts.gstatic.com/s/passionone/v18/Pby6FmL8HhTPqbjUzux3JEMq037owpYcuH8y.ttf",
            "900": "https://fonts.gstatic.com/s/passionone/v18/Pby6FmL8HhTPqbjUzux3JEMS0X7owpYcuH8y.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/passionone/v18/PbynFmL8HhTPqbjUzux3JEuX9l4.ttf"
    },
    {
        "family": "Passions Conflict",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/passionsconflict/v7/kmKnZrcrFhfafnWX9x0GuEC-zowow5NeYRI4CN2V.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/passionsconflict/v7/kmKnZrcrFhfafnWX9x0GuEC-zowow6NfaxY.ttf"
    },
    {
        "family": "Pathway Extreme",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pathwayextreme/v3/neITzCC3pJ0rsaH2_sD-QttXPfDVqVkarSqSwA.ttf",
            "italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neIRzCC3pJ0rsaH2_sD-QttXPfDlq1Mejy-CwJUv.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xak2N1FKiYz.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 8,
                "end": 144
            },
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Pathway Gothic One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pathwaygothicone/v15/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTFxeb80flQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pathwaygothicone/v15/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-tTVZa.ttf"
    },
    {
        "family": "Patrick Hand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/patrickhand/v23/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/patrickhand/v23/LDI1apSQOAYtSuYWp8ZhfYe8WMLO.ttf"
    },
    {
        "family": "Patrick Hand SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/patrickhandsc/v15/0nkwC9f7MfsBiWcLtY65AWDK873ViSi6JQc7Vg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/patrickhandsc/v15/0nkwC9f7MfsBiWcLtY65AWDK873liCK-.ttf"
    },
    {
        "family": "Pattaya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pattaya/v16/ea8ZadcqV_zkHY-XNdCn92ZEmVs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pattaya/v16/ea8ZadcqV_zkHY-XBdGt8w.ttf"
    },
    {
        "family": "Patua One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/patuaone/v20/ZXuke1cDvLCKLDcimxBI5PNvNA9LuA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/patuaone/v20/ZXuke1cDvLCKLDcimxB45flr.ttf"
    },
    {
        "family": "Pavanam",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPDOtQ9Wf0QcE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPPOpa8Q.ttf"
    },
    {
        "family": "Paytone One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/paytoneone/v23/0nksC9P7MfYHj2oFtYm2CiTqivr9iBq_.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/paytoneone/v23/0nksC9P7MfYHj2oFtYm2ChTrgP4.ttf"
    },
    {
        "family": "Peddana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v20",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWhh2aBYyMcKw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWthyQAQ.ttf"
    },
    {
        "family": "Peralta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/peralta/v19/hYkJPu0-RP_9d3kRGxAhrv956B8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/peralta/v19/hYkJPu0-RP_9d3kRKxErqg.ttf"
    },
    {
        "family": "Permanent Marker",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004LauCeg.ttf"
    },
    {
        "family": "Petemoss",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/petemoss/v7/A2BZn5tA2xgtGWHZgxkesKb9UouQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/petemoss/v7/A2BZn5tA2xgtGWHZgykfuqI.ttf"
    },
    {
        "family": "Petit Formal Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/petitformalscript/v17/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHnqP4gZSiE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/petitformalscript/v17/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qkHjgOw.ttf"
    },
    {
        "family": "Petrona",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/petrona/v32/mtG64_NXL7bZo9XXsXVStGsRwCU.ttf",
            "italic": "https://fonts.gstatic.com/s/petrona/v32/mtG44_NXL7bZo9XXgXdYsEkU0CWuFw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsTRAFYo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Philosopher",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/philosopher/v20/vEFV2_5QCwIS4_Dhez5jcVBpRUwU08qe.ttf",
            "italic": "https://fonts.gstatic.com/s/philosopher/v20/vEFX2_5QCwIS4_Dhez5jcWBrT0g21tqeR7c.ttf",
            "700": "https://fonts.gstatic.com/s/philosopher/v20/vEFI2_5QCwIS4_Dhez5jcWjVamgc-NaXXq7H.ttf",
            "700italic": "https://fonts.gstatic.com/s/philosopher/v20/vEFK2_5QCwIS4_Dhez5jcWBrd_QZ8tK1W77HtMo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/philosopher/v20/vEFV2_5QCwIS4_Dhez5jcWBoT0g.ttf"
    },
    {
        "family": "Phudu",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/phudu/v4/0FlaVPSHk0ya-6mfWh8MZB8g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKtWzkSg-q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Piazzolla",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/piazzolla/v36/N0bX2SlTPu5rIkWIZjVQIfTTkdbJYA.ttf",
            "italic": "https://fonts.gstatic.com/s/piazzolla/v36/N0bR2SlTPu5rIkWIZjVgI_7Xs9PZYIlI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/piazzolla/v36/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxkLz3gU.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 8,
                "end": 30
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Piedra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/piedra/v25/ke8kOg8aN0Bn7hTunEyHN_M3gA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/piedra/v25/ke8kOg8aN0Bn7hTenUaD.ttf"
    },
    {
        "family": "Pinyon Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pinyonscript/v22/6xKpdSJbL9-e9LuoeQiDRQR8aOLQO4bhiDY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pinyonscript/v22/6xKpdSJbL9-e9LuoeQiDRQR8WOPaPw.ttf"
    },
    {
        "family": "Pirata One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtoftiiEr5_BdZ8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtofhimOqw.ttf"
    },
    {
        "family": "Pixelify Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pixelifysans/v1/CHylV-3HFUT7aC4iv1TxGDR9FnoOimlReJw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TTp0E1ZZZY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Plaster",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ei5JOtLOeLI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ii9DPg.ttf"
    },
    {
        "family": "Platypi",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/platypi/v4/bMr3mSGU7pMlaX6-JgKMMMyTQ3o.ttf",
            "italic": "https://fonts.gstatic.com/s/platypi/v4/bMr1mSGU7pMlaX6-FgCGNO6WU3oT0g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/platypi/v4/bMromSGU7pMlaX6-PAmuwBQP4Hwe02Jz4q9isRw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Play",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/play/v19/6aez4K2oVqwIjtI8Hp8Tx3A.ttf",
            "700": "https://fonts.gstatic.com/s/play/v19/6ae84K2oVqwItm4TOpc423nTJTM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/play/v19/6aez4K2oVqwIvtM2Gg.ttf"
    },
    {
        "family": "Playball",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playball/v20/TK3gWksYAxQ7jbsKcj8Dl-tPKo2t.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playball/v20/TK3gWksYAxQ7jbsKcg8Cne8.ttf"
    },
    {
        "family": "Playfair",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playfair/v2/0nkrC9D7PO4KhmUJ5-bYRQDioeb0.ttf",
            "italic": "https://fonts.gstatic.com/s/playfair/v2/0nktC9D7PO4KhmUJ59baTwTApPb0kQM.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPkUeugcqyc.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 5,
                "end": 1200
            },
            {
                "tag": "wdth",
                "start": 87.5,
                "end": 112.5
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Playfair Display",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v37",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXPTvSgWE_-xU.ttf",
            "italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnYhUM66xV7PQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDRbtY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Playfair Display SC",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4GEcM2M4s.ttf",
            "italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke87OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbwMFeEzI4sNKg.ttf",
            "700": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpNcsdL4IUMyE.ttf",
            "700italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0qc4XK6ARIyH5IA.ttf",
            "900": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nTorNcsdL4IUMyE.ttf",
            "900italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0kcwXK6ARIyH5IA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lb8MFQ.ttf"
    },
    {
        "family": "Playpen Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "emoji",
            "latin",
            "latin-ext",
            "math",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playpensans/v13/dg4i_pj1p6gXP0gzAZgm4c8NSygiiyyhRw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playpensans/v13/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IvRhinU3EQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Playwrite AR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritear/v1/VEM2RohisJz5pTCzruCNjWbFrNGOsEkJZA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritear/v1/VEMjRohisJz5pTCzruCNjWbfp_N-aNWqYgKS-ftfmf6OTw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AT",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteat/v1/Gw69wc7n6kfJN4fVoKON7HIeDjZvt9mVvg.ttf",
            "italic": "https://fonts.gstatic.com/s/playwriteat/v1/Gw6jwc7n6kfJN4fVoKON7HIuDDxrldyFvm1n.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteat/v1/Gw6owc7n6kfJN4fVoKON7HIEBRSfb0U2uGBm2M76R0zGKA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AU NSW",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteaunsw/v4/6qLbKY4NtxD-qVlIPUIPenElWCCEQxMAZkM1pn4.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteaunsw/v4/6qLWKY4NtxD-qVlIPUIPenElWCCEWRgilpupBXi19xZjMY97Rjk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AU QLD",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteauqld/v4/SlGLmR-Yo5oYZX5BFVcEwSFSOXBRQgviqWC_O7Y.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteauqld/v4/SlGGmR-Yo5oYZX5BFVcEwSFSOXBRWADAWbgjmLBhA6-yML2eoGk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AU SA",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteausa/v4/YcmusZpNS1SdgmHbGgtRuUElnR3YkgJJtci2kA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteausa/v4/YcmhsZpNS1SdgmHbGgtRuUElnR3CmSC5bVQVlrclpZgRQuRpDg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AU TAS",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteautas/v4/Gfte7u9QuxsdI_QuuctXue3Elxxmah3VesW0BfM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteautas/v4/GftT7u9QuxsdI_QuuctXue3ElxxmcBb3ih0opvWiLLUEH5K7FFs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite AU VIC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteauvic/v4/bWtj7enUfwn0Hf1zjprKOJdcDy8r3QuXZgiClzY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteauvic/v4/bWtu7enUfwn0Hf1zjprKOJdcDy8rxwC1ltAeNDAAd4fTaYK1AhY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite BE VLG",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritebevlg/v1/GFDxWBdug3mQSvrAT9AL6fd4ZkB-cWAhatVBaGM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritebevlg/v1/GFD8WBdug3mQSvrAT9AL6fd4ZkB-a2sDmg3dy2W0blL8vMCNOCg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite BE WAL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritebewal/v1/DtV4Jwq5QbIzyrA6DHdJ2BksuUmahwBmkui5HNg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritebewal/v1/DtV1Jwq5QbIzyrA6DHdJ2BksuUmanQtEYjAlv96WFsWCGMuhFRg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite BR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritebr/v4/kJE0BuMK4Q07lDHc2Xp9vYgSrMxzBZ2lDA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritebr/v4/kJEhBuMK4Q07lDHc2Xp9vYgIp-6D3QEGCpthmFOPJsbpBA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite CA",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteca/v4/z7NTdR_4cT0NOrEAIElil930TNeRpoq5Zg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteca/v4/z7NGdR_4cT0NOrEAIElil93uR_VhfhYaYOijHiqvUpyw0A.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite CL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritecl/v1/-zki91m7wssz_XLkGgu8hy33oZX-Xup87g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritecl/v1/-zk391m7wssz_XLkGgu8hy3tqrcOhnbf6ForU8JajLu9Cw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite CO",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v6",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteco/v6/0FlTVP2Hl1iH-fv2BH4kJkgB-dMOdS7sSg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteco/v6/0FlGVP2Hl1iH-fv2BH4kJkgb8vH-rbJPTDqqx7ZIk-ZEFg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite CU",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritecu/v1/VuJ2dNDb2p7tvoFGLMPdf9xGYTFZt0rNpQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritecu/v1/VuJjdNDb2p7tvoFGLMPdf9xcahOpb9ZuoyXseRmxVeXxnw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite CZ",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritecz/v1/8vIP7wYp22pt_BUChSHeVxx_M9f08h-8bA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritecz/v1/8vIa7wYp22pt_BUChSHeVxxlOPUEKoMfap_FCI4bfqX1Ug.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DE Grund",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedegrund/v4/EJRLQhwoXdccriFurnRxqv-1MFyKy69g8Keepi2lHw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedegrund/v4/EJR-QhwoXdccriFurnRxqv-1MFyKy696-4VufrEGGbTZz2qHVwGePQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DE LA",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedela/v4/oY1G8e3fprboJ2HN4ogXTpFVJ8Q5Ln2ZCGKRvA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedela/v4/oY1J8e3fprboJ2HN4ogXTpFVJ8QjJV9p0P4yukst2FnqDgYohA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DE SAS",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedesas/v4/1Pt1g9vaRvmWghDdrE8IDuRPVrHN5Vs45aiOBrU.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedesas/v4/1Pt4g9vaRvmWghDdrE8IDuRPVrHN_1AaFXASpbMqJTeVgt6kQSE.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DE VA",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedeva/v4/VuJpdNPb2p7tvoFGLMPdeMxGN1p9v2HRrDH0eA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedeva/v4/VuJmdNPb2p7tvoFGLMPdeMxGN1pntEMhdK1XfsTyRSySj28pHw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DK Loopet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedkloopet/v1/memiYbuzy2qb3rtJGfM1FvY-GacDcsPvr6v9WSCHpm8.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedkloopet/v1/memVYbuzy2qb3rtJGfM1FvY-GacDcsPvtaDfqfgbBWmV75JJwdgQsw4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite DK Uloopet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritedkuloopet/v1/bWtn7e3Ufwn0Hf1zjprKPYlcDAoHknvYFiCDpTMddp8a.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritedkuloopet/v1/bWtS7e3Ufwn0Hf1zjprKPYlcDAoHknvYFjqIh8PF6jwcP5K06lUbKOPk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite ES",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritees/v4/kJE0BuMK4Q07lDHc2Xp9uokSrMxzBZ2lDA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritees/v4/kJEhBuMK4Q07lDHc2Xp9uokIp-6D3QEGCpthmFOPJsbpBA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite ES Deco",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteesdeco/v4/7Aulp-g3kjKKGkePXEf2jxctfDxlvHkw2-m9x2iC.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteesdeco/v4/7AuWp-g3kjKKGkePXEf2jxctfDxlvGM7-RllW8uEsjJ4yrdBOAVt.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite FR Moderne",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritefrmoderne/v4/3y9-6awucz3w5m4FFTzKolJRXhUk_u1yWs-tNtKENeNp.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritefrmoderne/v4/3y9L6awucz3w5m4FFTzKolJRXhUk_u1yWtWmFCJcqUBvK5aJuAKfA1T8.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite FR Trad",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v6",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritefrtrad/v6/sJoe3KxJjdGLJV3vyatrJE2pkQisWXkKHZ0f1CZO.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritefrtrad/v6/sJot3KxJjdGLJV3vyatrJE2pkQisWWMBP23HSIVI5tvAogv9d9Wb.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite GB J",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritegbj/v4/k3kJo8wSPe9dzQ1UGbvobAPhY4KN2wv287Sb.ttf",
            "italic": "https://fonts.gstatic.com/s/playwritegbj/v4/k3kLo8wSPe9dzQ1UGbvobAPhY7KP0Q_U9qSbAxY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritegbj/v4/k3kEo8wSPe9dzQ1UGbvobAPhY5iG-fsubxedDheGdc93adTT.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite GB S",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v5",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritegbs/v5/oPWW_kFkk-s1Xclhmlemy7jsNR53bHiez1PV.ttf",
            "italic": "https://fonts.gstatic.com/s/playwritegbs/v5/oPWU_kFkk-s1Xclhmlemy7jsNS51Zny8ykPVEGQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritegbs/v5/oPWb_kFkk-s1Xclhmlemy7jsNQR8TohGU_DTHWU6uhDMxp2I.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite HR",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritehr/v1/WWXVljmQYQCZM5qaU_dwQYcoZybLwMVWng.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritehr/v1/WWXAljmQYQCZM5qaU_dwQYcybAQ7GFn1mFNJPsoBxYDdBA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite HR Lijeva",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritehrlijeva/v1/gNMYW2dhS5-p7HvxrBYiWN2SsKqLWCrYkjtiTWz5UGA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritehrlijeva/v1/gNMvW2dhS5-p7HvxrBYiWN2SsKqLWCrYiDBAvbRl82ZY0d4zBvaVwYs.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite HU",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritehu/v1/A2Bdn59A0g0xA3zDhFw-0vfVU7mVsM4Fqg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritehu/v1/A2BIn59A0g0xA3zDhFw-0vfPWJtlaFKmrETx1PL6fOyXGw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite ID",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteid/v4/Cn-xJt2YWhlY2oC4KxifKQJ8pfghQ3eplw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteid/v4/Cn-kJt2YWhlY2oC4KxifKQJmrtrRm-sKkQqUl0-RN_NREg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite IE",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteie/v4/fC1mPYtWYWnH0hvndYd6GCGMVwLBplm9aw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteie/v4/fC1zPYtWYWnH0hvndYd6GCGWXCAxfsUebXFMyzioNpMk2w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite IN",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritein/v4/uk-kEGGpoLQ97mfv2J3cZzup5w50_o9T7Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritein/v4/uk-xEGGpoLQ97mfv2J3cZzuz7CyEJhPw65lkM7mMAR4t2w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite IS",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteis/v3/JTUQjI4o_SGg9lecLGptrD1hziTn89dtpQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteis/v3/JTUFjI4o_SGg9lecLGptrD17xQYXK0vOoz6jq6R9WX50ow.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite IT Moderna",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteitmoderna/v4/mFTuWaYCwKPK5cx6W8jy2kwDnSUe9q45vR4pxoPdAYRC.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteitmoderna/v4/mFTbWaYCwKPK5cx6W8jy2kwDnSUe9q45vQQi5HMFnSdEx2F5WihMuLzb.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite IT Trad",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteittrad/v4/SlGKmR6Yo5oYZX5BFVcEySBSPE50BivIh2y2Iq91.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteittrad/v4/SlG5mR6Yo5oYZX5BFVcEySBSPE50BjHDpZxuvgxzFq96u-6PgUlJ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite MX",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritemx/v4/6xKodSNbKtCe7KfhXg7RYSwoSMj-N4_4kQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritemx/v4/6xK9dSNbKtCe7KfhXg7RYSwyQ-oO7xNblyJr9wnd1xcVWA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite NG Modern",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritengmodern/v4/ijwJs4b2R9Qve5V5lNJb_yRhEfSep5NbDimE2tKY2yY.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritengmodern/v4/ijw-s4b2R9Qve5V5lNJb_yRhEfSep5NbFCKmKgoEeCA4V17tPDbj7m8.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite NL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritenl/v3/k3kXo84SPe9dzQ1UGbvoZQ3hKYiJ-Q7m8w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritenl/v3/k3kCo84SPe9dzQ1UGbvoZQ37Iqp5IZJF9bmaG9_FrY1HaA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite NO",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteno/v3/nuF-D_fYSZviRJYb-P2TrQOvBjiqFTrghA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteno/v3/nuFrD_fYSZviRJYb-P2TrQO1DRpazaZDgnw-49wgLKat_g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite NZ",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritenz/v4/d6lPkaOxRsyr_zZDmUYvh2TM1_JgjmRpOA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritenz/v4/d6lakaOxRsyr_zZDmUYvh2TW3NCQVvjKPjPjngAUSRpzhA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite PE",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v1",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritepe/v1/FwZc7-Amxlw-50y5PJugmImLpWm6_K7bkA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritepe/v1/FwZJ7-Amxlw-50y5PJugmImRrktKJDJ4lnesO2ltfPDPcg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite PL",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritepl/v3/0QInMXVf_4C2VH-yUr5uz72O85bS8ANesw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritepl/v3/0QIyMXVf_4C2VH-yUr5uz72U-LQiKJ_9tb1WmRfbxZ2RTw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite PT",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritept/v3/6NUR8FidKwOcfRjj8ukv5Lgkyf9Fdty2ew.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritept/v3/6NUE8FidKwOcfRjj8ukv5Lg-wt21rkAVfXUe9qDiffNnug.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite RO",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritero/v3/gokpH6fuA1J7QPJ04HFTGSWHmNZEq6TTFw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritero/v3/gok8H6fuA1J7QPJ04HFTGSWdk_S0czhwEf0j4a9YrZSGIg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite SK",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v3",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritesk/v3/9XUilJp0klrZDw3AZHcsJTBoxJuqbi3ezg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritesk/v3/9XU3lJp0klrZDw3AZHcsJTByz7latrF9yDIlf-2cjsK5cA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite TZ",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritetz/v4/RLp4K5rs6au7bzABmVQAOwnOZdM8t6g3VQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritetz/v4/RLptK5rs6au7bzABmVQAOwnUbvHMbzSUU27JDWwTiexIPw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite US Modern",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteusmodern/v4/H4c7BWmRlMXPhla3hmMaveiYz8nSDkIFNtk6Z7xL8AQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteusmodern/v4/H4cMBWmRlMXPhla3hmMaveiYz8nSDkIFLNIYl2TXUwK62YohNj2CYUY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite US Trad",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteustrad/v4/fdNk9tyHsnVPjW9trmV7wQ0stdwRBZ0uKDBFUEXz.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteustrad/v4/fdNX9tyHsnVPjW9trmV7wQ0stdwRBYclCsCdzOb1-cd1E8tQjqib.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite VN",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwritevn/v4/mtG94_hXJqPSu8nf5RBY5i0w2A6yHk9d8w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwritevn/v4/mtGo4_hXJqPSu8nf5RBY5i0q0yxCxtP-9TFBNUI9I-5NOQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Playwrite ZA",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v4",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/playwriteza/v4/Noa16Uzhw5CTOhXKt5-vwvhxPAR9mhHobg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/playwriteza/v4/Noag6Uzhw5CTOhXKt5-vwvhrNyaNQo1LaBq0EbLHXYQmmw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 400
            }
        ]
    },
    {
        "family": "Plus Jakarta Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Jox2S2CgOva.ttf",
            "italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz0ymghfvaV-E.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU79Sx_Q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Podkova",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/podkova/v31/K2FxfZ1EmftJSV9VWJ75JoKhHys.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzfU5GI4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Poetsen One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/poetsenone/v3/ke8hOgIaMUB37xCgvCntWtIvq_KREbG9.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poetsenone/v3/ke8hOgIaMUB37xCgvCntWuIuofY.ttf"
    },
    {
        "family": "Poiret One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbk5lWVscxdKE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbo5hcUg.ttf"
    },
    {
        "family": "Poller One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pollerone/v23/ahccv82n0TN3gia5E4Bud-lbgUS5u0s.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pollerone/v23/ahccv82n0TN3gia5E4BuR-hRhQ.ttf"
    },
    {
        "family": "Poltawski Nowy",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUhRq6ww480U1xsUpFXD-iDBMNZIhI8tIHh.ttf",
            "italic": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUjRq6ww480U1xsUpFXD-iDBPNbKBYesZHhV64.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KqCWXDCSR.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Poly",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitRLCAq2Lpris.ttf",
            "italic": "https://fonts.gstatic.com/s/poly/v16/MQpV-W6wKNitdLKKr0DsviuGWA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitdLGKrw.ttf"
    },
    {
        "family": "Pompiere",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pompiere/v19/VEMyRoxis5Dwuyeov6Wt5jDtreOL.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pompiere/v19/VEMyRoxis5Dwuyeov5Ws7DQ.ttf"
    },
    {
        "family": "Pontano Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pontanosans/v17/qFdD35GdgYR8EzR6oBLDHa3qwjUMg1siNQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOXLMrceGow.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Poor Story",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd4OmnLD0Z4zM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd0OitKA.ttf"
    },
    {
        "family": "Poppins",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
            "100italic": "https://fonts.gstatic.com/s/poppins/v21/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
            "200": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
            "200italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
            "300": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
            "300italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
            "regular": "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
            "italic": "https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
            "500": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
            "500italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
            "600": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
            "600italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
            "700": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
            "700italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
            "800": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
            "800italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
            "900": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
            "900italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrJJHedw.ttf"
    },
    {
        "family": "Port Lligat Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/portlligatsans/v22/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7N0Tx4xEcQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/portlligatsans/v22/kmKmZrYrGBbdN1aV7Vokow6Lw4s4p7J-Sw.ttf"
    },
    {
        "family": "Port Lligat Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/portlligatslab/v25/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7ob9yGLmfI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/portlligatslab/v25/LDIpaoiQNgArA8kR7ulhZ8P_NYOsg7sR8w.ttf"
    },
    {
        "family": "Potta One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pottaone/v17/FeVSS05Bp6cy7xI-YfxQ3Z5nm29Gww.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pottaone/v17/FeVSS05Bp6cy7xI-Yfxg3JRj.ttf"
    },
    {
        "family": "Pragati Narrow",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD17_ytN3M48a.ttf",
            "700": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5fd_GJMTlo_4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD27-wNc.ttf"
    },
    {
        "family": "Praise",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/praise/v7/qkBUXvUZ-cnFXcFyDvO67L9XmQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/praise/v7/qkBUXvUZ-cnFXcFCD_m-.ttf"
    },
    {
        "family": "Prata",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/prata/v20/6xKhdSpbNNCT-vWIAG_5LWwJ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/prata/v20/6xKhdSpbNNCT-sWJCms.ttf"
    },
    {
        "family": "Preahvihear",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/preahvihear/v29/6NUS8F-dNQeEYhzj7uluxswE49FJf8Wv.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/preahvihear/v29/6NUS8F-dNQeEYhzj7uluxvwF6dU.ttf"
    },
    {
        "family": "Press Start 2P",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK0nSgPJE4580.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK3nTivY.ttf"
    },
    {
        "family": "Pridi",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1SiE0jRUG0AqUc.ttf",
            "300": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc02i00jRUG0AqUc.ttf",
            "regular": "https://fonts.gstatic.com/s/pridi/v13/2sDQZG5JnZLfkfWao2krbl29.ttf",
            "500": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1uik0jRUG0AqUc.ttf",
            "600": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1CjU0jRUG0AqUc.ttf",
            "700": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc0mjE0jRUG0AqUc.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/pridi/v13/2sDQZG5JnZLfkcWbqW0.ttf"
    },
    {
        "family": "Princess Sofia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/princesssofia/v25/qWczB6yguIb8DZ_GXZst16n7GRz7mDUoupoI.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/princesssofia/v25/qWczB6yguIb8DZ_GXZst16n7GSz6kjE.ttf"
    },
    {
        "family": "Prociono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/prociono/v26/r05YGLlR-KxAf9GGO8upyDYtStiJ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/prociono/v26/r05YGLlR-KxAf9GGO_uowjI.ttf"
    },
    {
        "family": "Prompt",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf",
            "100italic": "https://fonts.gstatic.com/s/prompt/v10/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf",
            "200": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf",
            "200italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf",
            "300": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf",
            "300italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf",
            "regular": "https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf",
            "italic": "https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf",
            "500": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf",
            "500italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf",
            "600": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf",
            "600italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf",
            "700": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf",
            "700italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf",
            "800": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf",
            "800italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf",
            "900": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf",
            "900italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB2KZtoY.ttf"
    },
    {
        "family": "Prosto One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/prostoone/v19/OpNJno4VhNfK-RgpwWWxpipfWhXD00c.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/prostoone/v19/OpNJno4VhNfK-RgpwWWxlitVXg.ttf"
    },
    {
        "family": "Protest Guerrilla",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/protestguerrilla/v2/Qw3HZR5PDSL6K3irtrY-VJB2YzARHV0koJ8y_eiS.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/protestguerrilla/v2/Qw3HZR5PDSL6K3irtrY-VJB2YzARHW0lqps.ttf"
    },
    {
        "family": "Protest Revolution",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/protestrevolution/v2/11hcGofZ0kXBbxQXFB7MJsjtqnVw6Z2s8PIzTG1nQw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/protestrevolution/v2/11hcGofZ0kXBbxQXFB7MJsjtqnVw6Z2c8fg3.ttf"
    },
    {
        "family": "Protest Riot",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/protestriot/v2/d6lPkaOxWMKm7TdezXFmpkrM1_JgjmRpOA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/protestriot/v2/d6lPkaOxWMKm7TdezXFmpkr81vhk.ttf"
    },
    {
        "family": "Protest Strike",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/proteststrike/v2/0QI5MXdf4Y67Rn6vBog67ZjFlpzW0gZOs7BX.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/proteststrike/v2/0QI5MXdf4Y67Rn6vBog67ZjFlqzX2AI.ttf"
    },
    {
        "family": "Proza Libre",
        "variants": [
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyHovftvXWYyz.ttf",
            "italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjEdGHgj0k1DIQRyUEyyEotdN_1XJyz7zc.ttf",
            "500": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyELbV__fcpC69i6N.ttf",
            "500italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTCvceJSY8z6Np1k.ttf",
            "600": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEL3UP_fcpC69i6N.ttf",
            "600italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTAfbeJSY8z6Np1k.ttf",
            "700": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKTUf_fcpC69i6N.ttf",
            "700italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTGPaeJSY8z6Np1k.ttf",
            "800": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKPUv_fcpC69i6N.ttf",
            "800italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTH_ZeJSY8z6Np1k.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyEoudN8.ttf"
    },
    {
        "family": "Public Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/publicsans/v18/ijwRs572Xtc6ZYQws9YVwkNBdp_yw_k0.ttf",
            "italic": "https://fonts.gstatic.com/s/publicsans/v18/ijwTs572Xtc6ZYQws9YVwnNDfJvQxuk0Nig.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/publicsans/v18/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpmJwgct.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Puppies Play",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/puppiesplay/v9/wlp2gwHZEV99rG6M3NR9uB9vaAJSA_JN3Q.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/puppiesplay/v9/wlp2gwHZEV99rG6M3NR9uB9faQhW.ttf"
    },
    {
        "family": "Puritan",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIo9JrwRdaI50M.ttf",
            "italic": "https://fonts.gstatic.com/s/puritan/v24/845aNMgkAJ2VTtIoxJj6QfSN90PfXA.ttf",
            "700": "https://fonts.gstatic.com/s/puritan/v24/845dNMgkAJ2VTtIozCbfYd6j-0rGRes.ttf",
            "700italic": "https://fonts.gstatic.com/s/puritan/v24/845fNMgkAJ2VTtIoxJjC_dup_2jDVevnLQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIoxJv6QQ.ttf"
    },
    {
        "family": "Purple Purse",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/purplepurse/v23/qWctB66gv53iAp-Vfs4My6qyeBb_ujA4ug.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/purplepurse/v23/qWctB66gv53iAp-Vfs4My6qCeRz7.ttf"
    },
    {
        "family": "Qahiri",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v9",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/qahiri/v9/tsssAp1RZy0C_hGuU3Chrnmupw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/qahiri/v9/tsssAp1RZy0C_hGeUnql.ttf"
    },
    {
        "family": "Quando",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quando/v16/xMQVuFNaVa6YuW0pC6WzKX_QmA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quando/v16/xMQVuFNaVa6YuW0ZCq-3.ttf"
    },
    {
        "family": "Quantico",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quantico/v17/rax-HiSdp9cPL3KIF4xsLjxSmlLZ.ttf",
            "italic": "https://fonts.gstatic.com/s/quantico/v17/rax4HiSdp9cPL3KIF7xuJDhwn0LZ6T8.ttf",
            "700": "https://fonts.gstatic.com/s/quantico/v17/rax5HiSdp9cPL3KIF7TQARhasU7Q8Cad.ttf",
            "700italic": "https://fonts.gstatic.com/s/quantico/v17/rax7HiSdp9cPL3KIF7xuHIRfu0ry9TadML4.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quantico/v17/rax-HiSdp9cPL3KIF7xtJDg.ttf"
    },
    {
        "family": "Quattrocento",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quattrocento/v23/OZpEg_xvsDZQL_LKIF7q4jPHxGL7f4jFuA.ttf",
            "700": "https://fonts.gstatic.com/s/quattrocento/v23/OZpbg_xvsDZQL_LKIF7q4jP_eE3fd6PZsXcM9w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quattrocento/v23/OZpEg_xvsDZQL_LKIF7q4jP3xWj_.ttf"
    },
    {
        "family": "Quattrocento Sans",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quattrocentosans/v21/va9c4lja2NVIDdIAAoMR5MfuElaRB3zOvU7eHGHJ.ttf",
            "italic": "https://fonts.gstatic.com/s/quattrocentosans/v21/va9a4lja2NVIDdIAAoMR5MfuElaRB0zMt0r8GXHJkLI.ttf",
            "700": "https://fonts.gstatic.com/s/quattrocentosans/v21/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RykmrWN33AiasJ.ttf",
            "700italic": "https://fonts.gstatic.com/s/quattrocentosans/v21/va9X4lja2NVIDdIAAoMR5MfuElaRB0zMj_bTPXnijLsJV7E.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quattrocentosans/v21/va9c4lja2NVIDdIAAoMR5MfuElaRB0zPt0o.ttf"
    },
    {
        "family": "Questrial",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70uCFg.ttf"
    },
    {
        "family": "Quicksand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quicksand/v31/6xKtdSZaM9iE8KbpRA_RLF4MQOPiPg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58C-xw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Quintessential",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/quintessential/v22/fdNn9sOGq31Yjnh3qWU14DdtjY5wS7kmAyxM.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/quintessential/v22/fdNn9sOGq31Yjnh3qWU14Ddtjb5xQb0.ttf"
    },
    {
        "family": "Qwigley",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/qwigley/v18/1cXzaU3UGJb5tGoCuVxsi1mBmcE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/qwigley/v18/1cXzaU3UGJb5tGoCiV1mjw.ttf"
    },
    {
        "family": "Qwitcher Grypen",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxicypclp9tDilN9RrC5BSI1dZmrSGNAom-wpw.ttf",
            "700": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxiZypclp9tDilN9RrC5BSI1dZmT9ExkqkSsrvNXiA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxicypclp9tDilN9RrC5BSI1dZmbSWlE.ttf"
    },
    {
        "family": "REM",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rem/v2/Wnz3HAIoSDydZjovaRcFow.ttf",
            "italic": "https://fonts.gstatic.com/s/rem/v2/Wnz1HAIoSDytZDArSxIVoxbV.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPMLqnTseaA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Racing Sans One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/racingsansone/v15/sykr-yRtm7EvTrXNxkv5jfKKyDCwL3rmWpIBtA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/racingsansone/v15/sykr-yRtm7EvTrXNxkv5jfKKyDCALnDi.ttf"
    },
    {
        "family": "Radio Canada",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "canadian-aboriginal",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/radiocanada/v21/XRXT3ISXn0dBMcibU6jlAqrtcRADBHJ6ZA.ttf",
            "italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRXN3ISXn0dBMcibU6jlAqrdcxoHJndqZCsW.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nFMkTPJLuM.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Radio Canada Big",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/radiocanadabig/v1/LYjZdHrinEImAoQewU0hyTsPFra4eJSY8z6Np1k.ttf",
            "italic": "https://fonts.gstatic.com/s/radiocanadabig/v1/LYjfdHrinEImAoQewU0hyTsPFra4SJaS9xyIt1mcXg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/radiocanadabig/v1/LYjUdHrinEImAoQewU0hyTsPFra4Yp-6A-YRBF-RX6nNRb3o0JM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Radley",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/radley/v22/LYjDdGzinEIjCN19oAlEpVs3VQ.ttf",
            "italic": "https://fonts.gstatic.com/s/radley/v22/LYjBdGzinEIjCN1NogNAh14nVcfe.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/radley/v22/LYjDdGzinEIjCN1NoQNA.ttf"
    },
    {
        "family": "Rajdhani",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pasEcOsc-bGkqIw.ttf",
            "regular": "https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7q4AOeekWPrP.ttf",
            "500": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pb0EMOsc-bGkqIw.ttf",
            "600": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pbYF8Osc-bGkqIw.ttf",
            "700": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pa8FsOsc-bGkqIw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7p4BM-M.ttf"
    },
    {
        "family": "Rakkas",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rakkas/v19/Qw3cZQlNHiblL3j_lttPOeMcCw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rakkas/v19/Qw3cZQlNHiblL3jPl9FL.ttf"
    },
    {
        "family": "Raleway",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v34",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/raleway/v34/1Ptug8zYS_SKggPN-CoCTqluHfE.ttf",
            "italic": "https://fonts.gstatic.com/s/raleway/v34/1Ptsg8zYS_SKggPNyCgISotrDfGGxA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/raleway/v34/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCOPrQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Raleway Dots",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ralewaydots/v18/6NUR8FifJg6AfQvzpshgwJ8kyf9Fdty2ew.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ralewaydots/v18/6NUR8FifJg6AfQvzpshgwJ8UyPVB.ttf"
    },
    {
        "family": "Ramabhadra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LehNGX5uWw3o.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LShJMWw.ttf"
    },
    {
        "family": "Ramaraja",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CABIkqnB6aSQU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CACIloHQ.ttf"
    },
    {
        "family": "Rambla",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mr0I7IONthkwQ.ttf",
            "italic": "https://fonts.gstatic.com/s/rambla/v13/snfps0ip98hx6mrEIbgKFN10wYKa.ttf",
            "700": "https://fonts.gstatic.com/s/rambla/v13/snfos0ip98hx6mrMn50qPvN4yJuDYQ.ttf",
            "700italic": "https://fonts.gstatic.com/s/rambla/v13/snfus0ip98hx6mrEIYC2O_l86p6TYS-Y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mrEIrgK.ttf"
    },
    {
        "family": "Rammetto One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rammettoone/v19/LhWiMV3HOfMbMetJG3lQDpp9Mvuciu-_SQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rammettoone/v19/LhWiMV3HOfMbMetJG3lQDppNM_GY.ttf"
    },
    {
        "family": "Rampart One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rampartone/v10/K2F1fZFGl_JSR1tAWNG9R6qgLS76ZHOM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rampartone/v10/K2F1fZFGl_JSR1tAWNG9R5qhJyo.ttf"
    },
    {
        "family": "Ranchers",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ranchers/v17/zrfm0H3Lx-P2Xvs2AoDYDC79XTHv.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ranchers/v17/zrfm0H3Lx-P2Xvs2ArDZBio.ttf"
    },
    {
        "family": "Rancho",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rancho/v21/46kulbzmXjLaqZRlbWXgd0RY1g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rancho/v21/46kulbzmXjLaqZRVbG_k.ttf"
    },
    {
        "family": "Ranga",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ranga/v21/C8ct4cYisGb28p6CLDwZwmGE.ttf",
            "700": "https://fonts.gstatic.com/s/ranga/v21/C8cg4cYisGb28qY-AxgR6X2NZAn2.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ranga/v21/C8ct4cYisGb28q6DJjg.ttf"
    },
    {
        "family": "Rasa",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rasa/v22/xn7vYHIn1mWmTqJelgiQV9w.ttf",
            "italic": "https://fonts.gstatic.com/s/rasa/v22/xn7hYHIn1mWmfqBUkiqVR9zEfQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJV40d88.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Rationale",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rationale/v28/9XUnlJ92n0_JFxHIfHcsdlFMzLC2Zw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rationale/v28/9XUnlJ92n0_JFxHIfHccd1tI.ttf"
    },
    {
        "family": "Ravi Prakash",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAKHmNZEq6TTFw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAK3mdxA.ttf"
    },
    {
        "family": "Readex Pro",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/readexpro/v21/SLXNc1bJ7HE5YDoGPuzj59NebXZkiSo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC4USWg2Ek.ttf",
        "axes": [
            {
                "tag": "HEXP",
                "start": 0,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 160,
                "end": 700
            }
        ]
    },
    {
        "family": "Recursive",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v38",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/recursive/v38/8vIK7wMr0mhh-RQChyHeFGxbO_zo-w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk319tQg.ttf",
        "axes": [
            {
                "tag": "CASL",
                "start": 0,
                "end": 1
            },
            {
                "tag": "CRSV",
                "start": 0,
                "end": 1
            },
            {
                "tag": "MONO",
                "start": 0,
                "end": 1
            },
            {
                "tag": "slnt",
                "start": -15,
                "end": 0
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 1000
            }
        ]
    },
    {
        "family": "Red Hat Display",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIQ7wUr0m80wwYf0QCXZzYzUoTQ-jSgZYvdCQ.ttf",
            "italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIS7wUr0m80wwYf0QCXZzYzUoTg-D6kR47NCV5Z.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWQkkz_Q.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Red Hat Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redhatmono/v11/jVyN7nDnA2uf2zVvFAhhzEskX-ZT_gzweA.ttf",
            "italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVyT7nDnA2uf2zVvFAhhzEsUXexX3AngeAXx.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQV3Iy7DHvQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Red Hat Text",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redhattext/v14/RrQXbohi_ic6B3yVSzGBrMxgb60sE8yZPA.ttf",
            "italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQJbohi_ic6B3yVSzGBrMxQbacoMcmJPECN.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwZrfQdQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Red Rose",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redrose/v20/QdVVSTYiLBjouPgEUbLkkwVoknQx.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfszcz6o.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Redacted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redacted/v8/Z9XVDmdRShme2O_7aITe4u2El6GC.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redacted/v8/Z9XVDmdRShme2O_7aLTf6Ok.ttf"
    },
    {
        "family": "Redacted Script",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/redactedscript/v10/ypvEbXGRglhokR7dcC3d1-R6zmxqHUzVmbI397ldkg.ttf",
            "regular": "https://fonts.gstatic.com/s/redactedscript/v10/ypvBbXGRglhokR7dcC3d1-R6zmxSsWTxkZkr_g.ttf",
            "700": "https://fonts.gstatic.com/s/redactedscript/v10/ypvEbXGRglhokR7dcC3d1-R6zmxqDUvVmbI397ldkg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redactedscript/v10/ypvBbXGRglhokR7dcC3d1-R6zmxisG71.ttf"
    },
    {
        "family": "Reddit Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redditmono/v3/oPWL_kRmmu4oQ88oo13o49rMTDp_R2SX.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redditmono/v3/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUwCYqc3Pc.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Reddit Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redditsans/v4/EYq3maFOxq1T_-ETdN7EKTNscZef2mNE.ttf",
            "italic": "https://fonts.gstatic.com/s/redditsans/v4/EYq1maFOxq1T_-ETdN7EKQNue5O933NEWpo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redditsans/v4/EYqgmaFOxq1T_-ETdN7EKSlnU2dHRsBCV5uxbYxWAF3F.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Reddit Sans Condensed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redditsanscondensed/v3/m8JMjepOc6WYkkm2Dey9A5QGAQXmuL3va5IFbehGW74OXw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redditsanscondensed/v3/m8J_jepOc6WYkkm2Dey9A5QGAQXmuL3va5IfZsq2gyKtWVJro1kKYpQyvw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Redressed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v29",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/redressed/v29/x3dickHUbrmJ7wMy9MsBfPACvy_1BA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/redressed/v29/x3dickHUbrmJ7wMy9MsxffoG.ttf"
    },
    {
        "family": "Reem Kufi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/reemkufi/v21/2sDcZGJLip7W2J7v7wQDb2-4C7wFZQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnIGCV2g.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Reem Kufi Fun",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_14rOFYukkmyUEbF43fIryfkIbWc7gPbQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3kChpP4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ],
        "colorCapabilities": [
            "COLRv0"
        ]
    },
    {
        "family": "Reem Kufi Ink",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/reemkufiink/v9/oPWJ_kJmmu8hCvB9iFumxZSnRj5dQnSX1ko.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/reemkufiink/v9/oPWJ_kJmmu8hCvB9iFumxZSndj9XRg.ttf",
        "colorCapabilities": [
            "COLRv1",
            "SVG"
        ]
    },
    {
        "family": "Reenie Beanie",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/reeniebeanie/v20/z7NSdR76eDkaJKZJFkkjuvWxbP2_qoOgf_w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/reeniebeanie/v20/z7NSdR76eDkaJKZJFkkjuvWxXPy1rg.ttf"
    },
    {
        "family": "Reggae One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/reggaeone/v17/7r3DqX5msMIkeuwJwOJt_a5L5uH-mts.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/reggaeone/v17/7r3DqX5msMIkeuwJwOJtza9B4g.ttf"
    },
    {
        "family": "Rethink Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rethinksans/v5/AMOWz4SDuXOMCPfdoglY9JQ0U1K2w9lb4g.ttf",
            "italic": "https://fonts.gstatic.com/s/rethinksans/v5/AMOIz4SDuXOMCPfdoglY9JQEUViy4dxL4jSr.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rethinksans/v5/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqkmFROE_snQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Revalia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/revalia/v22/WwkexPimBE2-4ZPEeVruNIgJSNM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/revalia/v22/WwkexPimBE2-4ZPESVvkMA.ttf"
    },
    {
        "family": "Rhodium Libre",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rhodiumlibre/v19/1q2AY5adA0tn_ukeHcQHqpx6pETLeo2gm2U.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rhodiumlibre/v19/1q2AY5adA0tn_ukeHcQHqpx6lEXBfg.ttf"
    },
    {
        "family": "Ribeye",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ribeye/v25/L0x8DFMxk1MP9R3RvPCmRSlUig.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ribeye/v25/L0x8DFMxk1MP9R3hvfqi.ttf"
    },
    {
        "family": "Ribeye Marrow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ribeyemarrow/v24/GFDsWApshnqMRO2JdtRZ2d0vEAwTVWgKdtw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ribeyemarrow/v24/GFDsWApshnqMRO2JdtRZ2d0vIA0ZUQ.ttf"
    },
    {
        "family": "Righteous",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/righteous/v17/1cXxaUPXBpj2rGoU7C9mj3uEicG01A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/righteous/v17/1cXxaUPXBpj2rGoU7C9WjnGA.ttf"
    },
    {
        "family": "Risque",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/risque/v22/VdGfAZUfHosahXxoCUYVBJ-T5g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/risque/v22/VdGfAZUfHosahXxYCEwR.ttf"
    },
    {
        "family": "Road Rage",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/roadrage/v7/6NUU8F2fKAOBKjjr4ekvtMYAwdRZfw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/roadrage/v7/6NUU8F2fKAOBKjjr4ekftcwE.ttf"
    },
    {
        "family": "Roboto",
        "variants": [
            "100",
            "100italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v32",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
            "100italic": "https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
            "300": "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
            "300italic": "https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
            "regular": "https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
            "italic": "https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
            "500": "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
            "500italic": "https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf",
            "700": "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
            "700italic": "https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
            "900": "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
            "900italic": "https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu5GxP.ttf"
    },
    {
        "family": "Roboto Condensed",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf",
            "italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CAk8YvJEeg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyosBI5Xk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Roboto Flex",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/robotoflex/v26/NaPccZLOBv5T3oB7Cb4i0wu9TsDOCZRS.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/robotoflex/v26/NaNnepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGbt8jZktqc2V3Zs0KvDLdBP8SBZtOs2IifRuUZQMsPJtUsR4DEK6cULNeUx9XgTnH37Ha_FIAp4Fm0PP1hw45DntW2x0wZGzhPmr1YNMYKYn9_1IQXGwJAiUJVUMdN5YUW4O8HtSoXjC1z3QSabshNFVe3e0O5j3ZjrZCu23Qd4G0EBysQNK-QKavMl1cKq3tHXtXi8mzLjaAcbCkmA.ttf",
        "axes": [
            {
                "tag": "GRAD",
                "start": -200,
                "end": 150
            },
            {
                "tag": "XOPQ",
                "start": 27,
                "end": 175
            },
            {
                "tag": "XTRA",
                "start": 323,
                "end": 603
            },
            {
                "tag": "YOPQ",
                "start": 25,
                "end": 135
            },
            {
                "tag": "YTAS",
                "start": 649,
                "end": 854
            },
            {
                "tag": "YTDE",
                "start": -305,
                "end": -98
            },
            {
                "tag": "YTFI",
                "start": 560,
                "end": 788
            },
            {
                "tag": "YTLC",
                "start": 416,
                "end": 570
            },
            {
                "tag": "YTUC",
                "start": 528,
                "end": 760
            },
            {
                "tag": "opsz",
                "start": 8,
                "end": 144
            },
            {
                "tag": "slnt",
                "start": -10,
                "end": 0
            },
            {
                "tag": "wdth",
                "start": 25,
                "end": 151
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 1000
            }
        ]
    },
    {
        "family": "Roboto Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/robotomono/v23/L0x5DF4xlVMF-BfR8bXMIghMoX6-XqKC.ttf",
            "italic": "https://fonts.gstatic.com/s/robotomono/v23/L0x7DF4xlVMF-BfR8bXMIjhOq3qcW7KCG1w.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_QuW9.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Roboto Serif",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/robotoserif/v15/R70djywflP6FLr3gZx7K8Uy0Vxn9R5ShnA.ttf",
            "italic": "https://fonts.gstatic.com/s/robotoserif/v15/R70DjywflP6FLr3gZx7K8UyEVRP5ZZGxnH2a.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/robotoserif/v15/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotl6Z8BQ.ttf",
        "axes": [
            {
                "tag": "GRAD",
                "start": -50,
                "end": 100
            },
            {
                "tag": "opsz",
                "start": 8,
                "end": 144
            },
            {
                "tag": "wdth",
                "start": 50,
                "end": 150
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Roboto Slab",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v34",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/robotoslab/v34/BngMUXZYTXPIvIBgJJSb6tfK7KSJ4ACD.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmaWRm.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Rochester",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rochester/v22/6ae-4KCqVa4Zy6Fif-Uy31vWNTMwoQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rochester/v22/6ae-4KCqVa4Zy6Fif-UC3lHS.ttf"
    },
    {
        "family": "Rock 3D",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rock3d/v11/yYLp0hrL0PCo651513SnwRnQyNI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rock3d/v11/yYLp0hrL0PCo651553WtxQ.ttf"
    },
    {
        "family": "Rock Salt",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rocksalt/v22/MwQ0bhv11fWD6QsAVOZbsEk7hbBWrA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rocksalt/v22/MwQ0bhv11fWD6QsAVOZrsUM_.ttf"
    },
    {
        "family": "RocknRoll One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rocknrollone/v14/kmK7ZqspGAfCeUiW6FFlmEC9guVhs7tfUxc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rocknrollone/v14/kmK7ZqspGAfCeUiW6FFlmEC9suRrtw.ttf"
    },
    {
        "family": "Rokkitt",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v36",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rokkitt/v36/qFdE35qfgYFjGy5hoEGId9bL2h4.ttf",
            "italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdG35qfgYFjGy5hkEOCc_TOyh4Qig.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd6DKGbo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Romanesco",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSn3HwLqOqSBg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSX3XYP.ttf"
    },
    {
        "family": "Ropa Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScyKWjloU5KP2g.ttf",
            "italic": "https://fonts.gstatic.com/s/ropasans/v15/EYq3maNOzLlWtsZSScy6WDNscZef2mNE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScy6WzNs.ttf"
    },
    {
        "family": "Rosario",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v31",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rosario/v31/xfux0WDhWW_fOEoY6FT3zA7DpL4.ttf",
            "italic": "https://fonts.gstatic.com/s/rosario/v31/xfuz0WDhWW_fOEoY2Fb9yCzGtL7CMg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCVcyfeI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Rosarivo",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rosarivo/v22/PlI-Fl2lO6N9f8HaNAeC2nhMnNy5.ttf",
            "italic": "https://fonts.gstatic.com/s/rosarivo/v22/PlI4Fl2lO6N9f8HaNDeA0Hxumcy5ZX8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rosarivo/v22/PlI-Fl2lO6N9f8HaNDeD0Hw.ttf"
    },
    {
        "family": "Rouge Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rougescript/v18/LYjFdGbiklMoCIQOw1Ep3S4PVPXbUJWq9g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rougescript/v18/LYjFdGbiklMoCIQOw1Ep3S4_Vf_f.ttf"
    },
    {
        "family": "Rowdies",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/rowdies/v17/ptRMTieMYPNBAK219hth5O7yKQNute8.ttf",
            "regular": "https://fonts.gstatic.com/s/rowdies/v17/ptRJTieMYPNBAK21zrdJwObZNQo.ttf",
            "700": "https://fonts.gstatic.com/s/rowdies/v17/ptRMTieMYPNBAK219gtm5O7yKQNute8.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rowdies/v17/ptRJTieMYPNBAK21_rZDxA.ttf"
    },
    {
        "family": "Rozha One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rozhaone/v15/AlZy_zVFtYP12Zncg2khdXf4XB0Tow.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rozhaone/v15/AlZy_zVFtYP12Zncg2kRdH38.ttf"
    },
    {
        "family": "Rubik",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "arabic",
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV4nGp32S0H3f.ttf",
            "italic": "https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nErXmw1W3f9Ik.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFUUUw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Rubik 80s Fade",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubik80sfade/v2/U9MF6dW37nLSmnwZXyoV-uPXUhHwkbL8IHcK.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubik80sfade/v2/U9MF6dW37nLSmnwZXyoV-uPXUiHxm7Y.ttf"
    },
    {
        "family": "Rubik Beastly",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X07z8_ApHqqk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X4732-A.ttf"
    },
    {
        "family": "Rubik Broken Fax",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikbrokenfax/v1/NGSvv4rXG042O-GzH9sg1cUgl8w8YW-WdmGi300.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikbrokenfax/v1/NGSvv4rXG042O-GzH9sg1cUgl8w8UW6ccg.ttf"
    },
    {
        "family": "Rubik Bubbles",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikbubbles/v3/JIA1UVdwbHFJtwA7Us1BPFbRNTENfDxyRXI.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikbubbles/v3/JIA1UVdwbHFJtwA7Us1BPFbRBTAHeA.ttf"
    },
    {
        "family": "Rubik Burned",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqniHQuaCY5ZSg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqnSHAGe.ttf"
    },
    {
        "family": "Rubik Dirt",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikdirt/v2/DtVmJxC7WLEj1uIXEWAdulwm6gDXvwE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikdirt/v2/DtVmJxC7WLEj1uIXEWAdil0s7g.ttf"
    },
    {
        "family": "Rubik Distressed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldcWAhatVBaGM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldQWErbg.ttf"
    },
    {
        "family": "Rubik Doodle Shadow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikdoodleshadow/v1/rP2bp3im_k8G_wTVdvvMdHqmXTR3lEaLyKuZ3KOY7Gw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikdoodleshadow/v1/rP2bp3im_k8G_wTVdvvMdHqmXTR3lEaL-KqT2A.ttf"
    },
    {
        "family": "Rubik Doodle Triangles",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikdoodletriangles/v1/esDA301BLOmMKxKspb3g-domRuLPeaSn2bTzdLi_slZxgWE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikdoodletriangles/v1/esDA301BLOmMKxKspb3g-domRuLPeaSn2bTzRLm1tg.ttf"
    },
    {
        "family": "Rubik Gemstones",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikgemstones/v1/zrf90HrL0-_8Xb4DFM2rUkWbOVrOiCnGqi1GMw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikgemstones/v1/zrf90HrL0-_8Xb4DFM2rUkWbOVr-iSPC.ttf"
    },
    {
        "family": "Rubik Glitch",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZvmC7HONiSFQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZfmSTD.ttf"
    },
    {
        "family": "Rubik Glitch Pop",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikglitchpop/v1/tDbX2pGHhFcM0gB3hN2elZLa3G-MOwStSUrV_BE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikglitchpop/v1/tDbX2pGHhFcM0gB3hN2elZLa3G-MCwWnTQ.ttf"
    },
    {
        "family": "Rubik Iso",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikiso/v2/x3dickHUfr-S4VAI4sABfPACvy_1BA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikiso/v2/x3dickHUfr-S4VAI4sAxffoG.ttf"
    },
    {
        "family": "Rubik Lines",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubiklines/v1/_gP81R3vsjYzVW2Y6xFF-GSxYPp7oSNy.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubiklines/v1/_gP81R3vsjYzVW2Y6xFF-FSwav4.ttf"
    },
    {
        "family": "Rubik Maps",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmaps/v1/Gw6_wcjl80TZK9XxtbbejSYUChRqp9k.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmaps/v1/Gw6_wcjl80TZK9XxtbbevSceDg.ttf"
    },
    {
        "family": "Rubik Marker Hatch",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHQDL4iw0H6zw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHgDbQm.ttf"
    },
    {
        "family": "Rubik Maze",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmaze/v2/xMQRuF9ZVa2ftiJEavXSAX7inS-bxV4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmaze/v2/xMQRuF9ZVa2ftiJEavXSMX_omQ.ttf"
    },
    {
        "family": "Rubik Microbe",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZcs8aaKgiauE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZQs4QbA.ttf"
    },
    {
        "family": "Rubik Mono One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmonoone/v18/UqyJK8kPP3hjw6ANTdfRk9YSN-8wRqQrc_j9.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmonoone/v18/UqyJK8kPP3hjw6ANTdfRk9YSN98xTKA.ttf"
    },
    {
        "family": "Rubik Moonrocks",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikmoonrocks/v5/845ANMAmAI2VUZMLu_W0M7HqlDHnXcD7JGy1Sw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikmoonrocks/v5/845ANMAmAI2VUZMLu_W0M7HqlDHXXMr_.ttf"
    },
    {
        "family": "Rubik Pixels",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikpixels/v2/SlGXmQOaupkIeSx4CEpB7AdSaBYRagrQrA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikpixels/v2/SlGXmQOaupkIeSx4CEpB7AdiaRwV.ttf"
    },
    {
        "family": "Rubik Puddles",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcJfC0wVMT-aE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcFfG-xQ.ttf"
    },
    {
        "family": "Rubik Scribble",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikscribble/v1/snfzs0Cp48d67SuHQOpjXLsQpbqbSjORSo9W.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikscribble/v1/snfzs0Cp48d67SuHQOpjXLsQpYqaQDc.ttf"
    },
    {
        "family": "Rubik Spray Paint",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikspraypaint/v1/WnzhHBAoeBPUDTB4EWR82y6EXWPH-Ro-QoaBZQxP.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikspraypaint/v1/WnzhHBAoeBPUDTB4EWR82y6EXWPH-So_SII.ttf"
    },
    {
        "family": "Rubik Storm",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikstorm/v1/eLGYP-_uPgO5Ag7ju9JaouL9T2Xh9NQk.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikstorm/v1/eLGYP-_uPgO5Ag7ju9JaotL8RWE.ttf"
    },
    {
        "family": "Rubik Vinyl",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikvinyl/v1/iJWABXKIfDnIV4mQ5BfjvUXexox2ztOU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikvinyl/v1/iJWABXKIfDnIV4mQ5BfjvXXfzIg.ttf"
    },
    {
        "family": "Rubik Wet Paint",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-F-zz313DuvQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-1-jbz.ttf"
    },
    {
        "family": "Ruda",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ruda/v28/k3kfo8YQJOpFmn8XadbJM0A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsh__0qw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Rufina",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rufina/v15/Yq6V-LyURyLy-aKyoxRktOdClg.ttf",
            "700": "https://fonts.gstatic.com/s/rufina/v15/Yq6W-LyURyLy-aKKHztAvMxenxE0SA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rufina/v15/Yq6V-LyURyLy-aKCoh5g.ttf"
    },
    {
        "family": "Ruge Boogie",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rugeboogie/v28/JIA3UVFwbHRF_GIWSMhKNROiPzUveSxy.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rugeboogie/v28/JIA3UVFwbHRF_GIWSMhKNSOjNTE.ttf"
    },
    {
        "family": "Ruluko",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0pC6WzKX_QmA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0ZCq-3.ttf"
    },
    {
        "family": "Rum Raisin",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rumraisin/v22/nwpRtKu3Ih8D5avB4h2uJ3-IywA7eMM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rumraisin/v22/nwpRtKu3Ih8D5avB4h2uF36Czw.ttf"
    },
    {
        "family": "Ruslan Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ruslandisplay/v26/Gw6jwczl81XcIZuckK_e3UpfdzxrldyFvm1n.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ruslandisplay/v26/Gw6jwczl81XcIZuckK_e3Upfdwxqn9g.ttf"
    },
    {
        "family": "Russo One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/russoone/v16/Z9XUDmZRWg6M1LvRYsH-yMOInrib9Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/russoone/v16/Z9XUDmZRWg6M1LvRYsHOycmM.ttf"
    },
    {
        "family": "Ruthie",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ruthie/v26/gokvH63sGkdqXuU9lD53Q2u_mQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ruthie/v26/gokvH63sGkdqXuUNlTRz.ttf"
    },
    {
        "family": "Ruwudu",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ruwudu/v4/syky-y1tj6UzRKfNlQCT9tPdpw.ttf",
            "500": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1YSm3_vjBrlSILg.ttf",
            "600": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1TS63_vjBrlSILg.ttf",
            "700": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1KS-3_vjBrlSILg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ruwudu/v4/syky-y1tj6UzRKf9lAqX.ttf"
    },
    {
        "family": "Rye",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/rye/v15/r05XGLJT86YDFpTsXOqx4w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/rye/v15/r05XGLJT86YzF57o.ttf"
    },
    {
        "family": "STIX Two Text",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Vr02F12Xkf5whdwKf11l0l7mGiv_Q7dA.ttf",
            "italic": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Lr02F12Xkf5whdwKf11l0p7uMjt3V_dB0Yw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2ROZURU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "SUSE",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-08-28",
        "files": {
            "regular": "https://fonts.gstatic.com/s/suse/v1/MwQ5bhb078Wt6VhLPJp6qGI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/suse/v1/MwQsbhb078Wt81NpzELmC2QqjbGG7mq8bHY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Sacramento",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG0CoV_NxLeiw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4Csf-A.ttf"
    },
    {
        "family": "Sahitya",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAaScFPywEDnI.ttf",
            "700": "https://fonts.gstatic.com/s/sahitya/v17/6qLFKZkOuhnuqlJAUZsqGyQvEnvSexI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAWSYPOw.ttf"
    },
    {
        "family": "Sail",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJFBTDADYAbvw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJJBXJBA.ttf"
    },
    {
        "family": "Saira",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/saira/v20/memwYa2wxmKQyOkgR5IdU6Uf.ttf",
            "italic": "https://fonts.gstatic.com/s/saira/v20/mem-Ya2wxmKQyNkiTZY_VrUfTck.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCkskxkw.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 50,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Saira Condensed",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRMQgErUN8XuHNEtX81i9TmEkrnwetA2omSrzS8.ttf",
            "200": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnbcpg8Keepi2lHw.ttf",
            "300": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnCclg8Keepi2lHw.ttf",
            "regular": "https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf",
            "500": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnUchg8Keepi2lHw.ttf",
            "600": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9g8Keepi2lHw.ttf",
            "700": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnGc5g8Keepi2lHw.ttf",
            "800": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnBc1g8Keepi2lHw.ttf",
            "900": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnIcxg8Keepi2lHw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrvpOtA.ttf"
    },
    {
        "family": "Saira Extra Condensed",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri0jx9i5ss3a3.ttf",
            "200": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nR3ABgum-uoQ.ttf",
            "300": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rR3ABgum-uoQ.ttf",
            "regular": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT70L11Ct8sw.ttf",
            "500": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vR3ABgum-uoQ.ttf",
            "600": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zR3ABgum-uoQ.ttf",
            "700": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23R3ABgum-uoQ.ttf",
            "800": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27R3ABgum-uoQ.ttf",
            "900": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTra2_R3ABgum-uoQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTj7kjx.ttf"
    },
    {
        "family": "Saira Semi Condensed",
        "variants": [
            "100",
            "200",
            "300",
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM8rXT-8V8.ttf",
            "200": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMWg3j36Ebz.ttf",
            "300": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMWg3j36Ebz.ttf",
            "regular": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8LYuceqGT-.ttf",
            "500": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MWg3j36Ebz.ttf",
            "600": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMWg3j36Ebz.ttf",
            "700": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMWg3j36Ebz.ttf",
            "800": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMWg3j36Ebz.ttf",
            "900": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MWg3j36Ebz.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRX8KaOM.ttf"
    },
    {
        "family": "Saira Stencil One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sairastencilone/v16/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsMawif2YQ2.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sairastencilone/v16/SLXSc03I6HkvZGJ1GvvipLoYSTEL9DsNYQw.ttf"
    },
    {
        "family": "Salsa",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/salsa/v21/gNMKW3FiRpKj-imY8ncKEZez.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/salsa/v21/gNMKW3FiRpKj-hmZ-HM.ttf"
    },
    {
        "family": "Sanchez",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sanchez/v15/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf",
            "italic": "https://fonts.gstatic.com/s/sanchez/v15/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sanchez/v15/Ycm2sZJORluHnXbIfm9R-A.ttf"
    },
    {
        "family": "Sancreek",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sancreek/v25/pxiHypAnsdxUm159X7D-XV9NEe-K.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sancreek/v25/pxiHypAnsdxUm159X4D_V1s.ttf"
    },
    {
        "family": "Sankofa Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sankofadisplay/v2/Ktk1ALSRd4LucUDghJ2rTqXOoh33F6mZVY9Y5w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sankofadisplay/v2/Ktk1ALSRd4LucUDghJ2rTqXOoh3HFqOd.ttf"
    },
    {
        "family": "Sansita",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sansita/v11/QldONTRRphEb_-V7HBm7TXFf3qw.ttf",
            "italic": "https://fonts.gstatic.com/s/sansita/v11/QldMNTRRphEb_-V7LBuxSVNazqx2xg.ttf",
            "700": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JKWUaXl0wqVv3_g.ttf",
            "700italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJ9Xx-xodqz_joDQ.ttf",
            "800": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JLmXaXl0wqVv3_g.ttf",
            "800italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJ6X9-xodqz_joDQ.ttf",
            "900": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JJ2WaXl0wqVv3_g.ttf",
            "900italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJzX5-xodqz_joDQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sansita/v11/QldONTRRphEb_-V7LBixSQ.ttf"
    },
    {
        "family": "Sansita Swashed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXRzvFfZifTZgFlDDLgNkBydPKT31beyoRkJIA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpXTsffg.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 900
            }
        ]
    },
    {
        "family": "Sarabun",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/sarabun/v15/DtVhJx26TKEr37c9YHZJmnYI5gnOpg.ttf",
            "100italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVnJx26TKEr37c9aBBx_nwMxAzephhN.ttf",
            "200": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YNpoulwm6gDXvwE.ttf",
            "200italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxUl0s7iLSrwFUlw.ttf",
            "300": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YL5rulwm6gDXvwE.ttf",
            "300italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxNl4s7iLSrwFUlw.ttf",
            "regular": "https://fonts.gstatic.com/s/sarabun/v15/DtVjJx26TKEr37c9WBJDnlQN9gk.ttf",
            "italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVhJx26TKEr37c9aBBJmnYI5gnOpg.ttf",
            "500": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YOZqulwm6gDXvwE.ttf",
            "500italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxbl8s7iLSrwFUlw.ttf",
            "600": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YMptulwm6gDXvwE.ttf",
            "600italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxQlgs7iLSrwFUlw.ttf",
            "700": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YK5sulwm6gDXvwE.ttf",
            "700italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxJlks7iLSrwFUlw.ttf",
            "800": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YLJvulwm6gDXvwE.ttf",
            "800italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxOlos7iLSrwFUlw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sarabun/v15/DtVjJx26TKEr37c9aBNJmg.ttf"
    },
    {
        "family": "Sarala",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sarala/v12/uK_y4riEZv4o1w9RCh0TMv6EXw.ttf",
            "700": "https://fonts.gstatic.com/s/sarala/v12/uK_x4riEZv4o1w9ptjI3OtWYVkMpXA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sarala/v12/uK_y4riEZv4o1w9hCxcX.ttf"
    },
    {
        "family": "Sarina",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sarina/v23/-F6wfjF3ITQwasLhLkDUriBQxw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sarina/v23/-F6wfjF3ITQwasLRL0rQ.ttf"
    },
    {
        "family": "Sarpanch",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sarpanch/v13/hESy6Xt4NCpRuk6Pzh2ARIrX_20n.ttf",
            "500": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziV0ba7f1HEuRHkM.ttf",
            "600": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziVYaq7f1HEuRHkM.ttf",
            "700": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziU8a67f1HEuRHkM.ttf",
            "800": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziUgaK7f1HEuRHkM.ttf",
            "900": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziUEaa7f1HEuRHkM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sarpanch/v13/hESy6Xt4NCpRuk6Pzi2BTo4.ttf"
    },
    {
        "family": "Sassy Frass",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sassyfrass/v7/LhWhMVrGOe0FLb97BjhsE99dGNWQg_am.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sassyfrass/v7/LhWhMVrGOe0FLb97BjhsE-9cEtE.ttf"
    },
    {
        "family": "Satisfy",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/satisfy/v21/rP2Hp2yn6lkG50LoOZSCHBeHFl0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/satisfy/v21/rP2Hp2yn6lkG50LoCZWIGA.ttf"
    },
    {
        "family": "Sawarabi Gothic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sawarabigothic/v13/x3d4ckfVaqqa-BEj-I9mE65u3k3NBSk3E2YljQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sawarabigothic/v13/x3d4ckfVaqqa-BEj-I9mE65u3k39BCMz.ttf"
    },
    {
        "family": "Sawarabi Mincho",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sawarabimincho/v18/8QIRdiDaitzr7brc8ahpxt6GcIJTLahP46UDUw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sawarabimincho/v18/8QIRdiDaitzr7brc8ahpxt6GcIJjLKJL.ttf"
    },
    {
        "family": "Scada",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/scada/v15/RLpxK5Pv5qumeWJoxzUobkvv.ttf",
            "italic": "https://fonts.gstatic.com/s/scada/v15/RLp_K5Pv5qumeVJqzTEKa1vvffg.ttf",
            "700": "https://fonts.gstatic.com/s/scada/v15/RLp8K5Pv5qumeVrU6BEgRVfmZOE5.ttf",
            "700italic": "https://fonts.gstatic.com/s/scada/v15/RLp6K5Pv5qumeVJq9Y0lT1PEYfE5p6g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/scada/v15/RLpxK5Pv5qumeVJpzTE.ttf"
    },
    {
        "family": "Scheherazade New",
        "variants": [
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaZrFhTvxVnHDvUkUiHg8jprP4DCwNsOl4p5Is.ttf",
            "500": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM_dFHlYC-IKnoSE.ttf",
            "600": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM9tCHlYC-IKnoSE.ttf",
            "700": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM79DHlYC-IKnoSE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaZrFhTvxVnHDvUkUiHg8jprP4DOwJmPg.ttf"
    },
    {
        "family": "Schibsted Grotesk",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtaq9BYSsBdjFP.ttf",
            "italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz_5SSPQuCQF3t8uOwiUL-taUTtap9Day8jcyFPV1I.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNIsEwTshq.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Schoolbell",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIVxIQ6mRqfiQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIZxMa7g.ttf"
    },
    {
        "family": "Scope One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGD1W0_MJMGxiQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGDFWkXI.ttf"
    },
    {
        "family": "Seaweed Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/seaweedscript/v15/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/seaweedscript/v15/bx6cNx6Tne2pxOATYE8C_Rsoe3WI8qM.ttf"
    },
    {
        "family": "Secular One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/secularone/v12/8QINdiTajsj_87rMuMdKypDlMul7LJpK.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/secularone/v12/8QINdiTajsj_87rMuMdKyqDkOO0.ttf"
    },
    {
        "family": "Sedan",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sedan/v1/Yq6a-L-VVyD6-eOSiTpovf5b.ttf",
            "italic": "https://fonts.gstatic.com/s/sedan/v1/Yq6U-L-VVyD6-dOQgz5KuO5bjxE.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sedan/v1/Yq6a-L-VVyD6-dOTgz4.ttf"
    },
    {
        "family": "Sedan SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sedansc/v2/yMJRMIlvYZ3Jn1Y30Dq8fSx5i814.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sedansc/v2/yMJRMIlvYZ3Jn1Y30Aq9dyg.ttf"
    },
    {
        "family": "Sedgwick Ave",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJqMX2Ix.ttf"
    },
    {
        "family": "Sedgwick Ave Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sedgwickavedisplay/v21/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2czd-YnOzUD.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sedgwickavedisplay/v21/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM1cyfeI.ttf"
    },
    {
        "family": "Sen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sen/v9/6xKjdSxYI9_Hm_-MImrpLQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISCq5L4_AkA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Send Flowers",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sendflowers/v5/If2PXTjtZS-0Xqy13uCQSULvxwjjouU1iw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sendflowers/v5/If2PXTjtZS-0Xqy13uCQSULfxgLn.ttf"
    },
    {
        "family": "Sevillana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sevillana/v23/KFOlCnWFscmDt1Bfiy1vAx05IsDqlA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sevillana/v23/KFOlCnWFscmDt1Bfiy1fAhc9.ttf"
    },
    {
        "family": "Seymour One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/seymourone/v23/4iCp6Khla9xbjQpoWGGd0myIPYBvgpUI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/seymourone/v23/4iCp6Khla9xbjQpoWGGd0lyJN4Q.ttf"
    },
    {
        "family": "Shadows Into Light",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shadowsintolight/v19/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcsr4xzSMYA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shadowsintolight/v19/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzc8D8.ttf"
    },
    {
        "family": "Shadows Into Light Two",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shadowsintolighttwo/v17/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAvNGLNnIF0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shadowsintolighttwo/v17/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmpArHHA.ttf"
    },
    {
        "family": "Shalimar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shalimar/v7/uU9MCBoE6I6iNWFUvTPx8PCOg0uX.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shalimar/v7/uU9MCBoE6I6iNWFUvQPw-vQ.ttf"
    },
    {
        "family": "Shantell Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shantellsans/v10/FeVWS0pCoLIo-lcdY7kjvNoQs250xsQuLFg.ttf",
            "italic": "https://fonts.gstatic.com/s/shantellsans/v10/FeVIS0pCoLIo-lcdY7kjvNoQg2x-wuYrPFiwaw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shantellsans/v10/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYR8S1i3wvg.ttf",
        "axes": [
            {
                "tag": "BNCE",
                "start": -100,
                "end": 100
            },
            {
                "tag": "INFM",
                "start": 0,
                "end": 100
            },
            {
                "tag": "SPAC",
                "start": 0,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 800
            }
        ]
    },
    {
        "family": "Shanti",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shanti/v25/t5thIREMM4uSDgzgU0ezpKfwzA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shanti/v25/t5thIREMM4uSDgzQUk23.ttf"
    },
    {
        "family": "Share",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/share/v18/i7dEIFliZjKNF5VNHLq2cV5d.ttf",
            "italic": "https://fonts.gstatic.com/s/share/v18/i7dKIFliZjKNF6VPFr6UdE5dWFM.ttf",
            "700": "https://fonts.gstatic.com/s/share/v18/i7dJIFliZjKNF63xM56-WkJUQUq7.ttf",
            "700italic": "https://fonts.gstatic.com/s/share/v18/i7dPIFliZjKNF6VPLgK7UEZ2RFq7AwU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/share/v18/i7dEIFliZjKNF6VMFr4.ttf"
    },
    {
        "family": "Share Tech",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sharetech/v21/7cHtv4Uyi5K0OeZ7bohUwHoDmTcibrA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sharetech/v21/7cHtv4Uyi5K0OeZ7bohU8HsJnQ.ttf"
    },
    {
        "family": "Share Tech Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFc7pAsEIc3Xew.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFcLpQEA.ttf"
    },
    {
        "family": "Shippori Antique",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shipporiantique/v9/-F6qfid3KC8pdMyzR0qRyFUht11v8ldPg-IUDNg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shipporiantique/v9/-F6qfid3KC8pdMyzR0qRyFUht11vwlZFhw.ttf"
    },
    {
        "family": "Shippori Antique B1",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shipporiantiqueb1/v9/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40grRFCj9-5Y8Y.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shipporiantiqueb1/v9/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40gnRBIiw.ttf"
    },
    {
        "family": "Shippori Mincho",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGGAZweH5EbgHY6YExcZfDoj0BA2_-C7LoS7g.ttf",
            "500": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGDAZweH5EbgHY6YExcZfDoj0B4L9am5JEO5--2zg.ttf",
            "600": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGDAZweH5EbgHY6YExcZfDoj0B4A9Gm5JEO5--2zg.ttf",
            "700": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGDAZweH5EbgHY6YExcZfDoj0B4Z9Cm5JEO5--2zg.ttf",
            "800": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGDAZweH5EbgHY6YExcZfDoj0B4e9Om5JEO5--2zg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shipporimincho/v15/VdGGAZweH5EbgHY6YExcZfDoj0Bw2vWG.ttf"
    },
    {
        "family": "Shippori Mincho B1",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK2E2wCr44tulPdnn-xbIpJ9RgT9-nyjqBr1lO97Q.ttf",
            "500": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKeolP3nih5H4Uug.ttf",
            "600": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKVo5P3nih5H4Uug.ttf",
            "700": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKMo9P3nih5H4Uug.ttf",
            "800": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKLoxP3nih5H4Uug.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shipporiminchob1/v22/wXK2E2wCr44tulPdnn-xbIpJ9RgT9-nCj6pv.ttf"
    },
    {
        "family": "Shizuru",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shizuru/v11/O4ZSFGfvnxFiCA3i30IJlgUTj2A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shizuru/v11/O4ZSFGfvnxFiCA3i70MDkg.ttf"
    },
    {
        "family": "Shojumaru",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtlMBBJek0vA8A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtl8BRha.ttf"
    },
    {
        "family": "Short Stack",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX9STe3s.ttf"
    },
    {
        "family": "Shrikhand",
        "variants": [
            "regular"
        ],
        "subsets": [
            "gujarati",
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/shrikhand/v15/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/shrikhand/v15/a8IbNovtLWfR7T7bMJwrBYKU.ttf"
    },
    {
        "family": "Siemreap",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer"
        ],
        "version": "v28",
        "lastModified": "2023-08-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/siemreap/v28/Gg82N5oFbgLvHAfNl2YbnA8DLXpe.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/siemreap/v28/Gg82N5oFbgLvHAfNl1Yalgs.ttf"
    },
    {
        "family": "Sigmar",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sigmar/v7/hv-XlzJgIE8a85pUbWY3MTFgVg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sigmar/v7/hv-XlzJgIE8a85pkbGwz.ttf"
    },
    {
        "family": "Sigmar One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sigmarone/v18/co3DmWZ8kjZuErj9Ta3dk6Pjp3Di8U0.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sigmarone/v18/co3DmWZ8kjZuErj9Ta3do6Lpow.ttf"
    },
    {
        "family": "Signika",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/signika/v25/vEFR2_JTCgwQ5ejvK1YsB3hod0k.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9gTuozDkj.ttf",
        "axes": [
            {
                "tag": "GRAD",
                "start": -30,
                "end": 0
            },
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Signika Negative",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/signikanegative/v21/E218_cfngu7HiRpPX3ZpNE4kY5zKUvKrrpno9zY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS43tvdw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Silkscreen",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/silkscreen/v4/m8JXjfVPf62XiF7kO-i9ULRvamODxdI.ttf",
            "700": "https://fonts.gstatic.com/s/silkscreen/v4/m8JUjfVPf62XiF7kO-i9aAhATmuo2dudFvc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/silkscreen/v4/m8JXjfVPf62XiF7kO-i9YLVlbg.ttf"
    },
    {
        "family": "Simonetta",
        "variants": [
            "regular",
            "italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/simonetta/v27/x3dickHVYrCU5BU15c4BfPACvy_1BA.ttf",
            "italic": "https://fonts.gstatic.com/s/simonetta/v27/x3dkckHVYrCU5BU15c4xfvoGnSrlBBsy.ttf",
            "900": "https://fonts.gstatic.com/s/simonetta/v27/x3dnckHVYrCU5BU15c45-N0mtwTpDQIrGg.ttf",
            "900italic": "https://fonts.gstatic.com/s/simonetta/v27/x3d5ckHVYrCU5BU15c4xfsKCsA7tLwc7Gn88.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/simonetta/v27/x3dickHVYrCU5BU15c4xffoG.ttf"
    },
    {
        "family": "Single Day",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/singleday/v17/LYjHdGDjlEgoAcF95EI5jVoFUNfeQJU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/singleday/v17/LYjHdGDjlEgoAcF95EI5vVsPVA.ttf"
    },
    {
        "family": "Sintony",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sintony/v15/XoHm2YDqR7-98cVUITQnu98ojjs.ttf",
            "700": "https://fonts.gstatic.com/s/sintony/v15/XoHj2YDqR7-98cVUGYgIn9cDkjLp6C8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sintony/v15/XoHm2YDqR7-98cVUETUtvw.ttf"
    },
    {
        "family": "Sirin Stencil",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sirinstencil/v25/mem4YaWwznmLx-lzGfN7MdRydchGBq6al6o.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sirinstencil/v25/mem4YaWwznmLx-lzGfN7MdRyRclMAg.ttf"
    },
    {
        "family": "Six Caps",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sixcaps/v20/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sixcaps/v20/6ae_4KGrU7VR7bNmaYcT_3E.ttf"
    },
    {
        "family": "Sixtyfour",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sixtyfour/v1/OD5BuMCT1numDm3nakX3rEq4DL6w2w.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sixtyfour/v1/OD5vuMCT1numDm3nakXtp2h4jg463t9haG_3mBkVsV20uFT3BAE5f43ZlSA.ttf",
        "axes": [
            {
                "tag": "BLED",
                "start": 0,
                "end": 100
            },
            {
                "tag": "SCAN",
                "start": -53,
                "end": 100
            }
        ]
    },
    {
        "family": "Sixtyfour Convergence",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sixtyfourconvergence/v1/m8JMjepPf7mIglv5K__zM9srGA7wurbybZMFbehGW74OXw.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sixtyfourconvergence/v1/m8IQjepPf7mIglv5K__zM9srGA7wurbybZMfZsqG2Q6EWlJro5FJSJ4acT9PoOPwGgieaK7zkSpdXP-GrR9Yw9Tg7E4HGLbUKPlOh102topulw.ttf",
        "axes": [
            {
                "tag": "BLED",
                "start": 0,
                "end": 100
            },
            {
                "tag": "SCAN",
                "start": -53,
                "end": 100
            },
            {
                "tag": "XELA",
                "start": -100,
                "end": 100
            },
            {
                "tag": "YELA",
                "start": -100,
                "end": 100
            }
        ],
        "colorCapabilities": [
            "COLRv1"
        ]
    },
    {
        "family": "Skranji",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMYzuuklTm3Ek.ttf",
            "700": "https://fonts.gstatic.com/s/skranji/v13/OZpGg_dtriVFNerMW4eBtlzNwED-b4g.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMUzqklg.ttf"
    },
    {
        "family": "Slabo 13px",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/slabo13px/v15/11hEGp_azEvXZUdSBzzRcKer2wkYnvI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/slabo13px/v15/11hEGp_azEvXZUdSBzzRQKah3w.ttf"
    },
    {
        "family": "Slabo 27px",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/slabo27px/v14/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/slabo27px/v14/mFT0WbgBwKPR_Z4hGN2qgxkD0A.ttf"
    },
    {
        "family": "Slackey",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/slackey/v28/N0bV2SdQO-5yM0-dKlRaJdbWgdY.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/slackey/v28/N0bV2SdQO-5yM0-dGlVQIQ.ttf"
    },
    {
        "family": "Slackside One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/slacksideone/v11/EJRQQgMrXdcGsiBuvnRxodTwVy7VocNB6Iw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/slacksideone/v11/EJRQQgMrXdcGsiBuvnRxodTwZy_fpQ.ttf"
    },
    {
        "family": "Smokum",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/smokum/v28/TK3iWkUbAhopmrdGHjUHte5fKg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/smokum/v28/TK3iWkUbAhopmrd2Hz8D.ttf"
    },
    {
        "family": "Smooch",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/smooch/v7/o-0LIps4xW8U1xUBjqp_6hVdYg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/smooch/v7/o-0LIps4xW8U1xUxj6B7.ttf"
    },
    {
        "family": "Smooch Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/smoochsans/v13/c4mk1n5uGsXss2LJh1QH6ad91qa3WFv8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUBYd6ga.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Smythe",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1BOLh_uGInjA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1xObJ7.ttf"
    },
    {
        "family": "Sniglet",
        "variants": [
            "regular",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJxCmrYGkHgIs.ttf",
            "800": "https://fonts.gstatic.com/s/sniglet/v17/cIf4MaFLtkE3UjaJ_ImHRGEsnIJkWL4.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJ9CihZA.ttf"
    },
    {
        "family": "Snippet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu2v_Afcp9QWc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu6v7KeQ.ttf"
    },
    {
        "family": "Snowburst One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7eZ-UPyacPbo.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7SZ6eOw.ttf"
    },
    {
        "family": "Sofadi One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGcDOIETkmYDU.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGQDKCFQ.ttf"
    },
    {
        "family": "Sofia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_vu-sowsrqjk.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_su_uIg.ttf"
    },
    {
        "family": "Sofia Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6R-LCVXSLy9uPBwlATnOZwkxgtUb8.ttf",
            "italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6T-LCVXSLy9uPBwlATrOR6lzooQb9e6g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sJ3spPY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Sofia Sans Condensed",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05EGKVS5aVKd567NYXawnFKJaTtoAuLnLcPrNDVemxE.ttf",
            "italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05KGKVS5aVKd567NYXawnFKJaTtoAuLnIcNptT3f3xEagQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Gcls4K.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Sofia Sans Extra Condensed",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxoHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0cSpLE9Ukzek.ttf",
            "italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxqHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrJkt2liek-dA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmi0fjOI.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Sofia Sans Semi Condensed",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46k9laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURgWQ-826XvcQ.ttf",
            "italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46k7laPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWY090ySrcQYvw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEd9SE6w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Solitreo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/solitreo/v2/r05YGLlS5a9KYsyNO8upyDYtStiJ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/solitreo/v2/r05YGLlS5a9KYsyNO_uowjI.ttf"
    },
    {
        "family": "Solway",
        "variants": [
            "300",
            "regular",
            "500",
            "700",
            "800"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuLlgZms0QW3mqyg.ttf",
            "regular": "https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCWgnA9kuYMUg.ttf",
            "500": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCudlkZms0QW3mqyg.ttf",
            "700": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuPl8Zms0QW3mqyg.ttf",
            "800": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuIlwZms0QW3mqyg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCmg3o5.ttf"
    },
    {
        "family": "Sometype Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sometypemono/v1/70lVu745KGk_R3uxyq0WrROhGpOrQEdC7m8.ttf",
            "italic": "https://fonts.gstatic.com/s/sometypemono/v1/70lLu745KGk_R3uxyq0WrROhKpGhRGVH_m9opA.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTAMGH9biwQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Song Myung",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhr1GqhcitzeM.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhn1CggQ.ttf"
    },
    {
        "family": "Sono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sono/v6/aFTb7PNiY3U2EKzdohWxGYU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWtE358.ttf",
        "axes": [
            {
                "tag": "MONO",
                "start": 0,
                "end": 1
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Sonsie One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18YVu80lbp8JM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18UVq21g.ttf"
    },
    {
        "family": "Sora",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sora/v12/xMQbuFFYT72X_QIjD4e2OX8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnk-LKQc.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Sorts Mill Goudy",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0OjpM75PE.ttf",
            "italic": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3AZR9MED_6PSuS_50nEaVrfzgEbH8EirE-9PGLfQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEbHwEig.ttf"
    },
    {
        "family": "Source Code Pro",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_SiYsKILxRpg3hIP6sJ7fM7PqVOuHXvMY3xw.ttf",
            "italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_QiYsKILxRpg3hIP6sJ7fM7PqlOOvTnsMnx3C9.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtM9rSQ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Source Sans 3",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpStKy2OAdR1K-IwhWudF-R7wgQZMrc9HY.ttf",
            "italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpMtKy2OAdR1K-IwhWudF-R3woaYOjZ5HZl8Q.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky462EM9C4.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Source Serif 4",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFI2_tTDB4M7-auWDN0ahZJW2gc-NaXXq7H.ttf",
            "italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFK2_tTDB4M7-auWDN0ahZJW1ge8tK1W77HtMo.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjihdqnh1dCw.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 8,
                "end": 60
            },
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Space Grotesk",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mDoQDjQSkFtoMM3T6r8E7mDbZyCts0DqQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUXsiPMU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Space Mono",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/spacemono/v14/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf",
            "italic": "https://fonts.gstatic.com/s/spacemono/v14/i7dNIFZifjKcF5UAWdDRYER8QHi-EwWMbg.ttf",
            "700": "https://fonts.gstatic.com/s/spacemono/v14/i7dMIFZifjKcF5UAWdDRaPpZYFKQHwyVd3U.ttf",
            "700italic": "https://fonts.gstatic.com/s/spacemono/v14/i7dSIFZifjKcF5UAWdDRYERE_FeaGy6QZ3WfYg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spacemono/v14/i7dPIFZifjKcF5UAWdDRYEd8QA.ttf"
    },
    {
        "family": "Special Elite",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0nhncESXFtUsM.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0ntnYOTQ.ttf"
    },
    {
        "family": "Spectral",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9v2s13GY_etWWIJ.ttf",
            "200italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qrXHafOPXHIJErY.ttf",
            "300": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uSsF3GY_etWWIJ.ttf",
            "300italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qtHEafOPXHIJErY.ttf",
            "regular": "https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA-M-mHnOSOuk.ttf",
            "italic": "https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8kn3sTfukQHs.ttf",
            "500": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vKsV3GY_etWWIJ.ttf",
            "500italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qonFafOPXHIJErY.ttf",
            "600": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtl3GY_etWWIJ.ttf",
            "600italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qqXCafOPXHIJErY.ttf",
            "700": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uCt13GY_etWWIJ.ttf",
            "700italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qsHDafOPXHIJErY.ttf",
            "800": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uetF3GY_etWWIJ.ttf",
            "800italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qt3AafOPXHIJErY.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M_kn0.ttf"
    },
    {
        "family": "Spectral SC",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs1qwkTXPYeVXJZB.ttf",
            "200italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg26zWN4O3WYZB_sU.ttf",
            "300": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0OwUTXPYeVXJZB.ttf",
            "300italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg28jVN4O3WYZB_sU.ttf",
            "regular": "https://fonts.gstatic.com/s/spectralsc/v12/KtkpALCRZonmalTgyPmRfvWi6WDfFpuc.ttf",
            "italic": "https://fonts.gstatic.com/s/spectralsc/v12/KtkrALCRZonmalTgyPmRfsWg42T9E4ucRY8.ttf",
            "500": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs1WwETXPYeVXJZB.ttf",
            "500italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg25DUN4O3WYZB_sU.ttf",
            "600": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs16x0TXPYeVXJZB.ttf",
            "600italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg27zTN4O3WYZB_sU.ttf",
            "700": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0exkTXPYeVXJZB.ttf",
            "700italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg29jSN4O3WYZB_sU.ttf",
            "800": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0CxUTXPYeVXJZB.ttf",
            "800italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg28TRN4O3WYZB_sU.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spectralsc/v12/KtkpALCRZonmalTgyPmRfsWj42Q.ttf"
    },
    {
        "family": "Spicy Rice",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/spicyrice/v25/uK_24rSEd-Uqwk4jY1RyGv-2WkowRcc.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spicyrice/v25/uK_24rSEd-Uqwk4jY1RyKv68Xg.ttf"
    },
    {
        "family": "Spinnaker",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/spinnaker/v19/w8gYH2oyX-I0_rvR6Hmn3HwLqOqSBg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spinnaker/v19/w8gYH2oyX-I0_rvR6HmX3XYP.ttf"
    },
    {
        "family": "Spirax",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3cXktt-Csn-Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3sX0Fp.ttf"
    },
    {
        "family": "Splash",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/splash/v6/KtksAL2RZoDkbU6hpPPGNdS6wg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/splash/v6/KtksAL2RZoDkbU6RpfnC.ttf"
    },
    {
        "family": "Spline Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/splinesans/v10/_6_7ED73Uf-2WfU2LzycEYAlkiw_SQ5j.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpOFnoE9Hh.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Spline Sans Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/splinesansmono/v10/R70BjzAei_CDNLfgZxrW6wrZOF2Wb5WTmW2a6l0.ttf",
            "italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70HjzAei_CDNLfgZxrW6wrZOF2WX5eZnU-f-l1AuQ.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBiMotUwYM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Squada One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/squadaone/v18/BCasqZ8XsOrx4mcOk6MtWaA8WDBkHgs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/squadaone/v18/BCasqZ8XsOrx4mcOk6MtaaE2XA.ttf"
    },
    {
        "family": "Square Peg",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/squarepeg/v5/y83eW48Nzw6ZlUHc-phrBDHrHHfrFPE.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/squarepeg/v5/y83eW48Nzw6ZlUHc-phrNDDhGA.ttf"
    },
    {
        "family": "Sree Krushnadevaraya",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb2FhQuXir.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_HWa0lw.ttf"
    },
    {
        "family": "Sriracha",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sriracha/v14/0nkrC9D4IuYBgWcI9ObYRQDioeb0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sriracha/v14/0nkrC9D4IuYBgWcI9NbZTwQ.ttf"
    },
    {
        "family": "Srisakdi",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-jq8fSx5i814.ttf",
            "700": "https://fonts.gstatic.com/s/srisakdi/v16/yMJWMIlvdpDbkB0A-gIAUghxoNFxW0Hz.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-gq9dyg.ttf"
    },
    {
        "family": "Staatliches",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/staatliches/v13/HI_OiY8KO6hCsQSoAPmtMbectJG9O9PS.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/staatliches/v13/HI_OiY8KO6hCsQSoAPmtMYedvpU.ttf"
    },
    {
        "family": "Stalemate",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stalemate/v22/taiIGmZ_EJq97-UfkZRpuqSs8ZQpaQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stalemate/v22/taiIGmZ_EJq97-UfkZRZu66o.ttf"
    },
    {
        "family": "Stalinist One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v56",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stalinistone/v56/MQpS-WezM9W4Dd7D3B7I-UT7eZ-UPyacPbo.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stalinistone/v56/MQpS-WezM9W4Dd7D3B7I-UT7SZ6eOw.ttf"
    },
    {
        "family": "Stardos Stencil",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2TR71R3tiSx0g.ttf",
            "700": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tTvg-t29HSHw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2Th7l5z.ttf"
    },
    {
        "family": "Stick",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stick/v18/Qw3TZQpMCyTtJSvfvPVDMPoF.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stick/v18/Qw3TZQpMCyTtJRvetvE.ttf"
    },
    {
        "family": "Stick No Bills",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sticknobills/v15/bWth7ffXZwHuAa9Uld-oEK4QKkZo1w-1YxiC.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8QLK7K0.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 800
            }
        ]
    },
    {
        "family": "Stint Ultra Condensed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stintultracondensed/v23/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stintultracondensed/v23/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2AOqx8K.ttf"
    },
    {
        "family": "Stint Ultra Expanded",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stintultraexpanded/v22/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qoATQkWwam.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stintultraexpanded/v22/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd3qpCzA.ttf"
    },
    {
        "family": "Stoke",
        "variants": [
            "300",
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/stoke/v24/z7NXdRb7aTMfKNvFVgxC_pjcTeWU.ttf",
            "regular": "https://fonts.gstatic.com/s/stoke/v24/z7NadRb7aTMfKONpfihK1YTV.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stoke/v24/z7NadRb7aTMfKNNodCw.ttf"
    },
    {
        "family": "Strait",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/strait/v17/DtViJxy6WaEr1LZzeDhtkl0U7w.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/strait/v17/DtViJxy6WaEr1LZDeTJp.ttf"
    },
    {
        "family": "Style Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stylescript/v11/vm8xdRX3SV7Z0aPa88xzW5npeFT76NZnMw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stylescript/v11/vm8xdRX3SV7Z0aPa88xzW5nZeV7_.ttf"
    },
    {
        "family": "Stylish",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/stylish/v22/m8JSjfhPYriQkk7-fo35dLxEdmo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/stylish/v22/m8JSjfhPYriQkk7-TozzcA.ttf"
    },
    {
        "family": "Sue Ellen Francisco",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sueellenfrancisco/v20/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9ropF2lqk9H4.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sueellenfrancisco/v20/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9notP3g.ttf"
    },
    {
        "family": "Suez One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/suezone/v13/taiJGmd_EZ6rqscQgNFJkIqg-I0w.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/suezone/v13/taiJGmd_EZ6rqscQgOFImo4.ttf"
    },
    {
        "family": "Sulphur Point",
        "variants": [
            "300",
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLpkK5vv8KaycDcazWFPBj2afVU6n6kFUHPIFaU.ttf",
            "regular": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLp5K5vv8KaycDcazWFPBj2aRfkSu6EuTHo.ttf",
            "700": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLpkK5vv8KaycDcazWFPBj2afUU9n6kFUHPIFaU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLp5K5vv8KaycDcazWFPBj2adfgYvw.ttf"
    },
    {
        "family": "Sumana",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-G8Bji76zR4w.ttf",
            "700": "https://fonts.gstatic.com/s/sumana/v10/4UaArE5TqRBjGj--TDfG54fN6ppsKg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-28RLm.ttf"
    },
    {
        "family": "Sunflower",
        "variants": [
            "300",
            "500",
            "700"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v16",
        "lastModified": "2024-08-12",
        "files": {
            "300": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-06MfiqYsGBGBzCw.ttf",
            "500": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-0sMbiqYsGBGBzCw.ttf",
            "700": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-0-MDiqYsGBGBzCw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-06MfSqIEC.ttf"
    },
    {
        "family": "Sunshiney",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcgE8moUePWkg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcQEsOs.ttf"
    },
    {
        "family": "Supermercado One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/supermercadoone/v26/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/supermercadoone/v26/OpNXnpQWg8jc_xps_Gi14kVVEXOn20f9NA.ttf"
    },
    {
        "family": "Sura",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/sura/v19/SZc23FL5PbyzFf5UWzXtjUM.ttf",
            "700": "https://fonts.gstatic.com/s/sura/v19/SZc53FL5PbyzLUJ7fz3GkUrS8DI.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/sura/v19/SZc23FL5PbyzJf9eXw.ttf"
    },
    {
        "family": "Suranna",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v13",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58tBRZT2KmgP0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58hBVTSw.ttf"
    },
    {
        "family": "Suravaram",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIAi261Qv9roQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIwimSx.ttf"
    },
    {
        "family": "Suwannaphum",
        "variants": [
            "100",
            "300",
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v31",
        "lastModified": "2024-08-12",
        "files": {
            "100": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnAgHV7GtDvc8jbe8hXXL3B9cSWXx2VZmk.ttf",
            "300": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0J1-S8cRGcf3Ai.ttf",
            "regular": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnCgHV7GtDvc8jbe8hXXIWl_8C0Wg2V.ttf",
            "700": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0Z0OS8cRGcf3Ai.ttf",
            "900": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0h0uS8cRGcf3Ai.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnCgHV7GtDvc8jbe8hXXLWk9cQ.ttf"
    },
    {
        "family": "Swanky and Moo Moo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtbPkR64SYQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kktLng.ttf"
    },
    {
        "family": "Syncopate",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/syncopate/v21/pe0sMIuPIYBCpEV5eFdyAv2-C99ycg.ttf",
            "700": "https://fonts.gstatic.com/s/syncopate/v21/pe0pMIuPIYBCpEV5eFdKvtKaA_Rue1UwVg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/syncopate/v21/pe0sMIuPIYBCpEV5eFdCA_e6.ttf"
    },
    {
        "family": "Syne",
        "variants": [
            "regular"
        ],
        "subsets": [
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/syne/v22/8vIH7w4qzmVxq2dB9Uz_DEc.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_04uQ6lTYo.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Syne Mono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHzIqCkDyvqZA.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHDI6qg.ttf"
    },
    {
        "family": "Syne Tactile",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKh3ysdjvKU8Q.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKR3iEZ.ttf"
    },
    {
        "family": "Tac One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tacone/v4/ahcZv8Cj3zw7qDr8fO4hU-FwnU0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tacone/v4/ahcZv8Cj3zw7qDr8TO8rVw.ttf"
    },
    {
        "family": "Tai Heritage Pro",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tai-viet",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2023-05-31",
        "files": {
            "regular": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlfdQid-zgaNiNIYcUzJMU3IYyNoHxSENxuLuE.ttf",
            "700": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlYdQid-zgaNiNIYcUzJMU3IYyNmMB9NNRFMuhjCXY.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlfdQid-zgaNiNIYcUzJMU3IYyNkH1YFA.ttf"
    },
    {
        "family": "Tajawal",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l_6gLrZjiLlJ-G0.ttf",
            "300": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l5qjLrZjiLlJ-G0.ttf",
            "regular": "https://fonts.gstatic.com/s/tajawal/v10/Iura6YBj_oCad4k1rzaLCr5IlLA.ttf",
            "500": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l8KiLrZjiLlJ-G0.ttf",
            "700": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l4qkLrZjiLlJ-G0.ttf",
            "800": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l5anLrZjiLlJ-G0.ttf",
            "900": "https://fonts.gstatic.com/s/tajawal/v10/Iurf6YBj_oCad4k1l7KmLrZjiLlJ-G0.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tajawal/v10/Iura6YBj_oCad4k1nzeBDg.ttf"
    },
    {
        "family": "Tangerine",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOBDpxNhLBQ4Q.ttf",
            "700": "https://fonts.gstatic.com/s/tangerine/v17/Iurd6Y5j_oScZZow4VO5srNpjJtM6G0t9w.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOxD5ZJ.ttf"
    },
    {
        "family": "Tapestry",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2023-08-25",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tapestry/v4/SlGTmQecrosEYXhaGBIkqnB6aSQU.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tapestry/v4/SlGTmQecrosEYXhaGCIloHQ.ttf"
    },
    {
        "family": "Taprom",
        "variants": [
            "regular"
        ],
        "subsets": [
            "khmer",
            "latin"
        ],
        "version": "v27",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULbFQyk3-0kvHg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULb1QiMz.ttf"
    },
    {
        "family": "Tauri",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tauri/v18/TwMA-IISS0AM3IpVWHU_TBqO.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tauri/v18/TwMA-IISS0AM3LpUUnE.ttf"
    },
    {
        "family": "Taviraj",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/taviraj/v13/ahcbv8Cj3ylylTXzRIorV8N1jU2gog.ttf",
            "100italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcdv8Cj3ylylTXzTOwTM8lxr0iwolLl.ttf",
            "200": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRCYKd-lbgUS5u0s.ttf",
            "200italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTn-hRhWa8q0v8ag.ttf",
            "300": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzREIJd-lbgUS5u0s.ttf",
            "300italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT--tRhWa8q0v8ag.ttf",
            "regular": "https://fonts.gstatic.com/s/taviraj/v13/ahcZv8Cj3ylylTXzfO4hU-FwnU0.ttf",
            "italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcbv8Cj3ylylTXzTOwrV8N1jU2gog.ttf",
            "500": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRBoId-lbgUS5u0s.ttf",
            "500italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTo-pRhWa8q0v8ag.ttf",
            "600": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRDYPd-lbgUS5u0s.ttf",
            "600italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTj-1RhWa8q0v8ag.ttf",
            "700": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRFIOd-lbgUS5u0s.ttf",
            "700italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT6-xRhWa8q0v8ag.ttf",
            "800": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRE4Nd-lbgUS5u0s.ttf",
            "800italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT9-9RhWa8q0v8ag.ttf",
            "900": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRGoMd-lbgUS5u0s.ttf",
            "900italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT0-5RhWa8q0v8ag.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/taviraj/v13/ahcZv8Cj3ylylTXzTO8rVw.ttf"
    },
    {
        "family": "Teachers",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/teachers/v4/H4ckBXKVncXVmUGsgSY6wr-wg763.ttf",
            "italic": "https://fonts.gstatic.com/s/teachers/v4/H4ciBXKVncXVmUGsgRY4yLuShq63czE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/teachers/v4/H4c5BXKVncXVmUGsgTwx4E9oHx2xfjBr3poAYpRK.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Teko",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/teko/v20/LYjNdG7kmE0gTaR3pCtBtVs.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN8XG4Sz1zY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Tektur",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tektur/v3/XoHn2YHtS7q969kNAR4Jt9Yxlw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrwuVbtPacc.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Telex",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/telex/v17/ieVw2Y1fKWmIO9fTB1piKFIf.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/telex/v17/ieVw2Y1fKWmIO-fSDV4.ttf"
    },
    {
        "family": "Tenali Ramakrishna",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v12",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KJXqUFFvtA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-MKZ_u.ttf"
    },
    {
        "family": "Tenor Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tenorsans/v19/bx6ANxqUneKx06UkIXISr3JyC22IyqI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tenorsans/v19/bx6ANxqUneKx06UkIXISn3N4Dw.ttf"
    },
    {
        "family": "Text Me One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/textmeone/v24/i7dOIFdlayuLUvgoFvHQFWZcalayGhyV.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/textmeone/v24/i7dOIFdlayuLUvgoFvHQFVZdYFI.ttf"
    },
    {
        "family": "Texturina",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/texturina/v28/c4mi1nxpEtL3pXiAulRJmq159KOnWA.ttf",
            "italic": "https://fonts.gstatic.com/s/texturina/v28/c4mk1nxpEtL3pXiAulR5mKd91qa3WFv8.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGsUh0Z4.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 12,
                "end": 72
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Thasadith",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/thasadith/v11/mtG44_1TIqPYrd_f5R1YsEkU0CWuFw.ttf",
            "italic": "https://fonts.gstatic.com/s/thasadith/v11/mtG-4_1TIqPYrd_f5R1oskMQ8iC-F1ZE.ttf",
            "700": "https://fonts.gstatic.com/s/thasadith/v11/mtG94_1TIqPYrd_f5R1gDGYw2A6yHk9d8w.ttf",
            "700italic": "https://fonts.gstatic.com/s/thasadith/v11/mtGj4_1TIqPYrd_f5R1osnus3QS2PEpN8zxA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/thasadith/v11/mtG44_1TIqPYrd_f5R1osUMQ.ttf"
    },
    {
        "family": "The Girl Next Door",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/thegirlnextdoor/v22/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIV7t7w6bE2A.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/thegirlnextdoor/v22/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCY4VrF_.ttf"
    },
    {
        "family": "The Nautigal",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/thenautigal/v6/VdGZAZ8ZH51Lvng9fQV2bfKr5wVk09Se5Q.ttf",
            "700": "https://fonts.gstatic.com/s/thenautigal/v6/VdGGAZ8ZH51Lvng9fQV2bfKTWypA2_-C7LoS7g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/thenautigal/v6/VdGZAZ8ZH51Lvng9fQV2bfKb5g9g.ttf"
    },
    {
        "family": "Tienne",
        "variants": [
            "regular",
            "700",
            "900"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP0LkEPHSFNyxw.ttf",
            "700": "https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zLGzjQHhuzvef5Q.ttf",
            "900": "https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zFG7jQHhuzvef5Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP07kUnD.ttf"
    },
    {
        "family": "Tillana",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "800"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tillana/v13/VuJxdNvf35P4qJ1OeKbXOIFneRo.ttf",
            "500": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQFL-HIlMZRNcp0o.ttf",
            "600": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQH75HIlMZRNcp0o.ttf",
            "700": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQBr4HIlMZRNcp0o.ttf",
            "800": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQAb7HIlMZRNcp0o.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tillana/v13/VuJxdNvf35P4qJ1OSKfdPA.ttf"
    },
    {
        "family": "Tilt Neon",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tiltneon/v10/E21l_d7gguXdwD9LEFYsUwVUAuu3cw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tiltneon/v10/E21L_d7gguXdwD9LEFY2WCeElCNtd-eBqpHp1TzrkJSmwpj5ndxquUK8UOI.ttf",
        "axes": [
            {
                "tag": "XROT",
                "start": -45,
                "end": 45
            },
            {
                "tag": "YROT",
                "start": -45,
                "end": 45
            }
        ]
    },
    {
        "family": "Tilt Prism",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tiltprism/v11/5h1fiZgyPHoZ3YikNzWGZ2yQCUZIv3A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tiltprism/v11/5h11iZgyPHoZ3YikNzWGfWey2dCAZXT-bH9V4VGn-FJ7tLI25oc_rIbAoD6v.ttf",
        "axes": [
            {
                "tag": "XROT",
                "start": -45,
                "end": 45
            },
            {
                "tag": "YROT",
                "start": -45,
                "end": 45
            }
        ]
    },
    {
        "family": "Tilt Warp",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tiltwarp/v12/AlZy_zVDs5XpmO7yn3whdXf4XB0Tow.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tiltwarp/v12/AlZc_zVDs5XpmO7yn3w7flUoytXJp3z29uEwmEMLEJljLXvT8UJSZQBwC1M.ttf",
        "axes": [
            {
                "tag": "XROT",
                "start": -45,
                "end": 45
            },
            {
                "tag": "YROT",
                "start": -45,
                "end": 45
            }
        ]
    },
    {
        "family": "Timmana",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "telugu"
        ],
        "version": "v12",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpCmvbKHwJUOM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpOmrRLA.ttf"
    },
    {
        "family": "Tinos",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwgX8dGVh8TI-.ttf",
            "italic": "https://fonts.gstatic.com/s/tinos/v24/buE2poGnedXvwjX-fmFD9CI-4NU.ttf",
            "700": "https://fonts.gstatic.com/s/tinos/v24/buE1poGnedXvwj1AW0Fp2i43-cxL.ttf",
            "700italic": "https://fonts.gstatic.com/s/tinos/v24/buEzpoGnedXvwjX-Rt1s0CoV_NxLeiw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwjX9fmE.ttf"
    },
    {
        "family": "Tiny5",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tiny5/v1/KFOpCnmCvdGT7hw-z0hHAi88.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tiny5/v1/KFOpCnmCvdGT7iw_xUw.ttf"
    },
    {
        "family": "Tiro Bangla",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "bengali",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8MG9-UPeuz4i.ttf",
            "italic": "https://fonts.gstatic.com/s/tirobangla/v6/IFSiHe1Tm95E3O8b5i2V8PG_80f8vi4imBM.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8PG880c.ttf"
    },
    {
        "family": "Tiro Devanagari Hindi",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9fSOkOfG0Y3A.ttf",
            "italic": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55x8ezN7P8T4e0_CfIJrwdodg9HoYw0i-M9vSuMKXmgI3F_o.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9vSeMK.ttf"
    },
    {
        "family": "Tiro Devanagari Marathi",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkotDrDJYM2lAZ.ttf",
            "italic": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1zPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkouDpBpIu30AZbUY.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkouDqBpI.ttf"
    },
    {
        "family": "Tiro Devanagari Sanskrit",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59pEoEqgtOh0w.ttf",
            "italic": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoGzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59ZEIsuoNax06MM.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59ZE4su.ttf"
    },
    {
        "family": "Tiro Gurmukhi",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "gurmukhi",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvly7HAQsyA2Y.ttf",
            "italic": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3d4ckXSYq-Uqjc048JUF7JvpyzNBSk3E2YljQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvpy_NBQ.ttf"
    },
    {
        "family": "Tiro Kannada",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "kannada",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIYKSPl6tOU9Eg.ttf",
            "italic": "https://fonts.gstatic.com/s/tirokannada/v6/CSRm4ztKmvqaDxEDJFY7CIY6SvN-luAtEnKp.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIY6SfN-.ttf"
    },
    {
        "family": "Tiro Tamil",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "tamil"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirotamil/v10/m8JXjfVIf7OT22n3M-S_ULRvamODxdI.ttf",
            "italic": "https://fonts.gstatic.com/s/tirotamil/v10/m8JVjfVIf7OT22n3M-S_YLZlbkGG1dKEDw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirotamil/v10/m8JXjfVIf7OT22n3M-S_YLVlbg.ttf"
    },
    {
        "family": "Tiro Telugu",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "telugu"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTQ7PxlZWk2EPiSymjXdKSNQqn0X0BO.ttf",
            "italic": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTS7PxlZWk2EPiSymjXdJSPSK3WWlBOoas.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTQ7PxlZWk2EPiSymjXdJSMSK0.ttf"
    },
    {
        "family": "Titan One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/titanone/v15/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/titanone/v15/mFTzWbsGxbbS_J5cQcjCkjgj.ttf"
    },
    {
        "family": "Titillium Web",
        "variants": [
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "900"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffAzHKIx5YrSYqWM.ttf",
            "200italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1zZpaduWMmxA.ttf",
            "300": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffGjEKIx5YrSYqWM.ttf",
            "300italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5zZpaduWMmxA.ttf",
            "regular": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf",
            "italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPAcZTIAOhVxoMyOr9n_E7fdMbmCKZXbr2BsA.ttf",
            "600": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf",
            "600italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhzZpaduWMmxA.ttf",
            "700": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf",
            "700italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlzZpaduWMmxA.ttf",
            "900": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffEDBKIx5YrSYqWM.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPecZTIAOhVxoMyOr9n_E7fdMXmCA.ttf"
    },
    {
        "family": "Tomorrow",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/tomorrow/v17/WBLgrETNbFtZCeGqgR2xe2XiKMiokE4.ttf",
            "100italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLirETNbFtZCeGqgRXXQwHoLOqtgE5h0A.ttf",
            "200": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0dWkXIBsShiVd4.ttf",
            "200italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ63JDMCDjEd4yVY.ttf",
            "300": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR15WUXIBsShiVd4.ttf",
            "300italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ8nKDMCDjEd4yVY.ttf",
            "regular": "https://fonts.gstatic.com/s/tomorrow/v17/WBLmrETNbFtZCeGqgSXVcWHALdio.ttf",
            "italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLgrETNbFtZCeGqgRXXe2XiKMiokE4.ttf",
            "500": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0hWEXIBsShiVd4.ttf",
            "500italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ5HLDMCDjEd4yVY.ttf",
            "600": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0NX0XIBsShiVd4.ttf",
            "600italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ73MDMCDjEd4yVY.ttf",
            "700": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR1pXkXIBsShiVd4.ttf",
            "700italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ9nNDMCDjEd4yVY.ttf",
            "800": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR11XUXIBsShiVd4.ttf",
            "800italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ8XODMCDjEd4yVY.ttf",
            "900": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR1RXEXIBsShiVd4.ttf",
            "900italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ-HPDMCDjEd4yVY.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tomorrow/v17/WBLmrETNbFtZCeGqgRXUe2U.ttf"
    },
    {
        "family": "Tourney",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tourney/v13/AlZw_ztDtYzv1tzqzQwrcVX9TB0.ttf",
            "italic": "https://fonts.gstatic.com/s/tourney/v13/AlZy_ztDtYzv1tzq_Q4hdXf4XB0Tow.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFCZDBx.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Trade Winds",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM3Jq7PKP5Z_G.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM0Jr5vY.ttf"
    },
    {
        "family": "Train One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/trainone/v14/gyB-hwkiNtc6KnxUVjWHOqbZRY7JVQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trainone/v14/gyB-hwkiNtc6KnxUVjW3O6zd.ttf"
    },
    {
        "family": "Trirong",
        "variants": [
            "100",
            "100italic",
            "200",
            "200italic",
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic",
            "800",
            "800italic",
            "900",
            "900italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "thai",
            "vietnamese"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/trirong/v15/7r3EqXNgp8wxdOdOl-go3YRl6ujngw.ttf",
            "100italic": "https://fonts.gstatic.com/s/trirong/v15/7r3CqXNgp8wxdOdOn44QuY5hyO33g8IY.ttf",
            "200": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl0QJ_a5L5uH-mts.ttf",
            "200italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QFa9B4sP7itsB5g.ttf",
            "300": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlyAK_a5L5uH-mts.ttf",
            "300italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QcaxB4sP7itsB5g.ttf",
            "regular": "https://fonts.gstatic.com/s/trirong/v15/7r3GqXNgp8wxdOdOr4wi2aZg-ug.ttf",
            "italic": "https://fonts.gstatic.com/s/trirong/v15/7r3EqXNgp8wxdOdOn44o3YRl6ujngw.ttf",
            "500": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl3gL_a5L5uH-mts.ttf",
            "500italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QKa1B4sP7itsB5g.ttf",
            "600": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl1QM_a5L5uH-mts.ttf",
            "600italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QBapB4sP7itsB5g.ttf",
            "700": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlzAN_a5L5uH-mts.ttf",
            "700italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QYatB4sP7itsB5g.ttf",
            "800": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlywO_a5L5uH-mts.ttf",
            "800italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QfahB4sP7itsB5g.ttf",
            "900": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlwgP_a5L5uH-mts.ttf",
            "900italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QWalB4sP7itsB5g.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trirong/v15/7r3GqXNgp8wxdOdOn40o3Q.ttf"
    },
    {
        "family": "Trispace",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v24",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/trispace/v24/Yq6X-LKSQC3o56Lxxh5gluJSlggt.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9rkQh-yA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Trocchi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/trocchi/v17/qWcqB6WkuIDxDZLcDrtUvMeTYD0.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trocchi/v17/qWcqB6WkuIDxDZLcPrpeuA.ttf"
    },
    {
        "family": "Trochut",
        "variants": [
            "regular",
            "italic",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/trochut/v22/CHyjV-fDDlP9bDIw5nSIfVIPLns.ttf",
            "italic": "https://fonts.gstatic.com/s/trochut/v22/CHyhV-fDDlP9bDIw1naCeXAKPns8jw.ttf",
            "700": "https://fonts.gstatic.com/s/trochut/v22/CHymV-fDDlP9bDIw3sinWVokMnIllmA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trochut/v22/CHyjV-fDDlP9bDIw1nWCeQ.ttf"
    },
    {
        "family": "Truculenta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/truculenta/v22/LhWgMVvBKusVIfNYGi1-QP93NtmZmu8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAigwYPGg.ttf",
        "axes": [
            {
                "tag": "opsz",
                "start": 12,
                "end": 72
            },
            {
                "tag": "wdth",
                "start": 75,
                "end": 125
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Trykker",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPzhNnoOd2j22U.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPztNjiPQ.ttf"
    },
    {
        "family": "Tsukimi Rounded",
        "variants": [
            "300",
            "regular",
            "500",
            "600",
            "700"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-08-07",
        "files": {
            "300": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoZ3LJNksWZO0LvnZwkF3HtoB7VkVsqN7MT3T9X8g.ttf",
            "regular": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoc3LJNksWZO0LvnZwkF3HtoB7tPXMOP5gP1A.ttf",
            "500": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoZ3LJNksWZO0LvnZwkF3HtoB7VyVoqN7MT3T9X8g.ttf",
            "600": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoZ3LJNksWZO0LvnZwkF3HtoB7V5V0qN7MT3T9X8g.ttf",
            "700": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoZ3LJNksWZO0LvnZwkF3HtoB7VgVwqN7MT3T9X8g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tsukimirounded/v11/sJoc3LJNksWZO0LvnZwkF3HtoB7dPHkK.ttf"
    },
    {
        "family": "Tulpen One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v25",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/tulpenone/v25/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/tulpenone/v25/dFa6ZfeC474skLgesc0Cajw6-A.ttf"
    },
    {
        "family": "Turret Road",
        "variants": [
            "200",
            "300",
            "regular",
            "500",
            "700",
            "800"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0ONEdeLYk1Mq3ap.ttf",
            "300": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0PpEteLYk1Mq3ap.ttf",
            "regular": "https://fonts.gstatic.com/s/turretroad/v9/pxiAypMgpcBFjE84Zv-fE3tFOvODSVFF.ttf",
            "500": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0OxE9eLYk1Mq3ap.ttf",
            "700": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0P5FdeLYk1Mq3ap.ttf",
            "800": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0PlFteLYk1Mq3ap.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/turretroad/v9/pxiAypMgpcBFjE84Zv-fE0tEMPc.ttf"
    },
    {
        "family": "Twinkle Star",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/twinklestar/v6/pe0pMI6IL4dPoFl9LGEmY6WaA_Rue1UwVg.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/twinklestar/v6/pe0pMI6IL4dPoFl9LGEmY6WqAv5q.ttf"
    },
    {
        "family": "Ubuntu",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf",
            "300italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf",
            "regular": "https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf",
            "italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf",
            "500": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf",
            "500italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf",
            "700": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf",
            "700italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKeQ7z.ttf"
    },
    {
        "family": "Ubuntu Condensed",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtT-bfrg.ttf"
    },
    {
        "family": "Ubuntu Mono",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf",
            "italic": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf",
            "700": "https://fonts.gstatic.com/s/ubuntumono/v17/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf",
            "700italic": "https://fonts.gstatic.com/s/ubuntumono/v17/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOjCneDtsqEr0keqCMhbCc8CsE.ttf"
    },
    {
        "family": "Ubuntu Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ubuntusans/v2/co3CmWd6mSRtB7_9UaLWwIPJiXzr6FTJ.ttf",
            "italic": "https://fonts.gstatic.com/s/ubuntusans/v2/co3AmWd6mSRtB7_9UaLWwLPLg3jJ7UTJtlo.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ubuntusans/v2/co3omWd6mSRtB7_9UaLWwJnCq5ALePfPu1tPcW235R53LqrCPWbujzt6OfuBUA.ttf",
        "axes": [
            {
                "tag": "wdth",
                "start": 75,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 100,
                "end": 800
            }
        ]
    },
    {
        "family": "Ubuntu Sans Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "greek-ext",
            "latin",
            "latin-ext"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ubuntusansmono/v1/jVyR7mzgBHrR5yE7ZyRg0QRJMKI41g3CfRXxWZQ.ttf",
            "italic": "https://fonts.gstatic.com/s/ubuntusansmono/v1/jVyX7mzgBHrR5yE7ZyRg0QRJMKI45g_IeTf0SZST2g.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ubuntusansmono/v1/jVyc7mzgBHrR5yE7ZyRg0QRJMKI4zAbgjc1t-pKe27Ev_nYQgKM.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 700
            }
        ]
    },
    {
        "family": "Uchen",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "tibetan"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/uchen/v9/nKKZ-GokGZ1baIaSEQGodLxA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/uchen/v9/nKKZ-GokGZ1baLaTGwU.ttf"
    },
    {
        "family": "Ultra",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ultra/v23/zOLy4prXmrtY-tT6yLOD6NxF.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ultra/v23/zOLy4prXmrtY-uT7wrc.ttf"
    },
    {
        "family": "Unbounded",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unbounded/v8/Yq6W-LOTXCb04q32xlpAvMxenxE0SA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unbounded/v8/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6xjx44zNhA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 900
            }
        ]
    },
    {
        "family": "Uncial Antiqua",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-PfRS5VBBSSF.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-MfQQZE.ttf"
    },
    {
        "family": "Underdog",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/underdog/v23/CHygV-jCElj7diMroVSiU14GN2Il.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/underdog/v23/CHygV-jCElj7diMroWSjWVo.ttf"
    },
    {
        "family": "Unica One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unicaone/v18/DPEuYwWHyAYGVTSmalshdtffuEY7FA.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unicaone/v18/DPEuYwWHyAYGVTSmalsRd93b.ttf"
    },
    {
        "family": "UnifrakturCook",
        "variants": [
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "700": "https://fonts.gstatic.com/s/unifrakturcook/v23/IurA6Yli8YOdcoky-0PTTdkm56n05Uw13ILXs-h6.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unifrakturcook/v23/IurA6Yli8YOdcoky-0PTTdkm56n05Xw01oY.ttf"
    },
    {
        "family": "UnifrakturMaguntia",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unifrakturmaguntia/v20/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVun2xNNgNa1A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unifrakturmaguntia/v20/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVenmZJ.ttf"
    },
    {
        "family": "Unkempt",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unkempt/v21/2EbnL-Z2DFZue0DSSYYf8z2Yt_c.ttf",
            "700": "https://fonts.gstatic.com/s/unkempt/v21/2EbiL-Z2DFZue0DScTow1zWzq_5uT84.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unkempt/v21/2EbnL-Z2DFZue0DSeYcV9w.ttf"
    },
    {
        "family": "Unlock",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v26",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unlock/v26/7Au-p_8ykD-cDl7GKAjSwkUVOQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unlock/v26/7Au-p_8ykD-cDl72KQLW.ttf"
    },
    {
        "family": "Unna",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/unna/v23/AYCEpXzofN0NCpgBlGHCWFM.ttf",
            "italic": "https://fonts.gstatic.com/s/unna/v23/AYCKpXzofN0NOpoLkEPHSFNyxw.ttf",
            "700": "https://fonts.gstatic.com/s/unna/v23/AYCLpXzofN0NMiQusGnpRFpr3vc.ttf",
            "700italic": "https://fonts.gstatic.com/s/unna/v23/AYCJpXzofN0NOpozLGzjQHhuzvef5Q.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/unna/v23/AYCEpXzofN0NOpkLkA.ttf"
    },
    {
        "family": "Updock",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/updock/v5/nuF4D_3dVZ70UI9SjLK3602XBw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/updock/v5/nuF4D_3dVZ70UI9ijbiz.ttf"
    },
    {
        "family": "Urbanist",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v15",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyPqiZyxEimK3.ttf",
            "italic": "https://fonts.gstatic.com/s/urbanist/v15/L0x4DF02iFML4hGCyMqgbShmj3K3R7s.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4vF5mv.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "VT323",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2hsYHpT2dkNE.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2isZFJA.ttf"
    },
    {
        "family": "Vampiro One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLkqnsvhIor3K.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLnqmuPw.ttf"
    },
    {
        "family": "Varela",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHXJBBQFfvzDsQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHX5BR4B.ttf"
    },
    {
        "family": "Varela Round",
        "variants": [
            "regular"
        ],
        "subsets": [
            "hebrew",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoMGOD9gxZw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIfocuK.ttf"
    },
    {
        "family": "Varta",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/varta/v21/Qw3TZQpJHj_6LyvfvPVDMPoF.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD40i7s6.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Vast Shadow",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vastshadow/v19/pe0qMImKOZ1V62ZwbVY9dfe6Kdpickwp.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vastshadow/v19/pe0qMImKOZ1V62ZwbVY9dce7I94.ttf"
    },
    {
        "family": "Vazirmatn",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxxo8j6PP2D_kU2muijVGs-XAmn4eg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgzCRaT6w.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Vesper Libre",
        "variants": [
            "regular",
            "500",
            "700",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_Rd4D0-N2qIWVQ.ttf",
            "500": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdA-2ap0okKXKvPlw.ttf",
            "700": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAs2Cp0okKXKvPlw.ttf",
            "900": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAi2Kp0okKXKvPlw.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_RdIDkWJ.ttf"
    },
    {
        "family": "Viaoda Libre",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/viaodalibre/v18/vEFW2_lWCgoR6OKuRz9kcRVJb2IY2tOHXg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/viaodalibre/v18/vEFW2_lWCgoR6OKuRz9kcRV5bmgc.ttf"
    },
    {
        "family": "Vibes",
        "variants": [
            "regular"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmbd3HpbsuBlh.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmYd2FJI.ttf"
    },
    {
        "family": "Vibur",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRjTpLjoJd-Xa.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRgToJD4.ttf"
    },
    {
        "family": "Victor Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v3",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/victormono/v3/Yq6Q-LGQWyfv-LGy7lEO08ZavRQkSKZH.ttf",
            "italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6S-LGQWyfv-LGy7lEO0_ZYtxAGTbZH89E.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyO879hdaK.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 700
            }
        ]
    },
    {
        "family": "Vidaloka",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEass8yn4hnCci.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEavs9wHo.ttf"
    },
    {
        "family": "Viga",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiX_QIjD4e2OX8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiXzQMpCw.ttf"
    },
    {
        "family": "Vina Sans",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vinasans/v6/m8JQjfZKf6-d2273MP7zcJ5BZmqa3A.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vinasans/v6/m8JQjfZKf6-d2273MP7DcZRF.ttf"
    },
    {
        "family": "Voces",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/voces/v22/-F6_fjJyLyU8d4PBBG7YpzlJ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/voces/v22/-F6_fjJyLyU8d7PADmo.ttf"
    },
    {
        "family": "Volkhov",
        "variants": [
            "regular",
            "italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNeQTIOhHxzcD0.ttf",
            "italic": "https://fonts.gstatic.com/s/volkhov/v17/SlGSmQieoJcKemNecTAEgF52YD0NYw.ttf",
            "700": "https://fonts.gstatic.com/s/volkhov/v17/SlGVmQieoJcKemNeeY4hoHRYbDQUego.ttf",
            "700italic": "https://fonts.gstatic.com/s/volkhov/v17/SlGXmQieoJcKemNecTA8PHFSaBYRagrQrA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNecTMEgA.ttf"
    },
    {
        "family": "Vollkorn",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v27",
        "lastModified": "2024-09-30",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vollkorn/v27/0yb9GDoxxrvAnPhYGykuYkw2rQg1.ttf",
            "italic": "https://fonts.gstatic.com/s/vollkorn/v27/0yb7GDoxxrvAnPhYGxksaEgUqBg15TY.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vollkorn/v27/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGeGGmZ.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Vollkorn SC",
        "variants": [
            "regular",
            "600",
            "700",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnVhF5NH-iSq_E.ttf",
            "600": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVimhGluqYbPN5Yjn.ttf",
            "700": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVinFG1uqYbPN5Yjn.ttf",
            "900": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVin9GVuqYbPN5Yjn.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnViF4Pns.ttf"
    },
    {
        "family": "Voltaire",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v21",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/voltaire/v21/1Pttg8PcRfSblAvGvQooYKVnBOif.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/voltaire/v21/1Pttg8PcRfSblAvGvTopaqE.ttf"
    },
    {
        "family": "Vujahday Script",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v8",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/vujahdayscript/v8/RWmQoKGA8fEkrIPtSZ3_J7er2dUiDEtvAlaMKw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/vujahdayscript/v8/RWmQoKGA8fEkrIPtSZ3_J7er2dUSDUFr.ttf"
    },
    {
        "family": "Waiting for the Sunrise",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/waitingforthesunrise/v20/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ9h_ZYk5J.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/waitingforthesunrise/v20/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsbZR_Bs.ttf"
    },
    {
        "family": "Wallpoet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wallpoet/v20/f0X10em2_8RnXVVdUNbu7cXP8L8G.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wallpoet/v20/f0X10em2_8RnXVVdUObv58E.ttf"
    },
    {
        "family": "Walter Turncoat",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/walterturncoat/v23/snfys0Gs98ln43n0d-14ULoToe67YB2dQ5ZPqQ.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/walterturncoat/v23/snfys0Gs98ln43n0d-14ULoToe6LYReZ.ttf"
    },
    {
        "family": "Warnes",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v27",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/warnes/v27/pONn1hc0GsW6sW5OpiC2o6Lkqg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/warnes/v27/pONn1hc0GsW6sW5-pyqy.ttf"
    },
    {
        "family": "Water Brush",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v4",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/waterbrush/v4/AYCPpXPqc8cJWLhp4hywKHJq7PKP5Z_G.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/waterbrush/v4/AYCPpXPqc8cJWLhp4hywKEJr5vY.ttf"
    },
    {
        "family": "Waterfall",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/waterfall/v6/MCoRzAfo293fACdFKcwY2rH8D_EZwA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/waterfall/v6/MCoRzAfo293fACdFKcwo27v4.ttf"
    },
    {
        "family": "Wavefont",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v10",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wavefont/v10/L0x-DF00m0cP6hefyPqiZyxEimK3.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3V04DGVq8.ttf",
        "axes": [
            {
                "tag": "ROND",
                "start": 0,
                "end": 100
            },
            {
                "tag": "YELA",
                "start": -100,
                "end": 100
            },
            {
                "tag": "wght",
                "start": 4,
                "end": 1000
            }
        ]
    },
    {
        "family": "Wellfleet",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v23",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wellfleet/v23/nuF7D_LfQJb3VYgX6eyT42aLDhO2HA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wellfleet/v23/nuF7D_LfQJb3VYgX6eyj4myP.ttf"
    },
    {
        "family": "Wendy One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wendyone/v18/2sDcZGJOipXfgfXV5wgDb2-4C7wFZQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wendyone/v18/2sDcZGJOipXfgfXV5wgzbmW8.ttf"
    },
    {
        "family": "Whisper",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v5",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/whisper/v5/q5uHsoqtKftx74K9milCBxxdmYU.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/whisper/v5/q5uHsoqtKftx74K9qihIAw.ttf"
    },
    {
        "family": "WindSong",
        "variants": [
            "regular",
            "500"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/windsong/v11/KR1WBsyu-P-GFEW57r95HdG6vjH3.ttf",
            "500": "https://fonts.gstatic.com/s/windsong/v11/KR1RBsyu-P-GFEW57oeNNPWylS3-jVXm.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/windsong/v11/KR1WBsyu-P-GFEW57o94F9U.ttf"
    },
    {
        "family": "Wire One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v28",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wireone/v28/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wireone/v28/qFdH35Wah5htUhV75VGjU94.ttf"
    },
    {
        "family": "Wittgenstein",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wittgenstein/v2/WBLirEDOakJCHParhXGwMgvoLOqtgE5h0A.ttf",
            "italic": "https://fonts.gstatic.com/s/wittgenstein/v2/WBL8rEDOakJCHParhXGwMgvYLuCpoktx0E87.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wittgenstein/v2/WBL3rEDOakJCHParhXGwMgvyJ8hdWNLC1kI61G4TzBrxYw.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 900
            }
        ]
    },
    {
        "family": "Wix Madefor Display",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v10",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcl3EX9IbbyeJ8aOluD52KXgUA_7Ed1OVbkA0VeBNE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYFhYmtltfk.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Wix Madefor Text",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_lXI_oSymQ8Qj-Apx3HGN_Hu1RViIb5gwfsj4.ttf",
            "italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_jXI_oSymQ8Qj-Apx3HGN_Hu1RZiAR4i4aoj4S0A.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NNeEiJA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 400,
                "end": 800
            }
        ]
    },
    {
        "family": "Work Sans",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43RhPe6rol_lQ4A.ttf",
            "italic": "https://fonts.gstatic.com/s/worksans/v19/QGYqz_wNahGAdqQ43Rh_eaDstfxA4OD3.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBikJow.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 100,
                "end": 900
            }
        ]
    },
    {
        "family": "Workbench",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "math",
            "symbols"
        ],
        "version": "v1",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/workbench/v1/FeVSS05Gp6Et7FcfbPFQ3Z5nm29Gww.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/workbench/v1/FeV8S05Gp6Et7FcfbPFK1rynGd_MxtkvNFmoUDFhgF2VKTGQk6vapeOK2mY.ttf",
        "axes": [
            {
                "tag": "BLED",
                "start": 0,
                "end": 100
            },
            {
                "tag": "SCAN",
                "start": -53,
                "end": 100
            }
        ]
    },
    {
        "family": "Xanh Mono",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/xanhmono/v18/R70YjykVmvKCep-vWhSYmACQXzLhTg.ttf",
            "italic": "https://fonts.gstatic.com/s/xanhmono/v18/R70ejykVmvKCep-vWhSomgqUfTfxTo24.ttf"
        },
        "category": "monospace",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/xanhmono/v18/R70YjykVmvKCep-vWhSomQqU.ttf"
    },
    {
        "family": "Yaldevi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "sinhala"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yaldevi/v12/cY9Ffj6VW0NMrDWtFtfgy9HTkak.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJwvpZRY.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 700
            }
        ]
    },
    {
        "family": "Yanone Kaffeesatz",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v30",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y976aknfjLm_3lMKjiMgmUUYBs04b8cFeulHc6N.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZcWGP.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 200,
                "end": 700
            }
        ]
    },
    {
        "family": "Yantramanav",
        "variants": [
            "100",
            "300",
            "regular",
            "500",
            "700",
            "900"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-09-04",
        "files": {
            "100": "https://fonts.gstatic.com/s/yantramanav/v13/flU-Rqu5zY00QEpyWJYWN5-QXeNzDB41rZg.ttf",
            "300": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59Yf8NZIhI8tIHh.ttf",
            "regular": "https://fonts.gstatic.com/s/yantramanav/v13/flU8Rqu5zY00QEpyWJYWN6f0V-dRCQ41.ttf",
            "500": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN58AfsNZIhI8tIHh.ttf",
            "700": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59IeMNZIhI8tIHh.ttf",
            "900": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59wesNZIhI8tIHh.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yantramanav/v13/flU8Rqu5zY00QEpyWJYWN5f1XeM.ttf"
    },
    {
        "family": "Yarndings 12",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yarndings12/v2/55xreyp2N8T5P2LJbZAlkY9c8ZLMI2VUnQ.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yarndings12/v2/55xreyp2N8T5P2LJbZAlkY9s8JjI.ttf"
    },
    {
        "family": "Yarndings 12 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yarndings12charted/v2/eLGDP_DlKhO-DUfeqM4I_vDdJgmIh7hAvvbJ0t-dHaJH.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yarndings12charted/v2/eLGDP_DlKhO-DUfeqM4I_vDdJgmIh7hAvsbI2Ns.ttf"
    },
    {
        "family": "Yarndings 20",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yarndings20/v2/TuGWUVlkUohEQu8l7K8b-vNFB380PMTK1w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yarndings20/v2/TuGWUVlkUohEQu8l7K8b-vN1BnUw.ttf"
    },
    {
        "family": "Yarndings 20 Charted",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "math",
            "symbols"
        ],
        "version": "v2",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yarndings20charted/v2/QldRNSdbpg0G8vh0W2qxe0l-hcUPtY2VaLQm4UTqz5V9.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yarndings20charted/v2/QldRNSdbpg0G8vh0W2qxe0l-hcUPtY2VaIQn60A.ttf"
    },
    {
        "family": "Yatra One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "devanagari",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0xw1OBbRDvXw.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0BwlmF.ttf"
    },
    {
        "family": "Yellowtail",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yellowtail/v22/OZpGg_pnoDtINPfRIlLotlzNwED-b4g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yellowtail/v22/OZpGg_pnoDtINPfRIlLohl3HxA.ttf"
    },
    {
        "family": "Yeon Sung",
        "variants": [
            "regular"
        ],
        "subsets": [
            "korean",
            "latin"
        ],
        "version": "v21",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yeonsung/v21/QldMNTpbohAGtsJvUn6xSVNazqx2xg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yeonsung/v21/QldMNTpbohAGtsJvUn6BSFle.ttf"
    },
    {
        "family": "Yeseva One",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v22",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yesevaone/v22/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yesevaone/v22/OpNJno4ck8vc-xYpwWWxlitVXg.ttf"
    },
    {
        "family": "Yesteryear",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v18",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yesteryear/v18/dg4g_p78rroaKl8kRKo1r7wHTwonmyw.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yesteryear/v18/dg4g_p78rroaKl8kRKo1n70NSw.ttf"
    },
    {
        "family": "Yomogi",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yomogi/v12/VuJwdNrS2ZL7rpoPWIz5NIh-YA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yomogi/v12/VuJwdNrS2ZL7rpo_WYb9.ttf"
    },
    {
        "family": "Young Serif",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/youngserif/v2/3qTpojO2nS2VtkB3KtkQZ2t61EcYaQ7F.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/youngserif/v2/3qTpojO2nS2VtkB3KtkQZ1t73kM.ttf"
    },
    {
        "family": "Yrsa",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext",
            "vietnamese"
        ],
        "version": "v20",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yrsa/v20/wlp-gwnQFlxs5QvV-IwQwWc.ttf",
            "italic": "https://fonts.gstatic.com/s/yrsa/v20/wlpwgwnQFlxs1Qnf_K4V0WdXaQ.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCieSONU_LA.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 300,
                "end": 700
            }
        ]
    },
    {
        "family": "Ysabeau",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ysabeau/v2/kmK9ZqEiBAXLcnuMpD1v0ybZuuQ.ttf",
            "italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmK_ZqEiBAXLcnuMlD9l1wTcquRTtg.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7OWCAYxKcU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Ysabeau Infant",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-PlzpqOkkV94kBTQVdX1EWI8p_dREcBXxP.ttf",
            "italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-NlzpqOkkV94kBTQVdX1EWI_p9fxU-AGxPlDU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYFo83Kpql.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Ysabeau Office",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIrapaZKhM9RuQIp8FmdYrPPMLOub458jGL.ttf",
            "italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIpapaZKhM9RuQIp8FmdYrPPPLMs7ob9yGLmfI.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbh6MvYC3t.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Ysabeau SC",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "cyrillic-ext",
            "greek",
            "latin",
            "latin-ext",
            "math",
            "symbols",
            "vietnamese"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/ysabeausc/v2/Noa36Uro3JCIKAbW46nMuLl7OCZ4ihE.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw98UmqFbYU.ttf",
        "axes": [
            {
                "tag": "wght",
                "start": 1,
                "end": 1000
            }
        ]
    },
    {
        "family": "Yuji Boku",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yujiboku/v6/P5sAzZybeNzXsA9xj1Fkjb2r2dgvJA.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yujiboku/v6/P5sAzZybeNzXsA9xj1FUjLev.ttf"
    },
    {
        "family": "Yuji Hentaigana Akari",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yujihentaiganaakari/v12/cY9bfiyVT0VB6QuhWKOrpr6z58lnb_zYFnLIRTzODYALaA.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yujihentaiganaakari/v12/cY9bfiyVT0VB6QuhWKOrpr6z58lnb_zYFnL4RDbK.ttf"
    },
    {
        "family": "Yuji Hentaigana Akebono",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yujihentaiganaakebono/v13/EJRGQhkhRNwM-RtitGUwh930GU_f5KAlkuL0wQy9NKXRzrrF.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yujihentaiganaakebono/v13/EJRGQhkhRNwM-RtitGUwh930GU_f5KAlkuL0wTy8PqE.ttf"
    },
    {
        "family": "Yuji Mai",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yujimai/v6/ZgNQjPxdJ7DEHrS0gC38hmHmNpCO.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yujimai/v6/ZgNQjPxdJ7DEHrS0gB39jGU.ttf"
    },
    {
        "family": "Yuji Syuku",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v6",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yujisyuku/v6/BngNUXdTV3vO6Lw5ApOPqPfgwqiA-Rk.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yujisyuku/v6/BngNUXdTV3vO6Lw5ApOPmPbqxg.ttf"
    },
    {
        "family": "Yusei Magic",
        "variants": [
            "regular"
        ],
        "subsets": [
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v14",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/yuseimagic/v14/yYLt0hbAyuCmoo5wlhPkpjHR-tdfcIT_.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/yuseimagic/v14/yYLt0hbAyuCmoo5wlhPkpgHQ8NM.ttf"
    },
    {
        "family": "ZCOOL KuaiLe",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2mp4b3.ttf"
    },
    {
        "family": "ZCOOL QingKe HuangYou",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v15",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIi_28c8bHWA.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mR4ive4.ttf"
    },
    {
        "family": "ZCOOL XiaoWei",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v14",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1YFKQHwyVd3U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1UFOaGw.ttf"
    },
    {
        "family": "Zain",
        "variants": [
            "200",
            "300",
            "regular",
            "700",
            "800",
            "900"
        ],
        "subsets": [
            "arabic",
            "latin"
        ],
        "version": "v2",
        "lastModified": "2024-09-04",
        "files": {
            "200": "https://fonts.gstatic.com/s/zain/v2/sykz-y9lm7soOBrstSq9-trEvlQ.ttf",
            "300": "https://fonts.gstatic.com/s/zain/v2/sykz-y9lm7soOH7vtSq9-trEvlQ.ttf",
            "regular": "https://fonts.gstatic.com/s/zain/v2/syk8-y9lm7soANLHkSKW5tM.ttf",
            "700": "https://fonts.gstatic.com/s/zain/v2/sykz-y9lm7soOG7otSq9-trEvlQ.ttf",
            "800": "https://fonts.gstatic.com/s/zain/v2/sykz-y9lm7soOHLrtSq9-trEvlQ.ttf",
            "900": "https://fonts.gstatic.com/s/zain/v2/sykz-y9lm7soOFbqtSq9-trEvlQ.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zain/v2/syk8-y9lm7soMNPNlQ.ttf"
    },
    {
        "family": "Zen Antique",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zenantique/v13/AYCPpXPnd91Ma_Zf-Ri2JXJq7PKP5Z_G.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenantique/v13/AYCPpXPnd91Ma_Zf-Ri2JUJr5vY.ttf"
    },
    {
        "family": "Zen Antique Soft",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v13",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zenantiquesoft/v13/DtV4JwqzSL1q_KwnEWMc_3xfgW6ihwBmkui5HNg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenantiquesoft/v13/DtV4JwqzSL1q_KwnEWMc_3xfgW6itwFslg.ttf"
    },
    {
        "family": "Zen Dots",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zendots/v12/XRXX3ICfm00IGoesQeaETM_FcCIG.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zendots/v12/XRXX3ICfm00IGoesQdaFRss.ttf"
    },
    {
        "family": "Zen Kaku Gothic Antique",
        "variants": [
            "300",
            "regular",
            "500",
            "700",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-08-07",
        "files": {
            "300": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cM9TarWJtyZyGU.ttf",
            "regular": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB21-g3RKjc4d7.ttf",
            "500": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22dU9DarWJtyZyGU.ttf",
            "700": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cc8jarWJtyZyGU.ttf",
            "900": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22ck8DarWJtyZyGU.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenkakugothicantique/v16/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22-h1xY.ttf"
    },
    {
        "family": "Zen Kaku Gothic New",
        "variants": [
            "300",
            "regular",
            "500",
            "700",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v16",
        "lastModified": "2024-08-07",
        "files": {
            "300": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqpdKaWTSTGlMyd8.ttf",
            "regular": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMYW2drQpDw0GjzrVNFf_valaDBcznOkjtiTWz5UGA.ttf",
            "500": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqs9LaWTSTGlMyd8.ttf",
            "700": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqodNaWTSTGlMyd8.ttf",
            "900": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqr9PaWTSTGlMyd8.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenkakugothicnew/v16/gNMYW2drQpDw0GjzrVNFf_valaDBcznOojpoSQ.ttf"
    },
    {
        "family": "Zen Kurenaido",
        "variants": [
            "regular"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zenkurenaido/v17/3XFsEr0515BK2u6UUptu_gWJZfz22PRLd0U.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenkurenaido/v17/3XFsEr0515BK2u6UUptu_gWJVf383A.ttf"
    },
    {
        "family": "Zen Loop",
        "variants": [
            "regular",
            "italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v9",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zenloop/v9/h0GrssK16UsnJwHsEK9zqwzX5vOG.ttf",
            "italic": "https://fonts.gstatic.com/s/zenloop/v9/h0GtssK16UsnJwHsEJ9xoQj14-OGJ0w.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenloop/v9/h0GrssK16UsnJwHsEJ9yoQg.ttf"
    },
    {
        "family": "Zen Maru Gothic",
        "variants": [
            "300",
            "regular",
            "500",
            "700",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v17",
        "lastModified": "2024-08-07",
        "files": {
            "300": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0XIpIxzW5b-RxT-6A8jWAtCp-cQWpCPJqa_ajlvw.ttf",
            "regular": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0SIpIxzW5b-RxT-6A8jWAtCp-k7UJmNLGG9A.ttf",
            "500": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0XIpIxzW5b-RxT-6A8jWAtCp-cGWtCPJqa_ajlvw.ttf",
            "700": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0XIpIxzW5b-RxT-6A8jWAtCp-cUW1CPJqa_ajlvw.ttf",
            "900": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0XIpIxzW5b-RxT-6A8jWAtCp-caW9CPJqa_ajlvw.ttf"
        },
        "category": "sans-serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenmarugothic/v17/o-0SIpIxzW5b-RxT-6A8jWAtCp-U7Ehi.ttf"
    },
    {
        "family": "Zen Old Mincho",
        "variants": [
            "regular",
            "500",
            "600",
            "700",
            "900"
        ],
        "subsets": [
            "cyrillic",
            "greek",
            "japanese",
            "latin",
            "latin-ext"
        ],
        "version": "v12",
        "lastModified": "2024-08-07",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss0ApVaYytLwxTqcxfMyBveyYb3g31S2s8p.ttf",
            "500": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss3ApVaYytLwxTqcxfMyBveyb4Dqlla8dMgPgBu.ttf",
            "600": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss3ApVaYytLwxTqcxfMyBveyb4vrVla8dMgPgBu.ttf",
            "700": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss3ApVaYytLwxTqcxfMyBveyb5LrFla8dMgPgBu.ttf",
            "900": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss3ApVaYytLwxTqcxfMyBveyb5zrlla8dMgPgBu.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zenoldmincho/v12/tss0ApVaYytLwxTqcxfMyBveybb2iXk.ttf"
    },
    {
        "family": "Zen Tokyo Zoo",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v7",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRv8a1uRWe9amg.ttf"
        },
        "category": "display",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRvwaxkQQ.ttf"
    },
    {
        "family": "Zeyada",
        "variants": [
            "regular"
        ],
        "subsets": [
            "latin"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zeyada/v19/11hAGpPTxVPUbgZDNGatWKaZ3g.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zeyada/v19/11hAGpPTxVPUbgZzNWyp.ttf"
    },
    {
        "family": "Zhi Mang Xing",
        "variants": [
            "regular"
        ],
        "subsets": [
            "chinese-simplified",
            "latin"
        ],
        "version": "v17",
        "lastModified": "2024-08-12",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g0actfektIJ0.ttf"
        },
        "category": "handwriting",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g4aYneQ.ttf"
    },
    {
        "family": "Zilla Slab",
        "variants": [
            "300",
            "300italic",
            "regular",
            "italic",
            "500",
            "500italic",
            "600",
            "600italic",
            "700",
            "700italic"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v11",
        "lastModified": "2024-09-04",
        "files": {
            "300": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYpEY2HSjWlhzbaw.ttf",
            "300italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CVHapXnp2fazkfg.ttf",
            "regular": "https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFWj0w_HyIRlE.ttf",
            "italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFa4ZfeM_74wlPZtksIFaj86-F6NVlFqdA.ttf",
            "500": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYskZ2HSjWlhzbaw.ttf",
            "500italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CDHepXnp2fazkfg.ttf",
            "600": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYuUe2HSjWlhzbaw.ttf",
            "600italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CIHCpXnp2fazkfg.ttf",
            "700": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYoEf2HSjWlhzbaw.ttf",
            "700italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CRHGpXnp2fazkfg.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFajw6-A.ttf"
    },
    {
        "family": "Zilla Slab Highlight",
        "variants": [
            "regular",
            "700"
        ],
        "subsets": [
            "latin",
            "latin-ext"
        ],
        "version": "v19",
        "lastModified": "2024-09-04",
        "files": {
            "regular": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtIY2DwSXlM.ttf",
            "700": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0TET4YmVF0Mb6.ttf"
        },
        "category": "serif",
        "kind": "webfonts#webfont",
        "menu": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxitJaWQ.ttf"
    }
]
