import {
    SearchField as AriaSearchField,
    SearchFieldProps as AriaSearchFieldProps,
    ValidationResult
} from 'react-aria-components'
import { Button } from '../Button'
import { Description, FieldError, FieldGroup, Input, Label } from '../Field'
import { composeTailwindRenderProps } from '../utils'
import { Clear, SearchRounded } from '@material-ui/icons'
import { IconCircleX, IconCircleXFilled, IconClearAll } from '@tabler/icons-react'

export interface SearchFieldProps extends AriaSearchFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function SearchField(
    { label, description, errorMessage, ...props }: SearchFieldProps
) {
    return (
        <AriaSearchField {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-1 min-w-[40px] text-gray-800')}>
            {label && <Label>{label}</Label>}
            <FieldGroup>
                <SearchRounded aria-hidden className="w-4 h-4 ml-2 text-gray-400 dark:text-zinc-400 forced-colors:text-[ButtonText] group-disabled:text-gray-200 dark:group-disabled:text-zinc-600 forced-colors:group-disabled:text-[GrayText]" />
                <Input className="[&::-webkit-search-cancel-button]:hidden text-gray-800 dark:text-gray-200" placeholder='Search' />
                <Button variant="icon" className="mr-1 group-empty:invisible">
                    <IconCircleXFilled size={20} aria-hidden className="text-gray-500" />
                </Button>
            </FieldGroup>
            {description && <Description>{description}</Description>}
            <FieldError>{errorMessage}</FieldError>
        </AriaSearchField>
    )
}
