import { Button } from "@/components/Button";
import { ChevronLeft, Palette, ViewQuilt, Widgets } from "@material-ui/icons";
import { useNavigate } from "@tanstack/react-router";
import { NavHeader, NavItem, NavMenu, NavSection } from "../Home";
import { useThema } from "@/stores/Thema";

import { Icon } from "@/components/Icon";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

export const ThemerIndex = observer(() => {
    const navigate = useNavigate()

    const thema = useThema()
    const groups = thema.groups()

    if (!thema.theme) return <></>

    return (
        <>
            <Button variant="link" onPress={() => navigate({ to: "/" })}>
                <ChevronLeft />
                SITE EDITOR
            </Button>
            <NavMenu className="flex flex-col gap-2" aria-label="Theme settings menu">
                {groups.map(g =>
                    <NavSection>
                        <NavHeader>
                            {g.icon && <Icon icon={g.icon} className="mr-2" size={20}/>}
                            {g.name}
                        </NavHeader>
                        {g.components.map(c =>
                            <NavItem href={`/themer/${c.id}`}>{c.name}</NavItem>
                        )}
                    </NavSection>
                )}
            </NavMenu>
        </>
    );
})