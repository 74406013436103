export const BodyFonts = [
    'Abel',
    'ABeeZee',
    'Andika',
    'Anonymous Pro',
    'Averia Sans Libre',
    'Barlow Semi Condensed',
    'Bitter',
    'Bourier Prime',
    'Cormorant Garamond',
    'Crimson Text',
    'Didact Gothic',
    'Dosis',
    'Fanwood Text',
    'Gothic A1',
    'Imprima',
    'Inconsolata',
    'Josefin Sans',
    'Josefin Slab',
    'Jost',
    'Lato',
    'Libre Baskerville',
    'Lora',
    'Maven Pro',
    'Montserrat',
    'Noto Sans',
    'Nunito',
    'Nunito Sans',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'Pontano Sans',
    'Questrial',
    'Quicksand',
    'Raleway',
    'Red Hat Text',
    'Roboto Condensed',
    'Roboto Mono',
    'Roboto Slab',
    'Roboto',
    'Rubik',
    'Ruda',
    'Scope One',
    'Shanti',
    'Source Sans Pro',
    'Source Code Pro',
    'Space Mono',
    'Stylish',
    'Sunflower',
    'Tenor Sans',
    'Trykker',
    'Work Sans',
]