import { Button } from "@/components/Button"
import { Form } from "@/components/Form/Form"
import { Icon } from "@/components/Icon"
import { Modal } from "@/components/Modal/Modal"
import { TextField } from "@/components/TextField"
import { useThema } from "@/stores/Thema"
import { IconUpload } from "@tabler/icons-react"
import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { chain } from "react-aria"
import { Dialog, DialogTrigger, Heading, Text } from "react-aria-components"

interface PublishButtonProps {
    id?: string
}

export const PublishButton = ({id}: PublishButtonProps) => {
    const thema = useThema()
    const queryClient = useQueryClient()
    const publishMutation = useMutation({
        mutationFn: thema.publish,
        scope: {id: "publish"},
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["history"]})
    })

    const isScopePending = useIsMutating({
        predicate: m => m.options.scope?.id == "publish"
    }) > 0

    const {isPending} = publishMutation

    const publish = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const evt = e.nativeEvent as SubmitEvent
        const data = Object.fromEntries(new FormData(e.currentTarget)) as any

        evt.submitter?.setAttribute("disabled", "true")
        publishMutation.mutate({versionId: id, name: data.name})
    }

    return (
        <DialogTrigger>
            <Button isLoading={isPending} isDisabled={isScopePending}>
                <Text>PUBLISH</Text>
                <Icon icon={IconUpload}/>
            </Button>
            <Modal isDismissable className="w-[650px] max-h-[400px]">
                <Dialog className="outline-none max-h-[inherit] p-8 dark">
                    {({close}) => <>
                        <Heading className="text-xl mb-2">Publish</Heading>
                        <Form onSubmit={chain(publish, close)}>
                            <TextField label="Version Name" name="name" />
                            <div className="flex gap-2 justify-end">
                                <Button onPress={close} variant="link">Cancel</Button>
                                <Button type="submit" className="flex">Publish</Button>
                            </div>
                        </Form>
                    </>}
                </Dialog>
            </Modal>
        </DialogTrigger>
    )
}