import { observer } from "mobx-react-lite"
import { SideBar } from "../SideBar"
import { Outlet } from "@tanstack/react-router"

export const SettingsSideBar = observer(() => {
    return <>
        <SideBar>
            <Outlet/>
        </SideBar>
    </>
})