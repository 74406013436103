import { TokenType, useThema } from "@/stores/Thema"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { observer } from "mobx-react-lite"
import { HColorPicker } from "@/components/ColorPicker/ColorPicker"
import { MediaSelect } from "@/features/themer/components/MediaSelect"
import { FontSelect } from "@/components/widgets/FontSelect/FontSelect"
import { HeadingFonts } from "../lib/typography/HeadingFonts"

interface GroupProps {
    component: string
}

export const Group = observer((props: GroupProps) => {
    const thema = useThema()
    const comp = thema.component(props.component)

    if (!comp || !thema.theme) return <></>

    return (
        <Settings title={comp.name.toUpperCase()}>
            {comp.tokens.map(t => {
                if (t.type == TokenType.Color)
                    return (
                        <SettingsSection>
                            <HColorPicker isResettable label={t.name} defaultValue={t.value ?? undefined} onChange={v => {t.value = v?.toString("rgb") ?? null; thema.update()}}/>
                        </SettingsSection>
                    )
                else if (t.type == TokenType.Image)
                    return (
                        <SettingsSection label={t.name}>
                            <MediaSelect url={t.value ?? null} fit="contain" onChange={v => {t.value = v ?? null; thema.update()}}/>
                        </SettingsSection>
                    )
                else if (t.type == TokenType.FontFammily)
                    return (
                        <SettingsSection>
                            <FontSelect label={t.name} fonts={HeadingFonts.map(f => ({family: f}))} selectedKey={t.value ?? null} onSelectionChange={key => {t.value = key?.toString() ?? null; thema.update()}}/>
                        </SettingsSection>
                    )
                else
                    return <></>
            })}
        </Settings>
    );
})