import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  DesignerOptionalParams,
  LoginRequest,
  AuthLoginOptionalParams,
  AuthLoginResponse,
  AuthLogoutOptionalParams,
  MediaFilesListOptionalParams,
  MediaFilesListResponse,
  MediaUploadTokenGetOptionalParams,
  MediaUploadTokenGetResponse,
  ThemeSpecGetOptionalParams,
  ThemeSpecGetResponse,
  ThemeDraftGetOptionalParams,
  ThemeDraftGetResponse,
  CreateThemeReqDto,
  ThemeDraftCreateOptionalParams,
  ThemeDraftCreateResponse,
  ThemeGetOptionalParams,
  ThemeGetResponse,
  ThemeListOptionalParams,
  ThemeListResponse,
  ThemeDraftSaveOptionalParams,
  ThemeDraftSaveResponse,
  PublishReqDto,
  ThemePublishOptionalParams,
  ThemePublishResponse,
} from "./models";

export class Designer extends coreClient.ServiceClient {
  $host: string;
  xHrSiteId: number;

  /**
   * Initializes a new instance of the Designer class.
   * @param $host server parameter
   * @param xHrSiteId The Site ID
   * @param options The parameter options
   */
  constructor(
    $host: string,
    xHrSiteId: number,
    options?: DesignerOptionalParams,
  ) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }
    if (xHrSiteId === undefined) {
      throw new Error("'xHrSiteId' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: DesignerOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-designer/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
    this.xHrSiteId = xHrSiteId;
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  authLogin(
    body: LoginRequest,
    options?: AuthLoginOptionalParams,
  ): Promise<AuthLoginResponse> {
    return this.sendOperationRequest({ body, options }, authLoginOperationSpec);
  }

  /** @param options The options parameters. */
  authLogout(options?: AuthLogoutOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, authLogoutOperationSpec);
  }

  /** @param options The options parameters. */
  mediaFilesList(
    options?: MediaFilesListOptionalParams,
  ): Promise<MediaFilesListResponse> {
    return this.sendOperationRequest({ options }, mediaFilesListOperationSpec);
  }

  /**
   * @param filename
   * @param options The options parameters.
   */
  mediaUploadTokenGet(
    filename: string,
    options?: MediaUploadTokenGetOptionalParams,
  ): Promise<MediaUploadTokenGetResponse> {
    return this.sendOperationRequest(
      { filename, options },
      mediaUploadTokenGetOperationSpec,
    );
  }

  /** @param options The options parameters. */
  themeSpecGet(
    options?: ThemeSpecGetOptionalParams,
  ): Promise<ThemeSpecGetResponse> {
    return this.sendOperationRequest({ options }, themeSpecGetOperationSpec);
  }

  /** @param options The options parameters. */
  themeDraftGet(
    options?: ThemeDraftGetOptionalParams,
  ): Promise<ThemeDraftGetResponse> {
    return this.sendOperationRequest({ options }, themeDraftGetOperationSpec);
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  themeDraftCreate(
    body: CreateThemeReqDto,
    options?: ThemeDraftCreateOptionalParams,
  ): Promise<ThemeDraftCreateResponse> {
    return this.sendOperationRequest(
      { body, options },
      themeDraftCreateOperationSpec,
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  themeGet(
    id: string,
    options?: ThemeGetOptionalParams,
  ): Promise<ThemeGetResponse> {
    return this.sendOperationRequest({ id, options }, themeGetOperationSpec);
  }

  /** @param options The options parameters. */
  themeList(options?: ThemeListOptionalParams): Promise<ThemeListResponse> {
    return this.sendOperationRequest({ options }, themeListOperationSpec);
  }

  /**
   * @param name
   * @param options The options parameters.
   */
  themeDraftSave(
    name: string,
    options?: ThemeDraftSaveOptionalParams,
  ): Promise<ThemeDraftSaveResponse> {
    return this.sendOperationRequest(
      { name, options },
      themeDraftSaveOperationSpec,
    );
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  themePublish(
    body: PublishReqDto,
    options?: ThemePublishOptionalParams,
  ): Promise<ThemePublishResponse> {
    return this.sendOperationRequest(
      { body, options },
      themePublishOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const authLoginOperationSpec: coreClient.OperationSpec = {
  path: "/auth/login",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UserDto,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const authLogoutOperationSpec: coreClient.OperationSpec = {
  path: "/auth/auth/logout",
  httpMethod: "POST",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.xHrSiteId],
  serializer,
};
const mediaFilesListOperationSpec: coreClient.OperationSpec = {
  path: "/media",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "FileMeta" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const mediaUploadTokenGetOperationSpec: coreClient.OperationSpec = {
  path: "/media/{filename}/token",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.BlobUploadToken,
    },
  },
  urlParameters: [Parameters.$host, Parameters.filename],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themeSpecGetOperationSpec: coreClient.OperationSpec = {
  path: "/theme/spec",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeSpec,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themeDraftGetOperationSpec: coreClient.OperationSpec = {
  path: "/theme/draft",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeVersionDto,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  queryParameters: [Parameters.id, Parameters.search],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themeDraftCreateOperationSpec: coreClient.OperationSpec = {
  path: "/theme/draft",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeVersionDto,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const themeGetOperationSpec: coreClient.OperationSpec = {
  path: "/theme/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeVersionDto,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themeListOperationSpec: coreClient.OperationSpec = {
  path: "/theme",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ThemeVersionDto" },
          },
        },
      },
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  queryParameters: [Parameters.search],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themeDraftSaveOperationSpec: coreClient.OperationSpec = {
  path: "/theme/{name}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeVersionDto,
    },
  },
  urlParameters: [Parameters.$host, Parameters.name],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const themePublishOperationSpec: coreClient.OperationSpec = {
  path: "/theme/publish",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ThemeVersionDto,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
