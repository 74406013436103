import { ComboBox, ComboBoxProps } from "@/components/ComboBox/ComboBox"
import { SelectItem } from "@/components/Select"

type FontOption = {family: string}

export interface FontSelectProps extends Omit<ComboBoxProps<FontOption>,'children'> {
    fonts: {family: string}[]
    label?: string
}

export const FontSelect = ({fonts, ...props}: FontSelectProps) => {
    console.log("Font select render", props)
    return (
        <ComboBox {...props} items={fonts} canClear>
            {item => <SelectItem id={item.family} style={{fontFamily: item.family}}>{item.family}</SelectItem>}
        </ComboBox>
    )
}