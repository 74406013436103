import { useUser } from "@/stores/Auth"
import { usePreviewStore } from "@/stores/Preview"
import { useThema } from "@/stores/Thema"
import clsx from "clsx"
import { computed } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useRef, useState } from "react"


const sizes = {
    'Computer': 'w-full h-full',
    'Tablet': 'w-[834px] aspect-[7/10] scale-75 absolute',
    'Mobile': 'w-[412px] aspect-[9/18]',
}

export const OFrame = observer(() => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef<HTMLIFrameElement>(null)
    const user = useUser()
    const preview = usePreviewStore()

    const thema = useThema()

    const css = useMemo(() => computed(() => thema.css), []).get()

    const url = user?.selectedSite.url
    if (!url) return <></>

    useEffect(() => {
        if (!loaded || css == "") return
        console.log("Posting css")
        ref.current?.contentWindow?.postMessage({
            event: "sb-css-update",
            css: css
        }, url)
    },
    [loaded, css])

    useEffect(() => {
        console.log("Preview mount")
    }, [])

    return (
        <iframe
            onLoad={() => {
                console.log("Iframe loaded")
                setLoaded(true)
            }}
            className={clsx("bg-white min-h-0 min-w-0", sizes[preview.device])}
            ref={ref}
            src={`${url}?isDesigner=true`}
        />
    )
})