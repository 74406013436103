import { WebFonts } from "./WebFonts"

export class WebFont {
    spec: IWebFont

    constructor(spec: IWebFont) {
        this.spec = spec
    }

    static FromFamily(family: string): Nullable<WebFont> {
        const fontSpec = WebFonts.find(wf => wf.family == family)
        if (!fontSpec) return null
        return new WebFont(fontSpec)
    }

    get fontFamily() {
        const {family, category} = this.spec
        return `"${family}", ${category}`
    }

    get familyQuery() {
        const {family, variants, axes} = this.spec

        let fontStr = ""
        fontStr += family.split(" ").join("+")
        const weightRange = axes?.find(a => a.tag == "wght")

        const variantSet = new Set<string>
        const tuples: [string, number?][] = []

        const defaultWeight =
            weightRange
                ? `${weightRange.start}..${weightRange.end}`
                : "400"

        for (const variant of variants ?? []) {
            if (variant == "regular") tuples.push([defaultWeight])
            if (Number(variant)) tuples.push([variant])
            if (variant == "italic") {
                variantSet.add("ital")
                tuples.push([defaultWeight, 1])
            }

            const match = variant.match(/\d+/)
            if (match && variant.endsWith("italic")) {
                variantSet.add("ital")
                tuples.push([match[0], 1])
            }
        }

        variantSet.add("wght")

        fontStr += `:${[...variantSet.values()].join(",")}@`

        const renderedTuples = tuples.map(t => {
            const r = Array(variantSet.size) as any[]
            [...variantSet.values()].forEach((e, idx) => {
                r[variantSet.size - 1 - idx] = t[idx] ?? 0
            })
            return r.join(",")
        })

        fontStr += `${renderedTuples.sort().join(";")}`
        return fontStr
    }
}

export interface IWebFont {
    family: string
    variants: string[]
    category: string
    axes?: IWebFontAxes[]
}

export interface IWebFontAxes {
    tag: string
    start: number
    end: number
}
