export const HeadingFonts = [
    'Abel',
    'ABeeZee',
    'Abhaya Libre',
    'Abril Fatface',
    'Aclonica',
    'Acme',
    'Actor',
    'Adamina',
    'Advent Pro',
    'Aladin',
    'Alata',
    'Alatsi',
    'Aldrich',
    'Alef',
    'Alegreya',
    'Alegreya SC',
    'Alegreya Sans',
    'Alegreya Sans SC',
    'Aleo',
    'Alex Brush',
    'Alfa Slab One',
    'Alice',
    'Allerta',
    'Allerta Stencil',
    'Almendra',
    'Amarante',
    'Amaranth',
    'Amatic SC',
    'Amiko',
    'Amita',
    'Anaheim',
    'Andada',
    'Andika',
    'Annie Use Your Telescope',
    'Anonymous Pro',
    'Antic',
    'Antic Didone',
    'Antic Slab',
    'Anton',
    'Arapey',
    'Arbutus',
    'Arbutus Slab',
    'Architects Daughter',
    'Archivo',
    'Archivo Black',
    'Archivo Narrow',
    'Aref Ruqaa',
    'Arima Madurai',
    'Arimo',
    'Arizonia',
    'Armata',
    'Arsenal',
    'Artifika',
    'Arvo',
    'Arya',
    'Asap',
    'Asap Condensed',
    'Asar',
    'Assistant',
    'Asul',
    'Atma',
    'Audiowide',
    'Autour One',
    'Average',
    'Average Sans',
    'Averia Sans Libre',
    'Averia Serif Libre',
    'Bad Script',
    'Balsamiq Sans',
    'Balthazar',
    'Bangers',
    'Barlow',
    'Barlow Condensed',
    'Barlow Semi Condensed',
    'Barriecito',
    'Barrio',
    'Baskervville',
    'Baumans',
    'Bebas Neue',
    'Belgrano',
    'Bellefair',
    'Belleza',
    'Bellota',
    'Bellota Text',
    'Beth Ellen',
    'Bevan',
    'Big Shoulders Text',
    'Bigshot One',
    'Bilbo',
    'BioRhyme',
    'BioRhyme Expanded',
    'Biryani',
    'Bitter',
    'Black Ops One',
    'Bonbon',
    'Boogaloo',
    'Bowlby One',
    'Bowlby One SC',
    'Brawler',
    'Bree Serif',
    'Bubblegum Sans',
    'Bubbler One',
    'Buenard',
    'Bungee',
    'Cabin',
    'Cabin Condensed',
    'Cabin Sketch',
    'Caesar Dressing',
    'Cagliostro',
    'Cairo',
    'Caladea',
    'Calistoga',
    'Calligraffitti',
    'Cambay',
    'Cambo',
    'Candal',
    'Cantarell',
    'Cantata One',
    'Cantora One',
    'Capriola',
    'Cardo',
    'Carme',
    'Carrois Gothic',
    'Carrois Gothic SC',
    'Carter One',
    'Catamaran',
    'Caudex',
    'Caveat',
    'Caveat Brush',
    'Cedarville Cursive',
    'Ceviche One',
    'Chakra Petch',
    'Changa One',
    'Chango',
    'Charmonman',
    'Chau Philomene One',
    'Chela One',
    'Chelsea Market',
    'Cherry Cream Soda',
    'Cherry Swash',
    'Chewy',
    'Chicle',
    'Chilanka',
    'Chivo',
    'Chonburi',
    'Cinzel',
    'Cinzel Decorative',
    'Clicker Script',
    'Coda',
    'Coda Caption',
    'Coiny',
    'Combo',
    'Comfortaa',
    'Comic Neue',
    'Coming Soon',
    'Commissioner',
    'Concert One',
    'Contrail One',
    'Convergence',
    'Cookie',
    'Copse',
    'Corben',
    'Cormorant',
    'Cormorant Infant',
    'Cormorant SC',
    'Courgette',
    'Courier Prime',
    'Cousine',
    'Coustard',
    'Covered By Your Grace',
    'Crafty Girls',
    'Creepster',
    'Crete Round',
    'Crimson Pro',
    'Crimson Text',
    'Croissant One',
    'Crushed',
    'Cuprum',
    'Cute Font',
    'Cutive',
    'Cutive Mono',
    'DM Mono',
    'DM Serif Text',
    'Damion',
    'Dancing Script',
    'David Libre',
    'Dawning of a New Day',
    'Days One',
    'Dekko',
    'Delius',
    'Della Respira',
    'Didact Gothic',
    'Do Hyeon',
    'Dokdo',
    'Donegal One',
    'Dosis',
    'Dr Sugiyama',
    'East Sea Dokdo',
    'Economica',
    'Eczar',
    'El Messiri',
    'Electrolize',
    'Elsie',
    'Elsie Swash Caps',
    'Emilys Candy',
    'Engagement',
    'Epilogue',
    'Erica One',
    'Euphoria Script',
    'Ewert',
    'Exo',
    'Expletus Sans',
    'Fahkwang',
    'Fanwood Text',
    'Farro',
    'Farsan',
    'Fascinate',
    'Fasthand',
    'Fauna One',
    'Federant',
    'Federo',
    'Fenix',
    'Finger Paint',
    'Fjalla One',
    'Flamenco',
    'Fondamento',
    'Fontdiner Swanky',
    'Forum',
    'Francois One',
    'Frank Ruhl Libre',
    'Fredericka the Great',
    'Fredoka One',
    'Fresca',
    'Fugaz One',
    'Gabriela',
    'Gaegu',
    'Gafata',
    'Galada',
    'Galdeano',
    'Galindo',
    'Gamja Flower',
    'Gayathri',
    'Gelasio',
    'Gentium Basic',
    'Gilda Display',
    'Girassol',
    'Give You Glory',
    'Glass Antiqua',
    'Glegoo',
    'Gloria Hallelujah',
    'Goblin One',
    'Gochi Hand',
    'Gorditas',
    'Gothic A1',
    'Gotu',
    'Goudy Bookletter 1911',
    'Graduate',
    'Grand Hotel',
    'Grandstander',
    'Gravitas One',
    'Great Vibes',
    'Grenze',
    'Grenze Gotisch',
    'Griffy',
    'Gruppo',
    'Gudea',
    'Gupter',
    'Habibi',
    'Halant',
    'Hammersmith One',
    'Handlee',
    'Hanuman',
    'Happy Monkey',
    'Harmattan',
    'Headland One',
    'Heebo',
    'Henny Penny',
    'Hepta Slab',
    'Hi Melody',
    'Holtwood One SC',
    'Homemade Apple',
    'Homenaje',
    'Ibarra Real Nova',
    'Iceberg',
    'Iceland',
    'Imprima',
    'Inconsolata',
    'Inder',
    'Indie Flower',
    'Inika',
    'Inknut Antiqua',
    'Irish Grover',
    'Italiana',
    'Italianno',
    'Itim',
    'Jacques Francois',
    'Jacques Francois Shadow',
    'Jaldi',
    'Jim Nightshade',
    'Jockey One',
    'Josefin Sans',
    'Josefin Slab',
    'Jost',
    'Joti One',
    'Jua',
    'Judson',
    'Julee',
    'Julius Sans One',
    'Junge',
    'Jura',
    'Just Another Hand',
    'Just Me Again Down Here',
    'Kalam',
    'Kaushan Script',
    'Kavivanar',
    'Kavoon',
    'Kelly Slab',
    'Kite One',
    'Knewave',
    'KoHo',
    'Kodchasan',
    'Kotta One',
    'Kranky',
    'Kreon',
    'Kristi',
    'Krona One',
    'Krub',
    'Kufam',
    'Kumar One',
    'Kumbh Sans',
    'Kurale',
    'La Belle Aurore',
    'Lacquer',
    'Laila',
    'Lalezar',
    'Lancelot',
    'Lato',
    'Leckerli One',
    'Lekton',
    'Lemon',
    'Lemonada',
    'Lexend Deca',
    'Libre Baskerville',
    'Libre Caslon Display',
    'Libre Caslon Text',
    'Libre Franklin',
    'Life Savers',
    'Lilita One',
    'Lily Script One',
    'Limelight',
    'Linden Hill',
    'Lobster',
    'Lobster Two',
    'Londrina Outline',
    'Londrina Shadow',
    'Londrina Sketch',
    'Londrina Solid',
    'Long Cang',
    'Lora',
    'Love Ya Like A Sister',
    'Loved by the King',
    'Lovers Quarrel',
    'Luckiest Guy',
    'Lusitana',
    'Lustria',
    'Macondo Swash Caps',
    'Maiden Orange',
    'Maitree',
    'Major Mono Display',
    'Mako',
    'Mali',
    'Manrope',
    'Mansalva',
    'Marcellus',
    'Marcellus SC',
    'Marck Script',
    'Margarine',
    'Marko One',
    'Martel',
    'Martel Sans',
    'Marvel',
    'Mate',
    'Mate SC',
    'Maven Pro',
    'McLaren',
    'Meddon',
    'Meera Inimai',
    'Meie Script',
    'Merienda One',
    'Merriweather',
    'Merriweather Sans',
    'Metamorphous',
    'Metrophobic',
    'Michroma',
    'Milonga',
    'Miltonian Tattoo',
    'Mina',
    'Miniver',
    'Miriam Libre',
    'Mirza',
    'Mitr',
    'Modern Antiqua',
    'Mogra',
    'Molengo',
    'Monda',
    'Montaga',
    'Montez',
    'Montserrat',
    'Montserrat Alternates',
    'Montserrat Subrayada',
    'Mountains of Christmas',
    'Mouse Memoirs',
    'Mr Bedfort',
    'Mulish',
    'MuseoModerno',
    'Mystery Quest',
    'NTR',
    'Neucha',
    'Neuton',
    'New Rocker',
    'News Cycle',
    'Niconne',
    'Niramit',
    'Nixie One',
    'Norican',
    'Notable',
    'Nothing You Could Do',
    'Noticia Text',
    'Noto Sans',
    'Noto Serif',
    'Numans',
    'Nunito',
    'Nunito Sans',
    'Odibee Sans',
    'Offside',
    'Old Standard TT',
    'Oldenburg',
    'Oleo Script',
    'Open Sans',
    'Open Sans Condensed',
    'Oranienbaum',
    'Orbitron',
    'Oregano',
    'Orienta',
    'Original Surfer',
    'Oswald',
    'Over the Rainbow',
    'Overlock',
    'Overlock SC',
    'Overpass',
    'Overpass Mono',
    'Ovo',
    'Oxygen',
    'Oxygen Mono',
    'PT Mono',
    'PT Sans',
    'PT Serif',
    'Pacifico',
    'Palanquin Dark',
    'Pangolin',
    'Paprika',
    'Parisienne',
    'Passero One',
    'Passion One',
    'Pathway Gothic One',
    'Patrick Hand',
    'Patrick Hand SC',
    'Pattaya',
    'Paytone One',
    'Peralta',
    'Permanent Marker',
    'Petit Formal Script',
    'Petrona',
    'Philosopher',
    'Piazzolla',
    'Pinyon Script',
    'Pirata One',
    'Plaster',
    'Play',
    'Playball',
    'Playfair Display',
    'Playfair Display SC',
    'Poiret One',
    'Poller One',
    'Poly',
    'Pompiere',
    'Pontano Sans',
    'Poor Story',
    'Poppins',
    'Prata',
    'Press Start 2P',
    'Princess Sofia',
    'Prosto One',
    'Proza Libre',
    'Public Sans',
    'Puritan',
    'Purple Purse',
    'Quando',
    'Quantico',
    'Quattrocento',
    'Quattrocento Sans',
    'Questrial',
    'Quicksand',
    'Quintessential',
    'Racing Sans One',
    'Radley',
    'Rakkas',
    'Raleway',
    'Rambla',
    'Rammetto One',
    'Ranchers',
    'Rancho',
    'Ranga',
    'Rationale',
    'Ravi Prakash',
    'Recursive',
    'Red Hat Display',
    'Red Hat Text',
    'Redressed',
    'Reem Kufi',
    'Reenie Beanie',
    'Revalia',
    'Rhodium Libre',
    'Ribeye',
    'Ribeye Marrow',
    'Righteous',
    'Risque',
    'Roboto',
    'Roboto Condensed',
    'Roboto Mono',
    'Roboto Slab',
    'Rochester',
    'Rock Salt',
    'Rokkitt',
    'Romanesco',
    'Ropa Sans',
    'Rosario',
    'Rouge Script',
    'Rowdies',
    'Rozha One',
    'Rubik',
    'Rubik Mono One',
    'Ruda',
    'Rufina',
    'Ruge Boogie',
    'Ruluko',
    'Rum Raisin',
    'Russo One',
    'Ruthie',
    'Rye',
    'Sacramento',
    'Sahitya',
    'Sail',
    'Saira',
    'Salsa',
    'Sanchez',
    'Sancreek',
    'Sansita',
    'Sansita Swashed',
    'Sarina',
    'Satisfy',
    'Schoolbell',
    'Scope One',
    'Seaweed Script',
    'Secular One',
    'Sedgwick Ave',
    'Sedgwick Ave Display',
    'Sen',
    'Sevillana',
    'Shadows Into Light Two',
    'Shanti',
    'Shojumaru',
    'Short Stack',
    'Shrikhand',
    'Sigmar One',
    'Signika',
    'Simonetta',
    'Single Day',
    'Sintony',
    'Sirin Stencil',
    'Slackey',
    'Smokum',
    'Smythe',
    'Sniglet',
    'Snippet',
    'Snowburst One',
    'Sofadi One',
    'Sofia',
    'Solway',
    'Sonsie One',
    'Sora',
    'Sorts Mill Goudy',
    'Source Code Pro',
    'Source Sans Pro',
    'Source Serif Pro',
    'Space Mono',
    'Spartan',
    'Special Elite',
    'Spicy Rice',
    'Spinnaker',
    'Spirax',
    'Sriracha',
    'Staatliches',
    'Stalemate',
    'Stardos Stencil',
    'Stint Ultra Condensed',
    'Stint Ultra Expanded',
    'Stoke',
    'Stylish',
    'Sue Ellen Francisco',
    'Suez One',
    'Sulphur Point',
    'Sumana',
    'Sunflower',
    'Sunshiney',
    'Swanky and Moo Moo',
    'Syncopate',
    'Tangerine',
    'Tauri',
    'Telex',
    'Tenor Sans',
    'Text Me One',
    'Thasadith',
    'The Girl Next Door',
    'Tienne',
    'Tillana',
    'Timmana',
    'Tinos',
    'Titan One',
    'Trirong',
    'Trispace',
    'Trocchi',
    'Trochut',
    'Trykker',
    'Ultra',
    'Unica One',
    'Unlock',
    'Unna',
    'VT323',
    'Vampiro One',
    'Vast Shadow',
    'Vesper Libre',
    'Viaoda Libre',
    'Vibes',
    'Vibur',
    'Vidaloka',
    'Viga',
    'Vollkorn',
    'Vollkorn SC',
    'Voltaire',
    'Waiting for the Sunrise',
    'Walter Turncoat',
    'Wendy One',
    'Work Sans',
    'Yanone Kaffeesatz',
    'Yatra One',
    'Yellowtail',
    'Yeseva One',
    'Yesteryear',
    'Zeyada',
    'Zilla Slab',
    'Zil',
]