import type {Color, ColorPickerProps} from 'react-aria-components'
import {Button as RACButton, ColorPicker, Dialog, parseColor} from 'react-aria-components'
// import {MyColorSwatch} from './ColorSwatch';
import {HColorArea} from './ColorArea'
// import {MyColorSlider} from './ColorSlider';
// import {MyColorField} from './ColorField';

import './ColorPicker.css'
import { HColorField } from './ColorField'
import { Popover } from '../Popover/Popover'
import { HColorSwatch } from './ColorSwatch'
import { HColorSlider } from './ColorSlider'
import { useRef, useState } from 'react'
import { Label } from '../Field/Field'
import { Button } from '../Button/Button'

interface MyColorPickerProps extends Omit<ColorPickerProps, "onChange"> {
  label?: string;
  children?: React.ReactNode;
  isResettable?: boolean
  onChange?: (value: Color|undefined) => void
}

export function HColorPicker({ label, children, isResettable, defaultValue, onChange, ...props }: MyColorPickerProps) {
    const [initialDefault, setInitialDefault] = useState(defaultValue)
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState(defaultValue ? parseColor(defaultValue.toString()) : undefined)
    const ref = useRef(null)

    const onOpenChange = (val: boolean) => {
        setOpen(val)
        if (!val) onChange?.(color)
    }

    if (initialDefault != defaultValue) {
        setInitialDefault(defaultValue)
        setColor(defaultValue ? parseColor(defaultValue.toString()) : undefined)
    }

    const handleChange = (color: Color) => {
        if (color && typeof(color) === "object" && "alpha" in color)
            color.alpha = 1
        setColor(color)
    }


    return (
        <ColorPicker {...props} value={color} onChange={handleChange}>
            <Label>{label}</Label>
            <RACButton ref={ref} className="color-picker my-2" onPress={() => setOpen(true)}>
                <HColorSwatch hasColor={!!color} />
            </RACButton>
            <Popover triggerRef={ref} placement="bottom start" isOpen={open} onOpenChange={onOpenChange}>
                <Dialog className="color-picker-dialog" aria-label='Color picker'>
                    {children || (
                        <>
                            <HColorArea
                                colorSpace="hsb"
                                xChannel="saturation"
                                yChannel="brightness"
                            />
                            <HColorSlider colorSpace="hsb" channel="hue"/>
                            <HColorField value={color} label='Color' />
                            <Button onPress={() => onOpenChange(false)}>
                            UPDATE
                            </Button>
                        </>
                    )}
                </Dialog>
            </Popover>
            {isResettable && <Button variant='outline' className="w-full" onPress={() => onChange?.(undefined)}>
                Reset
            </Button>}
        </ColorPicker>
    )
}

<HColorPicker label="Fill color" defaultValue="#f00" />