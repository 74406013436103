import { Button } from "@/components/Button"
import { Form } from "@/components/Form/Form"
import { Modal } from "@/components/Modal/Modal"
import { TextField } from "@/components/TextField"
import { useThema } from "@/stores/Thema"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { chain } from "react-aria"
import { Dialog, DialogTrigger, Heading, Text } from "react-aria-components"


export const SaveButton = () => {
    const thema = useThema()
    const queryClient = useQueryClient()
    const publishMutation = useMutation({
        mutationFn: thema.save,
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["history"]})
    })

    const {isPending} = publishMutation

    const publish = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const evt = e.nativeEvent as SubmitEvent
        const data = Object.fromEntries(new FormData(e.currentTarget)) as any

        evt.submitter?.setAttribute("disabled", "true")
        publishMutation.mutate(data.name)
    }

    return (
        <DialogTrigger>
            <Button variant="link" isLoading={isPending} isDisabled={isPending}>
                <Text>SAVE VERSION</Text>
            </Button>
            <Modal isDismissable className="w-[650px] max-h-[400px]">
                <Dialog className="outline-none max-h-[inherit] p-8 dark">
                    {({close}) => <>
                        <Heading className="text-xl mb-2">Save Version</Heading>
                        <Form onSubmit={chain(publish, close)}>
                            <TextField label="Version Name" name="name" />
                            <div className="flex gap-2 justify-end">
                                <Button onPress={close} variant="link">CANCEL</Button>
                                <Button type="submit" className="flex">SAVE</Button>
                            </div>
                        </Form>
                    </>}
                </Dialog>
            </Modal>
        </DialogTrigger>
    )
}