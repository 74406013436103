import { Themer } from '@/features/themer/Themer'
import { FontLoader } from '@/components/widgets/FontLoader/FontLoader'
import { createFileRoute } from '@tanstack/react-router'
import { observer } from 'mobx-react-lite'
import { useThema } from '@/stores/Thema'
import { useEffect } from 'react'

export const ThemerRoute = observer(() => {
    const thema = useThema()

    useEffect(() => {
        thema.load()
    }, [])

    return (
        <div className='flex h-full w-full'>
            <FontLoader/>
            <Themer/>
        </div>
    )
})

export const Route = createFileRoute('/_themer')({
    component: ThemerRoute
})