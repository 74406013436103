import * as coreClient from "@azure/core-client";

export interface LoginRequest {
  username: string;
  password: string;
}

export interface UserDto {
  userId: number;
  username: string;
  firstName?: string;
  lastName?: string;
  siteId: number;
  sites: SiteDto[];
}

export interface SiteDto {
  id: number;
  name?: string;
  url: string;
  copyright?: string;
  footer?: string;
  layout?: Layout;
}

export interface Layout {
  menu?: string;
  preMods?: string;
}

export interface FileMeta {
  name?: string;
  uri?: string;
  lastModified?: Date;
  default?: boolean;
}

export interface BlobUploadToken {
  uri: string;
  sasToken: string;
  blobUri: string;
  container: string;
}

export interface ThemeSpec {
  version: string;
  groups: GroupSpec[];
  components: ComponentSpec[];
}

export interface GroupSpec {
  id: string;
  name: string;
  icon?: string;
  components: string[];
}

export interface ComponentSpec {
  id: string;
  name: string;
  tokens: TokenSpec[];
}

export interface TokenSpec {
  type: TokenType;
  id: string;
  name: string;
  description?: string;
  isRequired: boolean;
  value?: string;
}

export interface ThemeVersionDto {
  siteId: number;
  id: string;
  status: ThemeVersionStatus;
  name: string;
  createdAt: Date;
  publishedAt: Date;
  theme: ThemeDto;
}

export interface ThemeDto {
  specVersion: string;
  components: ComponentDto[];
}

export interface ComponentDto {
  id: string;
  tokens: TokenDto[];
}

export interface TokenDto {
  type: TokenType;
  id: string;
  value: string | null;
}

export interface ProblemDetails {
  /** Describes unknown properties. The value of an unknown property can be of "any" type. */
  [property: string]: any;
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
}

export interface CreateThemeReqDto {
  name?: string;
  theme: ThemeDto;
}

export interface PublishReqDto {
  name: string;
  versionId?: string;
}

/** Known values of {@link TokenType} that the service accepts. */
export enum KnownTokenType {
  /** Color */
  Color = "Color",
  /** Image */
  Image = "Image",
  /** String */
  String = "String",
  /** Number */
  Number = "Number",
  /** FontFamily */
  FontFamily = "FontFamily",
}

/**
 * Defines values for TokenType. \
 * {@link KnownTokenType} can be used interchangeably with TokenType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Color** \
 * **Image** \
 * **String** \
 * **Number** \
 * **FontFamily**
 */
export type TokenType = string;

/** Known values of {@link ThemeVersionStatus} that the service accepts. */
export enum KnownThemeVersionStatus {
  /** Created */
  Created = "Created",
  /** Publishing */
  Publishing = "Publishing",
  /** Published */
  Published = "Published",
}

/**
 * Defines values for ThemeVersionStatus. \
 * {@link KnownThemeVersionStatus} can be used interchangeably with ThemeVersionStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Created** \
 * **Publishing** \
 * **Published**
 */
export type ThemeVersionStatus = string;

/** Optional parameters. */
export interface AuthLoginOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the authLogin operation. */
export type AuthLoginResponse = UserDto;

/** Optional parameters. */
export interface AuthLogoutOptionalParams extends coreClient.OperationOptions {}

/** Optional parameters. */
export interface MediaFilesListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the mediaFilesList operation. */
export type MediaFilesListResponse = FileMeta[];

/** Optional parameters. */
export interface MediaUploadTokenGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the mediaUploadTokenGet operation. */
export type MediaUploadTokenGetResponse = BlobUploadToken;

/** Optional parameters. */
export interface ThemeSpecGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the themeSpecGet operation. */
export type ThemeSpecGetResponse = ThemeSpec;

/** Optional parameters. */
export interface ThemeDraftGetOptionalParams
  extends coreClient.OperationOptions {
  id?: string;
  search?: string;
}

/** Contains response data for the themeDraftGet operation. */
export type ThemeDraftGetResponse = ThemeVersionDto;

/** Optional parameters. */
export interface ThemeDraftCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the themeDraftCreate operation. */
export type ThemeDraftCreateResponse = ThemeVersionDto;

/** Optional parameters. */
export interface ThemeGetOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the themeGet operation. */
export type ThemeGetResponse = ThemeVersionDto;

/** Optional parameters. */
export interface ThemeListOptionalParams extends coreClient.OperationOptions {
  search?: string;
}

/** Contains response data for the themeList operation. */
export type ThemeListResponse = ThemeVersionDto[];

/** Optional parameters. */
export interface ThemeDraftSaveOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the themeDraftSave operation. */
export type ThemeDraftSaveResponse = ThemeVersionDto;

/** Optional parameters. */
export interface ThemePublishOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the themePublish operation. */
export type ThemePublishResponse = ThemeVersionDto;

/** Optional parameters. */
export interface DesignerOptionalParams
  extends coreClient.ServiceClientOptions {
  /** Overrides client endpoint. */
  endpoint?: string;
}
