import React, { createContext } from "react"
import { ContextValue, useContextProps } from "react-aria-components"
import { TablerIcon, IconProps as TablerIconsProps } from "@tabler/icons-react"
import * as tabler from "@tabler/icons-react"
import clsx from "clsx"

interface IconProps extends TablerIconsProps {
    children?: React.ReactElement
    icon?: TablerIcon | string
}

export const IconContext = createContext<ContextValue<IconProps, HTMLDivElement>>(null)

export const Icon = (props: IconProps) => {
    [props] = useContextProps(props, null, IconContext)
    const { children, icon } = props

    if (!icon && !children) throw new Error("Icon must have either an icon prop or children")

    //@ts-expect-error: Dynamic module access
    const ChildIcon = (typeof icon == "string") ? tabler[`Icon${icon}`] : icon!

    return children ? React.cloneElement(children, {
        slot: 'icon',
        focusable: 'false',
        role: 'img',
        className: clsx(
            children.props.className,
            props.className)
    }) : <ChildIcon {...props} />
}