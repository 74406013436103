import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  LoginRequest as LoginRequestMapper,
  CreateThemeReqDto as CreateThemeReqDtoMapper,
  PublishReqDto as PublishReqDtoMapper,
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: LoginRequestMapper,
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const xHrSiteId: OperationParameter = {
  parameterPath: "xHrSiteId",
  mapper: {
    serializedName: "x-hr-site-id",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const filename: OperationURLParameter = {
  parameterPath: "filename",
  mapper: {
    constraints: {
      MinLength: 1,
    },
    serializedName: "filename",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const id: OperationQueryParameter = {
  parameterPath: ["options", "id"],
  mapper: {
    serializedName: "id",
    type: {
      name: "Uuid",
    },
  },
};

export const search: OperationQueryParameter = {
  parameterPath: ["options", "search"],
  mapper: {
    serializedName: "search",
    type: {
      name: "String",
    },
  },
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: CreateThemeReqDtoMapper,
};

export const id1: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const name: OperationURLParameter = {
  parameterPath: "name",
  mapper: {
    serializedName: "name",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper: PublishReqDtoMapper,
};
